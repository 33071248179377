<template>
  <div class="col-12 background-repoort">
    <br>
    <div class="row mt-5">
      <div class="col-md-12">
        <h1 class="card-title">
          <center><template v-if="Rapport"> RAPPORTS {{ Rapport.month_label }}/{{ Rapport.year }} </template></center>
        </h1>
        <card>
          <div class="col-md-12">
            <div class="row justify-content-center">
              <div class="col-lg-3">
                <base-input label="CLIENT">
                  <el-select filterable class="select-primary"
                    placeholder="CLIENT"
                    clearable
                    v-model="ClientsSelects.Client"
                    :disabled="$apollo.queries.raports_Mongustave.loading">
                    <el-option
                      v-for="option in ClientsSelects.ListClients"
                      class="select-primary"
                      :value="option.id_utilisateur"
                      :label="option.client.toUpperCase()"
                      :key="option.id_utilisateur">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3">
                <base-input label="PRODUIT">
                  <el-select filterable class="select-primary"
                    placeholder="PRODUIT"
                    clearable
                    v-model="ProduitSelects.Produit"
                    :disabled="$apollo.queries.raports_Mongustave.loading">
                    <el-option
                      v-for="option in ProduitSelects.ListProduit"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-3">
                <base-input label="PACK">
                  <el-select filterable class="select-primary"
                    placeholder="PACK"
                    clearable
                    v-model="PackSelects.Pack"
                    :disabled="$apollo.queries.raports_Mongustave.loading">
                    <el-option
                      v-for="option in PackSelects.ListPack"
                      class="select-primary"
                      :value="option"
                      :label="option.toUpperCase()"
                      :key="option">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

            </div>

            <div class="col-md-12 float-right">
              <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                <base-button
                  v-if="$apollo.queries.raports_Mongustave.loading"
                  loading
                  round
                  class="btn btn-round btn-just-icon btn-primary mb-2"
                >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                <button
                  v-else
                  @click="Reset()"
                  class="btn btn-just-icon btn-primary mb-2"
                >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
              </div>
            </div>
            <div class="col-md-12 float-right">
              <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                <base-button
                  :disabled="$apollo.queries.raports_Mongustave.loading"
                  round
                  class="btn btn-round btn-just-icon btn-coreg mb-2"
                  @click.native="showAddRapport = true"
                >AJOUTER UNE LIGNE</base-button>
              </div>
            </div>
          </div>
        </card>
      </div>

      <div class="card" v-for="(rapprot,index) in ListRapports_Filter" :key="index">
        <span slot="header" class="badge-pill badge-mongustave tab-title" style="text-transform: uppercase;font-weight: bold;">
          <center>{{ rapprot[0].client }}</center>
        </span>
        <div class="card-body">
          <!--  -->
          <el-table :data="rapprot" show-summary :summary-method="getSummaries" row-class-name="booking-table-rows">
            <el-table-column
              prop='produit'
              label="produit"
              sortable
              min-width="140"
              show-overflow-tooltip
            >
              <template scope="scope">
                {{ ProduitSelects.ListProduit.find(t => t.value === scope.row.produit).label }}
              </template>
            </el-table-column>
            <el-table-column
              prop='type'
              label="Pack"
              sortable
              min-width="140"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop='volume'
              label="Volume"
              sortable
              min-width="140"
              show-overflow-tooltip
            >
              <template scope="scope">
                <base-input
                  :value="scope.row.volume"
                  type="number"
                  @keyup.enter.native="changeVolume($event, scope.row)"
                  :disabled="disable_edit"
                ></base-input>
              </template>
            </el-table-column>
            <el-table-column
              prop='prix_lead'
              label="Prix U HT"
              sortable
              min-width="140"
              show-overflow-tooltip
            >
              <template scope="scope">
                <base-input
                  :value="scope.row.prix_lead"
                  type="number"
                  @keyup.enter.native="changePrix($event, scope.row)"
                  :disabled="disable_edit"
                ></base-input>
              </template>
            </el-table-column>
            <el-table-column
              prop=''
              label="Total HT"
              sortable
              min-width="140"
              show-overflow-tooltip
              :sort-method="CustomSort_ca"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.prix_lead * scope.row.volume,true,2) + ' €' }}
              </template>
            </el-table-column>
            <el-table-column
              prop=''
              label="Total TTC"
              sortable
              min-width="140"
              show-overflow-tooltip
              :sort-method="CustomSort_ca"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.prix_lead * scope.row.volume,true,2) + ' €' }}
              </template>
            </el-table-column>
            <!-- <el-table-column
              prop=''
              label="Adresse de Facturation"
              sortable
              min-width="200"
              show-overflow-tooltip
            >
            </el-table-column> -->
            <el-table-column
              prop=''
              label="Action"
              sortable
              min-width="200"
              show-overflow-tooltip
            >
              <template scope="scope">
                <el-tooltip
                  content="Dupliquer"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    @click.native="DupliquerRow(scope.row)"
                    class="btn-link"
                    type="success"
                    size="sm"
                    icon
                  >
                    <i class="tim-icons icon-single-copy-04"></i>
                  </base-button>
                </el-tooltip>
                <el-tooltip
                  content="Supprimer"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    @click.native="DeleteRow(scope.row)"
                    class="btn-link"
                    type="danger"
                    size="sm"
                    icon
                    :disabled="disable_delete"
                  >
                    <i class="fas fa-trash-alt"></i>
                  </base-button>
                </el-tooltip>
                <!-- <el-tooltip
                  content="Email destinataires"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    @click="openMailRecipient(scope.row)"
                    class="btn-link"
                    type="market"
                    size="sm"
                    icon
                  >
                    <i class="fas fa-at"></i>
                  </base-button>
                </el-tooltip>

                <el-tooltip
                  content="PDF"
                  effect="light"
                  :open-delay="300"
                  placement="top"
                >
                  <base-button
                    @click.native="handleDownload(scope.row)"
                    class="btn-link"
                    type="success"
                    size="sm"
                    icon
                  >
                    <i class="el-icon-document"></i>
                  </base-button>
                </el-tooltip> -->
              </template>
            </el-table-column>

          </el-table>
        </div>
      </div>
    </div>
     <!-- Mondal Add Rapport -->
    <modal
      :show.sync="showAddRapport"
      body-classes="p-0"
      class="modal-listbase">
      <card type="secondary"
        header-classes="bg-white pb-6"
        body-classes="px-lg-6 py-lg-6"
        class="border-0 mb-0">

        <el-tooltip
          content="Quitter"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <base-button
            @click.native="showAddRapport = false"
            class="btn-link float-right"
            type="danger"
            size="sm"
            icon
          >
            <i class="tim-icons icon-simple-remove icon-large"></i>
          </base-button>
        </el-tooltip>

        <br>
        <br>

        <div class="form-row">

          <base-input label="CLIENT" class="col-md-6" :error="getError('Client')">
            <el-select filterable class="select-primary"
              placeholder="CLIENT"
              clearable
              name="Client"
              v-validate="'required'"
              v-model="ClientsSelects.Client_add">
              <el-option
                v-for="option in ClientsSelects.ListClients"
                class="select-primary text-uppercase"
                :value="option.id_utilisateur"
                :label="option.client"
                :key="option.id_utilisateur">
              </el-option>
            </el-select>
          </base-input>

          <base-input label="Produit" class="col-md-6" :error="getError('Produit')">
            <el-select filterable class="select-primary"
              placeholder="PRODUIT"
              clearable
              name="Produit"
              v-validate="'required'"
              v-model="ProduitSelects.Produit_add">
              <el-option
                v-for="option in ProduitSelects.ListProduit"
                class="select-primary text-uppercase"
                :value="option.value"
                :label="option.label"
                :key="option.value">
              </el-option>
            </el-select>
          </base-input>

        </div>

        <div class="form-row">

          <base-input label="Pack" class="col-md-6" :error="getError('Pack')">
            <el-select filterable class="select-primary"
              placeholder="PACK"
              clearable
              name="Pack"
              v-validate="'required'"
              v-model="PackSelects.Pack_add">
              <el-option
                v-for="option in PackSelects.ListPack"
                class="select-primary text-uppercase"
                :value="option"
                :label="option"
                :key="option">
              </el-option>
            </el-select>
          </base-input>

          <base-input label="Volume" placeholder="VOLUME"
            class="col-md-6"
            name="Volume"
            v-model="Volume_add"
            :error="getError('Volume')"
            type="number"
            v-validate="'required'"
          >
          </base-input>

        </div>

        <div class="form-row">
          <base-input label="Prix U HT" placeholder="PRIX U HT"
            class="col-md-6"
            name="Prix U HT"
            v-model="prixLead_add"
            :error="getError('Prix U HT')"
            type="number"
            v-validate="'required'"
          >
          </base-input>

        </div>

        <center>
          <base-button
            v-if="!loaderAddRepport"
            type="info"
            @click="AddRepport"
            >{{ $t("buttons.BtnAdd").toUpperCase() }}
          </base-button>
          <base-button
            v-else
            type="info"
            loading
            >
          </base-button>
        </center>
      </card>
    </modal>
    <!-- END MODAL ADD RAPPORT -->
  </div>
</template>

<script>
  import { Select, Option } from 'element-ui';
  import { Table, TableColumn } from 'element-ui';
  import gql from 'graphql-tag'
  import swal from 'sweetalert2'
  import { Modal } from 'src/components';


export default {
  computed: {
    ListRapports_Filter() {
      let list = null
      try {
        list = this.raports_Mongustave.filter(rapport => {
          return (rapport.id_utilisateur+"").match(this.ClientsSelects.Client) &&
                 (rapport.produit+"").match(this.ProduitSelects.Produit) &&
                 (rapport.type+"").match(this.PackSelects.Pack)
        })
        return this.structData(list)
      } catch (error) {
        console.log(error)
        return []
      }

    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Modal
  },
  apollo: {
    raports_Mongustave: {
      client: 'MARKET',
      query: gql` query ($month: Int!, $year: Int!) {
        raports_Mongustave(month: $month, year: $year) {
          id_rapport
          id_utilisateur
          id_client
          client
          produit
          type
          volume
          prix_lead
          mois
          annee
          date_creation
          etat
          email_destinataires
          mail_envoye
        }
      }`,
      variables () {
        return {
          month: this.Rapport.mounth,
          year: this.Rapport.year
        }
      },
      skip() {
        return this.SkipQuery;
      },
      fetchPolicy: 'network-only',
      result({ data, loader, networkStatus }) {
        this.structData(data.raports_Mongustave)
      }
    }
  },
  data() {
    return {
      ClientsSelects: {
        Client: '',
        Client_add: '',
        ListClients: []
      },
      ProduitSelects: {
        Produit: '',
        Produit_add: '',
        ListProduit: [
          { value: 1, label: "SANTE" },
          { value: 2, label: "ANIMAUX" },
          { value: 3, label: "MRH" },
          { value: 4, label: "AUTO" },
          { value: 5, label: "EMPRUNTEUR" },
          { value: 6, label: "CRÉDIT CONSO" }
        ]
      },
      Volume_add: null,
      prixLead_add: null,
      PackSelects: {
        Pack: '',
        Pack_add: '',
        ListPack: ["devalidation", "salaries", "seniors", "standard", "tns"]
      },
      Rapports: [],
      showAddRapport: false,
      loaderAddRepport: false,
      Rapport: null,
      SkipQuery: true,
      raports_Mongustave: [],
      disable_edit: false,
      disable_delete: false
    }
  },
  methods: {
    CustomSort_ca(a, b) {
      return (a.prix_lead * a.volume) - (b.prix_lead * b.volume)
    },
    Reset() {
      this.ClientsSelects.Client =
      this.ProduitSelects.Produit =
      this.PackSelects.Pack = ''
    },
    structData(data) {
      let data_result = []
      let result = data.map(a => a.id_utilisateur);
      let ids = [...new Set(result)]
      let filtre_client = []

      ids.forEach(id => {
        if(id != null){
          data_result.push(data.filter(x => x.id_utilisateur==id))
          filtre_client.push(data.find(x => x.id_utilisateur==id))
        }
      });

      this.Rapports = data_result
      this.ClientsSelects.ListClients = filtre_client
      // filtre client

      return data_result
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    changeVolume(e, row) {
      let newvalue = e.target.value;

      if(newvalue == '') {
        return
      }
      newvalue = parseInt(newvalue)
      console.log(newvalue)

      swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Vous êtes en train de modifier le Volume!",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'Modifier',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          this.disable_edit = true
          this.$apollo.mutate({
            client: 'MARKET',
            mutation: gql`mutation ($rapport_id: ID!, $volume: Float!) {
              changeVolumeMongustave(rapport_id: $rapport_id, volume: $volume)
            }`,
            variables: {
              rapport_id: row.id_rapport,
              volume: newvalue
            },
          }).then(response => {
            if (response.data.changeVolumeMongustave) {
              this.$notify({type: 'success', message: "Volume a été bien modifié." })
              row.volume = newvalue
            }
            else {
              this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            }
            this.disable_edit = false
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            this.disable_edit = false
          })

        }
      })

    },
    changePrix(e, row) {
      let newvalue = e.target.value;

      if(newvalue == '') {
        return
      }
      newvalue = parseFloat(newvalue)

      swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Vous êtes en train de modifier le Prix U HT!",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'Modifier',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          this.disable_edit = true
          this.$apollo.mutate({
            client: 'MARKET',
            mutation: gql`mutation ($rapport_id: ID!, $prix_lead: Float!) {
              changeLeadPriceMongustave(rapport_id: $rapport_id, prix_lead: $prix_lead)
            }`,
            variables: {
              rapport_id: row.id_rapport,
              prix_lead: newvalue
            },
          }).then(response => {
            if (response.data.changeLeadPriceMongustave) {
              this.$notify({type: 'success', message: "Prix U HT a été bien modifié." })
              row.prix_lead = newvalue
            }
            else {
              this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            }
            this.disable_edit = false
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            this.disable_edit = false
          })
        }
      })

    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      let sum_volume = 0
      let sum_ht = 0
      let sum_ttc = 0
      data.forEach(element => {
        sum_volume += element.volume
        sum_ht += element.prix_lead * element.volume
        sum_ttc += element.prix_lead * element.volume
      });
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '';
          return;
        }
        else if( index === 2) {
          sums[index] = this.formatThounsends(sum_volume);
          return;
        }
        else if( index === 4) {
          sums[index] = this.formatThounsends(sum_ht,true,2) + ' €';
          return;
        }
        else if( index === 5) {
          sums[index] = this.formatThounsends(sum_ttc,true,2) + ' €';
          return;
        }
        else {
          sums[index] = '';
          return;
        }
      });
      return sums;
    },
    getError(fieldName) {
      return this.errors.first(fieldName)
    },
    async AddRepport() {
      let validate
      await this.$validator.validateAll().then(isValid => {
        validate = isValid
      })
      if (!validate)
        return

      this.loaderAddRepport = true

      this.$apollo.mutate({
        client: 'MARKET',
        mutation: gql`mutation ($id_utilisateur: ID!,$client: String!,$produit: Int!,$type: String!,$prix_lead: Float!,$volume: Int!,$mois: Int!,  $annee: Int!) {
          ajouteFactureMongustave(id_utilisateur:$id_utilisateur, client:$client, produit:$produit, type:$type, prix_lead: $prix_lead,volume:$volume, mois:$mois, annee:$annee)
        }`,
        variables: {
          id_utilisateur: this.ClientsSelects.Client_add,
          client: this.ClientsSelects.ListClients.find(x => x.id_utilisateur == this.ClientsSelects.Client_add).client,
          produit: this.ProduitSelects.Produit_add,
          type: this.PackSelects.Pack_add,
          prix_lead: this.prixLead_add,
          volume: this.Volume_add,
          mois: this.Rapport.mounth,
          annee: this.Rapport.year,
        },
      }).then(response => {
        if (response.data.ajouteFactureMongustave) {
          this.$apollo.queries.raports_Mongustave.refetch({
            month: this.Rapport.mounth,
            year: this.Rapport.year
          })
          this.$notify({type: 'success', message: "ligne a été bien ajouté." })
        }
        else {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        }
        this.loaderAddRepport = false
        this.clearDataModal()
        this.showAddRapport = false
      }).catch(error => {
        this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        this.loaderAddRepport = false
      })

    },
    DeleteRow(row) {
      swal.fire({
        title: 'Êtes-vous sûr?',
        text: "",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'Supprimer',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          this.disable_delete = true
          this.$apollo.mutate({
            client: 'MARKET',
            mutation: gql`mutation ($rapport_id: ID!) {
              supprimeFactureMongustave(rapport_id: $rapport_id)
            }`,
            variables: {
              rapport_id: row.id_rapport
            },
          }).then(response => {
            if (response.data.supprimeFactureMongustave) {
              this.$apollo.queries.raports_Mongustave.refetch({
                month: this.Rapport.mounth,
                year: this.Rapport.year
              })
              this.$notify({type: 'success', message: "ligne a été bien supprimé." })
            }
            else {
              this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            }
            this.disable_delete = false
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
            this.disable_delete = false
          })
        }
      })
    },
    clearDataModal() {
      this.ClientsSelects.Client_add = ''
      this.ProduitSelects.Produit_add = ''
      this.PackSelects.Pack_add = ''
      this.prixLead_add = null
      this.Volume_add = null
    },
    DupliquerRow(row) {
      this.ClientsSelects.Client_add = row.id_utilisateur
      this.ProduitSelects.Produit_add = row.produit
      this.PackSelects.Pack_add = row.type
      this.prixLead_add = row.prix_lead
      this.Volume_add = row.volume

      this.showAddRapport = true
    },
  },
  created() {
    if (localStorage.getItem("darkMode") === 'true') {
      localStorage.setItem("darkMode", 'false')
      window.location.reload()
    }

    if((this.Rapport = JSON.parse(localStorage.getItem("rapport"))) == null) {
      this.$router.push({ name: "Globale Reports" })
      return
    }
    this.SkipQuery = false
  },
}
</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
</style>
