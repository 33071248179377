<template>
  <card>
    <div class="row justify-content-center">
      <div class="col-md-10">
        <div class="row">
          <div class="col-sm-5">
            <base-input label="Langue *" :error="getError('langue')">
              <el-select class="select-primary" placeholder="LANGUE" name="langue" v-validate="'required'"
                v-model="oi.langue">
                <el-option v-for="option in langues" class="select-primary" :value="option.value" :label="option.name"
                  :key="option.name">
                </el-option>
              </el-select>
            </base-input>
          </div>

          <div class="col-sm-5">
            <base-input v-model="dateFR" type="text" placeholder="DATE DE CRÉATION DE L'OI"
              label="DATE DE CRÉATION DE L'OI" required disabled></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-5">
            <base-input v-model="oi.nom" label="NOM" type="text" placeholder="NOM" name="nom" v-validate="'required'"
              :error="getError('nom')" required>
            </base-input>
          </div>
          <div class="col-sm-5">
            <base-input v-model="oi.prenom" label="Prénom" type="text" placeholder="PRÉNOM" name="prenom"
              v-validate="'required'" :error="getError('prenom')" required>
            </base-input>
          </div>
          <div class="col-sm-5">
            <base-input label="ADRESSE" :error="getError('adresse')" required>
              <textarea v-model="oi.adresse" name="adresse" v-validate="'required'" class="form-control"
                rows="3"></textarea>
            </base-input>
          </div>
          <div class="col-sm-5">
            <base-input label="COMPLÉMENT D'ADRESSE">
              <textarea v-model="oi.complement_adresse" class="form-control" rows="3"></textarea>
            </base-input>
          </div>
          <div class="col-sm-5">
            <base-input v-model="oi.nom_societe" label="NOM DE LA SOCIETÉ" type="text" placeholder="NOM DE LA SOCIETÉ"
              name="nom de la Societé" v-validate="'required'" :error="getError('nom de la Societé')" required>
            </base-input>
          </div>
          <div class="col-sm-5">
            <base-input v-model="oi.pays" label="PAYS" type="text" placeholder="PAYS" name="pays"
              v-validate="'required'" :error="getError('pays')" required>
            </base-input>
          </div>
          <div class="col-sm-5">
            <base-input v-model="oi.ville" label="VILLE" type="text" placeholder="VILLE" name="ville"
              v-validate="'required'" :error="getError('ville')" required>
            </base-input>
          </div>
          <div class="col-sm-5">
            <base-input v-model="oi.code_postal" label="CODE POSTALE" type="text" placeholder="CODE POSTALE"
              name="code postale" v-validate="'required'" :error="getError('code postale')" required>
            </base-input>
          </div>

          <div class="col-sm-5">
            <base-input v-model="oi.nom_payeur" label="NOM DU PAYEUR" type="text" placeholder="NOM DU PAYEUR">
            </base-input>
          </div>
          <div class="col-sm-5">
            <base-input v-model="oi.prenom_payeur" label="PRÉNOM DU PAYEUR" type="text" placeholder="PRÉNOM DU PAYEUR">
            </base-input>
          </div>
          <div class="col-sm-5">
            <base-input v-model="oi.fonction_payeur" label="FONCTION DU PAYEUR" type="email"
              placeholder="FONCTION DU PAYEUR"></base-input>
          </div>

          <div class="col-sm-5">
            <base-input v-model="oi.email_payeur" label="EMAIL DU PAYEUR" type="text" placeholder="EMAIL DU PAYEUR">
            </base-input>
          </div>
          <div class="col-sm-5">
            <base-input v-model="oi.tel_payeur" label="TÉL DU PAYEUR" type="text" placeholder="TÉL DU PAYEUR">
            </base-input>
          </div>
          <div class="col-sm-5">
            <base-input v-model="oi.num_tva_or_vat" label="N° DE TVA OU VAT NUMBER" type="text"
              placeholder="N° DE TVA OU VAT NUMBER"></base-input>
          </div>
        </div>
      </div>
      
      <div class="col-md-12">
        <center>
          <div
            class="btn-group btn-group-toggle"
            data-toggle="buttons"
          >
            <label :disabled="!$route.params.id_annonceur" class="btn btn-sm btn-info btn-simple" :class="{ active: ShowPerfForm }">
              <input
                type="radio"
                @click="ShowPerfForm = !ShowPerfForm"
                name="options"
                autocomplete="off"
              />
              <span class="d-none d-sm-block" style="width: 150px;"> <img src="/img/weedo-it-logo-blanc.png" alt="" /> Weedo Perf</span>
              <span class="d-block d-sm-none">
                <img src="/img/weedo-it-logo-blanc.png" alt="" />
              </span>
            </label>
          </div>
        </center>
      </div>

      <div class="col-md-12" >
        <template v-if="ShowPerfForm">
          <div class="col-md-12">
            <card>
              <div class="col-sm-5">
                <base-input label="NOM DE LA CAMPAGNE" :error="getError('campagne')" required>
                  <el-select allow-create default-first-option filterable multiple class="select-primary"
                    placeholder="NOM DE LA CAMPAGNE" name="campagne" v-validate="'required'"
                    v-model="campagnesList.campagne">
                    <el-option v-for="option in campagnesList.campagnes" class="select-primary"
                      :value="option.id_programme + '---' + option.Titre" :label="option.Titre" :key="option.Titre">
                    </el-option>
                  </el-select>
                </base-input>
              </div><br>

              <div class="row">
                <label>SYSTÈME DE RÉMUNÉRATION :</label>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <base-checkbox inline class="mb-5 col-sm-1" v-model="showCPL">CPL </base-checkbox>
                  <template v-if="showCPL">
                    <base-input class="col-sm-3" label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="oi.prix_unitaire_CPL"></base-input>
                    <base-input class="col-sm-3" label="VOLUME" type="number" placeholder="VOLUME"
                      v-model="oi.volume_CPL" :disabled="oi.volume_CPL_illimite"></base-input>
                    <base-checkbox inline class="mb-2" v-model="oi.volume_CPL_illimite">ILLIMITÉ </base-checkbox>
                    <base-input class="col-sm-3" label="MONTANT TOTAL" type="number" placeholder="MONTANT TOTAL"
                      v-model="oi.montant_total_CPL" disabled></base-input>
                  </template>
                </div>

                <div class="row">
                  <base-checkbox inline class="mb-5 col-sm-1" v-model="showCPC">CPC </base-checkbox>
                  <template v-if="showCPC">
                    <base-input class="col-sm-3" label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="oi.prix_unitaire_CPC"></base-input>
                    <base-input class="col-sm-3" label="VOLUME" type="number" placeholder="VOLUME"
                      v-model="oi.volume_CPC" :disabled="oi.volume_CPC_illimite"></base-input>
                    <base-checkbox inline class="mb-2" v-if="showCPC" v-model="oi.volume_CPC_illimite">ILLIMITÉ
                    </base-checkbox>
                    <base-input class="col-sm-3" label="MONTANT TOTAL" type="number" placeholder="MONTANT TOTAL"
                      v-model="oi.montant_total_CPC" disabled></base-input>
                  </template>
                </div>

                <div class="row">
                  <base-checkbox inline class="mb-5 col-sm-1" v-model="showCPAFixe">CPA fixe </base-checkbox>
                  <template v-if="showCPAFixe">
                    <base-input class="col-sm-3" label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="oi.prix_unitaire_CPA_fixe" value="CPA fixe"></base-input>
                    <base-input class="col-sm-3" label="VOLUME" type="number" placeholder="VOLUME"
                      v-model="oi.volume_CPA_fixe" :disabled="oi.volume_CPA_fixe_illimite"></base-input>
                    <base-checkbox inline class="mb-2" v-model="oi.volume_CPA_fixe_illimite">ILLIMITÉ
                    </base-checkbox>
                    <base-input class="col-sm-3" label="MONTANT TOTAL" type="number" placeholder="MONTANT TOTAL"
                      v-model="oi.montant_total_CPA_fixe" disabled></base-input>
                  </template>
                </div>

                <div class="row">
                  <base-checkbox inline class="mb-5 col-sm-1" v-model="showCPAPourcentage">CPA % </base-checkbox>
                  <template v-if="showCPAPourcentage">
                    <base-input class="col-sm-3" label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="oi.prix_unitaire_CPA_pourcentage" value="CPA pourcentage"></base-input>
                    <base-input class="col-sm-3" label="VOLUME" type="number" placeholder="VOLUME"
                      v-model="oi.volume_CPA_pourcentage" :disabled="oi.volume_CPA_pourcentage_illimite">
                    </base-input>
                    <base-checkbox inline class="mb-2" v-model="oi.volume_CPA_pourcentage_illimite">
                      ILLIMITÉ </base-checkbox>
                    <base-input class="col-sm-3" label="MONTANT TOTAL" type="number" placeholder="MONTANT TOTAL"
                      v-model="oi.montant_total_CPA_pourcentage" disabled></base-input>
                  </template>
                </div>

                <div class="row">
                  <base-checkbox inline class="mb-5 col-sm-1" v-model="showCPM">CPM </base-checkbox>
                  <div class="col-sm-3" v-if="showCPM">
                    <base-input label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="oi.prix_unitaire_CPM" value="CPM"></base-input>
                  </div>
                  <div class="col-sm-3" v-if="showCPM">
                    <base-input label="VOLUME" type="number" placeholder="VOLUME" v-model="oi.volume_CPM" :disabled="oi.volume_CPM_illimite">
                    </base-input>
                  </div>
                  <base-checkbox inline class="mb-2" v-if="showCPM" v-model="oi.volume_CPM_illimite">ILLIMITÉ
                  </base-checkbox>
                  <div class="col-sm-3" v-if="showCPM">
                    <base-input label="MONTANT TOTAL" type="number" placeholder="MONTANT TOTAL"
                      v-model="oi.montant_total_CPM" disabled></base-input>
                  </div>
                </div>

                <div class="row">
                  <base-checkbox inline class="mb-5 col-sm-1" v-model="showCPD">CPD </base-checkbox>
                  <div class="col-sm-3" v-if="showCPD">
                    <base-input label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="oi.prix_unitaire_CPD" value="CPD"></base-input>
                  </div>
                  <div class="col-sm-3" v-if="showCPD">
                    <base-input label="VOLUME" type="number" placeholder="VOLUME" v-model="oi.volume_CPD" :disabled="oi.volume_CPD_illimite">
                    </base-input>
                  </div>
                  <base-checkbox inline class="mb-2" v-if="showCPD" v-model="oi.volume_CPD_illimite">ILLIMITÉ
                  </base-checkbox>
                  <div class="col-sm-3" v-if="showCPD">
                    <base-input label="MONTANT TOTAL" type="number" placeholder="MONTANT TOTAL"
                      v-model="oi.montant_total_CPD" disabled></base-input>
                  </div>
                </div>

                <div class="row">
                  <base-checkbox inline class="mb-5 col-sm-1" v-model="showCliclead">Cliclead </base-checkbox>
                  <div class="col-sm-3" v-if="showCliclead">
                    <base-input label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="oi.prix_unitaire_Cliclead" value="Cliclead"></base-input>
                  </div>
                  <div class="col-sm-3" v-if="showCliclead">
                    <base-input label="VOLUME" type="number" placeholder="VOLUME" v-model="oi.volume_Cliclead" :disabled="oi.volume_Cliclead_illimite">
                    </base-input>
                  </div>
                  <base-checkbox inline class="mb-2" v-if="showCliclead" v-model="oi.volume_Cliclead_illimite">
                    ILLIMITÉ
                  </base-checkbox>
                  <div class="col-sm-3" v-if="showCliclead">
                    <base-input label="MONTANT TOTAL" type="number" placeholder="MONTANT TOTAL"
                      v-model="oi.montant_total_Cliclead" disabled></base-input>
                  </div>
                </div>

                <div class="row">
                  <base-checkbox inline class="mb-5 col-sm-1" v-model="showCPV">CPV </base-checkbox>
                  <div class="col-sm-3" v-if="showCPV">
                    <base-input label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="oi.prix_unitaire_CPV" value="CPV"></base-input>
                  </div>
                  <div class="col-sm-3" v-if="showCPV">
                    <base-input label="VOLUME" type="number" placeholder="VOLUME" v-model="oi.volume_CPV" :disabled="oi.volume_CPV_illimite">
                    </base-input>
                  </div>
                  <base-checkbox inline class="mb-2" v-if="showCPV" v-model="oi.volume_CPV_illimite">ILLIMITÉ
                  </base-checkbox>
                  <div class="col-sm-3" v-if="showCPV">
                    <base-input label="MONTANT TOTAL" type="number" placeholder="MONTANT TOTAL"
                      v-model="oi.montant_total_CPV" disabled></base-input>
                  </div>
                </div>

                <div class="row">
                  <base-checkbox inline class="mb-5 col-sm-1" v-model="showCPI">CPI </base-checkbox>
                  <div class="col-sm-3" v-if="showCPI">
                    <base-input label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="oi.prix_unitaire_CPI" value="CPI"></base-input>
                  </div>
                  <div class="col-sm-3" v-if="showCPI">
                    <base-input label="VOLUME" type="number" placeholder="VOLUME" v-model="oi.volume_CPI" :disabled="oi.volume_CPI_illimite">
                    </base-input>
                  </div>
                  <base-checkbox inline class="mb-2" v-if="showCPI" v-model="oi.volume_CPI_illimite">ILLIMITÉ
                  </base-checkbox>
                  <div class="col-sm-3" v-if="showCPI">
                    <base-input label="MONTANT TOTAL" type="number" placeholder="MONTANT TOTAL"
                      v-model="oi.montant_total_CPI" disabled></base-input>
                  </div>
                </div>

                <div class="row">
                  <base-checkbox inline class="mb-5 col-sm-1" v-model="showNumMobile">Mobile </base-checkbox>
                  <div class="col-sm-3" v-if="showNumMobile">
                    <base-input label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="oi.prix_unitaire_NUMERO_MOBILE" value="NUMERO_MOBILE"></base-input>
                  </div>
                  <div class="col-sm-3" v-if="showNumMobile">
                    <base-input label="VOLUME" type="number" placeholder="VOLUME" v-model="oi.volume_NUMERO_MOBILE" :disabled="oi.volume_NUMERO_MOBILE_illimite">
                    </base-input>
                  </div>
                  <base-checkbox inline class="mb-4" v-if="showNumMobile"
                    v-model="oi.volume_NUMERO_MOBILE_illimite">ILLIMITÉ
                  </base-checkbox>
                  <div class="col-sm-3" v-if="showNumMobile">
                    <base-input label="MONTANT TOTAL" type="number" placeholder="MONTANT TOTAL"
                      v-model="oi.montant_total_NUMERO_MOBILE" disabled></base-input>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-md-12">
            <card>
              <div class="row">
                <label>LEVIERS :</label>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <base-checkbox v-for="levier in leviers" inline class="mb-3 col-md-2" v-bind:key="levier.id"
                    v-model="levier.checked" v-bind:id="levier.id">{{levier.name}}</base-checkbox><br>
                  <base-checkbox inline class="mb-3 col-md-2" v-model="showAutreLevier">Autres </base-checkbox><br>
                  <base-input type="text" v-if="showAutreLevier" v-model="oi.autre"></base-input>
                </div>
              </div>
            </card>
          </div>
          <div class="col-md-12">
            <card>
              <div class="row">
                <label>INCENTIVE :</label>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <base-checkbox v-for="incentive in incentives" v-bind:key="incentive.id" v-bind:id="incentive.id"
                    inline class="mb-3" v-model="incentive.checked" :value="incentive.value">{{ incentive.name }}
                  </base-checkbox>
                </div>
              </div>
            </card>
          </div>
        </template>
      </div>

      <div class="col-md-12">
        <center>
          <div
            class="btn-group btn-group-toggle"
            data-toggle="buttons"
          >
            <label class="btn btn-sm btn-coreg btn-simple" :class="{ active: ShowCoregForm }">
              <input
                type="radio"
                @click="ShowCoregForm = !ShowCoregForm"
                name="options"
                autocomplete="off"
              />
              <span class="d-none d-sm-block" style="width: 150px;"> <img src="/img/weedo-it-logo-blanc.png" alt="" /> Weedo Coreg</span>
              <span class="d-block d-sm-none">
                <img src="/img/weedo-it-logo-blanc.png" alt="" />
              </span>
            </label>
          </div>
        </center>
      </div>

      <div class="col-md-12" >  
        <template v-if="ShowCoregForm">
          <div class="col-md-12">
            <card>
              <base-input label="TYPE">
                <div class="col-sm-12">
                  <base-radio inline name="coreg" class="mb-3" v-model="OiCoreg.Type">
                    COREG
                  </base-radio>
                  <base-radio inline name="sponso" class="mb-3" v-model="OiCoreg.Type">
                    SPONSO
                  </base-radio>
                </div>
              </base-input>
            </card>
          </div>
          <div class="col-md-12">
            <card>
              <div class="row">
                <base-input 
                  label="CAMPAGNE" 
                  placeholder="CAMPAGNE" 
                  v-model="OiCoreg.campagne" 
                  class="col-md-6"
                ></base-input>
                <base-input 
                  label="COÛT LEAD" 
                  placeholder="COÛT LEAD" 
                  type="number" 
                  v-model="OiCoreg.prix_unitaire_CPL"
                  class="col-md-6"
                ></base-input>
              </div>
              <div class="row">
                <base-input 
                  label="VOLUME" 
                  placeholder="VOLUME" 
                  type="number" 
                  v-model="OiCoreg.volume"
                  class="col-md-6"
                ></base-input>
                <!-- <base-input 
                  label="MONTANT TOTAL" 
                  placeholder="MONTANT TOTAL" 
                  type="number" 
                  :value="SUMtotalCoreg"
                  class="col-md-6"
                ></base-input> -->
                <base-input 
                  label="CAPPING" 
                  placeholder="CAPPING" 
                  v-model="OiCoreg.capping"
                  class="col-md-6"
                ></base-input>
              </div>
              <div class="row">
                <base-input 
                  label="MENTIONS À AJOUTER" 
                  placeholder="MENTIONS À AJOUTER" 
                  v-model="OiCoreg.mentions"
                  class="col-md-6"
                ></base-input>
              </div>
              <base-input label="Champs">
                <div class="col-sm-12">
                  <base-checkbox v-for="Champs in ChampsCoeg" inline class="mb-2 col-md-2" v-bind:key="Champs.id"
                    v-model="Champs.checked" v-bind:id="Champs.id">{{Champs.value}}
                  </base-checkbox>
                </div>
              </base-input>
              <base-input label="FILTRES">
                <div class="col-md-12">
                  <div class="row">
                    <base-input 
                      label="AGE" 
                      placeholder="AGE" 
                      v-model="OiCoreg.filtre_age"
                      class="col-md-6"
                    ></base-input>
                    <base-input 
                      label="SEXE" 
                      placeholder="SEXE" 
                      v-model="OiCoreg.filtre_sexe"
                      class="col-md-6"
                    ></base-input>
                  </div>
                  <div class="row">
                    <base-input 
                      label="GÉOLOCALISATION" 
                      placeholder="GÉOLOCALISATION" 
                      v-model="OiCoreg.filtre_geo"
                      class="col-md-6"
                    ></base-input>
                    <base-input 
                      label="AUTRE" 
                      placeholder="AUTRE" 
                      v-model="OiCoreg.filtre_autre"
                      class="col-md-6"
                    ></base-input>
                  </div>
                </div>
              </base-input>

              <base-input label="QUESTIONS QUALIFIANTES">
                <div class="col-sm-12">
                  <div class="row justify-content-center">
                    <base-radio inline name="true" class="mb-3" v-model="OiCoreg.qq">
                      OUI
                    </base-radio>
                    <base-radio inline name="false" class="mb-3" v-model="OiCoreg.qq">
                      NON
                    </base-radio>
                  </div>
                  <div class="row justify-content-center">
                    <base-input v-if="OiCoreg.qq=='true'" class="col-sm-7" v-model="FirstQuestion"></base-input>
                    <base-button
                      v-if="OiCoreg.qq=='true'"
                      @click.native="Questions.push('')"
                      class="col-sm-1 edit btn-link"
                      type="success"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-simple-add"></i>
                    </base-button>
                    <template v-for="(Q, index) in Questions">
                      <base-input v-if="OiCoreg.qq=='true'" class="col-sm-7" v-model="Questions[index]" :key="Q.id"></base-input>
                      <base-button
                        :key="Q.id"
                        v-if="OiCoreg.qq=='true'"
                        @click.native="Questions.shift()"
                        class="col-sm-1 edit btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-simple-remove"></i>
                      </base-button>
                    </template>
                  </div>
                </div>
              </base-input>
              <div class="row">
                <base-input 
                  label="DATE LIMITE" 
                  v-model="OiCoreg.date_limite" 
                  placeholder="DATE LIMITE"
                  class="col-md-6"
                ></base-input>
                <base-input label="WELCOME MAIL" class="col-md-6">
                  <div class="col-sm-12">
                    <base-radio inline name="true" class="mb-3" v-model="OiCoreg.welcome_mail">
                      OUI
                    </base-radio>
                    <base-radio inline name="false" class="mb-3" v-model="OiCoreg.welcome_mail">
                      NON
                    </base-radio>
                  </div>
                </base-input>
              </div>
              <div class="row">
                <base-input 
                  label="TAUX REJET MAXIMUM" 
                  v-model="OiCoreg.taux_rejet_max" 
                  placeholder="TAUX REJET MAXIMUM"
                  class="col-md-6"
                ></base-input>
                <base-input label="INCENTIVE" class="col-md-6">
                  <div class="col-sm-12">
                    <base-radio inline name="true" class="mb-3" v-model="OiCoreg.incentive">
                      OUI
                    </base-radio>
                    <base-radio inline name="false" class="mb-3" v-model="OiCoreg.incentive">
                      NON
                    </base-radio>
                  </div>
                </base-input>
              </div>
              <base-input label="LIVRAISON DES CONTACTS">
                <div class="col-sm-12">
                  <base-radio  name="webservice" class="mb-3" v-model="OiCoreg.livraison_contact">
                    WEBSERVICE
                  </base-radio>
                  <base-radio  name="telechargement_csv" class="mb-3" v-model="OiCoreg.livraison_contact">
                    TÉLÉCHARGEMENT DE FICHIER CSV
                  </base-radio>
                  <base-radio  name="livraison_ftp" class="mb-3" v-model="OiCoreg.livraison_contact">
                    Livraison de fichier sur FTP
                  </base-radio>
                </div>
              </base-input>
            </card>
          </div>
        </template>
      </div>
      
      <div class="col-md-12">
        <center>
          <div
            class="btn-group btn-group-toggle"
            data-toggle="buttons"
          >
            <label class="btn btn-sm btn-market btn-simple" :class="{ active: ShowMarketForm }">
              <input
                type="radio"
                @click="ShowMarketForm = !ShowMarketForm"
                name="options"
                autocomplete="off"
              />
              <span class="d-none d-sm-block" style="width: 150px;"> <img src="/img/weedo-it-logo-blanc.png" alt="" /> Weedo Market</span>
              <span class="d-block d-sm-none">
                <img src="/img/weedo-it-logo-blanc.png" alt="" />
              </span>
            </label>
          </div>
        </center>
      </div>

      <div class="col-md-12">
        <template v-if="ShowMarketForm">
          <div class="col-md-12">
            <card>
              <base-input label="LEVIERS">
                <div class="col-sm-12">
                  <base-radio inline name="Comparateur Mon Gustave - Mise en relation" class="mb-3" v-model="OiMarket.Leviers">
                    Comparateur Mon Gustave - Mise en relation
                  </base-radio>
                  <base-radio inline name="Weedo Market - Leads" class="mb-3" v-model="OiMarket.Leviers">
                    Weedo Market - Leads
                  </base-radio>
                </div>
              </base-input>
            </card>
          </div>

          <div class="col-md-12">
            <card>
              <div class="row">
                <base-input 
                  label="NOM CAMPAGNE" 
                  placeholder="NOM CAMPAGNE" 
                  v-model="OiMarket.nom_campagne"
                  class="col-md-6"
                ></base-input>
                <base-input label="TYPE CAMPAGNE" class="col-md-6">
                  <el-select class="select-primary"
                    placeholder="TYPE CAMPAGNE"
                    v-model="OiMarket.type_campagne">
                    <el-option 
                      v-for="option in OiMarket.type_campagnes" 
                      class="select-primary"
                      :value="option.value" 
                      :label="option.label" 
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <template v-if="OiMarket.Leviers == 'Weedo Market - Leads' ">
                <div class="col-md-12">
                  <div class="row">
                    <base-checkbox inline class="mb-4 col-sm-1" v-model="ShowBRONZE">TRIO </base-checkbox>
                    <base-input v-if="ShowBRONZE" class="col-sm-3" label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="OiMarket.prix_unitaire_Bronze" ></base-input>
                    <base-input v-if="ShowBRONZE" class="col-sm-3" label="VOLUME" type="number" placeholder="VOLUME" 
                      v-model="OiMarket.volume_Bronze"></base-input>
                    <base-input v-if="ShowBRONZE" label="MONTANT TOTAL" class="col-sm-3" type="number" placeholder="MONTANT TOTAL"
                      v-model="OiMarket.montant_total_Bronze" disabled></base-input>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <base-checkbox inline class="mb-4 col-sm-1" v-model="ShowSILVER">DUO </base-checkbox>
                    <base-input v-if="ShowSILVER" class="col-sm-3" label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="OiMarket.prix_unitaire_Silver" ></base-input>
                    <base-input v-if="ShowSILVER" class="col-sm-3" label="VOLUME" type="number" placeholder="VOLUME" 
                      v-model="OiMarket.volume_Silver"></base-input>
                    <base-input v-if="ShowSILVER" label="MONTANT TOTAL" class="col-sm-3" type="number" placeholder="MONTANT TOTAL"
                      v-model="OiMarket.montant_total_Silver" disabled></base-input>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <base-checkbox inline class="mb-4 col-sm-1" v-model="ShowGOLD">EXCLUSIF </base-checkbox>
                    <base-input v-if="ShowGOLD" class="col-sm-3" label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="OiMarket.prix_unitaire_Gold" ></base-input>
                    <base-input v-if="ShowGOLD" class="col-sm-3" label="VOLUME" type="number" placeholder="VOLUME" 
                      v-model="OiMarket.volume_Gold"></base-input>
                    <base-input v-if="ShowGOLD" label="MONTANT TOTAL" class="col-sm-3" type="number" placeholder="MONTANT TOTAL"
                      v-model="OiMarket.montant_total_Gold" disabled></base-input>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="row">
                    <base-checkbox inline class="mb-4 col-sm-1" v-model="ShowDESTOCKAGE">DESTOCKAGE </base-checkbox>
                    <base-input v-if="ShowDESTOCKAGE" class="col-sm-3" label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="OiMarket.prix_unitaire_Destockage"></base-input>
                    <base-input v-if="ShowDESTOCKAGE" class="col-sm-3" label="VOLUME" type="number" placeholder="VOLUME" 
                      v-model="OiMarket.volume_Destockage"></base-input>
                    <base-input v-if="ShowDESTOCKAGE" label="MONTANT TOTAL" class="col-sm-3" type="number" placeholder="MONTANT TOTAL"
                      v-model="OiMarket.montant_total_Destockage" disabled></base-input>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="col-md-12">
                  <div class="row">
                    <base-checkbox inline class="mb-4 col-sm-1" v-model="ShowMER">MER</base-checkbox>
                    <base-input v-if="ShowMER" class="col-sm-3" label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                      v-model="OiMarket.prix_unitaire_MER" ></base-input>
                    <base-input v-if="ShowMER" class="col-sm-3" label="VOLUME" type="number" placeholder="VOLUME" 
                    v-model="OiMarket.volume_MER"></base-input>
                    <base-input v-if="ShowMER" label="MONTANT TOTAL" class="col-sm-3" type="number" placeholder="MONTANT TOTAL"
                      v-model="OiMarket.montant_total_MER" disabled></base-input>
                  </div>
                </div>
              </template>
              <base-input label="">
                <div class="col-sm-12">
                  <base-checkbox inline class="mb-3 col-md-3" v-model="OiMarket.Incentive_clic_non_autorise">
                  INCENTIVE AU CLIC NON AUTORISÉ</base-checkbox>
                  <base-checkbox inline class="mb-2 col-md-4" v-model="OiMarket.Incentive_remplissage_non_autorise">
                  INCENTIVE AU REMPLISSAGE NON AUTORISÉ</base-checkbox>
                  <base-checkbox inline class="mb-2 col-md-4" v-model="OiMarket.suppression_mensuelle_maximum">
                  10% DE SUPPRESSION MENSUELLE MAXIMUM</base-checkbox>
                </div>
              </base-input>
            </card>
          </div>

        </template>
      </div>

      <div class="col-md-12">
        <center>
          <div
            class="btn-group btn-group-toggle"
            data-toggle="buttons"
          >
            <label class="btn btn-sm btn-warning btn-simple" :class="{ active: ShowDataForm }">
              <input
                type="radio"
                @click="ShowDataForm = !ShowDataForm"
                name="options"
                autocomplete="off"
              />
              <span class="d-none d-sm-block" style="width: 150px;"> <img src="/img/weedo-it-logo-blanc.png" alt="" /> Weedo Data</span>
              <span class="d-block d-sm-none">
                <img src="/img/weedo-it-logo-blanc.png" alt="" />
              </span>
            </label>
          </div>
        </center>
      </div>


      <div class="col-md-12">
        <template v-if="ShowDataForm">
          <div class="col-md-12">
            <card>
              <div class="row">
                <base-input 
                  label="NOM CAMPAGNE" 
                  placeholder="NOM CAMPAGNE" 
                  v-model="OiData.nom_campagne"
                  class="col-md-6"
                ></base-input>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <base-checkbox inline class="mb-4 col-sm-1" v-model="ShowCPL_DATA">CPL </base-checkbox>
                  <base-input v-if="ShowCPL_DATA" class="col-sm-3" label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                    v-model="OiData.prix_unitaire_CPL" ></base-input>
                  <base-input v-if="ShowCPL_DATA" class="col-sm-3" label="VOLUME" type="number" placeholder="VOLUME" 
                    v-model="OiData.volume_CPL" :disabled="OiData.volume_CPL_illimite"></base-input>
                  <base-checkbox inline class="mb-2" v-if="ShowCPL_DATA" v-model="OiData.volume_CPL_illimite">ILLIMITÉ
                  </base-checkbox>
                  <base-input v-if="ShowCPL_DATA" label="MONTANT TOTAL" class="col-sm-3" type="number" placeholder="MONTANT TOTAL"
                    v-model="OiData.montant_total_CPL" disabled></base-input>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <base-checkbox inline class="mb-4 col-sm-1" v-model="ShowCPC_DATA">CPC </base-checkbox>
                  <base-input v-if="ShowCPC_DATA" class="col-sm-3" label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                    v-model="OiData.prix_unitaire_CPC" ></base-input>
                  <base-input v-if="ShowCPC_DATA" class="col-sm-3" label="VOLUME" type="number" placeholder="VOLUME" 
                    v-model="OiData.volume_CPC" :disabled="OiData.volume_CPC_illimite"></base-input>
                  <base-checkbox inline class="mb-2" v-if="ShowCPC_DATA" v-model="OiData.volume_CPC_illimite">ILLIMITÉ
                  </base-checkbox>
                  <base-input v-if="ShowCPC_DATA" label="MONTANT TOTAL" class="col-sm-3" type="number" placeholder="MONTANT TOTAL"
                    v-model="OiData.montant_total_CPC" disabled></base-input>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <base-checkbox inline class="mb-4 col-sm-1" v-model="ShowCPM_DATA">CPM </base-checkbox>
                  <base-input v-if="ShowCPM_DATA" class="col-sm-3" label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                    v-model="OiData.prix_unitaire_CPM" ></base-input>
                  <base-input v-if="ShowCPM_DATA" class="col-sm-3" label="VOLUME" type="number" placeholder="VOLUME" 
                    v-model="OiData.volume_CPM" :disabled="OiData.volume_CPM_illimite"></base-input>
                  <base-checkbox inline class="mb-2" v-if="ShowCPM_DATA" v-model="OiData.volume_CPM_illimite">ILLIMITÉ
                  </base-checkbox>
                  <base-input v-if="ShowCPM_DATA" label="MONTANT TOTAL" class="col-sm-3" type="number" placeholder="MONTANT TOTAL"
                    v-model="OiData.montant_total_CPM" disabled></base-input>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <base-checkbox inline class="mb-4 col-sm-1" v-model="ShowCPD_DATA">CPD </base-checkbox>
                  <base-input v-if="ShowCPD_DATA" class="col-sm-3" label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                    v-model="OiData.prix_unitaire_CPD" ></base-input>
                  <base-input v-if="ShowCPD_DATA" class="col-sm-3" label="VOLUME" type="number" placeholder="VOLUME" 
                    v-model="OiData.volume_CPD" :disabled="OiData.volume_CPD_illimite"></base-input>
                  <base-checkbox inline class="mb-2" v-if="ShowCPD_DATA" v-model="OiData.volume_CPD_illimite">ILLIMITÉ
                  </base-checkbox>
                  <base-input v-if="ShowCPD_DATA" label="MONTANT TOTAL" class="col-sm-3" type="number" placeholder="MONTANT TOTAL"
                    v-model="OiData.montant_total_CPD" disabled></base-input>
                </div>
              </div>
              <div class="col-md-12">
                <div class="row">
                  <base-checkbox inline class="mb-4 col-sm-1" v-model="ShowMOBILE_DATA">MOBILE </base-checkbox>
                  <base-input v-if="ShowMOBILE_DATA" class="col-sm-3" label="PRIX UNITAIRE" type="number" placeholder="PRIX UNITAIRE"
                    v-model="OiData.prix_unitaire_MOBILE"></base-input>
                  <base-input v-if="ShowMOBILE_DATA" class="col-sm-3" label="VOLUME" type="number" placeholder="VOLUME" 
                    v-model="OiData.volume_MOBILE" :disabled="OiData.volume_MOBILE_illimite"></base-input>
                  <base-checkbox inline class="mb-2" v-if="ShowMOBILE_DATA" v-model="OiData.volume_MOBILE_illimite">ILLIMITÉ
                  </base-checkbox>
                  <base-input v-if="ShowMOBILE_DATA" label="MONTANT TOTAL" class="col-sm-3" type="number" placeholder="MONTANT TOTAL"
                    v-model="OiData.montant_total_MOBILE" disabled></base-input>
                </div>
              </div>
              <base-input label="LEVIERS">
                <div class="col-sm-12">
                  <base-checkbox inline class="mb-2 col-md-2" v-model="OiData.leviers.emailling">
                  EMAILLING</base-checkbox>
                  <base-checkbox inline class="mb-2 col-md-2" v-model="OiData.leviers.mobile">
                  MOBILE</base-checkbox>
                  <base-checkbox inline class="mb-2 col-md-2" v-model="OiData.leviers.sms">
                  SMS</base-checkbox>
                </div>
              </base-input>
            </card>
          </div>

        </template>
      </div>

      <!-- GENERAL FORM -->
      <hr/>
      <div class="col-md-10">
        <div class="row">
          <div class="col-sm-5">
            <base-input label="Date de diffusion">
              <el-date-picker v-model="oi.date_diffusion" value-format="yyyy-MM-dd" type="date" placeholder="Date de diffusion"
                :disabled="withoutDateDiffusion"></el-date-picker>
            </base-input>
          </div>
          <div class="col-sm-5">
            <base-input label="Date de Fin">
              <el-date-picker v-model="oi.date_fin" value-format="yyyy-MM-dd"  type="date" placeholder="Date de Fin"></el-date-picker>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-5">
            <base-input label="SIGNATURE/TAMPON WEEDOIT" :error="getError('signature/tampon WeedoIT')" required>
              <el-select class="select-primary" placeholder="SIGNATURE/TAMPON WEEDOIT"
                name="signature/tampon WeedoIT" v-validate="'required'" v-model="oi.signature_and_tampon">
                <el-option v-for="option in AnnonceurSelects.AnnonceurList" class="select-primary"
                  v-if="option.nom_client.match('GHALEM')"
                  :value="option.prenom_client + ' ' + option.nom_client"
                  :label="option.prenom_client.toUpperCase() + ' ' + option.nom_client.toUpperCase()" 
                  :key="option.id_client">
                </el-option>
              </el-select>
            </base-input>
          </div>

          <div class="col-sm-5">
            <base-input label="PAIEMENT" :error="getError('paiement')" required>
              <el-select class="select-primary" placeholder="PAIEMENT" name="paiement" v-validate="'required'"
                v-model="oi.paiement">
                <el-option class="select-primary" value="Fin de mois">Fin de mois</el-option>
                <el-option class="select-primary" value="Prepaiement 100">Prépaiement 100%</el-option>
                <el-option class="select-primary" value="30 jours">Paiement: 30 jours </el-option>
                <el-option class="select-primary" value="45 jours">Paiement: 45 jours </el-option>
                <el-option class="select-primary" value="60 jours">Paiement: 60 jours </el-option>
              </el-select>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-10">
            <base-input label="Descriptif">
              <textarea v-model="oi.descriptif" class="form-control" rows="3"></textarea>
            </base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-5">
            <base-input v-model="oi.taux_deduplication" label="Taux de suppression mensuel maximum"
              type="number" placeholder="Taux de suppression mensuel maximum"></base-input>
          </div>
          <div class="col-sm-5">
            <base-input v-model="oi.taux_depassement" label="Taux de dépassement mensuel maximum" type="number"
              placeholder="Taux de dépassement mensuel maximum"></base-input>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-5">
            <base-input label="Prestations complémentaires">
              <textarea v-model="oi.prestations_complementaires" class="form-control" rows="3"
                :disabled="oi.pas_de_frais_pack"></textarea>
            </base-input>
          </div>
          <div class="col-sm-5">
            <base-input v-model="oi.prestations_complementaires_prix"
              label="Montant Prestations complémentaires" type="number"
              placeholder="Montant Prestations complémentaires" :disabled="oi.pas_de_frais_pack">
            </base-input>
          </div>
          <div class="col-sm-2">
            <label></label><br />
            <base-checkbox inline v-model="oi.pas_de_frais_pack">ne pas ajouter au montant total </base-checkbox>
            <br>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-5">
            <base-input label="Frais de set up" type="number" placeholder="Frais de set up"
              v-model="oi.frais_set_up">
            </base-input>
          </div>
          <div class="col-sm-5">
            <base-input label="MONTANT TOTAL" type="number" placeholder="MONTANT TOTAL" disabled v-model="SUMtotal">
            </base-input>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <base-button loading v-if="loading" type="info" class="space">{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
        <base-button v-else type="info" class="space" @click="createOI()">VALIDER</base-button>
        <base-button class="space" v-if="oi.visible_for_annonceur" type="success"
          @click="oi.visible_for_annonceur = !(oi.visible_for_annonceur)">RENDRE INVISIBLE</base-button>
        <base-button class="space pull-right" v-else type="danger"
          @click="oi.visible_for_annonceur = !(oi.visible_for_annonceur)">RENDRE VISIBLE</base-button>
      </div>

    </div>

  </card>
</template>

<script>
  import { TabPane, Tabs } from 'src/components';
  import { Select, Option, DatePicker, TimeSelect } from 'element-ui'
  import { ImageUpload } from 'src/components'
  import { Table, TableColumn } from 'element-ui'
  import { BaseRadio } from 'src/components/index';
  import gql from 'graphql-tag'

  export default {
    watch: {
      showCPL(newValue){
        if (newValue === false) {
          this.oi.prix_unitaire_CPL = 0
          this.oi.volume_CPL = 0
          this.oi.volume_CPL_illimite = false
          this.oi.montant_total_CPL = 0
        }
      },
      showCPC(newValue){
        if (newValue === false) {
          this.oi.prix_unitaire_CPC = 0
          this.oi.volume_CPC = 0
          this.oi.volume_CPC_illimite = false
          this.oi.montant_total_CPC = 0
        }
      },
      showCPAFixe(newValue){
        if (newValue === false) {
          this.oi.prix_unitaire_CPA_fixe = 0
          this.oi.volume_CPA_fixe = 0
          this.oi.volume_CPA_fixe_illimite = false
          this.oi.montant_total_CPA_fixe = 0
        }
      },
      showCPAPourcentage(newValue){
        if (newValue === false) {
          this.oi.prix_unitaire_CPA_pourcentage = 0
          this.oi.volume_CPA_pourcentage = 0
          this.oi.volume_CPA_pourcentage_illimite = false
          this.oi.montant_total_CPA_pourcentage = 0
        }
      },
      showCPM(newValue){
        if (newValue === false) {
          this.oi.prix_unitaire_CPM = 0
          this.oi.volume_CPM = 0
          this.oi.volume_CPM_illimite = false
          this.oi.montant_total_CPM = 0
        }
      },
      showCPD(newValue){
        if (newValue === false) {
          this.oi.prix_unitaire_CPD = 0
          this.oi.volume_CPD = 0
          this.oi.volume_CPD_illimite = false
          this.oi.montant_total_CPD = 0
        }
      },
      showCliclead(newValue){
        if (newValue === false) {
          this.oi.prix_unitaire_Cliclead = 0
          this.oi.volume_Cliclead = 0
          this.oi.volume_Cliclead_illimite = false
          this.oi.montant_total_Cliclead = 0
        }
      },
      showCPV(newValue){
        if (newValue === false) {
          this.oi.prix_unitaire_CPV = 0
          this.oi.volume_CPV = 0
          this.oi.volume_CPV_illimite = false
          this.oi.montant_total_CPV = 0
        }
      },
      showCPI(newValue){
        if (newValue === false) {
          this.oi.prix_unitaire_CPI = 0
          this.oi.volume_CPI = 0
          this.oi.volume_CPI_illimite = false
          this.oi.montant_total_CPI = 0
        }
      },
      showNumMobile(newValue){
        if (newValue === false) {
          this.oi.prix_unitaire_NUMERO_MOBILE = 0
          this.oi.volume_NUMERO_MOBILE = 0
          this.oi.volume_NUMERO_MOBILE_illimite = false
          this.oi.montant_total_NUMERO_MOBILE = 0
        }
      },
      "oi.pas_de_frais_pack"() {
        this.oi.prestations_complementaires_prix = 0
      },
      "withoutDateDiffusion"() {
        this.oi.date_diffusion = ''
      },
      "oi.prix_unitaire_CPL"() {
        this.oi.montant_total_CPL = this.oi.volume_CPL * this.oi.prix_unitaire_CPL
      },
      "oi.volume_CPL"() {
        this.oi.montant_total_CPL = this.oi.volume_CPL * this.oi.prix_unitaire_CPL
      },
      "oi.prix_unitaire_CPC"() {
        this.oi.montant_total_CPC = this.oi.volume_CPC * this.oi.prix_unitaire_CPC
      },
      "oi.volume_CPC"() {
        this.oi.montant_total_CPC = this.oi.volume_CPC * this.oi.prix_unitaire_CPC
      },
      "oi.prix_unitaire_CPA_fixe"() {
        this.oi.montant_total_CPA_fixe = this.oi.volume_CPA_fixe * this.oi.prix_unitaire_CPA_fixe
      },
      "oi.volume_CPA_fixe"() {
        this.oi.montant_total_CPA_fixe = this.oi.volume_CPA_fixe * this.oi.prix_unitaire_CPA_fixe
      },
      "oi.prix_unitaire_CPA_pourcentage"() {
        this.oi.montant_total_CPA_pourcentage = this.oi.volume_CPA_pourcentage * this.oi.prix_unitaire_CPA_pourcentage
      },
      "oi.volume_CPA_pourcentage"() {
        this.oi.montant_total_CPA_pourcentage = this.oi.volume_CPA_pourcentage * this.oi.prix_unitaire_CPA_pourcentage
      },
      "oi.prix_unitaire_CPM"() {
        this.oi.montant_total_CPM = this.oi.volume_CPM * this.oi.prix_unitaire_CPM
      },
      "oi.volume_CPM"() {
        this.oi.montant_total_CPM = this.oi.volume_CPM * this.oi.prix_unitaire_CPM
      },
      "oi.prix_unitaire_CPD"() {
        this.oi.montant_total_CPD = this.oi.volume_CPD * this.oi.prix_unitaire_CPD
      },
      "oi.volume_CPD"() {
        this.oi.montant_total_CPD = this.oi.volume_CPD * this.oi.prix_unitaire_CPD
      },
      "oi.prix_unitaire_Cliclead"() {
        this.oi.montant_total_Cliclead = this.oi.volume_Cliclead * this.oi.prix_unitaire_Cliclead
      },
      "oi.volume_Cliclead"() {
        this.oi.montant_total_Cliclead = this.oi.volume_Cliclead * this.oi.prix_unitaire_Cliclead
      },
      "oi.prix_unitaire_CPV"() {
        this.oi.montant_total_CPV = this.oi.volume_CPV * this.oi.prix_unitaire_CPV
      },
      "oi.volume_CPV"() {
        this.oi.montant_total_CPV = this.oi.volume_CPV * this.oi.prix_unitaire_CPV
      },
      "oi.prix_unitaire_CPI"() {
        this.oi.montant_total_CPI = this.oi.volume_CPI * this.oi.prix_unitaire_CPI
      },
      "oi.volume_CPI"() {
        this.oi.montant_total_CPI = this.oi.volume_CPI * this.oi.prix_unitaire_CPI
      },
      "oi.prix_unitaire_NUMERO_MOBILE"() {
        this.oi.montant_total_NUMERO_MOBILE = this.oi.volume_NUMERO_MOBILE * this.oi.prix_unitaire_NUMERO_MOBILE
      },
      "oi.volume_NUMERO_MOBILE"() {
        this.oi.montant_total_NUMERO_MOBILE = this.oi.volume_NUMERO_MOBILE * this.oi.prix_unitaire_NUMERO_MOBILE
      },
      "OiMarket.prix_unitaire_Bronze"() {
        this.OiMarket.montant_total_Bronze = this.OiMarket.prix_unitaire_Bronze * this.OiMarket.volume_Bronze
      },
      "OiMarket.volume_Bronze"() {
        this.OiMarket.montant_total_Bronze = this.OiMarket.prix_unitaire_Bronze * this.OiMarket.volume_Bronze
      },
      "OiMarket.prix_unitaire_Silver"() {
        this.OiMarket.montant_total_Silver = this.OiMarket.prix_unitaire_Silver * this.OiMarket.volume_Silver
      },
      "OiMarket.volume_Silver"() {
        this.OiMarket.montant_total_Silver = this.OiMarket.prix_unitaire_Silver * this.OiMarket.volume_Silver
      },
      "OiMarket.prix_unitaire_Gold"() {
        this.OiMarket.montant_total_Gold = this.OiMarket.prix_unitaire_Gold * this.OiMarket.volume_Gold
      },
      "OiMarket.volume_Gold"() {
        this.OiMarket.montant_total_Gold = this.OiMarket.prix_unitaire_Gold * this.OiMarket.volume_Gold
      },
      "OiMarket.prix_unitaire_MER"() {
        this.OiMarket.montant_total_MER = this.OiMarket.prix_unitaire_MER * this.OiMarket.volume_MER
      },
      "OiMarket.volume_MER"() {
        this.OiMarket.montant_total_MER = this.OiMarket.prix_unitaire_MER * this.OiMarket.volume_MER
      },
      "OiMarket.volume_Destockage"() {
        this.OiMarket.montant_total_Destockage = this.OiMarket.prix_unitaire_Destockage * this.OiMarket.volume_Destockage
      },
      "OiMarket.prix_unitaire_Destockage"() {
        this.OiMarket.montant_total_Destockage = this.OiMarket.prix_unitaire_Destockage * this.OiMarket.volume_Destockage
      },
      ShowBRONZE(newValue){
        if (newValue === false) {
          this.OiMarket.prix_unitaire_Bronze = 0 
          this.OiMarket.volume_Bronze = 0
          this.OiMarket.montant_total_Bronze = 0
        }
      },
      ShowSILVER(newValue){
        if (newValue === false) {
          this.OiMarket.prix_unitaire_Silver = 0 
          this.OiMarket.volume_Silver = 0
          this.OiMarket.montant_total_Silver = 0
        }
      },
      ShowGOLD(newValue){
        if (newValue === false) {
          this.OiMarket.prix_unitaire_Gold = 0 
          this.OiMarket.volume_Gold = 0
          this.OiMarket.montant_total_Gold = 0
        }
      },
      ShowDESTOCKAGE(newValue){
        if (newValue === false) {
          this.OiMarket.prix_unitaire_Destockage = 0 
          this.OiMarket.volume_Destockage = 0
          this.OiMarket.montant_total_Destockage = 0
        }
      },
      ShowMER(newValue){
        if (newValue === false) {
          this.OiMarket.prix_unitaire_MER = 0 
          this.OiMarket.volume_MER = 0
          this.OiMarket.montant_total_MER = 0
        }
      },
      "OiMarket.Leviers"(newValue){
        if(newValue == "Weedo Market - Leads"){
          this.ShowMER = false
        }else if(newValue == "Comparateur Mon Gustave - Mise en relation"){
          this.ShowBRONZE = false
          this.ShowSILVER = false
          this.ShowGOLD = false
          this.ShowDESTOCKAGE = false
        }
      },
      "OiCoreg.qq"(newValue) {
        if (newValue == "false") {
          this.FirstQuestion = ""
          this.Questions = []
        }
      },
      ShowCPL_DATA(newValue){
        if (newValue === false) {
          this.OiData.prix_unitaire_CPL = 0
          this.OiData.volume_CPL = 0
          this.OiData.montant_total_CPL = 0
          this.OiData.volume_CPL_illimite = false
        }
      },
      ShowCPM_DATA(newValue){
        if (newValue === false) {
          this.OiData.prix_unitaire_CPM = 0
          this.OiData.volume_CPM = 0
          this.OiData.montant_total_CPM = 0
          this.OiData.volume_CPM_illimite = false
        }
      },
      ShowCPD_DATA(newValue){
        if (newValue === false) {
          this.OiData.prix_unitaire_CPD = 0
          this.OiData.volume_CPD = 0
          this.OiData.montant_total_CPD = 0
          this.OiData.volume_CPD_illimite = false
        }
      },
      ShowMOBILE_DATA(newValue){
        if (newValue === false) {
          this.OiData.prix_unitaire_MOBILE = 0
          this.OiData.volume_MOBILE = 0
          this.OiData.montant_total_MOBILE = 0
          this.OiData.volume_MOBILE_illimite = false
        }
      },
      ShowCPC_DATA(newValue){
        if (newValue === false) {
          this.OiData.prix_unitaire_CPC = 0
          this.OiData.volume_CPC = 0
          this.OiData.montant_total_CPC = 0
          this.OiData.volume_CPC_illimite = false
        }
      },
      "OiData.prix_unitaire_CPL"() {
        this.OiData.montant_total_CPL = this.OiData.prix_unitaire_CPL * this.OiData.volume_CPL
      },
      "OiData.volume_CPL"() {
        this.OiData.montant_total_CPL = this.OiData.prix_unitaire_CPL * this.OiData.volume_CPL
      },
      "OiData.prix_unitaire_CPM"() {
        this.OiData.montant_total_CPM = this.OiData.prix_unitaire_CPM * this.OiData.volume_CPM
      },
      "OiData.volume_CPM"() {
        this.OiData.montant_total_CPM = this.OiData.prix_unitaire_CPM * this.OiData.volume_CPM
      },
      "OiData.prix_unitaire_CPD"() {
        this.OiData.montant_total_CPD = this.OiData.prix_unitaire_CPD * this.OiData.volume_CPD
      },
      "OiData.volume_CPD"() {
        this.OiData.montant_total_CPD = this.OiData.prix_unitaire_CPD * this.OiData.volume_CPD
      },
      "OiData.prix_unitaire_MOBILE"() {
        this.OiData.montant_total_MOBILE = this.OiData.prix_unitaire_MOBILE * this.OiData.volume_MOBILE
      },
      "OiData.volume_MOBILE"() {
        this.OiData.montant_total_MOBILE = this.OiData.prix_unitaire_MOBILE * this.OiData.volume_MOBILE
      },
      "OiData.prix_unitaire_CPC"() {
        this.OiData.montant_total_CPC = this.OiData.prix_unitaire_CPC * this.OiData.volume_CPC
      },
      "OiData.volume_CPC"() {
        this.OiData.montant_total_CPC = this.OiData.prix_unitaire_CPC * this.OiData.volume_CPC
      },
      "OiData.volume_CPL_illimite"(newValue) {
        if(newValue)
          this.OiData.volume_CPL = 0
      },
      "OiData.volume_CPM_illimite"(newValue) {
        if(newValue)
          this.OiData.volume_CPM = 0
      },
      "OiData.volume_CPD_illimite"(newValue) {
        if(newValue)
          this.OiData.volume_CPD = 0
      },
      "OiData.volume_MOBILE_illimite"(newValue) {
        if(newValue)
          this.OiData.volume_MOBILE = 0
      },
      "OiData.volume_CPC_illimite"(newValue) {
        if(newValue)
          this.OiData.volume_CPC = 0
      },
      "oi.volume_CPL_illimite"(newValue) {
        if(newValue)
          this.oi.volume_CPL = 0
      },
      "oi.volume_CPC_illimite"(newValue) {
        if(newValue)
          this.oi.volume_CPC = 0
      },
      "oi.volume_CPA_fixe_illimite"(newValue) {
        if(newValue)
          this.oi.volume_CPA_fixe = 0
      },
      "oi.volume_CPA_pourcentage_illimite"(newValue) {
        if(newValue)
          this.oi.volume_CPA_pourcentage = 0
      },
      "oi.volume_CPM_illimite"(newValue) {
        if(newValue)
          this.oi.volume_CPM = 0
      },
      "oi.volume_CPD_illimite"(newValue) {
        if(newValue)
          this.oi.volume_CPD = 0
      },
      "oi.volume_Cliclead_illimite"(newValue) {
        if(newValue)
          this.oi.volume_Cliclead = 0
      },
      "oi.volume_CPV_illimite"(newValue) {
        if(newValue)
          this.oi.volume_CPV = 0
      },
      "oi.volume_CPI_illimite"(newValue) {
        if(newValue)
          this.oi.volume_CPI = 0
      },
      "oi.volume_NUMERO_MOBILE_illimite"(newValue) {
        if(newValue)
          this.oi.volume_NUMERO_MOBILE = 0
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      ImageUpload,
      BaseRadio,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [DatePicker.name]: DatePicker,
      [TimeSelect.name]: TimeSelect,
      TabPane,
      Tabs
    },
    apollo: {
      client: {
        query: gql` query ($id: ID) {
          utilisateur(id: $id)
          {
            first_name
            last_name
            society
            address
            country
            city
            mobile
            postal_code
            tva_intracommunautaire
          }
        }`,
        skip() {
          return this.CopyOI != null
        },
        result({ data, loader, networkStatus }) {
          this.oi.nom = data.utilisateur.first_name
          this.oi.prenom = data.utilisateur.last_name
          this.oi.nom_societe = data.utilisateur.society
          this.oi.adresse = data.utilisateur.address
          this.oi.pays = data.utilisateur.country
          this.oi.ville = data.utilisateur.city
          this.oi.code_postal = data.utilisateur.postal_code
          this.oi.num_tva_or_vat = data.utilisateur.tva_intracommunautaire
        },
        variables() {
          return {
            id: this.$route.params.id_utilisateur
          }
        },
        update: data => data
      },
      // getClientWeedoitCaOrAdminOI: {
      //   query: gql`query {
      //   getClientWeedoitCaOrAdminOI{
      //     id_client
      //     prenom_client
      //     nom_client
      //   }
      // }`,
      //   result({ data, loader, networkStatus }) {
      //     this.AnnonceurSelects.AnnonceurList = data.getClientWeedoitCaOrAdminOI
      //   }
      // },
      chargerAffaire: {
        query: gql`query {
          chargerAffaire(droit:["ca","ad"],etat:"valide",bloque:"non"){
            id_client
            nom_client
            prenom_client
        },
      }`,
        result({ data, loader, networkStatus }) {
          this.AnnonceurSelects.AnnonceurList = data.chargerAffaire
        },
        update: data => data
      },
      getCampagnesOI: {
        query: gql` query (
          $id_client: Int!
        ) 
        {
          getCampagnesOI
          (
            id_client: $id_client
          )
          {
            id_programme
            Titre
          }
        }`,
        fetchPolicy: 'no-cache',
        result({ data, loader, networkStatus }) {
          this.campagnesList.campagnes = data.getCampagnesOI
          if (this.CopyOI != null) {
            let campagnelist = []
            let arrayIdprg = this.CopyOI.oi_myaffil.id_programme.split(",")
            let arrayTitleprg = this.CopyOI.oi_myaffil.nom_campagne.split(",")
            for (let i = 0; i < arrayIdprg.length; i++) {
              this.campagnesList.campagnes.forEach(campagne => {
                console.log(campagne)
                if(campagne.id_programme.trim() == arrayIdprg[i].trim() && campagne.Titre.trim() == arrayTitleprg[i].trim()) {
                  campagnelist.push(campagne.id_programme  + '---' + campagne.Titre)
                }
              });
            }
            this.campagnesList.campagne = campagnelist
          }
        },
        variables() {
          return {
            id_client: this.$route.params.id_annonceur
          }
        },
        skip() {
          return !this.$route.params.id_annonceur
        }
      }
    },
    computed: {
      SUMtotal() {
        let total = 0
        total =
          this.oi.montant_total_CPL + this.oi.montant_total_CPC +
          this.oi.montant_total_CPA_fixe + this.oi.montant_total_CPA_pourcentage +
          this.oi.montant_total_CPM + this.oi.montant_total_CPD +
          this.oi.montant_total_Cliclead + this.oi.montant_total_CPV +
          this.oi.montant_total_CPI + this.oi.montant_total_NUMERO_MOBILE +
          (parseInt(this.oi.frais_set_up) || 0) + (parseInt(this.oi.prestations_complementaires_prix) || 0) +
          (this.OiCoreg.prix_unitaire_CPL * this.OiCoreg.volume) +
          this.OiMarket.montant_total_Bronze + this.OiMarket.montant_total_Silver +
          this.OiMarket.montant_total_Gold + this.OiMarket.montant_total_MER + this.OiMarket.montant_total_Destockage +
          this.OiData.montant_total_CPL + this.OiData.montant_total_CPM +
          this.OiData.montant_total_CPD + this.OiData.montant_total_MOBILE + this.OiData.montant_total_CPC

        return total
      }
    },
    data() {
      return {
        CopyOI: null,
        ShowBRONZE: false,
        ShowSILVER: false,
        ShowGOLD: false,
        ShowDESTOCKAGE: false,
        ShowMER: false,
        ShowPerfForm: false,
        ShowCoregForm: false,
        ShowMarketForm: false,
        ShowDataForm: false,
        ShowCPL_DATA: false,
        ShowCPC_DATA: false,
        ShowCPM_DATA: false,
        ShowCPD_DATA: false,
        ShowMOBILE_DATA: false,
        FirstQuestion: "",
        Questions: [],
        ChampsCoeg: [
          { id: 1, name: "Civilité", value: "CIVILITÉ", checked: false },
          { id: 2, name: "Nom", value: "NOM", checked: false },
          { id: 3, name: "Prenom", value: "PRENOM", checked: false },
          { id: 4, name: "Email", value: "EMAIL", checked: false },
          { id: 5, name: "Téléphone", value: "TÉLÉPHONE", checked: false },
          { id: 6, name: "Cp", value: "CP", checked: false },
          { id: 7, name: "Ville", value: "VILLE", checked: false },
          { id: 8, name: "Adresse", value: "ADRESSE", checked: false },
          { id: 9, name: "Date naissance", value: "DATE NAISSANCE", checked: false },
          { id: 10, name: "Ip", value: "IP", checked: false },
          { id: 11, name: "Source", value: "SOURCE", checked: false },
          { id: 12, name: "Date/Heure Collecte", value: "DATE/HEURE COLLECTE", checked: false }
        ],
        OiCoreg: {
          Type: "coreg",
          campagne: '',
          prix_unitaire_CPL: 0,
          volume: 0,
          montant_total: 0,
          capping: '',
          mentions: '',
          filtre_age: '',
          filtre_sexe: '',
          filtre_geo: '',
          filtre_autre: '',
          qq: "false",
          incentive: "false",
          welcome_mail: "true",
          livraison_contact: "webservice",
          taux_rejet_max: '',
          date_limite: ''
        },
        OiMarket: {
          Leviers: "Weedo Market - Leads",
          nom_campagne: "",
          type_campagne: "CPL",
          type_campagnes: [
            {value: "CPL", label: "CPL"}
          ],
          prix_unitaire_Bronze: 0,
          volume_Bronze: 0,
          prix_unitaire_Silver: 0,
          volume_Silver: 0,
          prix_unitaire_Gold: 0,
          volume_Gold: 0,
          prix_unitaire_MER: 0,
          volume_MER: 0,
          prix_unitaire_Destockage: 0,
          volume_Destockage: 0,
          Incentive_clic_non_autorise: true,
          Incentive_remplissage_non_autorise: true,
          suppression_mensuelle_maximum: false,
          montant_total_Bronze: 0,
          montant_total_Silver: 0,
          montant_total_Gold: 0,
          montant_total_MER: 0,
          montant_total_Destockage: 0
        },
        OiData: {
          nom_campagne: "",
          prix_unitaire_CPL: 0,
          volume_CPL: 0,
          montant_total_CPL: 0,
          volume_CPL_illimite: false,
          prix_unitaire_CPC: 0,
          volume_CPC: 0,
          montant_total_CPC: 0,
          volume_CPC_illimite: false,
          prix_unitaire_CPM: 0,
          volume_CPM: 0,
          montant_total_CPM: 0,
          volume_CPM_illimite: false,
          prix_unitaire_CPD: 0,
          volume_CPD: 0,
          montant_total_CPD: 0,
          volume_CPD_illimite: false,
          prix_unitaire_MOBILE: 0,
          volume_MOBILE:0,
          montant_total_MOBILE: 0,
          volume_MOBILE_illimite: false,
          leviers: {
            emailling: false,
            mobile: false,
            sms: false
          }
        },
        dateFR: new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear(),
        loading: false,
        langues: [
          { name: 'FRANÇAIS', value: 'FR' },
          { name: 'ANGLAIS', value: 'EN' }
        ],
        campagnesList: {
          campagne: '',
          campagnes: []
        },
        leviers: [
          { id: 1, name: "Emailing", value: "Emailing", checked: false },
          { id: 2, name: "Site Under", value: "Site Under", checked: false },
          { id: 3, name: "Pop Under", value: "Pop Under", checked: false },
          { id: 4, name: "Coregistration", value: "Coregistration", checked: false },
          { id: 5, name: "Sponsoring", value: "Sponsoring", checked: false },
          { id: 6, name: "Enquête", value: "Enquête", checked: false },
          { id: 7, name: "Video", value: "Video", checked: false },
          { id: 8, name: "Social media", value: "Social media", checked: false },
          { id: 9, name: "RTB", value: "RTB", checked: false },
          { id: 10, name: "Remarketing", value: "Remarketing", checked: false },
          { id: 11, name: "Flux produits", value: "Flux produits", checked: false },
          { id: 12, name: "Mobile", value: "Mobile", checked: false },
          { id: 13, name: "SMS (location + routage)", value: "SMS (location + routage)", checked: false },
          { id: 14, name: "Comparateur Mon Gustave - Mise en relation", value: "Comparateur Mon Gustave : Mise en relation", checked: false },
        ],
        incentives: [
          {
            id: 1,
            name: 'Incentive au clic',
            value: 'Incentive au clic',
            attr: 'incentive_clic',
            checked: false
          },
          {
            id: 2,
            name: 'Incentive au remplissage',
            value: 'Incentive au remplissage',
            attr: 'incentive_remplissage',
            checked: false
          }
        ],
        oi: {
          id_annonceur: null,
          id_createur: null,
          langue: 'FR',
          nom_campagne: [],
          leviers: [],
          incentives: [],
          date_creation_oi: this.getNow(),
          nom_societe: '',
          adresse: '',
          complement_adresse: '',
          code_postal: '',
          ville: '',
          pays: '',
          nom: '',
          prenom: '',
          num_tva_or_vat: '',
          volume: 0,
          frais_set_up: 0,
          montant_total: '',
          date_diffusion: '',
          date_fin: '',
          taux_deduplication: 0,
          taux_depassement: 0,
          descriptif: '',
          signature_and_tampon: '',
          paiement: '',
          visible_for_annonceur: false,
          id_client: '',
          id_programme: '',
          id_createur: '',

          prix_unitaire_CPL: 0,
          prix_unitaire_CPC: 0,
          prix_unitaire_CPA_fixe: 0,
          prix_unitaire_CPA_pourcentage: 0,
          prix_unitaire_CPM: 0,
          prix_unitaire_CPD: 0,
          prix_unitaire_Cliclead: 0,
          prix_unitaire_CPV: 0,
          prix_unitaire_CPI: 0,
          prix_unitaire_NUMERO_MOBILE: 0,

          volume_CPL: 0,
          volume_CPC: 0,
          volume_CPA_fixe: 0,
          volume_CPA_pourcentage: 0,
          volume_CPM: 0,
          volume_CPD: 0,
          volume_Cliclead: 0,
          volume_CPV: 0,
          volume_CPI: 0,
          volume_NUMERO_MOBILE: 0,

          volume_CPL_illimite: false,
          volume_CPC_illimite: false,
          volume_CPA_fixe_illimite: false,
          volume_CPA_pourcentage_illimite: false,
          volume_CPM_illimite: false,
          volume_CPD_illimite: false,
          volume_Cliclead_illimite: false,
          volume_CPV_illimite: false,
          volume_CPI_illimite: false,
          volume_NUMERO_MOBILE_illimite: false,

          montant_total_CPL: 0,
          montant_total_CPC: 0,
          montant_total_CPA_fixe: 0,
          montant_total_CPA_pourcentage: 0,
          montant_total_CPM: 0,
          montant_total_CPD: 0,
          montant_total_Cliclead: 0,
          montant_total_CPV: 0,
          montant_total_CPI: 0,
          montant_total_NUMERO_MOBILE: 0,

          prestations_complementaires: '',
          prestations_complementaires_prix: 0,
          pas_de_frais_pack: false,
          autre: '',
          offre_commerciale: 'Aucune',
          nom_payeur: '',
          prenom_payeur: '',
          fonction_payeur: '',
          email_payeur: '',
          tel_payeur: ''
        },
        showCPL: false,
        showCPC: false,
        showCPAFixe: false,
        showCPAPourcentage: false,
        showCPD: false,
        showCPM: false,
        showCliclead: false,
        showCPV: false,
        showCPI: false,
        showNumMobile: false,
        showAutreLevier: false,
        AnnonceurSelects: {
          Annonceur: '',
          AnnonceurList: []
        },
        withoutDateDiffusion: false
      }
    },
    methods: {
      async createOI() {
        let validate
        await this.$validator.validateAll().then(isValid => {
          validate = isValid
        })
        if (!validate)
          return
        
        if (this.ShowPerfForm == false && this.ShowCoregForm == false && this.ShowMarketForm == false && this.ShowDataForm == false) {
          this.$notify({type: 'warning', verticalAlign: 'top', horizontalAlign: 'right', message: 'Choisir une ou plusieurs plateformes' });
          return
        }
        this.loading = true
        this.oi.leviers = []
        this.oi.incentives = []
        this.oi.montant_total = this.SUMtotal
        this.leviers.forEach(levier => {
          if (levier.checked)
            this.oi.leviers.push(levier.value)
        });
        if (this.showAutreLevier) {
          this.oi.leviers.push("Autres")
        }
        if (this.ShowPerfForm) {
          this.campagnesList.campagne.forEach((campagne, index) => {
            if (!campagne.includes("---")) {
              this.campagnesList.campagne[index] = 0 + "---" + campagne
            }
          });
        }
        this.incentives.forEach(incentive => {
          if (incentive.checked)
            this.oi.incentives.push(incentive.attr)
        });

        this.oi.nom_campagne = this.campagnesList.campagne
        this.oi.id_annonceur = this.$route.params.id_annonceur || null
        this.oi.id_createur = JSON.parse(localStorage.getItem('utilisateur')).id_clien_myaffil //current user

        let formData = new FormData()
        formData.append("id_annonceur", this.oi.id_annonceur)
        formData.append("id_createur", this.oi.id_createur)
        formData.append("langue", this.oi.langue)
        formData.append("nom_campagne", this.oi.nom_campagne)
        formData.append("leviers", this.oi.leviers)
        formData.append("incentives", this.oi.incentives)
        formData.append("date_creation_oi", this.oi.date_creation_oi)
        formData.append("nom_societe", this.oi.nom_societe)
        formData.append("adresse", this.oi.adresse)
        formData.append("complement_adresse", this.oi.complement_adresse)
        formData.append("code_postal", this.oi.code_postal)
        formData.append("ville", this.oi.ville)
        formData.append("pays", this.oi.pays)
        formData.append("nom", this.oi.nom)
        formData.append("prenom", this.oi.prenom)
        formData.append("num_tva_or_vat", this.oi.num_tva_or_vat)
        formData.append("volume", this.oi.volume)
        formData.append("frais_set_up", this.oi.frais_set_up)
        formData.append("montant_total", this.oi.montant_total)
        formData.append("date_diffusion", this.oi.date_diffusion)
        formData.append("date_fin", this.oi.date_fin)
        formData.append("taux_deduplication", this.oi.taux_deduplication)
        formData.append("taux_depassement", this.oi.taux_depassement)
        formData.append("descriptif", this.oi.descriptif)
        formData.append("signature_and_tampon", this.oi.signature_and_tampon)
        formData.append("paiement", this.oi.paiement)
        formData.append("visible_for_annonceur", this.oi.visible_for_annonceur)
        formData.append("id_client", this.$route.params.id_utilisateur)
        formData.append("id_programme", this.oi.id_programme)
        formData.append("prix_unitaire_CPL", this.oi.prix_unitaire_CPL)
        formData.append("prix_unitaire_CPC", this.oi.prix_unitaire_CPC)
        formData.append("prix_unitaire_CPA_fixe", this.oi.prix_unitaire_CPA_fixe)
        formData.append("prix_unitaire_CPA_pourcentage", this.oi.prix_unitaire_CPA_pourcentage)
        formData.append("prix_unitaire_CPM", this.oi.prix_unitaire_CPM)
        formData.append("prix_unitaire_CPD", this.oi.prix_unitaire_CPD)
        formData.append("prix_unitaire_Cliclead", this.oi.prix_unitaire_Cliclead)
        formData.append("prix_unitaire_CPV", this.oi.prix_unitaire_CPV)
        formData.append("prix_unitaire_CPI", this.oi.prix_unitaire_CPI)
        formData.append("prix_unitaire_NUMERO_MOBILE", this.oi.prix_unitaire_NUMERO_MOBILE)
        formData.append("volume_CPL", this.oi.volume_CPL)
        formData.append("volume_CPC", this.oi.volume_CPC)
        formData.append("volume_CPA_fixe", this.oi.volume_CPA_fixe)
        formData.append("volume_CPA_pourcentage", this.oi.volume_CPA_pourcentage)
        formData.append("volume_CPM", this.oi.volume_CPM)
        formData.append("volume_CPD", this.oi.volume_CPD)
        formData.append("volume_Cliclead", this.oi.volume_Cliclead)
        formData.append("volume_CPV", this.oi.volume_CPV)
        formData.append("volume_CPI", this.oi.volume_CPI)
        formData.append("volume_NUMERO_MOBILE", this.oi.volume_NUMERO_MOBILE)
        formData.append("volume_CPC_illimite", JSON.stringify(this.oi.volume_CPC_illimite))
        formData.append("volume_CPA_fixe_illimite", JSON.stringify(this.oi.volume_CPA_fixe_illimite))
        formData.append("volume_CPA_pourcentage_illimite", JSON.stringify(this.oi.volume_CPA_pourcentage_illimite))
        formData.append("volume_CPL_illimite", JSON.stringify(this.oi.volume_CPL_illimite))
        formData.append("volume_CPM_illimite", JSON.stringify(this.oi.volume_CPM_illimite))
        formData.append("volume_CPD_illimite", JSON.stringify(this.oi.volume_CPD_illimite))
        formData.append("volume_Cliclead_illimite", JSON.stringify(this.oi.volume_Cliclead_illimite))
        formData.append("volume_CPV_illimite", JSON.stringify(this.oi.volume_CPV_illimite))
        formData.append("volume_CPI_illimite", JSON.stringify(this.oi.volume_CPI_illimite))
        formData.append("volume_NUMERO_MOBILE_illimite", JSON.stringify(this.oi.volume_NUMERO_MOBILE_illimite))
        formData.append("montant_total_CPL", this.oi.montant_total_CPL)
        formData.append("montant_total_CPC", this.oi.montant_total_CPC)
        formData.append("montant_total_CPA_fixe", this.oi.montant_total_CPA_fixe)
        formData.append("montant_total_CPA_pourcentage", this.oi.montant_total_CPA_pourcentage)
        formData.append("montant_total_CPM", this.oi.montant_total_CPM)
        formData.append("montant_total_Cliclead", this.oi.montant_total_Cliclead)
        formData.append("montant_total_CPV", this.oi.montant_total_CPV)
        formData.append("montant_total_CPI", this.oi.montant_total_CPI)
        formData.append("montant_total_CPD", this.oi.montant_total_CPD)
        formData.append("montant_total_NUMERO_MOBILE", this.oi.montant_total_NUMERO_MOBILE)
        formData.append("prestations_complementaires", this.oi.prestations_complementaires)
        formData.append("prestations_complementaires_prix", this.oi.prestations_complementaires_prix)
        formData.append("pas_de_frais_pack", JSON.stringify(this.oi.pas_de_frais_pack))
        formData.append("autre", this.oi.autre)
        formData.append("offre_commerciale", this.oi.offre_commerciale)
        formData.append("nom_payeur", this.oi.nom_payeur)
        formData.append("prenom_payeur", this.oi.prenom_payeur)
        formData.append("fonction_payeur", this.oi.fonction_payeur)
        formData.append("email_payeur", this.oi.email_payeur)
        formData.append("tel_payeur", this.oi.tel_payeur)

        //                              Platfom Flag                               //
        let arr_flag_oi = []
        if (this.ShowPerfForm)
          arr_flag_oi.push("myaffil")
        if (this.ShowCoregForm)
          arr_flag_oi.push("mycoreg")
        if (this.ShowMarketForm)
          arr_flag_oi.push("leadmarket")
        if (this.ShowDataForm)
          arr_flag_oi.push("datadeal")

        formData.append("arr_flag_oi", JSON.stringify(arr_flag_oi))
        //                             My Coreg fields                             //
        let questions = ""
        if (this.OiCoreg.qq == 'true') {
          questions = this.FirstQuestion + ";"
          this.Questions.forEach(q => {
            questions += q + ";"
          });
        }
        let camps = ""
        this.ChampsCoeg.forEach(c => {
          if(c.checked)
            camps += c.name + ","
        });
        formData.append("type", this.OiCoreg.Type)
        formData.append("campagne", this.OiCoreg.campagne)
        formData.append("campagne_id", JSON.stringify(null))
        formData.append("capping", this.OiCoreg.capping)
        formData.append("mentions", this.OiCoreg.mentions)
        formData.append("champs", camps)
        formData.append("filtre_age", this.OiCoreg.filtre_age)
        formData.append("filtre_sexe", this.OiCoreg.filtre_sexe)
        formData.append("filtre_geo", this.OiCoreg.filtre_geo)
        formData.append("filtre_autre", this.OiCoreg.filtre_autre)
        formData.append("qq",  JSON.stringify(this.OiCoreg.qq == "true"?true:false))
        formData.append("qq_value", questions)
        formData.append("date_limite", this.OiCoreg.date_limite)
        formData.append("welcome_mail", JSON.stringify(this.OiCoreg.welcome_mai == "true"?true:false))
        formData.append("livraison_contact", this.OiCoreg.livraison_contact)
        formData.append("incentive", JSON.stringify(this.OiCoreg.incentive == "true"?true:false))
        formData.append("taux_rejet_maximum", this.OiCoreg.taux_rejet_max)
        formData.append("volume", this.OiCoreg.volume)
        formData.append("prix_unitaire_CPL_coreg", this.OiCoreg.prix_unitaire_CPL)
        //                             My Coreg fields                             //

        //                             Lead Makret fields                             //
        formData.append("leviers_leadmarket", this.OiMarket.Leviers)
        formData.append("nom_campagne_leadmarket", this.OiMarket.nom_campagne)
        formData.append("type_campagne_leadmarket", this.OiMarket.type_campagne)
        formData.append("prix_unitaire_Bronze", this.OiMarket.prix_unitaire_Bronze)
        formData.append("volume_Bronze", this.OiMarket.volume_Bronze)
        formData.append("prix_unitaire_Silver", this.OiMarket.prix_unitaire_Silver)
        formData.append("volume_Silver", this.OiMarket.volume_Silver)
        formData.append("prix_unitaire_Gold", this.OiMarket.prix_unitaire_Gold)
        formData.append("volume_Gold", this.OiMarket.volume_Gold)
        formData.append("prix_unitaire_MER", this.OiMarket.prix_unitaire_MER)
        formData.append("volume_MER", this.OiMarket.volume_MER)
        formData.append("volume_Destockage", this.OiMarket.volume_Destockage)
        formData.append("prix_unitaire_Destockage", this.OiMarket.prix_unitaire_Destockage)
        formData.append("Incentive_clic_non_autorise", this.OiMarket.Incentive_clic_non_autorise?1:0)
        formData.append("Incentive_remplissage_non_autorise", this.OiMarket.Incentive_remplissage_non_autorise?1:0)
        formData.append("suppression_mensuelle_maximum", this.OiMarket.suppression_mensuelle_maximum?1:0)
        //                             Lead Makret fields                             //

        //                             datadeal fields                             //
        formData.append("nom_campagne_datadeal", this.OiData.nom_campagne)
        let leviers_data = ""
        if(this.OiData.leviers.emailling)
          leviers_data += "emailling,"
        if(this.OiData.leviers.mobile)
          leviers_data += "mobile,"
        if(this.OiData.leviers.sms)
          leviers_data += "sms,"

        formData.append("leviers_datadeal", leviers_data)
        formData.append("prix_unitaire_CPL_datadeal", this.OiData.prix_unitaire_CPL)
        formData.append("prix_unitaire_CPC_datadeal", this.OiData.prix_unitaire_CPC)
        formData.append("prix_unitaire_CPD_datadeal", this.OiData.prix_unitaire_CPD)
        formData.append("prix_unitaire_CPM_datadeal", this.OiData.prix_unitaire_CPM)
        formData.append("prix_unitaire_numero_mobile_datadeal", this.OiData.prix_unitaire_MOBILE)
        formData.append("volume_CPL_datadeal", this.OiData.volume_CPL)
        formData.append("volume_CPC_datadeal", this.OiData.volume_CPC)
        formData.append("volume_CPD_datadeal", this.OiData.volume_CPD)
        formData.append("volume_CPM_datadeal", this.OiData.volume_CPM)
        formData.append("volume_NUMERO_MOBILE_datadeal", this.OiData.volume_MOBILE)
        formData.append("volume_CPL_illimite_datadeal", this.OiData.volume_CPL_illimite?1:0)
        formData.append("volume_CPC_illimite_datadeal", this.OiData.volume_CPC_illimite?1:0)
        formData.append("volume_CPD_illimite_datadeal", this.OiData.volume_CPD_illimite?1:0)
        formData.append("volume_CPM_illimite_datadeal", this.OiData.volume_CPM_illimite?1:0)
        formData.append("volume_NUMERO_MOBILE_illimite_datadeal", this.OiData.volume_MOBILE_illimite?1:0)
        //                             datadeal fields                             //

        const url_api = 'https://api-auth-plf.wee-do-it.net/api/v1/createoi'
        let accessToken = localStorage.getItem("token_jwt")
        await this.axios.post(url_api, formData,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'authorization': `Bearer ${accessToken}`
            }
          })
          .then(response => {
            if (response.data.code == "OI200") {
              this.$notify({type: 'success', verticalAlign: 'top', horizontalAlign: 'right', message: 'Le Document OI a été bien ajouté.' });
              setTimeout(function () {
                this.$router.push({ name: 'liste de OI disponible Admin' });
              }.bind(this), 1000)
            }
          })
          .catch(() => {
            console.log('error occured')
          })
        this.loading = false
      },
      getNow() {
        const today = new Date();
        const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        const dateTime = date;
        return dateTime;
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      }
    },
    created() {
      if ((this.CopyOI = JSON.parse(localStorage.getItem("Copyoi"))) != null ) {
        localStorage.removeItem("Copyoi")
        // GLOBAL FILEDS : 
        this.oi.nom_societe = this.CopyOI.nom_societe
        this.oi.adresse = this.CopyOI.adresse
        this.oi.complement_adresse = (this.CopyOI.complement_adresse == "null") ? "" : this.CopyOI.complement_adresse
        this.oi.code_postal = (this.CopyOI.code_postal == "null") ? "" : this.CopyOI.code_postal
        this.oi.ville = (this.CopyOI.ville == "null") ? "" : this.CopyOI.ville
        this.oi.pays = (this.CopyOI.pays == "null") ? "" : this.CopyOI.pays
        this.oi.nom = (this.CopyOI.nom_representant == "null") ? "" : this.CopyOI.nom_representant
        this.oi.prenom = (this.CopyOI.prenom_representant == "null") ? "" : this.CopyOI.prenom_representant
        this.oi.num_tva_or_vat = (this.CopyOI.num_tva_or_vat == "null") ? "" : this.CopyOI.num_tva_or_vat
        this.oi.date_diffusion = (this.CopyOI.date_diffusion == "null" || this.CopyOI.date_diffusion == null) ? "" : this.CopyOI.date_diffusion
        this.oi.date_fin = (this.CopyOI.date_fin == "null" || this.CopyOI.date_fin == null) ? "" : this.CopyOI.date_fin
        this.oi.signature_and_tampon = (this.CopyOI.signature_and_tampon == "null") ? "" : this.CopyOI.signature_and_tampon
        this.oi.paiement = (this.CopyOI.paiement == "null") ? "" : this.CopyOI.paiement
        this.oi.descriptif = (this.CopyOI.descriptif == "null") ? "" : this.CopyOI.descriptif
        this.oi.taux_deduplication = (this.CopyOI.taux_deduplication == "null") ? "" : this.CopyOI.taux_deduplication
        this.oi.taux_depassement = (this.CopyOI.taux_depassement == "null") ? "" : this.CopyOI.taux_depassement
        this.oi.frais_set_up = (this.CopyOI.frais_set_up == "null") ? "" : this.CopyOI.frais_set_up
        this.oi.montant_total = (this.CopyOI.montant_total == "null") ? "" : this.CopyOI.montant_total
        this.oi.prestations_complementaires = (this.CopyOI.prestations_complementaires == "null") ? "" : this.CopyOI.prestations_complementaires
        this.oi.prestations_complementaires_prix = this.CopyOI.prestations_complementaires_prix
        this.oi.pas_de_frais_pack = (this.CopyOI.pas_de_frais_pack == "oui") ? true : false

        if(this.CopyOI.oi_myaffil) {
          this.ShowPerfForm = true
        
          this.oi.autre = this.CopyOI.oi_myaffil.autre
          if (this.CopyOI.oi_myaffil.autre)
            this.showAutreLevier = true

          // Set SYSTÈME DE RÉMUNÉRATION :
          if(this.CopyOI.oi_myaffil.prix_unitaire_CPL != 0) {
            this.showCPL = true
            this.oi.prix_unitaire_CPL = this.CopyOI.oi_myaffil.prix_unitaire_CPL
            this.oi.volume_CPL = this.CopyOI.oi_myaffil.volume_CPL
            this.oi.volume_CPL_illimite = (this.CopyOI.oi_myaffil.volume_CPL_illimite == "non") ? false : true
          }
          if(this.CopyOI.oi_myaffil.prix_unitaire_CPC != 0) {
            this.showCPC = true
            this.oi.prix_unitaire_CPC = this.CopyOI.oi_myaffil.prix_unitaire_CPC
            this.oi.volume_CPC = this.CopyOI.oi_myaffil.volume_CPC
            this.oi.volume_CPC_illimite = (this.CopyOI.oi_myaffil.volume_CPC_illimite == "non") ? false : true
          }
          if(this.CopyOI.oi_myaffil.prix_unitaire_CPA_fixe != 0) {
            this.showCPAFixe = true
            this.oi.prix_unitaire_CPA_fixe = this.CopyOI.oi_myaffil.prix_unitaire_CPA_fixe
            this.oi.volume_CPA_fixe = this.CopyOI.oi_myaffil.volume_CPA_fixe
            this.oi.volume_CPA_fixe_illimite = (this.CopyOI.oi_myaffil.volume_CPA_fixe_illimite == "non") ? false : true
          }
          if(this.CopyOI.oi_myaffil.prix_unitaire_CPA_pourcentage != 0) {
            this.showCPAPourcentage = true
            this.oi.prix_unitaire_CPA_pourcentage = this.CopyOI.oi_myaffil.prix_unitaire_CPA_pourcentage
            this.oi.volume_CPA_pourcentage = this.CopyOI.oi_myaffil.volume_CPA_pourcentage
            this.oi.volume_CPA_pourcentage_illimite = (this.CopyOI.oi_myaffil.volume_CPA_pourcentage_illimite == "non") ? false : true
          }
          if(this.CopyOI.oi_myaffil.prix_unitaire_CPM != 0) {
            this.showCPM = true
            this.oi.prix_unitaire_CPM = this.CopyOI.oi_myaffil.prix_unitaire_CPM
            this.oi.volume_CPM = this.CopyOI.oi_myaffil.volume_CPM
            this.oi.volume_CPM_illimite = (this.CopyOI.oi_myaffil.volume_CPM_illimite == "non") ? false : true
          }
          if(this.CopyOI.oi_myaffil.prix_unitaire_CPD != 0) {
            this.showCPD = true
            this.oi.prix_unitaire_CPD = this.CopyOI.oi_myaffil.prix_unitaire_CPD
            this.oi.volume_CPD = this.CopyOI.oi_myaffil.volume_CPD
            this.oi.volume_CPD_illimite = (this.CopyOI.oi_myaffil.volume_CPD_illimite == "non") ? false : true
          }
          if(this.CopyOI.oi_myaffil.prix_unitaire_Cliclead != 0) {
            this.showCliclead = true
            this.oi.prix_unitaire_Cliclead = this.CopyOI.oi_myaffil.prix_unitaire_Cliclead
            this.oi.volume_Cliclead = this.CopyOI.oi_myaffil.volume_Cliclead
            this.oi.volume_Cliclead_illimite = (this.CopyOI.oi_myaffil.volume_Cliclead_illimite == "non") ? false : true
          }
          if(this.CopyOI.oi_myaffil.prix_unitaire_CPV != 0) {
            this.showCPV = true
            this.oi.prix_unitaire_CPV = this.CopyOI.oi_myaffil.prix_unitaire_CPV
            this.oi.volume_CPV_illimite = this.CopyOI.oi_myaffil.volume_CPV_illimite
            this.oi.volume_CPV_illimite = (this.CopyOI.oi_myaffil.volume_CPV_illimite == "non") ? false : true
          }
          if(this.CopyOI.oi_myaffil.prix_unitaire_CPI != 0) {
            this.showCPI = true
            this.oi.prix_unitaire_CPI = this.CopyOI.oi_myaffil.prix_unitaire_CPI
            this.oi.volume_CPI_illimite = this.CopyOI.oi_myaffil.volume_CPI_illimite
            this.oi.volume_CPI_illimite = (this.CopyOI.oi_myaffil.volume_CPI_illimite == "non") ? false : true
          }
          if(this.CopyOI.oi_myaffil.prix_unitaire_NUMERO_MOBILE != 0) {
            this.showNumMobile = true
            this.oi.prix_unitaire_NUMERO_MOBILE = this.CopyOI.oi_myaffil.prix_unitaire_NUMERO_MOBILE
            this.oi.volume_NUMERO_MOBILE = this.CopyOI.oi_myaffil.volume_NUMERO_MOBILE
            this.oi.volume_NUMERO_MOBILE_illimite = (this.CopyOI.oi_myaffil.volume_NUMERO_MOBILE_illimite == "non") ? false : true
          }

          // LEVIERS :
          if (this.CopyOI.oi_myaffil.leviers && this.CopyOI.oi_myaffil.leviers != "") {
            this.CopyOI.oi_myaffil.leviers.split("-").forEach(levier => {
              this.leviers.forEach(element => {
                if(element.value == levier)
                  element.checked = true
              });
            });
          }

          // INCENTIVE :
          if (this.CopyOI.oi_myaffil.incentive && this.CopyOI.oi_myaffil.incentive != "") {
            this.CopyOI.oi_myaffil.incentive.split("-").forEach(incentive => {
              this.incentives.find(x => x.attr === incentive).checked = true
            })
          }
          
        }
        if(this.CopyOI.oi_mycoreg) {
          this.ShowCoregForm = true
          this.OiCoreg.type = this.CopyOI.oi_mycoreg.type
          this.OiCoreg.campagne = (this.CopyOI.oi_mycoreg.campagne == "null") ? "" : this.CopyOI.oi_mycoreg.campagne
          this.OiCoreg.prix_unitaire_CPL = this.CopyOI.oi_mycoreg.prix_unitaire_CPL
          this.OiCoreg.volume = this.CopyOI.oi_mycoreg.volume
          this.OiCoreg.montant_total = this.CopyOI.oi_mycoreg.montant_total
          this.OiCoreg.capping = (this.CopyOI.oi_mycoreg.capping == "null") ? "" : this.CopyOI.oi_mycoreg.capping
          this.OiCoreg.mentions = (this.CopyOI.oi_mycoreg.mentions == "null") ? "" : this.CopyOI.oi_mycoreg.mentions
          this.OiCoreg.filtre_age = (this.CopyOI.oi_mycoreg.filtre_age == "null") ? "" : this.CopyOI.oi_mycoreg.filtre_age
          this.OiCoreg.filtre_sexe = (this.CopyOI.oi_mycoreg.filtre_sexe == "null") ? "" : this.CopyOI.oi_mycoreg.filtre_sexe
          this.OiCoreg.filtre_geo = (this.CopyOI.oi_mycoreg.filtre_geo == "null") ? "" : this.CopyOI.oi_mycoreg.filtre_geo
          this.OiCoreg.filtre_autre = (this.CopyOI.oi_mycoreg.filtre_autre == "null") ? "" : this.CopyOI.oi_mycoreg.filtre_autre
          this.OiCoreg.qq = this.CopyOI.oi_mycoreg.qq + ""
          this.OiCoreg.incentive = this.CopyOI.oi_mycoreg.incentive + ""
          this.OiCoreg.welcome_mail = this.CopyOI.oi_mycoreg.welcome_mail + ""
          this.OiCoreg.livraison_contact = this.CopyOI.oi_mycoreg.livraison_contact
          this.OiCoreg.taux_rejet_max = (this.CopyOI.oi_mycoreg.taux_rejet_maximum == "null") ? "" : this.CopyOI.oi_mycoreg.taux_rejet_maximum
          this.OiCoreg.date_limite = (this.CopyOI.oi_mycoreg.date_limite == "null") ? "" : this.CopyOI.oi_mycoreg.date_limite
          //this.ChampsCoeg
          if (this.CopyOI.oi_mycoreg.champs) {
            this.CopyOI.oi_mycoreg.champs.split(",").forEach(champ => {
              if (champ)
                this.ChampsCoeg.find(c => c.name == champ).checked = true
            });
          }

          // Question
          if (this.CopyOI.oi_mycoreg.qq_value != null) {
            this.CopyOI.oi_mycoreg.qq_value.split(";").forEach((q , key) => {
              if (q) {
                if (key == 0)
                  this.FirstQuestion = q
                else
                  this.Questions.push(q)
              }
            })
          }
        }

        if(this.CopyOI.oi_leadmarket) {
          this.ShowMarketForm = true
          this.OiMarket.Leviers = this.CopyOI.oi_leadmarket.leviers
          this.OiMarket.nom_campagne = this.CopyOI.oi_leadmarket.nom_campagne
          this.OiMarket.type_campagne = this.CopyOI.oi_leadmarket.type_campagne
          this.OiMarket.prix_unitaire_Bronze = this.CopyOI.oi_leadmarket.prix_unitaire_Bronze
          this.OiMarket.volume_Bronze = this.CopyOI.oi_leadmarket.volume_Bronze
          this.OiMarket.prix_unitaire_Silver = this.CopyOI.oi_leadmarket.prix_unitaire_Silver
          this.OiMarket.volume_Silver = this.CopyOI.oi_leadmarket.volume_Silver
          this.OiMarket.prix_unitaire_Gold = this.CopyOI.oi_leadmarket.prix_unitaire_Gold
          this.OiMarket.volume_Gold = this.CopyOI.oi_leadmarket.volume_Gold
          this.OiMarket.prix_unitaire_MER = this.CopyOI.oi_leadmarket.prix_unitaire_MER
          this.OiMarket.volume_MER = this.CopyOI.oi_leadmarket.volume_MER
          this.OiMarket.prix_unitaire_Destockage = this.CopyOI.oi_leadmarket.prix_unitaire_Destockage
          this.OiMarket.volume_Destockage = this.CopyOI.oi_leadmarket.volume_Destockage
          this.OiMarket.Incentive_clic_non_autorise = this.CopyOI.oi_leadmarket.Incentive_clic_non_autorise
          this.OiMarket.Incentive_remplissage_non_autorise = this.CopyOI.oi_leadmarket.Incentive_remplissage_non_autorise
          this.OiMarket.suppression_mensuelle_maximum = this.CopyOI.oi_leadmarket.suppression_mensuelle_maximum
          
          this.ShowBRONZE = this.CopyOI.oi_leadmarket.prix_unitaire_Bronze != 0
          this.ShowSILVER = this.CopyOI.oi_leadmarket.prix_unitaire_Silver != 0
          this.ShowGOLD = this.CopyOI.oi_leadmarket.prix_unitaire_Gold != 0
          this.ShowDESTOCKAGE = this.CopyOI.oi_leadmarket.prix_unitaire_Destockage != 0
          this.ShowMER = this.CopyOI.oi_leadmarket.prix_unitaire_MER != 0
        }

        if(this.CopyOI.oi_datadeal) {
          this.ShowDataForm = true
          this.OiData.nom_campagne = this.CopyOI.oi_datadeal.nom_campagne
          this.OiData.prix_unitaire_CPL = this.CopyOI.oi_datadeal.prix_unitaire_CPL
          this.OiData.volume_CPL = this.CopyOI.oi_datadeal.volume_CPL
          this.OiData.volume_CPL_illimite = (this.CopyOI.oi_datadeal.volume_CPL_illimite == "1") ? true : false
          this.OiData.prix_unitaire_CPM = this.CopyOI.oi_datadeal.prix_unitaire_CPM
          this.OiData.volume_CPM = this.CopyOI.oi_datadeal.volume_CPM
          this.OiData.volume_CPM_illimite = (this.CopyOI.oi_datadeal.volume_CPM_illimite == "1") ? true : false
          this.OiData.prix_unitaire_CPD = this.CopyOI.oi_datadeal.prix_unitaire_CPD
          this.OiData.volume_CPD = this.CopyOI.oi_datadeal.volume_CPD
          this.OiData.volume_CPD_illimite = (this.CopyOI.oi_datadeal.volume_CPD_illimite == "1") ? true : false
          this.OiData.prix_unitaire_MOBILE = this.CopyOI.oi_datadeal.prix_unitaire_NUMERO_MOBILE
          this.OiData.volume_MOBILE = this.CopyOI.oi_datadeal.volume_NUMERO_MOBILE
          this.OiData.volume_MOBILE_illimite = (this.CopyOI.oi_datadeal.volume_NUMERO_MOBILE_illimite == "1") ? true : false
          this.OiData.prix_unitaire_CPC = this.CopyOI.oi_datadeal.prix_unitaire_CPC
          this.OiData.volume_CPC = this.CopyOI.oi_datadeal.volume_CPC
          this.OiData.volume_CPC_illimite = (this.CopyOI.oi_datadeal.volume_CPC_illimite == "1") ? true : false

          if(this.CopyOI.oi_datadeal.leviers != "") {
            this.CopyOI.oi_datadeal.leviers.split(",").forEach(levier => {
              if (levier && levier == "emailling") {
                this.OiData.leviers.emailling = true
              }
              
              else if (levier && levier == "mobile") {
                this.OiData.leviers.mobile = true
              }

              else if (levier && levier == "sms") {
                this.OiData.leviers.sms = true
              }
            });
          }

          this.ShowCPC_DATA = this.CopyOI.oi_datadeal.prix_unitaire_CPC != 0
          this.ShowCPD_DATA = this.CopyOI.oi_datadeal.prix_unitaire_CPD != 0
          this.ShowCPL_DATA = this.CopyOI.oi_datadeal.prix_unitaire_CPL != 0
          this.ShowCPM_DATA = this.CopyOI.oi_datadeal.prix_unitaire_CPM != 0
          this.ShowMOBILE_DATA = this.CopyOI.oi_datadeal.prix_unitaire_NUMERO_MOBILE != 0
        }
      }
    },
  }
</script>