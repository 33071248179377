<template>
  <div class="col-12 background-repoort">
    <br>
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="row">
          <div class="col-sm-6 text-left">
            <h2 class="card-title"> SYNTHÈSE CLIENT </h2>
          </div>
        </div>
        <div
          class="btn-group btn-group-toggle"
          data-toggle="buttons"
        >
          <label class="btn btn-sm btn-info btn-simple" :class="[showCaClient === 1 ? 'active' : '', loadingQueries ? 'disabled' : '']">
            <input
              type="radio"
              name="options"
              autocomplete="off"
              @click="setShowCaClient(1)"
            />
            <span class="d-none d-sm-block" style="width: 180px;">
              <i class="fas fa-users" style="font-size: 20px;"></i> CA PAR ANNONCEUR
            </span>
            <span class="d-block d-sm-none">
              <i class="fas fa-users"></i>
            </span>
          </label>
          <label class="btn btn-sm btn-info btn-simple" :class="[showCaClient === 2 ? 'active' : '', loadingQueries ? 'disabled' : '']">
            <input
              type="radio"
              name="options"
              autocomplete="off"
              @click="setShowCaClient(2);"
            />
            <span class="d-none d-sm-block" style="width: 180px;">
              <i class="fa-solid fa-bullhorn" style="font-size: 20px;"></i> CA PAR EDITEUR
            </span>
            <span class="d-block d-sm-none">
              <i class="fas fa-users"></i>
            </span>
          </label>
          <label class="btn btn-sm btn-info btn-simple" :class="[showCaClient === 3 ? 'active' : '', loadingQueries ? 'disabled' : '']">
            <input
              type="radio"
              name="options"
              autocomplete="off"
              @click="setShowCaClient(3)"
            />
            <span class="d-none d-sm-block" style="width: 150px;">
              <i class="fas fa-poll" style="font-size: 20px;"></i> CA PAR POLE
            </span>
            <span class="d-block d-sm-none">
              <i class="fas fa-poll"></i>
            </span>
          </label>
        </div>
        <card>
          <div class="row">
            <div class="col-xl-10 col-lg-12 col-12 order-xl-1 order-1">
              <div class="row">
                <base-input class="col-md-3">
                  <el-select filterable class="select-primary"
                    placeholder="ANNEE"
                    clearable
                    v-model="annee"
                    :disabled="loadingQueries">
                    <el-option
                      v-for="option in ListYears"
                      class="select-primary"
                      :value="option"
                      :label="option"
                      :key="option">
                    </el-option>
                  </el-select>
                </base-input>
                <base-input class="col-md-3">
                  <el-select filterable class="select-primary"
                    placeholder="TRIMESTRE"
                    clearable
                    v-model="Trimestre"
                    :disabled="loadingQueries">
                    <el-option
                      v-for="option in ListTrimestre"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
                <base-input class="col-md-3">
                  <el-select filterable class="select-primary"
                    placeholder="PLATFORME"
                    clearable
                    v-model="Platforme"
                    :disabled="loadingQueries || this.CaMois1.length == 0">
                    <el-option
                      v-for="option in ListPlatforme"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
                <template v-if="showCaClient == 2">
                <!-- <base-input class="col-md-3">
                  <el-select filterable class="select-primary"
                    placeholder="ANNONCEUR"
                    clearable
                    v-model="Annonceur"
                    :disabled="loadingQueries">
                    <el-option
                      v-for="option in listAnnonceur"
                      class="select-primary"
                      :value="option.id_client"
                      :label="(option.societe_client+'').toLocaleUpperCase()"
                      :key="option.key">
                    </el-option>
                  </el-select>
                </base-input> -->
                <!-- <base-input class="col-md-3">
                  <el-select filterable class="select-primary"
                    placeholder="TM"
                    clearable
                    v-model="Tm"
                    :disabled="loadingQueries">
                    <el-option
                      v-for="option in listManager"
                      class="select-primary"
                      :value="option.nom_client+' '+option.prenom_client"
                      :label="(option.prenom_client+' '+option.nom_client).toLocaleUpperCase()"
                      :key="option.id_client">
                    </el-option>
                  </el-select>
                </base-input> -->
                </template>
              </div>
            </div>
            <div class="col-xl-2 col-lg-12 col-12 order-xl-2 order-3 mt-4">
              <base-button
                v-if="loadingQueries"
                loading
                class="btn btn-just-icon btn-default d-block"
              >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
              <button
                v-else
                class="btn btn-just-icon btn-default d-block"
                @click="search"
              >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
            </div>
          </div>
        </card>
        <CaClientT v-if="showCaClient==1" :AllYear="Trimestre === 5" :dataCaClientT="dataCaClientT"
                  :labelM1="labelM1" :labelM2="labelM2" :labelM3="labelM3" :labelM4="labelM4" :labelM5="labelM5" :labelM6="labelM6"
                  :labelM7="labelM7" :labelM8="labelM8" :labelM9="labelM9" :labelM10="labelM10" :labelM11="labelM11" :labelM12="labelM12"
                  :loading="loadingQueries" ref="CaClientT"/>

        <CaEditeur v-if="showCaClient==2" :AllYear="Trimestre === 5" :dataCaClientT="dataCaEditeurT"
                  :labelM1="labelM1" :labelM2="labelM2" :labelM3="labelM3" :labelM4="labelM4" :labelM5="labelM5" :labelM6="labelM6"
                  :labelM7="labelM7" :labelM8="labelM8" :labelM9="labelM9" :labelM10="labelM10" :labelM11="labelM11" :labelM12="labelM12"
                  :loading="loadingQueries" ref="CaClientT"/>

        <CaPlatform v-else-if="showCaClient==3" :dataCaPlf="dataCaPlf" :dataChart="dataChart" :loading="loadingQueries"/>
      </div>
    </div>
  </div>
</template>

<script>
  import { Select, Option } from 'element-ui'
  import gql from 'graphql-tag'
  import moment from 'moment'
  import CaClientT from './CaClient/CaClientT.vue'
  import CaPlatform from './CaClient/CaPlatform.vue'
  import CaEditeur from './CaClient/CaEditeur.vue'

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      CaClientT,
      CaPlatform,
      CaEditeur
    },
    watch: {
      Trimestre() {
        this.CaMois1 = [];
        this.CaMois1_Editeur = [];
        this.Trimestre_Month()
        this.Platforme = ''
      },
      annee() {
        this.Platforme = ''
      },
      Platforme(val) {
        this.$refs.CaClientT.filterPlatfomre(val)
      }
    },
    apollo: {
      listAnnonceur: {
        query: gql`
          query {
            listAnnonceur {
              id_client
              email_client
              societe_client
            }
          }
        `,
      },
      listManager: {
        query: gql`
          query {
            listManager(
              bloque: "non"
              etat: "valide"
              droit: ["ma", "tem"]
              orderBy: [
                { field: "prenom_client", order: ASC }
                { field: "nom_client", order: ASC }
              ]
            ) {
              id_client
              nom_client
              prenom_client
              email_client
            }
          }
        `
      },
      surveillanceLeadsFirstMonth: {
        client: 'AUTH',
         query: gql` query ($m1: Int, $annee: Int, $annonceur: Int, $tm: String) {
          CaMois1:getCaAnnonceurPlf (mois: $m1, annee: $annee){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
          CaMois1_Editeur:getCaEditeurPlf (mois: $m1, annee: $annee, annonceur: $annonceur, tm: $tm){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }

        }`,
        variables () {
          return {
            m1: this.m1,
            annee: this.annee,
            annonceur: this.Annonceur,
            tm: this.Tm
          }
        },
        skip () {
          return this.SkipQueryFirstMonth
        },
        result({ data, loader, networkStatus }) {
          // annonceur
          this.CaMois1 = alasql(this.queryCa,[data.CaMois1])
          //prepare data for Component CaClientT - editeur
          this.CaMois1_Editeur = alasql(this.queryCa,[data.CaMois1_Editeur])
          // prepare data for Componet CaPlatform
          this.CaPlf_Mois1 = alasql('SELECT SUM(ca_MG) as ca_MG,SUM(ca_LM) as ca_LM, SUM(ca_perf) as ca_perf, SUM(ca_coreg) as ca_coreg,? as mois from ? ',[this.labelM1, data.CaMois1])
          // nombre client Actif
          // WEEDO PERF
          this.CaPlf_Mois1[0].clt_WP = alasql('SELECT count(*) as nb_ctl from ? where ca_perf != 0',[data.CaMois1])[0].nb_ctl
          // WEEDO MARKET
          this.CaPlf_Mois1[0].clt_WM = alasql('SELECT count(*) as nb_ctl from ? where ca_LM != 0',[data.CaMois1])[0].nb_ctl
          // WEEDO COREG
          this.CaPlf_Mois1[0].clt_WC = alasql('SELECT count(*) as nb_ctl from ? where ca_coreg != 0',[data.CaMois1])[0].nb_ctl
          // MONGUSTAVE
          this.CaPlf_Mois1[0].clt_MG = alasql('SELECT count(*) as nb_ctl from ? where ca_MG != 0',[data.CaMois1])[0].nb_ctl
          this.SkipQueryFirstMonth = true
          this.SkipQuerySecondMonth = false

        },
        //fetchPolicy: 'network-only',
        update: data => data
      },
      surveillanceLeadsSecondMonth: {
        client: 'AUTH',
         query: gql` query ($m2: Int, $annee: Int, $annonceur: Int, $tm: String) {
          CaMois2:getCaAnnonceurPlf (mois: $m2, annee: $annee){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
          CaMois2_Editeur:getCaEditeurPlf (mois: $m2, annee: $annee, annonceur: $annonceur, tm: $tm){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }

        }`,
        variables () {
          return {
            m2: this.m2,
            annee: this.annee,
            annonceur: this.Annonceur,
            tm: this.Tm
          }
        },
        skip () {
          return this.SkipQuerySecondMonth
        },
        result({ data, loader, networkStatus }) {
          // annonceur
          this.CaMois2 = alasql(this.queryCa,[data.CaMois2])
          //prepare data for Component CaClientT - editeur
          this.CaMois2_Editeur = alasql(this.queryCa,[data.CaMois2_Editeur])
          // prepare data for Componet CaPlatform
          this.CaPlf_Mois2 = alasql('SELECT SUM(ca_MG) as ca_MG,SUM(ca_LM) as ca_LM, SUM(ca_perf) as ca_perf, SUM(ca_coreg) as ca_coreg,? as mois from ? ',[this.labelM2, data.CaMois2])
          // nombre client Actif
          // WEEDO PERF
          this.CaPlf_Mois2[0].clt_WP = alasql('SELECT count(*) as nb_ctl from ? where ca_perf != 0',[data.CaMois2])[0].nb_ctl
          // WEEDO MARKET
          this.CaPlf_Mois2[0].clt_WM = alasql('SELECT count(*) as nb_ctl from ? where ca_LM != 0',[data.CaMois2])[0].nb_ctl
          // WEEDO COREG
          this.CaPlf_Mois2[0].clt_WC = alasql('SELECT count(*) as nb_ctl from ? where ca_coreg != 0',[data.CaMois2])[0].nb_ctl
          // MONGUSTAVE
          this.CaPlf_Mois2[0].clt_MG = alasql('SELECT count(*) as nb_ctl from ? where ca_MG != 0',[data.CaMois2])[0].nb_ctl
          this.SkipQuerySecondMonth = true
          this.SkipQueryThirdMonth = false

        },
        //fetchPolicy: 'network-only',
        update: data => data
      },
      surveillanceLeadsThirdMonth: {
        client: 'AUTH',
         query: gql` query ($m3: Int, $annee: Int, $annonceur: Int, $tm: String) {
          CaMois3:getCaAnnonceurPlf (mois: $m3, annee: $annee){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
          CaMois3_Editeur:getCaEditeurPlf (mois: $m3, annee: $annee, annonceur: $annonceur, tm: $tm){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }

        }`,
        variables () {
          return {
            m3: this.m3,
            annee: this.annee,
            annonceur: this.Annonceur,
            tm: this.Tm
          }
        },
        skip () {
          return this.SkipQueryThirdMonth
        },
        result({ data, loader, networkStatus }) {
          this.CaMois3 =alasql(this.queryCa,[data.CaMois3])
          this.CaMois3_Editeur =alasql(this.queryCa,[data.CaMois3_Editeur])
          this.CaPlf_Mois3 = alasql('SELECT SUM(ca_MG) as ca_MG,SUM(ca_LM) as ca_LM, SUM(ca_perf) as ca_perf, SUM(ca_coreg) as ca_coreg,? as mois from ? ',[this.labelM3, data.CaMois3])
          // nombre client Actif
          // WEEDO PERF
          this.CaPlf_Mois3[0].clt_WP = alasql('SELECT count(*) as nb_ctl from ? where ca_perf != 0',[data.CaMois3])[0].nb_ctl
          // WEEDO MARKET
          this.CaPlf_Mois3[0].clt_WM = alasql('SELECT count(*) as nb_ctl from ? where ca_LM != 0',[data.CaMois3])[0].nb_ctl
          // WEEDO COREG
          this.CaPlf_Mois3[0].clt_WC = alasql('SELECT count(*) as nb_ctl from ? where ca_coreg != 0',[data.CaMois3])[0].nb_ctl
          // MONGUSTAVE
          this.CaPlf_Mois3[0].clt_MG = alasql('SELECT count(*) as nb_ctl from ? where ca_MG != 0',[data.CaMois3])[0].nb_ctl
          if (this.Trimestre === 5){
            this.SkipQueryThirdMonth = true
            this.SkipQueryFourthMonth = false;

          }else{
            this.SkipQueryThirdMonth = true
            this.loadingQueries = false;
          }

        },
        //fetchPolicy: 'network-only',
        update: data => data
      },
      surveillanceLeadsFourthMonth: {
        client: 'AUTH',
         query: gql` query ($m4: Int, $annee: Int, $annonceur: Int, $tm: String) {
          CaMois4:getCaAnnonceurPlf (mois: $m4, annee: $annee){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
          CaMois4_Editeur:getCaEditeurPlf (mois: $m4, annee: $annee, annonceur: $annonceur, tm: $tm){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
        }`,
        variables () {
          return {
            m4: this.m4,
            annee: this.annee,
            annonceur: this.Annonceur,
            tm: this.Tm
          }
        },
        skip () {
          return this.SkipQueryFourthMonth
        },
        result({ data, loader, networkStatus }) {
                    //prepare data for Component CaClientT - annonceur
          this.CaMois4 = alasql(this.queryCa,[data.CaMois4])
          //prepare data for Component CaClientT - editeur
          this.CaMois4_Editeur = alasql(this.queryCa,[data.CaMois4_Editeur])
          // prepare data for Componet CaPlatform
          this.CaPlf_Mois4 = alasql('SELECT SUM(ca_MG) as ca_MG,SUM(ca_LM) as ca_LM, SUM(ca_perf) as ca_perf, SUM(ca_coreg) as ca_coreg,? as mois from ? ',[this.labelM4, data.CaMois4])
          // nombre client Actif
          // WEEDO PERF
          this.CaPlf_Mois4[0].clt_WP = alasql('SELECT count(*) as nb_ctl from ? where ca_perf != 0',[data.CaMois4])[0].nb_ctl
          // WEEDO MARKET
          this.CaPlf_Mois4[0].clt_WM = alasql('SELECT count(*) as nb_ctl from ? where ca_LM != 0',[data.CaMois4])[0].nb_ctl
          // WEEDO COREG
          this.CaPlf_Mois4[0].clt_WC = alasql('SELECT count(*) as nb_ctl from ? where ca_coreg != 0',[data.CaMois4])[0].nb_ctl
          // MONGUSTAVE
          this.CaPlf_Mois4[0].clt_MG = alasql('SELECT count(*) as nb_ctl from ? where ca_MG != 0',[data.CaMois4])[0].nb_ctl
          this.SkipQueryFourthMonth = true
          this.SkipQueryFifthMonth = false
        },
        //fetchPolicy: 'network-only',
        update: data => data
      },
      surveillanceLeadsFifthMonth: {
        client: 'AUTH',
         query: gql` query ($m5: Int, $annee: Int, $annonceur: Int, $tm: String) {
          CaMois5:getCaAnnonceurPlf (mois: $m5, annee: $annee){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
          CaMois5_Editeur:getCaEditeurPlf (mois: $m5, annee: $annee, annonceur: $annonceur, tm: $tm){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
        }`,
        variables () {
          return {
            m5: this.m5,
            annee: this.annee,
            annonceur: this.Annonceur,
            tm: this.Tm
          }
        },
        skip () {
          return this.SkipQueryFifthMonth
        },
        result({ data, loader, networkStatus }) {
          this.CaMois5 = alasql(this.queryCa,[data.CaMois5])
          //prepare data for Component CaClientT - editeur
          this.CaMois5_Editeur = alasql(this.queryCa,[data.CaMois5_Editeur])
          // prepare data for Componet CaPlatform
          this.CaPlf_Mois5 = alasql('SELECT SUM(ca_MG) as ca_MG,SUM(ca_LM) as ca_LM, SUM(ca_perf) as ca_perf, SUM(ca_coreg) as ca_coreg,? as mois from ? ',[this.labelM5, data.CaMois5])
          // nombre client Actif
          // WEEDO PERF
          this.CaPlf_Mois5[0].clt_WP = alasql('SELECT count(*) as nb_ctl from ? where ca_perf != 0',[data.CaMois5])[0].nb_ctl
          // WEEDO MARKET
          this.CaPlf_Mois5[0].clt_WM = alasql('SELECT count(*) as nb_ctl from ? where ca_LM != 0',[data.CaMois5])[0].nb_ctl
         // WEEDO COREG
          this.CaPlf_Mois5[0].clt_WC = alasql('SELECT count(*) as nb_ctl from ? where ca_coreg != 0',[data.CaMois5])[0].nb_ctl
          // MONGUSTAVE
          this.CaPlf_Mois5[0].clt_MG = alasql('SELECT count(*) as nb_ctl from ? where ca_MG != 0',[data.CaMois5])[0].nb_ctl
          this.SkipQueryFifthMonth = true
          this.SkipQuerySixthMonth = false
        },
        //fetchPolicy: 'network-only',
        update: data => data
      },
      surveillanceLeadsSixthMonth: {
        client: 'AUTH',
         query: gql` query ($m6: Int, $annee: Int, $annonceur: Int, $tm: String) {
          CaMois6:getCaAnnonceurPlf (mois: $m6, annee: $annee){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
          CaMois6_Editeur:getCaEditeurPlf (mois: $m6, annee: $annee, annonceur: $annonceur, tm: $tm){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
        }`,
        variables () {
          return {
            m6: this.m6,
            annee: this.annee,
            annonceur: this.Annonceur,
            tm: this.Tm
          }
        },
        skip () {
          return this.SkipQuerySixthMonth
        },
        result({ data, loader, networkStatus }) {
          this.CaMois6 = alasql(this.queryCa,[data.CaMois6])
          //prepare data for Component CaClientT - editeur
          this.CaMois6_Editeur = alasql(this.queryCa,[data.CaMois6_Editeur])
          // prepare data for Componet CaPlatform
          this.CaPlf_Mois6 = alasql('SELECT SUM(ca_MG) as ca_MG,SUM(ca_LM) as ca_LM, SUM(ca_perf) as ca_perf, SUM(ca_coreg) as ca_coreg,? as mois from ? ',[this.labelM6, data.CaMois6])
          // nombre client Actif
          // WEEDO PERF
          this.CaPlf_Mois6[0].clt_WP = alasql('SELECT count(*) as nb_ctl from ? where ca_perf != 0',[data.CaMois6])[0].nb_ctl
          // WEEDO MARKET
          this.CaPlf_Mois6[0].clt_WM = alasql('SELECT count(*) as nb_ctl from ? where ca_LM != 0',[data.CaMois6])[0].nb_ctl
          // WEEDO COREG
          this.CaPlf_Mois6[0].clt_WC = alasql('SELECT count(*) as nb_ctl from ? where ca_coreg != 0',[data.CaMois6])[0].nb_ctl
          // MONGUSTAVE
          this.CaPlf_Mois6[0].clt_MG = alasql('SELECT count(*) as nb_ctl from ? where ca_MG != 0',[data.CaMois6])[0].nb_ctl
          this.SkipQuerySixthMonth = true
          this.SkipQuerySeventhMonth = false
        },
        //fetchPolicy: 'network-only',
        update: data => data
      },
      surveillanceLeadsSeventhMonth: {
        client: 'AUTH',
         query: gql` query ($m7: Int, $annee: Int, $annonceur: Int, $tm: String) {
          CaMois7:getCaAnnonceurPlf (mois: $m7, annee: $annee){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
          CaMois7_Editeur:getCaEditeurPlf (mois: $m7, annee: $annee, annonceur: $annonceur, tm: $tm){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
        }`,
        variables () {
          return {
            m7: this.m7,
            annee: this.annee,
            annonceur: this.Annonceur,
            tm: this.Tm
          }
        },
        skip () {
          return this.SkipQuerySeventhMonth
        },
        result({ data, loader, networkStatus }) {
          this.CaMois7 = alasql(this.queryCa,[data.CaMois7])
          //prepare data for Component CaClientT - editeur
          this.CaMois7_Editeur = alasql(this.queryCa,[data.CaMois7_Editeur])
          // prepare data for Componet CaPlatform
          this.CaPlf_Mois7 = alasql('SELECT SUM(ca_MG) as ca_MG,SUM(ca_LM) as ca_LM, SUM(ca_perf) as ca_perf, SUM(ca_coreg) as ca_coreg,? as mois from ? ',[this.labelM7, data.CaMois7])
          // nombre client Actif
          // WEEDO PERF
          this.CaPlf_Mois7[0].clt_WP = alasql('SELECT count(*) as nb_ctl from ? where ca_perf != 0',[data.CaMois7])[0].nb_ctl
          // WEEDO MARKET
          this.CaPlf_Mois7[0].clt_WM = alasql('SELECT count(*) as nb_ctl from ? where ca_LM != 0',[data.CaMois7])[0].nb_ctl
          // WEEDO COREG
          this.CaPlf_Mois7[0].clt_WC = alasql('SELECT count(*) as nb_ctl from ? where ca_coreg != 0',[data.CaMois7])[0].nb_ctl
          // MONGUSTAVE
          this.CaPlf_Mois7[0].clt_MG = alasql('SELECT count(*) as nb_ctl from ? where ca_MG != 0',[data.CaMois7])[0].nb_ctl
          this.SkipQuerySeventhMonth = true
          this.SkipQueryEighthMonth = false
        },
        //fetchPolicy: 'network-only',
        update: data => data
      },
      surveillanceLeadsEighthMonth: {
        client: 'AUTH',
         query: gql` query ($m8: Int, $annee: Int, $annonceur: Int, $tm: String) {
          CaMois8:getCaAnnonceurPlf (mois: $m8, annee: $annee){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
          CaMois8_Editeur:getCaEditeurPlf (mois: $m8, annee: $annee, annonceur: $annonceur, tm: $tm){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
        }`,
        variables () {
          return {
            m8: this.m8,
            annee: this.annee,
            annonceur: this.Annonceur,
            tm: this.Tm
          }
        },
        skip () {
          return this.SkipQueryEighthMonth
        },
        result({ data, loader, networkStatus }) {
          this.CaMois8 = alasql(this.queryCa,[data.CaMois8])
          //prepare data for Component CaClientT - editeur
          this.CaMois8_Editeur = alasql(this.queryCa,[data.CaMois8_Editeur])
          // prepare data for Componet CaPlatform
          this.CaPlf_Mois8 = alasql('SELECT SUM(ca_MG) as ca_MG,SUM(ca_LM) as ca_LM, SUM(ca_perf) as ca_perf, SUM(ca_coreg) as ca_coreg,? as mois from ? ',[this.labelM8, data.CaMois8])
          // nombre client Actif
          // WEEDO PERF
          this.CaPlf_Mois8[0].clt_WP = alasql('SELECT count(*) as nb_ctl from ? where ca_perf != 0',[data.CaMois8])[0].nb_ctl
          // WEEDO MARKET
          this.CaPlf_Mois8[0].clt_WM = alasql('SELECT count(*) as nb_ctl from ? where ca_LM != 0',[data.CaMois8])[0].nb_ctl
          // WEEDO COREG
          this.CaPlf_Mois8[0].clt_WC = alasql('SELECT count(*) as nb_ctl from ? where ca_coreg != 0',[data.CaMois8])[0].nb_ctl
          // MONGUSTAVE
          this.CaPlf_Mois8[0].clt_MG = alasql('SELECT count(*) as nb_ctl from ? where ca_MG != 0',[data.CaMois8])[0].nb_ctl
          this.SkipQueryEighthMonth = true
          this.SkipQueryNinthMonth = false
        },
        //fetchPolicy: 'network-only',
        update: data => data
      },
      surveillanceLeadsNinthMonth: {
        client: 'AUTH',
         query: gql` query ($m9: Int, $annee: Int, $annonceur: Int, $tm: String) {
          CaMois9:getCaAnnonceurPlf (mois: $m9, annee: $annee){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
          CaMois9_Editeur:getCaEditeurPlf (mois: $m9, annee: $annee, annonceur: $annonceur, tm: $tm){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
        }`,
        variables () {
          return {
            m9: this.m9,
            annee: this.annee,
            annonceur: this.Annonceur,
            tm: this.Tm
          }
        },
        skip () {
          return this.SkipQueryNinthMonth
        },
        result({ data, loader, networkStatus }) {
          this.CaMois9 = alasql(this.queryCa,[data.CaMois9])
          //prepare data for Component CaClientT - editeur
          this.CaMois9_Editeur = alasql(this.queryCa,[data.CaMois9_Editeur])
          // prepare data for Componet CaPlatform
          this.CaPlf_Mois9 = alasql('SELECT SUM(ca_MG) as ca_MG,SUM(ca_LM) as ca_LM, SUM(ca_perf) as ca_perf, SUM(ca_coreg) as ca_coreg,? as mois from ? ',[this.labelM9, data.CaMois9])
          // nombre client Actif
          // WEEDO PERF
          this.CaPlf_Mois9[0].clt_WP = alasql('SELECT count(*) as nb_ctl from ? where ca_perf != 0',[data.CaMois9])[0].nb_ctl
          // WEEDO MARKET
          this.CaPlf_Mois9[0].clt_WM = alasql('SELECT count(*) as nb_ctl from ? where ca_LM != 0',[data.CaMois9])[0].nb_ctl
          // WEEDO COREG
          this.CaPlf_Mois9[0].clt_WC = alasql('SELECT count(*) as nb_ctl from ? where ca_coreg != 0',[data.CaMois9])[0].nb_ctl
          // MONGUSTAVE
          this.CaPlf_Mois9[0].clt_MG = alasql('SELECT count(*) as nb_ctl from ? where ca_MG != 0',[data.CaMois9])[0].nb_ctl
          this.SkipQueryNinthMonth = true
          this.SkipQueryTenthMonth = false
        },
        //fetchPolicy: 'network-only',
        update: data => data
      },
      surveillanceLeadsTenthMonth: {
        client: 'AUTH',
         query: gql` query ($m10: Int, $annee: Int, $annonceur: Int, $tm: String) {
          CaMois10:getCaAnnonceurPlf (mois: $m10, annee: $annee){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
          CaMois10_Editeur:getCaEditeurPlf (mois: $m10, annee: $annee, annonceur: $annonceur, tm: $tm){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
        }`,
        variables () {
          return {
            m10: this.m10,
            annee: this.annee,
            annonceur: this.Annonceur,
            tm: this.Tm
          }
        },
        skip () {
          return this.SkipQueryTenthMonth
        },
        result({ data, loader, networkStatus }) {
          this.CaMois10 = alasql(this.queryCa,[data.CaMois10])
          //prepare data for Component CaClientT - editeur
          this.CaMois10_Editeur = alasql(this.queryCa,[data.CaMois10_Editeur])
          // prepare data for Componet CaPlatform
          this.CaPlf_Mois10 = alasql('SELECT SUM(ca_MG) as ca_MG,SUM(ca_LM) as ca_LM, SUM(ca_perf) as ca_perf, SUM(ca_coreg) as ca_coreg,? as mois from ? ',[this.labelM10, data.CaMois10])
          // nombre client Actif
          // WEEDO PERF
          this.CaPlf_Mois10[0].clt_WP = alasql('SELECT count(*) as nb_ctl from ? where ca_perf != 0',[data.CaMois10])[0].nb_ctl
          // WEEDO MARKET
          this.CaPlf_Mois10[0].clt_WM = alasql('SELECT count(*) as nb_ctl from ? where ca_LM != 0',[data.CaMois10])[0].nb_ctl
          // WEEDO COREG
          this.CaPlf_Mois10[0].clt_WC = alasql('SELECT count(*) as nb_ctl from ? where ca_coreg != 0',[data.CaMois10])[0].nb_ctl
          // MONGUSTAVE
          this.CaPlf_Mois10[0].clt_MG = alasql('SELECT count(*) as nb_ctl from ? where ca_MG != 0',[data.CaMois10])[0].nb_ctl
          this.SkipQueryTenthMonth = true
          this.SkipQueryEleventhMonth = false
        },
        //fetchPolicy: 'network-only',
        update: data => data
      },
      surveillanceLeadsEleventhMonth: {
        client: 'AUTH',
         query: gql` query ($m11: Int, $annee: Int, $annonceur: Int, $tm: String) {
          CaMois11:getCaAnnonceurPlf (mois: $m11, annee: $annee){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
          CaMois11_Editeur:getCaEditeurPlf (mois: $m11, annee: $annee, annonceur: $annonceur, tm: $tm){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
        }`,
        variables () {
          return {
            m11: this.m11,
            annee: this.annee,
            annonceur: this.Annonceur,
            tm: this.Tm
          }
        },
        skip () {
          return this.SkipQueryEleventhMonth
        },
        result({ data, loader, networkStatus }) {
          this.CaMois11 = alasql(this.queryCa,[data.CaMois11])
          //prepare data for Component CaClientT - editeur
          this.CaMois11_Editeur = alasql(this.queryCa,[data.CaMois11_Editeur])
          // prepare data for Componet CaPlatform
          this.CaPlf_Mois11 = alasql('SELECT SUM(ca_MG) as ca_MG,SUM(ca_LM) as ca_LM, SUM(ca_perf) as ca_perf, SUM(ca_coreg) as ca_coreg,? as mois from ? ',[this.labelM11, data.CaMois11])
          // nombre client Actif
          // WEEDO PERF
          this.CaPlf_Mois11[0].clt_WP = alasql('SELECT count(*) as nb_ctl from ? where ca_perf != 0',[data.CaMois11])[0].nb_ctl
          // WEEDO MARKET
          this.CaPlf_Mois11[0].clt_WM = alasql('SELECT count(*) as nb_ctl from ? where ca_LM != 0',[data.CaMois11])[0].nb_ctl
          // WEEDO COREG
          this.CaPlf_Mois11[0].clt_WC = alasql('SELECT count(*) as nb_ctl from ? where ca_coreg != 0',[data.CaMois11])[0].nb_ctl
          // MONGUSTAVE
          this.CaPlf_Mois11[0].clt_MG = alasql('SELECT count(*) as nb_ctl from ? where ca_MG != 0',[data.CaMois11])[0].nb_ctl
          this.SkipQueryEleventhMonth = true
          this.SkipQueryTwelfthMonth = false
        },
        //fetchPolicy: 'network-only',
        update: data => data
      },
      surveillanceLeadsTwelfthMonth: {
        client: 'AUTH',
         query: gql` query ($m12: Int, $annee: Int, $annonceur: Int, $tm: String) {
          CaMois12:getCaAnnonceurPlf (mois: $m12, annee: $annee){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
          CaMois12_Editeur:getCaEditeurPlf (mois: $m12, annee: $annee, annonceur: $annonceur, tm: $tm){
            id_user
            society
            ca_perf
            ca_coreg
            ca_LM
            ca_MG
            mois
            annee
            secteur
            id_client_myaffile
            id_client_mycoreg
            id_client_leadmarket
            created_at
            client_mongustave
            email
            phone
          }
        }`,
        variables () {
          return {
            m12: this.m12,
            annee: this.annee,
            annonceur: this.Annonceur,
            tm: this.Tm
          }
        },
        skip () {
          return this.SkipQueryTwelfthMonth
        },
        result({ data, loader, networkStatus }) {
          this.CaMois12 = alasql(this.queryCa,[data.CaMois12])
          //prepare data for Component CaClientT - editeur
          this.CaMois12_Editeur = alasql(this.queryCa,[data.CaMois12_Editeur])
          // prepare data for Componet CaPlatform
          this.CaPlf_Mois12 = alasql('SELECT SUM(ca_MG) as ca_MG,SUM(ca_LM) as ca_LM, SUM(ca_perf) as ca_perf, SUM(ca_coreg) as ca_coreg,? as mois from ? ',[this.labelM12, data.CaMois12])
          // nombre client Actif
          // WEEDO PERF
          this.CaPlf_Mois12[0].clt_WP = alasql('SELECT count(*) as nb_ctl from ? where ca_perf != 0',[data.CaMois12])[0].nb_ctl
          // WEEDO MARKET
          this.CaPlf_Mois12[0].clt_WM = alasql('SELECT count(*) as nb_ctl from ? where ca_LM != 0',[data.CaMois12])[0].nb_ctl
          // WEEDO COREG
          this.CaPlf_Mois12[0].clt_WC = alasql('SELECT count(*) as nb_ctl from ? where ca_coreg != 0',[data.CaMois12])[0].nb_ctl
          // MONGUSTAVE
          this.CaPlf_Mois12[0].clt_MG = alasql('SELECT count(*) as nb_ctl from ? where ca_MG != 0',[data.CaMois12])[0].nb_ctl
          this.SkipQueryTwelfthMonth = true;
          this.loadingQueries = false;
        },
        //fetchPolicy: 'network-only',
        update: data => data
      },
    },
    data() {
      return {
        Tm: '',
        Annonceur: '',
        m1: null,
        m2: null,
        m3: null,
        m4: null,
        m5: null,
        m6: null,
        m7: null,
        m8: null,
        m9: null,
        m10: null,
        m11: null,
        m12: null,
        labelM1: '',
        labelM2: '',
        labelM3: '',
        labelM4: '',
        labelM5: '',
        labelM6: '',
        labelM7: '',
        labelM8: '',
        labelM9: '',
        labelM10: '',
        labelM11: '',
        labelM12: '',
        annee: moment().year(),
        Trimestre: moment().quarter(),
        ListYears: [],
        ListTrimestre: [
          {value: 5 ,label:"TOUT L'ANNÉE"},
          {value: 1 ,label:"PREMIER TRIMESTRE"},
          {value: 2 ,label:"DEUXIÈME TRIMESTRE"},
          {value: 3 ,label:"TROISIÈME TRIMESTRE"},
          {value: 4 ,label:"QUATRIÈME TRIMESTRE"}
        ],
        Platforme: '',
        ListPlatforme: [
          {value: "perf" ,label:"WEEDO PERF"},
          {value: "market" ,label:"WEEDO MARKET"},
          {value: "coreg" ,label:"WEEDO COREG"},
          {value: "mg" ,label:"MON GUSTAVE"}
        ],
        CaMois1: [],
        CaMois2: [],
        CaMois3: [],
        CaMois4: [],
        CaMois5: [],
        CaMois6: [],
        CaMois7: [],
        CaMois8: [],
        CaMois9: [],
        CaMois10: [],
        CaMois11: [],
        CaMois12: [],
        CaMois1_Editeur: [],
        CaMois2_Editeur: [],
        CaMois3_Editeur: [],
        CaMois4_Editeur: [],
        CaMois5_Editeur: [],
        CaMois6_Editeur: [],
        CaMois7_Editeur: [],
        CaMois8_Editeur: [],
        CaMois9_Editeur: [],
        CaMois10_Editeur: [],
        CaMois11_Editeur: [],
        CaMois12_Editeur: [],
        CaPlf_Mois1: [],
        CaPlf_Mois2: [],
        CaPlf_Mois3: [],
        CaPlf_Mois4: [],
        CaPlf_Mois5: [],
        CaPlf_Mois6: [],
        CaPlf_Mois7: [],
        CaPlf_Mois8: [],
        CaPlf_Mois9: [],
        CaPlf_Mois10: [],
        CaPlf_Mois11: [],
        CaPlf_Mois12: [],
       // dataCaClientT: [],
        //dataCaEditeurT: [],
        /* dataChart: {
          WP: [],
          WM: [],
          WC: [],
        }, */
        showCaClient: 1,
        SkipQueryFirstMonth: true,
        SkipQuerySecondMonth: true,
        SkipQueryThirdMonth: true,
        SkipQueryFourthMonth: true,
        SkipQueryFifthMonth: true,
        SkipQuerySixthMonth: true,
        SkipQuerySeventhMonth: true,
        SkipQueryEighthMonth: true,
        SkipQueryNinthMonth: true,
        SkipQueryTenthMonth: true,
        SkipQueryEleventhMonth: true,
        SkipQueryTwelfthMonth: true,
        loadingQueries : false,
        queryCa : "SELECT id_user, first(society) as society, SUM(ca_perf) as ca_perf, SUM(ca_coreg) as ca_coreg, SUM(ca_LM) as ca_LM, SUM(ca_MG) as ca_MG, mois, annee, first(secteur) AS secteur, first(id_client_myaffile) as id_client_myaffile, first(id_client_mycoreg) as id_client_mycoreg, first(id_client_leadmarket) as id_client_leadmarket,first(created_at) as created_at, first(client_mongustave) as client_mongustave, first(email) as email, first(phone) as phone, SUM(ca_coreg)+SUM(ca_LM)+SUM(ca_perf)+SUM(ca_MG) as ca_Global from ? GROUP BY id_user,society,mois",
      }
    },
    created() {
      this.Trimestre_Month()
      //this.ListYears = [2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022]
      for (let year = 2011; year <= this.annee; year++){
          this.ListYears.push(year);
      }
    },
    computed:{
      dataCaClientT(){
        // annonceur
          this.CaMois1.forEach(element => {
            let find_mois1 = this.CaMois1.find(x => x.id_user == element.id_user);
            let find_mois2 = this.CaMois2.find(x => x.id_user == element.id_user);
            let find_mois3 = this.CaMois3.find(x => x.id_user == element.id_user);
            let find_mois4 = this.CaMois4.find(x => x.id_user == element.id_user);
            let find_mois5 = this.CaMois5.find(x => x.id_user == element.id_user);
            let find_mois6 = this.CaMois6.find(x => x.id_user == element.id_user);
            let find_mois7 = this.CaMois7.find(x => x.id_user == element.id_user);
            let find_mois8 = this.CaMois8.find(x => x.id_user == element.id_user);
            let find_mois9 = this.CaMois9.find(x => x.id_user == element.id_user);
            let find_mois10 = this.CaMois10.find(x => x.id_user == element.id_user);
            let find_mois11 = this.CaMois11.find(x => x.id_user == element.id_user);
            let find_mois12 = this.CaMois12.find(x => x.id_user == element.id_user);

            element.ca_perf_m1 = (find_mois1 && find_mois1.ca_perf != undefined ) ? find_mois1.ca_perf : 0;
            element.ca_perf_m2 = (find_mois2 && find_mois2.ca_perf != undefined ) ? find_mois2.ca_perf : 0;
            element.ca_perf_m3 = (find_mois3 && find_mois3.ca_perf != undefined ) ? find_mois3.ca_perf : 0;
            element.ca_perf_m4 = (find_mois4 && find_mois4.ca_perf != undefined ) ? find_mois4.ca_perf : 0;
            element.ca_perf_m5 = (find_mois5 && find_mois5.ca_perf != undefined ) ? find_mois5.ca_perf : 0;
            element.ca_perf_m6 = (find_mois6 && find_mois6.ca_perf != undefined ) ? find_mois6.ca_perf : 0;
            element.ca_perf_m7 = (find_mois7 && find_mois7.ca_perf != undefined ) ? find_mois7.ca_perf : 0;
            element.ca_perf_m8 = (find_mois8 && find_mois8.ca_perf != undefined ) ? find_mois8.ca_perf : 0;
            element.ca_perf_m9 = (find_mois9 && find_mois9.ca_perf != undefined ) ? find_mois9.ca_perf : 0;
            element.ca_perf_m10 = (find_mois10 && find_mois10.ca_perf != undefined ) ? find_mois10.ca_perf : 0;
            element.ca_perf_m11 = (find_mois11 && find_mois11.ca_perf != undefined ) ? find_mois11.ca_perf : 0;
            element.ca_perf_m12 = (find_mois12 && find_mois12.ca_perf != undefined ) ? find_mois12.ca_perf : 0;

            element.ca_LM_m1 = (find_mois1 && find_mois1.ca_LM != undefined ) ? find_mois1.ca_LM : 0;
            element.ca_LM_m2 = (find_mois2 && find_mois2.ca_LM != undefined ) ? find_mois2.ca_LM : 0;
            element.ca_LM_m3 = (find_mois3 && find_mois3.ca_LM != undefined ) ? find_mois3.ca_LM : 0;
            element.ca_LM_m4 = (find_mois4 && find_mois4.ca_LM != undefined ) ? find_mois4.ca_LM : 0;
            element.ca_LM_m5 = (find_mois5 && find_mois5.ca_LM != undefined ) ? find_mois5.ca_LM : 0;
            element.ca_LM_m6 = (find_mois6 && find_mois6.ca_LM != undefined ) ? find_mois6.ca_LM : 0;
            element.ca_LM_m7 = (find_mois7 && find_mois7.ca_LM != undefined ) ? find_mois7.ca_LM : 0;
            element.ca_LM_m8 = (find_mois8 && find_mois8.ca_LM != undefined ) ? find_mois8.ca_LM : 0;
            element.ca_LM_m9 = (find_mois9 && find_mois9.ca_LM != undefined ) ? find_mois9.ca_LM : 0;
            element.ca_LM_m10 = (find_mois10 && find_mois10.ca_LM != undefined ) ? find_mois10.ca_LM : 0;
            element.ca_LM_m11 = (find_mois11 && find_mois11.ca_LM != undefined ) ? find_mois11.ca_LM : 0;
            element.ca_LM_m12 = (find_mois12 && find_mois12.ca_LM != undefined ) ? find_mois12.ca_LM : 0;

            element.ca_coreg_m1 = (find_mois1 && find_mois1.ca_coreg != undefined ) ? find_mois1.ca_coreg : 0;
            element.ca_coreg_m2 = (find_mois2 && find_mois2.ca_coreg != undefined ) ? find_mois2.ca_coreg : 0;
            element.ca_coreg_m3 = (find_mois3 && find_mois3.ca_coreg != undefined ) ? find_mois3.ca_coreg : 0;
            element.ca_coreg_m4 = (find_mois4 && find_mois4.ca_coreg != undefined ) ? find_mois4.ca_coreg : 0;
            element.ca_coreg_m5 = (find_mois5 && find_mois5.ca_coreg != undefined ) ? find_mois5.ca_coreg : 0;
            element.ca_coreg_m6 = (find_mois6 && find_mois6.ca_coreg != undefined ) ? find_mois6.ca_coreg : 0;
            element.ca_coreg_m7 = (find_mois7 && find_mois7.ca_coreg != undefined ) ? find_mois7.ca_coreg : 0;
            element.ca_coreg_m8 = (find_mois8 && find_mois8.ca_coreg != undefined ) ? find_mois8.ca_coreg : 0;
            element.ca_coreg_m9 = (find_mois9 && find_mois9.ca_coreg != undefined ) ? find_mois9.ca_coreg : 0;
            element.ca_coreg_m10 = (find_mois10 && find_mois10.ca_coreg != undefined ) ? find_mois10.ca_coreg : 0;
            element.ca_coreg_m11 = (find_mois11 && find_mois11.ca_coreg != undefined ) ? find_mois11.ca_coreg : 0;
            element.ca_coreg_m12 = (find_mois12 && find_mois12.ca_coreg != undefined ) ? find_mois12.ca_coreg : 0;

            element.ca_MG_m1 = (find_mois1 && find_mois1.ca_MG != undefined ) ? find_mois1.ca_MG : 0;
            element.ca_MG_m2 = (find_mois2 && find_mois2.ca_MG != undefined ) ? find_mois2.ca_MG: 0;
            element.ca_MG_m3 = (find_mois3 && find_mois3.ca_MG != undefined ) ? find_mois3.ca_MG : 0;
            element.ca_MG_m4 = (find_mois4 && find_mois4.ca_MG != undefined ) ? find_mois4.ca_MG : 0;
            element.ca_MG_m5 = (find_mois5 && find_mois5.ca_MG != undefined ) ? find_mois5.ca_MG : 0;
            element.ca_MG_m6 = (find_mois6 && find_mois6.ca_MG != undefined ) ? find_mois6.ca_MG : 0;
            element.ca_MG_m7 = (find_mois7 && find_mois7.ca_MG != undefined ) ? find_mois7.ca_MG : 0;
            element.ca_MG_m8 = (find_mois8 && find_mois8.ca_MG != undefined ) ? find_mois8.ca_MG : 0;
            element.ca_MG_m9 = (find_mois9 && find_mois9.ca_MG != undefined ) ? find_mois9.ca_MG : 0;
            element.ca_MG_m10 = (find_mois10 && find_mois10.ca_MG != undefined ) ? find_mois10.ca_MG : 0;
            element.ca_MG_m11 = (find_mois11 && find_mois11.ca_MG != undefined ) ? find_mois11.ca_MG : 0;
            element.ca_MG_m12 = (find_mois12 && find_mois12.ca_MG != undefined ) ? find_mois12.ca_MG : 0;

            element.ca_Global_m1 = (find_mois1 && find_mois1.ca_Global != undefined ) ? find_mois1.ca_Global : 0;
            element.ca_Global_m2 = (find_mois2 && find_mois2.ca_Global != undefined ) ? find_mois2.ca_Global : 0;
            element.ca_Global_m3 = (find_mois3 && find_mois3.ca_Global != undefined ) ? find_mois3.ca_Global : 0;
            element.ca_Global_m4 = (find_mois4 && find_mois4.ca_Global != undefined ) ? find_mois4.ca_Global : 0;
            element.ca_Global_m5 = (find_mois5 && find_mois5.ca_Global != undefined ) ? find_mois5.ca_Global : 0;
            element.ca_Global_m6 = (find_mois6 && find_mois6.ca_Global != undefined ) ? find_mois6.ca_Global : 0;
            element.ca_Global_m7 = (find_mois7 && find_mois7.ca_Global != undefined ) ? find_mois7.ca_Global : 0;
            element.ca_Global_m8 = (find_mois8 && find_mois8.ca_Global != undefined ) ? find_mois8.ca_Global : 0;
            element.ca_Global_m9 = (find_mois9 && find_mois9.ca_Global != undefined ) ? find_mois9.ca_Global : 0;
            element.ca_Global_m10 = (find_mois10 && find_mois10.ca_Global != undefined ) ? find_mois10.ca_Global : 0;
            element.ca_Global_m11 = (find_mois11 && find_mois11.ca_Global != undefined ) ? find_mois11.ca_Global : 0;
            element.ca_Global_m12 = (find_mois12 && find_mois12.ca_Global != undefined ) ? find_mois12.ca_Global : 0;


          });
          if(this.Trimestre === 5){
            //console.log(this.CaMois1.filter(obj => { return (obj.ca_Global + obj.ca_Global_m3 + obj.ca_Global_m2 + obj.ca_Global_m4 + obj.ca_Global_m5 + obj.ca_Global_m6 + obj.ca_Global_m7 + obj.ca_Global_m8 + obj.ca_Global_m9 + obj.ca_Global_m10 + obj.ca_Global_m11 + obj.ca_Global_m12) != 0 }))
            return  this.CaMois1.filter(obj => { return (obj.ca_Global + obj.ca_Global_m3 + obj.ca_Global_m2 + obj.ca_Global_m4 + obj.ca_Global_m5 + obj.ca_Global_m6 + obj.ca_Global_m7 + obj.ca_Global_m8 + obj.ca_Global_m9 + obj.ca_Global_m10 + obj.ca_Global_m11 + obj.ca_Global_m12) != 0 })
          }

          //console.log(this.CaMois1.filter(obj => { return (obj.ca_Global + obj.ca_Global_m3 + obj.ca_Global_m2) != 0 }))
          return this.CaMois1.filter(obj => { return (obj.ca_Global + obj.ca_Global_m3 + obj.ca_Global_m2) != 0 })
        },
      dataCaEditeurT(){
        // editeur
          this.CaMois1_Editeur.forEach(element => {
            let find_mois1 = this.CaMois1_Editeur.find(x => x.id_user == element.id_user);
            let find_mois2 = this.CaMois2_Editeur.find(x => x.id_user == element.id_user);
            let find_mois3 = this.CaMois3_Editeur.find(x => x.id_user == element.id_user);
            let find_mois4 = this.CaMois4_Editeur.find(x => x.id_user == element.id_user);
            let find_mois5 = this.CaMois5_Editeur.find(x => x.id_user == element.id_user);
            let find_mois6 = this.CaMois6_Editeur.find(x => x.id_user == element.id_user);
            let find_mois7 = this.CaMois7_Editeur.find(x => x.id_user == element.id_user);
            let find_mois8 = this.CaMois8_Editeur.find(x => x.id_user == element.id_user);
            let find_mois9 = this.CaMois9_Editeur.find(x => x.id_user == element.id_user);
            let find_mois10 = this.CaMois10_Editeur.find(x => x.id_user == element.id_user);
            let find_mois11 = this.CaMois11_Editeur.find(x => x.id_user == element.id_user);
            let find_mois12 = this.CaMois12_Editeur.find(x => x.id_user == element.id_user);

            element.ca_perf_m1 = (find_mois1 && find_mois1.ca_perf != undefined ) ? find_mois1.ca_perf : 0;
            element.ca_perf_m2 = (find_mois2 && find_mois2.ca_perf != undefined ) ? find_mois2.ca_perf : 0;
            element.ca_perf_m3 = (find_mois3 && find_mois3.ca_perf != undefined ) ? find_mois3.ca_perf : 0;
            element.ca_perf_m4 = (find_mois4 && find_mois4.ca_perf != undefined ) ? find_mois4.ca_perf : 0;
            element.ca_perf_m5 = (find_mois5 && find_mois5.ca_perf != undefined ) ? find_mois5.ca_perf : 0;
            element.ca_perf_m6 = (find_mois6 && find_mois6.ca_perf != undefined ) ? find_mois6.ca_perf : 0;
            element.ca_perf_m7 = (find_mois7 && find_mois7.ca_perf != undefined ) ? find_mois7.ca_perf : 0;
            element.ca_perf_m8 = (find_mois8 && find_mois8.ca_perf != undefined ) ? find_mois8.ca_perf : 0;
            element.ca_perf_m9 = (find_mois9 && find_mois9.ca_perf != undefined ) ? find_mois9.ca_perf : 0;
            element.ca_perf_m10 = (find_mois10 && find_mois10.ca_perf != undefined ) ? find_mois10.ca_perf : 0;
            element.ca_perf_m11 = (find_mois11 && find_mois11.ca_perf != undefined ) ? find_mois11.ca_perf : 0;
            element.ca_perf_m12 = (find_mois12 && find_mois12.ca_perf != undefined ) ? find_mois12.ca_perf : 0;

            element.ca_LM_m1 = (find_mois1 && find_mois1.ca_LM != undefined ) ? find_mois1.ca_LM : 0;
            element.ca_LM_m2 = (find_mois2 && find_mois2.ca_LM != undefined ) ? find_mois2.ca_LM : 0;
            element.ca_LM_m3 = (find_mois3 && find_mois3.ca_LM != undefined ) ? find_mois3.ca_LM : 0;
            element.ca_LM_m4 = (find_mois4 && find_mois4.ca_LM != undefined ) ? find_mois4.ca_LM : 0;
            element.ca_LM_m5 = (find_mois5 && find_mois5.ca_LM != undefined ) ? find_mois5.ca_LM : 0;
            element.ca_LM_m6 = (find_mois6 && find_mois6.ca_LM != undefined ) ? find_mois6.ca_LM : 0;
            element.ca_LM_m7 = (find_mois7 && find_mois7.ca_LM != undefined ) ? find_mois7.ca_LM : 0;
            element.ca_LM_m8 = (find_mois8 && find_mois8.ca_LM != undefined ) ? find_mois8.ca_LM : 0;
            element.ca_LM_m9 = (find_mois9 && find_mois9.ca_LM != undefined ) ? find_mois9.ca_LM : 0;
            element.ca_LM_m10 = (find_mois10 && find_mois10.ca_LM != undefined ) ? find_mois10.ca_LM : 0;
            element.ca_LM_m11 = (find_mois11 && find_mois11.ca_LM != undefined ) ? find_mois11.ca_LM : 0;
            element.ca_LM_m12 = (find_mois12 && find_mois12.ca_LM != undefined ) ? find_mois12.ca_LM : 0;

            element.ca_coreg_m1 = (find_mois1 && find_mois1.ca_coreg != undefined ) ? find_mois1.ca_coreg : 0;
            element.ca_coreg_m2 = (find_mois2 && find_mois2.ca_coreg != undefined ) ? find_mois2.ca_coreg : 0;
            element.ca_coreg_m3 = (find_mois3 && find_mois3.ca_coreg != undefined ) ? find_mois3.ca_coreg : 0;
            element.ca_coreg_m4 = (find_mois4 && find_mois4.ca_coreg != undefined ) ? find_mois4.ca_coreg : 0;
            element.ca_coreg_m5 = (find_mois5 && find_mois5.ca_coreg != undefined ) ? find_mois5.ca_coreg : 0;
            element.ca_coreg_m6 = (find_mois6 && find_mois6.ca_coreg != undefined ) ? find_mois6.ca_coreg : 0;
            element.ca_coreg_m7 = (find_mois7 && find_mois7.ca_coreg != undefined ) ? find_mois7.ca_coreg : 0;
            element.ca_coreg_m8 = (find_mois8 && find_mois8.ca_coreg != undefined ) ? find_mois8.ca_coreg : 0;
            element.ca_coreg_m9 = (find_mois9 && find_mois9.ca_coreg != undefined ) ? find_mois9.ca_coreg : 0;
            element.ca_coreg_m10 = (find_mois10 && find_mois10.ca_coreg != undefined ) ? find_mois10.ca_coreg : 0;
            element.ca_coreg_m11 = (find_mois11 && find_mois11.ca_coreg != undefined ) ? find_mois11.ca_coreg : 0;
            element.ca_coreg_m12 = (find_mois12 && find_mois12.ca_coreg != undefined ) ? find_mois12.ca_coreg : 0;

            element.ca_MG_m1 = 0;
            element.ca_MG_m2 = 0;
            element.ca_MG_m3 = 0;
            element.ca_MG_m4 = 0;
            element.ca_MG_m5 = 0;
            element.ca_MG_m6 = 0;
            element.ca_MG_m7 = 0;
            element.ca_MG_m8 = 0;
            element.ca_MG_m9 = 0;
            element.ca_MG_m10 = 0;
            element.ca_MG_m11 = 0;
            element.ca_MG_m12 = 0;

            element.ca_Global_m1 = ( find_mois1 &&  find_mois1.ca_Global != undefined ) ? find_mois1.ca_Global : 0;
            element.ca_Global_m2 = ( find_mois2 &&  find_mois2.ca_Global != undefined ) ? find_mois2.ca_Global : 0;
            element.ca_Global_m3 = ( find_mois3 &&  find_mois3.ca_Global != undefined ) ? find_mois3.ca_Global : 0;
            element.ca_Global_m4 = ( find_mois4 &&  find_mois4.ca_Global != undefined ) ? find_mois4.ca_Global : 0;
            element.ca_Global_m5 = ( find_mois5 &&  find_mois5.ca_Global != undefined ) ? find_mois5.ca_Global : 0;
            element.ca_Global_m6 = ( find_mois6 &&  find_mois6.ca_Global != undefined ) ? find_mois6.ca_Global : 0;
            element.ca_Global_m7 = ( find_mois7 &&  find_mois7.ca_Global != undefined ) ? find_mois7.ca_Global : 0;
            element.ca_Global_m8 = ( find_mois8 &&  find_mois8.ca_Global != undefined ) ? find_mois8.ca_Global : 0;
            element.ca_Global_m9 = ( find_mois9 &&  find_mois9.ca_Global != undefined ) ? find_mois9.ca_Global : 0;
            element.ca_Global_m10 = ( find_mois10 &&  find_mois10.ca_Global != undefined ) ? find_mois10.ca_Global : 0;
            element.ca_Global_m11 = ( find_mois11 &&  find_mois11.ca_Global != undefined ) ? find_mois11.ca_Global : 0;
            element.ca_Global_m12 = ( find_mois12 &&  find_mois12.ca_Global != undefined ) ? find_mois12.ca_Global : 0;
          });

           if(this.Trimestre === 5){
            //console.log(this.CaMois1.filter(obj => { return (obj.ca_Global + obj.ca_Global_m3 + obj.ca_Global_m2 + obj.ca_Global_m4 + obj.ca_Global_m5 + obj.ca_Global_m6 + obj.ca_Global_m7 + obj.ca_Global_m8 + obj.ca_Global_m9 + obj.ca_Global_m10 + obj.ca_Global_m11 + obj.ca_Global_m12) != 0 }))
            return this.CaMois1_Editeur.filter(obj => { return (obj.ca_Global + obj.ca_Global_m3 + obj.ca_Global_m2 + obj.ca_Global_m4 + obj.ca_Global_m5 + obj.ca_Global_m6 + obj.ca_Global_m7 + obj.ca_Global_m8 + obj.ca_Global_m9 + obj.ca_Global_m10 + obj.ca_Global_m11 + obj.ca_Global_m12) != 0 })
          }

          //console.log(this.CaMois1_Editeur.filter(obj => { return (obj.ca_Global + obj.ca_Global_m3 + obj.ca_Global_m2) != 0 }))
          return this.CaMois1_Editeur.filter(obj => { return (obj.ca_Global + obj.ca_Global_m2 + obj.ca_Global_m3) != 0 })

      },
      dataCaPlf(){
          return [...this.CaPlf_Mois1, ...this.CaPlf_Mois2, ...this.CaPlf_Mois3, ...this.CaPlf_Mois4, ...this.CaPlf_Mois5, ...this.CaPlf_Mois6, ...this.CaPlf_Mois7, ...this.CaPlf_Mois8, ...this.CaPlf_Mois9, ...this.CaPlf_Mois10, ...this.CaPlf_Mois11, ...this.CaPlf_Mois12]
      },
      dataChart(){
        let background = (this.Trimestre === 5) ? ['#2264FF', '#F2290E', '#A4A4A4', '#862323', '#008000', '#800080', '#002e2e', '#00d1d1', '#575700', '#00a8a8', '#00a8a5', '#00a8a9'] : ['#2264FF', '#F2290E', '#A4A4A4']
        // setting chart
          let label = this.dataCaPlf.map(a => a.mois);
          let setting = {
            label: '',
            pointRadius: 0,
            pointHoverRadius: 0,
            backgroundColor: background,
            borderWidth: 0,
          }
          let dataChart = {
                            WP: [],
                            WM: [],
                            WC: [],
                          }
          // data chart WEEDO PERF
          dataChart.WP = {
            labels: label,
            datasets: [
              {
                ...setting,
                data: this.dataCaPlf.map(a => parseInt(a.ca_perf))
              }
            ]
          }
          // data chart WEDDO MARKET
          dataChart.WM = {
            labels: label,
            datasets: [
              {
                ...setting,
                data: this.dataCaPlf.map(a => parseInt(a.ca_LM))
              }
            ]
          }
          // data chart WEDDO COREG
          dataChart.WC = {
            labels: label,
            datasets: [
              {
                ...setting,
                data: this.dataCaPlf.map(a => parseInt(a.ca_coreg))
              }
            ]
          }
          // data chart MONGUSTAVE
          dataChart.MG = {
            labels: label,
            datasets: [
              {
                ...setting,
                data: this.dataCaPlf.map(a => parseInt(a.ca_MG))
              }
            ]
          }
        return dataChart
      }

    },
    methods: {
        //here
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
       setShowCaClient(val){
        this.showCaClient = val
        this.Platforme = ''
       },
      async search() {
        this.loadingQueries = true;
        await this.sleep(100);
        this.Platforme = ''
        this.CaMois1 = [];
        this.CaMois2 = [];
        this.CaMois3 = [];
        this.CaMois4 = [];
        this.CaMois5 = [];
        this.CaMois6 = [];
        this.CaMois7 = [];
        this.CaMois8 = [];
        this.CaMois9 = [];
        this.CaMois10 = [];
        this.CaMois11 = [];
        this.CaMois12 = [];
        this.CaMois1_Editeur = [];
        this.CaMois2_Editeur = [];
        this.CaMois3_Editeur = [];
        this.CaMois4_Editeur = [];
        this.CaMois5_Editeur = [];
        this.CaMois6_Editeur = [];
        this.CaMois7_Editeur = [];
        this.CaMois8_Editeur = [];
        this.CaMois9_Editeur = [];
        this.CaMois10_Editeur = [];
        this.CaMois11_Editeur = [];
        this.CaMois12_Editeur = [];
        this.CaPlf_Mois1 = [];
        this.CaPlf_Mois2 = [];
        this.CaPlf_Mois3 = [];
        this.CaPlf_Mois4 = [];
        this.CaPlf_Mois5 = [];
        this.CaPlf_Mois6 = [];
        this.CaPlf_Mois7 = [];
        this.CaPlf_Mois8 = [];
        this.CaPlf_Mois9 = [];
        this.CaPlf_Mois10 = [];
        this.CaPlf_Mois11 = [];
        this.CaPlf_Mois12 = [];
        this.Trimestre_Month()
        this.SkipQueryFirstMonth = false
      },
      Trimestre_Month() {
        if(this.Trimestre === 1){
          this.m1 = 1
          this.m2 = 2
          this.m3 = 3
        } else if (this.Trimestre === 2) {
          this.m1 = 4
          this.m2 = 5
          this.m3 = 6
        } else if (this.Trimestre === 3) {
          this.m1 = 7
          this.m2 = 8
          this.m3 = 9
        } else if (this.Trimestre === 4) {
          this.m1 = 10
          this.m2 = 11
          this.m3 = 12
        }
        else {
          this.m1 = 1
          this.m2 = 2
          this.m3 = 3
          this.m4 = 4
          this.m5 = 5
          this.m6 = 6
          this.m7 = 7
          this.m8 = 8
          this.m9 = 9
          this.m10 = 10
          this.m11 = 11
          this.m12 = 12
        }
        this.labelM1 = moment(this.m1, 'MM').locale('fr').format('MMMM');
        this.labelM2 = moment(this.m2, 'MM').locale('fr').format('MMMM');
        this.labelM3 = moment(this.m3, 'MM').locale('fr').format('MMMM');
        this.labelM4 = moment(this.m4, 'MM').locale('fr').format('MMMM');
        this.labelM5 = moment(this.m5, 'MM').locale('fr').format('MMMM');
        this.labelM6 = moment(this.m6, 'MM').locale('fr').format('MMMM');
        this.labelM7 = moment(this.m7, 'MM').locale('fr').format('MMMM');
        this.labelM8 = moment(this.m8, 'MM').locale('fr').format('MMMM');
        this.labelM9 = moment(this.m9, 'MM').locale('fr').format('MMMM');
        this.labelM10 = moment(this.m10, 'MM').locale('fr').format('MMMM');
        this.labelM11 = moment(this.m11, 'MM').locale('fr').format('MMMM');
        this.labelM12 = moment(this.m12, 'MM').locale('fr').format('MMMM');

      }
    }
  }

</script>
