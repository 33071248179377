<template>
  <div class="col-12 background-repoort">
    <div class="row mt-5">
      <div class="col-12">
        <div class="row">
          <div class="col-sm-12 text-left">
            <h2 class="card-title"> 
              <center><template v-if="Facture"> LES FACTURES {{ Facture.month_label }}/{{ Facture.year }} </template></center>
            </h2>
          </div>
        </div>
        <div class="col-md-12">
          <el-tooltip
            content="télécharger toutes les factures du mois "
            effect="light"
            placement="top"
          >
            <base-button v-if="!loadDownload" type="market" size="sm" @click="DownloadAllFacture()">
              <i class="fas fa-download icon-large" ></i>
            </base-button>
             <base-button v-else loading type="market" size="sm"></base-button>
          </el-tooltip>
          <div class="card">
            <div class="card-body">
              <el-table :data="listFactures" v-loading="!SkipQuery" show-summary row-class-name="booking-table-rows" :summary-method="getSummaries">
                <el-table-column
                  prop='client'
                  label="Client"
                  sortable
                  min-width="180"
                  show-overflow-tooltip
                  :sort-method="CustomSort_Society"
                >
                </el-table-column>
                <el-table-column
                  prop='ca'
                  label="Total HT"
                  sortable
                  min-width="140"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.ca,true,2) + ' €' }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop='ca'
                  label="Total TTC"
                  sortable
                  min-width="140"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.ca,true,2) + ' €' }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop=''
                  label="Action"
                  sortable
                  min-width="200"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    <el-tooltip
                      content="Email destinataires"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click="openMailRecipient(scope.row)"
                        class="btn-link"
                        type="market"
                        size="sm"
                        icon
                      >
                        <i class="fas fa-at"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                      content="PDF"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="handleDownload(scope.row)"
                        class="btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="el-icon-document"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                      :content="scope.row.mail_envoye==0 ? 'Envoyer' : 'Renvoyer'"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        :disabled="disable_send"
                        @click.native="SendMail(scope.row)"
                        class="btn-link"
                        :type="scope.row.mail_envoye==0 ? 'info' : 'danger'"
                        size="sm"
                        icon
                      >
                        <i class="fas fa-paper-plane"></i>
                      </base-button>
                    </el-tooltip>
                  </template>
                </el-table-column>
              </el-table>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal email -->
    <modal
      :show.sync="showMailRecipient"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-listbase"
    >
      <card
        type="secondary"
        header-classes="bg-white pb-6"
        body-classes="px-lg-6 py-lg-6"
        class="border-0 mb-0"
      >
        <el-tooltip
          content="Quitter"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <base-button
            @click.native="showMailRecipient = false"
            class="btn-link float-right"
            type="danger"
            size="sm"
            icon
          >
            <i class="tim-icons icon-simple-remove icon-large"></i>
          </base-button>
        </el-tooltip>

        <br>
        <br>
      <template>
        <div class="row justify-content-center">
          <base-input class="col-sm-7" v-model="MailRecipient.defaultEmail"></base-input>
          <base-button
            @click.native="MailRecipient.autherEmail.unshift(MailRecipient.defaultEmail);MailRecipient.defaultEmail=''"
            class="col-sm-1 edit btn-link"
            type="success"
            size="sm"
            icon
          >
            <i class="tim-icons icon-simple-add"></i>
          </base-button>
          <template v-for="(Q, index) in MailRecipient.autherEmail">
            <base-input class="col-sm-7" v-model="MailRecipient.autherEmail[index]" :key="Q.id"></base-input>
            <base-button
              :key="Q.id"
              @click.native="MailRecipient.autherEmail.splice(index, 1)"
              class="col-sm-1 edit btn-link"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </template>
        </div>
        <div class="row justify-content-center">
          <base-button @click="valideMailRecipient()">VALIDER</base-button>
        </div>
      </template>
      </card>
    </modal>
    <modal
      :show.sync="ShowPDF"
      body-classes="p-0"
      class="modal-listbase"
      :show-close="true"
      >
        <base-button style="float: right;" @click.native="ShowPDF = false" class="like btn-link" type="danger" size="sm" icon>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>
        <object :data="PDFURL" type="application/pdf" style="width: 100%;height: 900px;">
          <iframe :src="PDFURL"></iframe>
        </object>
    </modal>
  </div> 
</template>


<script>
  import { Select, Option } from 'element-ui'
  import { Table, TableColumn } from 'element-ui';
  import gql from 'graphql-tag'
  import {Modal} from 'src/components'
  import { BaseSwitch } from 'src/components/index'
  import swal from 'sweetalert2'
  import JSZip from 'jszip';

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Modal,
      BaseSwitch
    },
    apollo: {
      raports_MongustaveGrouped: {
        client: 'MARKET',
        query: gql` query ($month: Int!, $year: Int!) {
          raports_MongustaveGrouped(month: $month, year: $year) {
            id_rapport
            id_utilisateur
            id_client
            client
            produit
            type
            volume
            prix_lead
            mois
            annee
            date_creation
            etat
            email_destinataires
            mail_envoye
            ca
          }
        }`,
        variables () {
          return {
            month: this.Facture.mounth,
            year: this.Facture.year
          }
        },
        skip() {
          return this.SkipQuery;
        },
        fetchPolicy: 'network-only',
        result({ data, loader, networkStatus }) {
          this.listFactures = data.raports_MongustaveGrouped
          this.SkipQuery = true
        }
      }
    },
    computed: {
    },
    data() {
      return {
        Facture: null,
        loadDownload: false,
        SkipQuery: true,
        disable_send: false,
        PDFURL: '',
        listFactures: [],
        showMailRecipient: false,
        ShowPDF: false,
        dataMailRecipient: null,
        MailRecipient: {
          defaultEmail: '',
          autherEmail: []
        },
      }
    },
    methods: {
      openMailRecipient(row) {
        this.showMailRecipient = true;
        this.dataMailRecipient = row;

        this.MailRecipient.defaultEmail = ""
        this.MailRecipient.autherEmail = []

        if (row.email_destinataires) {
          this.MailRecipient.autherEmail = row.email_destinataires.split(";")
        }
      },
      async handleDownload(row, file) {
        const url_api = "https://apileadmarket.wee-do-it.net/api/generate-facture-pdf-mongustave";
        // const url_api = "http://leadmarket/api/generate-facture-pdf-mongustave"
        let accessToken = localStorage.getItem("token_jwt");
        let formData = new FormData()
        formData.append('mois', row.mois)
        formData.append('annee', row.annee)
        formData.append('id_utilisateur', row.id_utilisateur)
        let blob = null
        await this.axios
          .post(url_api,formData , {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded',authorization: `Bearer ${accessToken}` },
            responseType: "blob"
          }).then(function(response) {
            blob = new Blob([response.data], { type: 'application/pdf' })
            if (!file) {
              this.ShowPDF = true
              this.PDFURL = window.URL.createObjectURL(blob)
            }
          }.bind(this))
          .catch(function (error) {
            console.log(error.message);
          })
          return blob
      },
      DownloadAllFacture(){
        this.loadDownload = true;
        var zip = new JSZip();
        this.listFactures.forEach(element => {
          let row = {id_utilisateur: element.id_utilisateur, mois: element.mois, annee: element.annee}
          zip.file("facture_" + element.client + "_" + element.mois + "-" + element.annee + ".pdf", this.handleDownload(row, true));
        });
        zip.generateAsync({
          type: "base64"
        }).then(function(content) {
          var element = document.createElement('a');
          element.setAttribute('href', 'data:text/plain;base64,' + content);
          element.setAttribute('download', 'factures_'+this.Facture.mounth+'-'+this.Facture.year+'.zip');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          this.loadDownload = false;
        }.bind(this));
      },
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      getSummaries(param) {
        const { columns, data } = param;

        let Sum_ca = 0
        data.forEach(facture => {
          Sum_ca += facture.ca
        });
        
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 1) {
            sums[index] = this.formatThounsends(Sum_ca,true,2) + ' €'
            return;
          }
          else if( index === 2) {
            sums[index] = this.formatThounsends(Sum_ca,true,2) + ' €'
            return;
          }
          else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      valideMailRecipient() {
        let emails = ""
        // check email
        this.MailRecipient.autherEmail.forEach(email => {
          if (email && this.validateEmail(email)) {
            emails += email+";"
          }
        });
        emails = emails.slice(0, -1)

        this.$apollo.mutate({
          client: 'MARKET',
          mutation: gql`mutation ($id_utilisateur: ID!,$mois: Int!, $annee: Int!, $emails: String!) {
            changeMailRecipientMongustave(id_utilisateur: $id_utilisateur, mois: $mois, annee: $annee, emails: $emails)
          }`,
          variables: {
            id_utilisateur: this.dataMailRecipient.id_utilisateur,
            mois: this.dataMailRecipient.mois,
            annee: this.dataMailRecipient.annee,
            emails: emails
          },
        }).then(response => {
          if (response.data.changeMailRecipientMongustave) {
            this.$notify({type: 'success', message: "Email destinataire a été bien modifié." }) 
            this.dataMailRecipient.email_destinataires = emails
          }
          else {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          }           
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
        })
      },
      CustomSort_Society(a, b) {
        let s1 = a.client+""
        let s2 = b.client+""
        return s1.localeCompare(s2)
      },
      SendMail(row) {

        swal.fire({
          title: 'Êtes-vous sûr?',
          text: "",
          width: 400,
          showCancelButton: true,
          confirmButtonColor: '#988fc3',
          cancelButtonColor: '#27293D',
          confirmButtonText: 'Envoyer',
          cancelButtonText: 'Annuler'
        }).then((result) => {
          if (result.value) {
            this.disable_send = true
            this.$apollo.mutate({
              client: 'MARKET',
              mutation: gql`mutation ($id_utilisateur: ID!, $mois: Int!, $annee: Int!) {
                envoyerFactureMongustave(id_utilisateur: $id_utilisateur, mois: $mois, annee: $annee)
              }`,
              variables: {
                id_utilisateur: row.id_utilisateur,
                mois: row.mois,
                annee: row.annee
              },
            }).then(response => {
              if (response.data.envoyerFactureMongustave) {
                this.$notify({type: 'success', message: "Mail a été bien envoyé." }) 
                row.mail_envoye = 1
              }
              else {
                this.$notify({type: 'warning', message: "Le mail n'est pas envoyé" })
              }
              this.disable_send = false
            }).catch(error => {
              this.$notify({type: 'warning', message: "Le mail n'est pas envoyé" })
              this.disable_send = false
            })
          }
        })
      }
    },
    created() {
      if((this.Facture = JSON.parse(localStorage.getItem("facture"))) == null) {
        this.$router.push({ name: "Globale Reports" })
        return
      }
      // localStorage.removeItem("facture")
      this.SkipQuery = false
    },
  }
</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
</style>