<template>
  <div class="col-12 background-repoort">
    <div class="row mt-5">
      <div class="col-12">
        <div class="row">
          <div class="col-sm-12 text-left">
            <h2 class="card-title">
              <center>
                <template v-if="Payment"> 
                  REVENUS ET PAIEMENTS DÉFINITIFS
                </template>
              </center>
            </h2>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row justify-content-center">

            <div class="col-lg-3">
              <base-input>
                <el-select filterable class="select-primary"
                           placeholder="PLATFORME"
                           clearable
                           v-model="PlatformeSelects.Platforme">
                  <el-option
                          v-for="option in PlatformeSelects.ListPlatform"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="option.value">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col-lg-3">
              <base-input>
                <el-select filterable class="select-primary"
                           placeholder="EDITEURS"
                           clearable
                           v-model="EditeursSelects.Editeur">
                  <el-option
                          v-for="option in EditeursSelects.ListEditeur"
                          class="select-primary"
                          :value="option.societe_client"
                          :label="option.societe_client.toUpperCase()"
                          :key="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col-lg-3">
              <base-input>
                <el-select filterable class="select-primary"
                           placeholder="ETAT"
                           clearable
                           v-model="EtatSelects.Etat">
                  <el-option
                          v-for="option in EtatSelects.ListEtat"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="option.value">
                  </el-option>
                </el-select>
              </base-input>
            </div>

          </div>
          <div class="row justify-content-center">
            <div class="col-lg-3">
              <base-input>
                <el-select filterable class="select-primary"
                           placeholder="MOIS"
                           v-model="Month"
                           :disabled="!SkipQuery || !SkipQuery_all">
                  <el-option
                          v-for="option in MonthList"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="option.value">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            <div class="col-lg-3">
              <base-input>
                <el-select filterable class="select-primary"
                           placeholder="ANNEE"
                           v-model="Year"
                           :disabled="!SkipQuery || !SkipQuery_all">
                  <el-option
                          v-for="option in YearList"
                          class="select-primary"
                          :value="option"
                          :label="option"
                          :key="option">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col-lg-3">
              <base-input>
                <el-select 
                  clearable class="select-primary"
                  placeholder="STATUS"
                  v-model="vip_editeur"
                  :disabled="!SkipQuery || !SkipQuery_all"
                >
                  <el-option class="select-primary" value="vip" label="VIP"></el-option>
                </el-select>
              </base-input>
            </div>

          </div>

          <div class="col-md-12 float-right">
            <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
              <base-button
                      v-if="!SkipQuery || !SkipQuery_all"
                      loading
                      round
                      class="btn btn-round btn-just-icon btn-primary mb-2"
              >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
              <button
                      v-else
                      @click=""
                      class="btn btn-round btn-just-icon btn-primary mb-2"
              >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <el-table :data="listPayments_Filter" show-summary :summary-method="getSummaries" v-loading="!SkipQuery || !SkipQuery_all">

                <el-table-column type="expand">
                  <template slot-scope="props">
                    <p><b>Derniere modification : </b> {{ props.row.date_modification_perf || props.row.date_modification_coreg }} </p>
                  </template>
                </el-table-column>

                <el-table-column
                        prop= 'num_perf'
                        label= "num"
                        min-width="80"
                >
                  <template scope="scope">
                    {{ scope.row.num_perf || scope.row.num_coreg }}
                  </template>
                </el-table-column>

                <el-table-column
                        prop= 'editeur'
                        label= "editeur"
                        sortable
                        min-width="160"
                        show-overflow-tooltip
                >
                  <template scope="scope">
                    <template v-if="scope.row.statut_compte">
                      <span style="color:#FF9800; font-size: 17px;">
                        <i class="fa fa-star"></i>
                      </span>
                    </template>
                    {{ scope.row.editeur.toUpperCase() }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop='mois'
                  label="mois"
                  min-width="100"
                  show-overflow-tooltip
                  sortable
                >
                  <template scope="scope">
                    {{ scope.row.nom_mois.toUpperCase() }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop= 'revenus_credits_mois'
                  label= "Revenus"
                  sortable
                  min-width="130"
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.revenus_credits_mois,true,2) + ' €' }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop= 'solde'
                  label= "solde"
                  sortable
                  min-width="130"
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.solde,true,2) + ' €' }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop= 'somme'
                  label= "Somme"
                  sortable
                  min-width="130"
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.somme,true,2) + ' €' }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop= 'message_perf'
                  label= "Description"
                  min-width="160"
                >
                  <template scope="scope">
                    {{ scope.row.message_perf || scope.row.message_coreg }}
                    <!-- {{ (scope.row.date_perf) ? 'le : '+ scope.row.date_perf.split(' ')[0] : '' }} -->
                  </template>
                </el-table-column>

                <!-- <el-table-column
                  prop= 'date_modification_perf'
                  label= "Derniere modification"
                  min-width="140"
                >
                  <template scope="scope">
                    {{ scope.row.date_modification_perf || scope.row.date_modification_coreg }}
                  </template>
                </el-table-column> -->

                <el-table-column
                  prop= 'etat'
                  label= "Etat"
                  min-width="150"
                >
                  <template scope="scope">
                    <template v-if="scope.row.etat_perf != null">
                        <select class="custom-select" @change="StatChanged(scope.row)" v-if="scope.row.etat_perf == 1 || scope.row.etat_perf == 3" v-model="scope.row.etat_perf">
                          <option v-for="option in EtatSelects.ListEtat" :key="option.value" :value="option.value">{{ option.label }}</option>
                        </select>
                        <template v-if="scope.row.etat_perf != 1 && scope.row.etat_perf != 3">
                        <center>
                          -
                        </center>
                        </template>
                    </template>

                    <template v-else>
                        <select class="custom-select" @change="StatChanged(scope.row)" v-if="scope.row.etat_coreg == 1 || scope.row.etat_coreg == 3" v-model="scope.row.etat_coreg">
                            <option v-for="option in EtatSelects.ListEtat" :key="option.value" :value="option.value">{{ option.label }}</option>
                        </select>
                        <template v-if="scope.row.etat_coreg != 1 && scope.row.etat_coreg != 3">
                        <center>
                          -
                        </center>
                        </template>
                    </template>
                </template>
                </el-table-column>

                <el-table-column
                  prop= 'date_perf'
                  label= "Date Upload"
                  min-width="155"
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    <label :style="'color:'+setColorDateupload(scope.row)">
                      {{ scope.row.date_perf || scope.row.date_coreg || '-' }}
                    </label>
                  </template>
                </el-table-column>

                <el-table-column :min-width="120" align="right" label="Actions">
                  <div slot-scope="props">
                      <button title="PDF signé" class="btn btn-link btn-icon btn-fab btn-warning btn-sm" v-if="props.row.pdf_perf == null && props.row.pdf_coreg == null">
                        -
                      </button>
                      <button title="PDF signé" class="btn btn-link btn-icon btn-fab btn-success btn-sm" v-else @click="OpenPDF(props.row.pdf_perf || props.row.pdf_coreg)">
                        <i class="el-icon-download"></i>
                      </button>
                      <button title="Informations bancaires" class="btn btn-link btn-icon btn-fab btn-info btn-sm" @click="userId_Info = props.row.id_utilisateur; showInfosBancaire = true">
                        <i class="tim-icons icon-wallet-43"></i>
                      </button>
                  </div>
                </el-table-column>
                
                <el-table-column
                  label="Platforme"
                  :min-width="110"
                  class="table-striped"
                >
                  <template scope="scope">
                    <span class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.flag == 'multi' || scope.row.flag == 'perf' ">
                      <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                           viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                          />
                        </g>
                      </svg>
                    </span>
                    <span class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.flag == 'multi' || scope.row.flag == 'coreg'">
                      <svg version="1.1" id="Calque_1" fill="#54c4d5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                           viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                          />
                        </g>
                      </svg>
                    </span>
                  </template>
                </el-table-column>

              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
            :show.sync="showInfosBancaire"
            body-classes="p-0"
            class="modal-listbase">
      <card type="secondary"
            header-classes="pb-6"
            body-classes="px-lg-6 py-lg-6"
            class="border-0 mb-0">
        <template slot="header">
          <base-button
                  @click.native="showInfosBancaire = false"
                  class="btn-link float-right"
                  type="danger"
                  size="sm"
                  icon
          >
            <i class="tim-icons icon-simple-remove"></i>
          </base-button>
        </template>

        <template v-if="infosBancaire">
          <div class="form-row">
            <label class="col-md-6"><b>{{ (infosBancaire.international == 0) ? 'FRANÇAIS' : 'INTERNATIONAL' }}</b></label>
            <label class="col-md-6"><b>NOM DE BANQUE : </b>{{ infosBancaire.nom_banque }} </label>
          </div>

          <div class="form-row">
            <label class="col-md-6"><b>Titulaire : </b>{{ infosBancaire.titulaire }}</label>
            <label class="col-md-6"><b>RIB : </b>{{ infosBancaire.rib }}</label>
          </div>

          <div class="form-row">
            <label class="col-md-6"><b>IBAN : </b>{{ infosBancaire.iban }} </label>
            <label class="col-md-6"><b>SWIFT / BIC : </b>{{ infosBancaire.swift }}</label>
          </div>
          <div class="form-row">
            <label class="col-md-6"><b>Domiciliation : </b>{{ infosBancaire.domiciliation }} </label>
            <label class="col-md-6"><b>TVA : </b>{{ infosBancaire.tva }} </label>

          </div>
        </template>
      </card>
    </modal>
  </div>
</template>

<script>
  import { Select, Option } from 'element-ui'
  import { Table, TableColumn } from 'element-ui';
  import gql from 'graphql-tag'
  import { Modal } from 'src/components'
  import moment from 'moment';

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Modal
    },
    apollo: {
      getPaiment: {
        client: 'AUTH',
        query: require("src/graphql/Payments.gql"),
        fetchPolicy: 'network-only',
        result({ data, loader, networkStatus }) {
          this.listPayments = data.getPaiment
          this.SkipQuery = true
          // FILLING FILTERS
          this.listPayments.forEach(element => {
            this.EditeursSelects.ListEditeur.push({ societe_client: element.editeur })
          });
          this.EditeursSelects.ListEditeur.sort((a, b) => a.societe_client.localeCompare(b.societe_client));
        },
        skip() {
          return this.SkipQuery;
        },
        variables () {
          return {
            annee: this.Year,
            mois: this.Month
          }
        }
      },
      infosBancaire: {
        client: "AUTH",
        query: gql`
          query($id_affailie: ID) {
            infosBancaire(id_affailie: $id_affailie) {
              id
              international
              titulaire
              nom_banque
              n_compte
              domiciliation
              rib
              iban
              swift
              tva
            }
          }
        `,
        skip() {
          return this.userId_Info == null
        },
        variables() {
          return {
            id_affailie: this.userId_Info
          };
        },
        result({ data, loader, networkStatus }) {

        }
      }
    },
    computed: {
      listPayments_Filter() {
        let list = null

        try {
          list = this.listPayments.filter((payment) => {
            return  payment.editeur.match(this.EditeursSelects.Editeur) &&
                    (payment.etat_perf+"").match(this.EtatSelects.Etat) &&
                    (payment.flag.match(this.PlatformeSelects.Platforme)  || payment.flag.match("multi") ) &&
                    (payment.statut_compte+"").match(this.vip_editeur)
          })
        } catch (error) {
          return list
        }

        return list
      }
    },
    watch: {
      showInfosBancaire(value) {
        if(!value)
          this.infosBancaire = null
      },
      Month(value) {
        if (value == "all") {
          this.RunAllPaiment();
        }
        else {
          this.SkipQuery = false
        }
      },
      Year() {
        if (this.Month == "all") {
          this.RunAllPaiment();
        }
        else {
          this.SkipQuery = false
        }
      }
    },
    data() {
      return {
        vip_editeur: '',
        SkipQuery: true,
        SkipQuery_all: true,
        showInfosBancaire: false,
        userId_Info: null,
        Payment: null,
        listPayments: [],
        MonthList: [],
        Month: null,
        YearList: [],
        Year: null,
        EditeursSelects: {
          Editeur: '',
          ListEditeur: []
        },
        PlatformeSelects: {
          Platforme: '',
          ListPlatform: [
            { value: "perf", label: "WEEDO PERF" },
            { value: "coreg", label: "WEEDO COREG" }
          ]
        },
        EtatSelects: {
          Etat: '',
          ListEtat: [
            { value: 0, label:"Revenus reportes"},
            { value: 1, label:"AF emis"},
            { value: 2, label:"Non-parvenu"},
            { value: 3, label:"Réceptionné"},
            { value: 4, label:"Payé"},
            { value: 5, label:"Non-conforme"},
            { value: 6, label:"Prepaiment cb"},
            { value: 7, label:"Bloqué"}
          ]
        },
        fields: [
          { key: 'num_perf', label: 'num' },
          { key: 'editeur', label: 'editeur', sortable: true },
          { key: 'mois', label: 'mois', sortable: true },
          { key: 'revenus_credits_mois', label: 'Revenus', sortable: true },
          { key: 'solde', label: 'solde', sortable: true },
          { key: 'somme', label: 'Somme', sortable: true },
          { key: 'message_perf', label: 'Description' },
          { key: 'etat', label: 'Etat' },
          { key: 'date_perf', label: 'Date Upload' },
          'platforme',
          'actions'
        ],
      }
    },
    methods: {
      async StatChanged(row) {
        await this.$apollo.mutate({
          client: "AUTH",
          mutation: require("src/graphql/mutation/Update_Payment_Stat.gql"),
          variables: {
            id_perf: row.num_perf,
            id_coreg: row.num_coreg,
            etat: row.etat_perf || row.etat_coreg
          }
        }).then(response => {
          this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })

        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
        })
      },
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      OpenPDF(url) {
        window.open(url, '_blank');
      },
      sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
      async RunAllPaiment() {
        this.SkipQuery_all = false
        this.listPayments = []
        this.MonthList.forEach(element => {
          if (element.value != "all") {
            this.SkipQuery_all = false
            this.$apollo.query({
              client: 'AUTH',
              fetchPolicy: 'network-only',
              query: require("src/graphql/Payments.gql"),
              variables: {
                annee: this.Year,
                mois: element.value
              },
            }).then(data => {
              this.listPayments = this.listPayments.concat(data.data.getPaiment);
            }).catch((error) => {
              console.error(error)
            })
          }
        });
        await this.sleep(25000);
        this.SkipQuery_all = true
        // FILLING FILTERS
        this.listPayments.forEach(element => {
          this.EditeursSelects.ListEditeur.push({ societe_client: element.editeur })
        });
        this.EditeursSelects.ListEditeur = this.EditeursSelects.ListEditeur.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.societe_client === value.societe_client
          ))
        )
        this.EditeursSelects.ListEditeur.sort((a, b) => a.societe_client.localeCompare(b.societe_client));
      },
      getSummaries(param) {
        const { columns, data } = param;

        let Revenus = 0
        let Solde = 0
        let Somme = 0
        data.forEach(payment => {
          Revenus += payment.revenus_credits_mois
          Solde += payment.solde
          Somme += payment.somme
        });

        const sums = [];
        columns.forEach((column, index) => {
          if (index === 4) {
            sums[index] = this.formatThounsends(Revenus,true,2) + ' €'
            return;
          }
          else if( index === 5) {
            sums[index] = this.formatThounsends(Solde,true,2) + ' €'
            return;
          }
          else if( index === 6) {
            sums[index] = this.formatThounsends(Somme,true,2) + ' €'
            return;
          }
          else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      setColorDateupload(row) {
        const date = row.date_perf || row.date_coreg || null
        const etat = row.etat_perf || row.etat_coreg
        let listetat = [4, 0, 6]

        if (date != null) {
          var a = moment();
          var b = moment(date, "YYYY-MM-DD");
          if (a.diff(b, 'days') >= 45 && !listetat.includes(etat)) {
            return "orange"
          }
          return ""
        }
        return ""
      }
    },
    created() {
      moment.locale('fr');
      this.MonthList = []
      this.YearList = []
      this.MonthList.push({value: "all", label: "TOUTE L'ANNÉE"})
      var m = moment();
      for (let i = 0; i < 12; i++) {
        this.MonthList.push({value: m.months(i).format('MM'), label: m.months(i).format('MMMM').toUpperCase()})
      } 

      for (let index = 2011; index <= moment().format("YYYY"); index++) {
        this.YearList.push(index)
      }

      if((this.Payment = JSON.parse(localStorage.getItem("payment"))) == null) {
        this.$router.push({ name: "Globale Reports" })
        return
      }
      this.Month = ((this.Payment.mounth+"").length==1) ? "0"+this.Payment.mounth : this.Payment.mounth+""
      this.Year = this.Payment.year
      // localStorage.removeItem("payment")
      this.SkipQuery = false
    },
  }
</script>

<style scoped>
  .custom-select {
    background-color: #000000;
    border-width: 1px;
    border-color: #2b3553;
    color: #ffffff;
  }
</style>