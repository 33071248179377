<template>
	<div class="container-fluid">
		<div class="btn-group btn-group-toggle" data-toggle="buttons">
			<router-link
				class="btn btn-sm btn-info btn-simple"
				:class="$route.name == 'stats-valides-myaffil' ? 'active' : ''"
				id="0"
				data-toggle="collapse"
				href="#collapseone"
				aria-expanded="false"
				aria-controls="collapseExample"
				:to="{ name: 'stats-valides-myaffil' }"
			>

			<span class="d-none d-sm-block"> 
				<img src="/img/weedo-it-logo-blanc.png" alt="" /> Weedo Perf
			</span>
			<span class="d-block d-sm-none">
			  <img src="/img/weedo-it-logo-blanc.png" alt="" />
			</span>

			</router-link>
			<router-link
				class="btn btn-sm btn-coreg btn-simple"
				:class="$router.name == 'stats-valides-mycoreg' ? 'active' : ''"
				id="1"
				data-toggle="collapse"
				href="#collapsetwo"
				aria-expanded="false"
				aria-controls="collapseExample"
				:to="{ name: 'stats-valides-mycoreg' }"
			>
				<input type="radio" class="d-none d-sm-none" name="options" />
				<span class="d-none d-sm-block" > 
					<img src="/img/weedo-it-logo-blanc.png" alt="" /> Weedo Coreg
				</span>
				<span class="d-block d-sm-none">
					<img src="/img/weedo-it-logo-blanc.png" alt="" />
				</span>
			</router-link>
		</div>
		<p>&nbsp;</p>
		<zoom-center-transition :duration="200" mode="out-in">
			<router-view></router-view>
		</zoom-center-transition>
	</div>
</template>

<script>
import { ZoomCenterTransition } from "vue2-transitions";

export default {
	components: {
		ZoomCenterTransition
	}
};
</script>
