<template>
  <div class="col-12 background-repoort">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="col-12">
          <div class="row">
            <div class="col-md-12">
              <div
                class="btn-group btn-group-toggle"
                data-toggle="buttons"
              >
                <label class="btn btn-sm btn-info btn-simple">
                  <input
                    type="radio"
                    @click="SwitchToPerf"
                    name="options"
                    autocomplete="off"
                  />
                  <span class="d-none d-sm-block" style="width: 180px;"> <img src="/img/weedo-it-logo-blanc.png" alt="" /> WEEDO PERF LIGHT</span>
                  <span class="d-block d-sm-none">
                    <img src="/img/weedo-it-logo-blanc.png" alt="" />
                  </span>
                </label>
                <!-- <label class="btn btn-sm btn-info btn-simple">
                  <input
                    type="radio"
                    @click="SwitchToPerf_origin"
                    name="options"
                    autocomplete="off"
                  />
                  <span class="d-none d-sm-block" style="width: 150px;"> <img src="/img/weedo-it-logo-blanc.png" alt="" /> WEEDO PERF</span>
                  <span class="d-block d-sm-none">
                    <img src="/img/weedo-it-logo-blanc.png" alt="" />
                  </span>
                </label> -->
                <label class="btn btn-sm btn-coreg btn-simple active">
                  <input
                    type="radio"
                    @click=""
                    name="options"
                    autocomplete="off"
                  />
                  <span class="d-none d-sm-block" style="width: 150px;"> <img src="/img/weedo-it-logo-blanc.png" alt="" /> WEEDO COREG</span>
                  <span class="d-block d-sm-none">
                    <img src="/img/weedo-it-logo-blanc.png" alt="" />
                  </span>
                </label>
                <label class="btn btn-sm btn-warning btn-simple">
                  <input
                          type="radio"
                          @click="SwitchToMongustave"
                          name="options"
                          autocomplete="off"
                  />
                  <span class="d-none d-sm-block" style="width: 150px;">
                    <img src="img/ico-mongustave.png"  width="34" height="29"/> MON GUSTAVE</span>
                  <span class="d-block d-sm-none">
                    <img src="img/ico-mongustave.png"  width="34" height="29"/>
                  </span>
                </label>
              </div>
            </div>
            <div class="col-sm-12 text-left">
              <h2 class="card-title">
                <center><template v-if="Rapport"> RAPPORTS {{ Rapport.month_label }}/{{ Rapport.year }} </template></center>
              </h2>
            </div>
          </div>
          <div class="col-md-12">

            <div class="row justify-content-center">
              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                    placeholder="EDITEURS"
                    clearable
                    v-model="EditeursSelects.Editeur">
                    <el-option
                      v-for="option in EditeursSelects.ListEditeur"
                      class="select-primary"
                      :value="option"
                      :label="option.toUpperCase()"
                      :key="option">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                    placeholder="ANNONCEURS"
                    clearable
                    v-model="AnnonceursSelects.Annonceurs">
                    <el-option
                      v-for="option in AnnonceursSelects.ListAnnonceurs"
                      class="select-primary"
                      :value="option"
                      :label="option.toUpperCase()"
                      :key="option">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                    placeholder="CAMPAGNES"
                    clearable
                    v-model="CampagnesSelects.Campagnes">
                    <el-option
                      v-for="option in CampagnesSelects.ListCampagnes"
                      class="select-primary text-uppercase"
                      v-if="option.client === AnnonceursSelects.Annonceurs"
                      :value="option.value"
                      :label="option.value"
                      :key="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

            </div>

            <div class="row justify-content-center">
              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                    placeholder="TYPE"
                    clearable
                    v-model="TypeSelects.Type">
                    <el-option
                      v-for="option in TypeSelects.ListType"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                    placeholder="SUPPORT"
                    clearable
                    v-model="SupportSelects.Support">
                    <el-option
                      v-for="option in SupportSelects.ListSupport"
                      class="select-primary"
                      :value="option"
                      :label="option.toUpperCase()"
                      :key="option">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                    placeholder="EMPLACEMENT"
                    clearable
                    v-model="EmplacementSelects.Emplacement">
                    <el-option
                      v-for="option in EmplacementSelects.ListEmplacement"
                      class="select-primary"
                      :value="option"
                      :label="option.toUpperCase()"
                      :key="option">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <div class="col-md-12 float-right">
              <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                <base-button
                  v-if="$apollo.queries.getRaport.loading"
                  loading
                  round
                  class="btn btn-round btn-just-icon btn-primary mb-2"
                >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                <button
                  v-else
                  @click="Reset()"
                  class="btn btn-round btn-just-icon btn-primary mb-2"
                >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
              </div>
            </div>
            <div class="col-md-12 float-right">
              <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                <base-button
                  round
                  :disabled="$apollo.queries.getRaport.loading"
                  class="btn btn-round btn-just-icon btn-coreg mb-2"
                  @click.native="showAddRapport = true"
                  :class="{'disable_input' : disableMaj}"
                >AJOUTER UNE LIGNE</base-button>
              </div>
            </div>
            <div class="col-md-12 float-right">
              <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                <base-button
                  :disabled="$apollo.queries.getRaport.loading"
                  round
                  class="btn btn-round btn-just-icon btn-coreg mb-2"
                  @click.native="exportcsv"
                >
                  <i class="far fa-file-excel"></i>EXPORT
                </base-button>
              </div>
            </div>
          </div>
        </div>
        <div class="card" v-for="(list,index) in ListRapports_Filter">
          <span slot="header" class="badge-pill badge-coreg tab-title" style="text-transform: uppercase;font-weight: bold;">
            {{ list.rapportEditeur[0].Editeur }}
          </span>
          <div class="card-body">
            <el-table :data="list.rapportEditeur" :ref="'table'+list.rapportEditeur[0].id_editeur" @selection-change="(items) => handleSelectionChange(index, items)" show-summary :summary-method="getSummaries">

              <el-table-column
                type="selection"
                width="55">
              </el-table-column>

              <el-table-column
                prop= 'ID'
                label= "id"
                sortable
                min-width="90"
              >
              </el-table-column>

              <el-table-column
                prop= 'Campagne'
                label= "Campagne"
                sortable
                min-width="140"
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ scope.row.Campagne.toUpperCase() }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'Client'
                label= "Client"
                sortable
                min-width="180"
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ scope.row.Client.toUpperCase() }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'Type'
                label= "Type"
                sortable
                min-width="100"
              >
                <template scope="scope">
                  {{ scope.row.Type.toUpperCase() }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'Support'
                label= "Support"
                sortable
                min-width="130"
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ scope.row.Support.toUpperCase() }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'Emplacement'
                label= "Emplacement"
                sortable
                min-width="150"
                show-overflow-tooltip
              >
                <template scope="scope">
                  {{ scope.row.Emplacement.toUpperCase() }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'impression'
                label= "impression"
                sortable
                min-width="135"
              >
              </el-table-column>

              <el-table-column
                prop= 'lead_brut'
                label= "lead brut"
                sortable
                min-width="140px"
              >
              </el-table-column>

              <el-table-column
                prop= 'lead_net'
                label= "lead net"
                sortable
                min-width="140px"
              >
                <template scope="scope">
                  <base-input
                    v-model.number="scope.row.lead_net"
                    type="number"
                    @keyup.enter.native="EditRow_onEnter(scope.row)"
                    :disabled="disableinput"
                    :class="{'disable_input' : disableMaj}"
                  ></base-input>
                </template>
              </el-table-column>

              <el-table-column
                prop= 'somme_ht'
                label= "Somme (HT)"
                sortable
                min-width="140px"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.somme_ht,true,2) + ' €' }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'somme_ttc'
                label= "Somme (TTC)"
                sortable
                min-width="145px"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.somme_ttc,true,2) + ' €' }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'somme_ht_c'
                label= "Somme (HT)"
                sortable
                min-width="140px"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.somme_ht_c,true,2) + ' €' }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'marge'
                label= "marge (HT)"
                sortable
                min-width="140px"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.marge,true,2) + ' €' }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'pourcentage'
                label= "%"
                sortable
                min-width="120px"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.pourcentage,true,2) + ' %' }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'prix_lead'
                label= "Prix de lead (€)"
                sortable
                min-width="160px"
              >
                <template scope="scope">
                  <base-input
                    v-model.number="scope.row.prix_lead "
                    type="number"
                    @keyup.enter.native="EditRow_onEnter(scope.row)"
                    :disabled="disableinput"
                    :class="{'disable_input' : disableMaj}"
                  ></base-input>
                </template>
              </el-table-column>
              <el-table-column
                prop= 'prix_client'
                label= "Prix client (€)"
                sortable
                min-width="160px"
              >
                <template scope="scope">
                  <base-input
                    v-model.number="scope.row.prix_client"
                    type="number"
                    @keyup.enter.native="EditRow_onEnter(scope.row)"
                    :disabled="disableinput"
                    :class="{'disable_input' : disableMaj}"
                  ></base-input>
                </template>
              </el-table-column>

              <el-table-column
                prop= 'payee'
                label= "Payée"
                sortable
                min-width="110px"
              >
              </el-table-column>

              <el-table-column
                label="Action"
                min-width="100px"
              >
                <div slot-scope="props">
                  <el-tooltip
                    content="Dupliquer"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                    v-if="props.row.ID != '-'"
                  >
                    <base-button
                      @click.native="DupliquerRow(props.row)"
                      class="btn-link"
                      type="success"
                      size="sm"
                      icon
                      :class="{'disable_input' : disableMaj}"
                    >
                      <i class="tim-icons icon-single-copy-04"></i>
                    </base-button>
                  </el-tooltip>
                </div>
              </el-table-column>

            </el-table>

            <div class="d-flex justify-content-end" v-if="list.sold.etat != '10'">
              <label class="lablelTabel">{{ list.sold.etat }}</label><br>
              <base-button v-if="list.sold.etat != 'Paye'" @click="GenrateRepport(list.rapportEditeur[0].id_editeur, list.sold,'annuler')" type="coreg" round class="float-right" title="" :class="{'disable_input' : disableMaj}">
                Annuler <i class="tim-icons icon-minimal-right"></i>
              </base-button>
            </div>
            <div class="d-flex justify-content-end" v-else>
              <label class="lablelTabel">à valider</label><br>
              <base-button @click="GenrateRepport(list.rapportEditeur[0].id_editeur, list.sold,'valider')" type="coreg" round class="float-right" title="" :class="{'disable_input' : disableMaj}">
                Valider <i class="tim-icons icon-minimal-right"></i>
              </base-button>
            </div>
          </div>
        </div>

        <div class="card" v-if="!$apollo.queries.getRaport.loading">
          <span slot="header" class="badge badge-pill badge-warning tab-title">
            TOTAL
          </span>
          <div class="card-body">
            <el-table :data="SumTotal">

              <el-table-column
                prop= 'Impression'
                label= "Impression"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Impression) }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'Lead_brut'
                label= "Leads brut"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Lead_brut) }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'Lead_net'
                label= "Leads net"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Lead_net) }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'Somme_ht'
                label= "Somme ht"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Somme_ht,true,2) + ' €' }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'Somme_ttc'
                label= "Somme ttc"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Somme_ttc,true,2) + ' €' }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'Somme_ht_c'
                label= "Somme ht"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Somme_ht_c,true,2) + ' €' }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'Marge'
                label= "Marge"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Marge,true,2) + ' €' }}
                </template>
              </el-table-column>

              <el-table-column
                prop= 'Pourcentage'
                label= "%"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Pourcentage,true,2) + ' %' }}
                </template>
              </el-table-column>

            </el-table>
          </div>
        </div>

      </div>
    </div>

    <!-- Mondal Add Rapport -->
    <modal_selfEvent
      :show.sync="showAddRapport"
      body-classes="p-0"
      class="modal-listbase">
      <card type="secondary"
        header-classes="bg-white pb-6"
        body-classes="px-lg-6 py-lg-6"
        class="border-0 mb-0">

        <el-tooltip
          content="Quitter"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <base-button
            @click.native="showAddRapport = false"
            class="btn-link float-right"
            type="danger"
            size="sm"
            icon
          >
            <i class="tim-icons icon-simple-remove icon-large"></i>
          </base-button>
        </el-tooltip>

        <br>
        <br>

        <div class="form-row">

          <base-input label="Editeurs" class="col-md-6" :error="getError('editeur')">
            <el-select filterable class="select-primary"
              placeholder="EDITEURS"
              clearable
              name="editeur"
              v-validate="'required'"
              v-model="EditeursSelects_Query.Editeur">
              <el-option
                v-for="option in EditeursSelects_Query.ListEditeur"
                class="select-primary text-uppercase"
                :value="option.id"
                :label="option.nom"
                :key="option.id">
              </el-option>
            </el-select>
          </base-input>

          <base-input label="Support" class="col-md-6" :error="getError('Support')">
            <el-select filterable class="select-primary"
              placeholder="SUPPORT"
              clearable
              name="Support"
              v-validate="'required'"
              v-model="SupportSelects_Query.Support">
              <el-option
                v-for="option in SupportSelects_Query.ListSupport"
                v-if="option.id_editeur === EditeursSelects_Query.Editeur"
                class="select-primary text-uppercase"
                :value="option.id"
                :label="option.nom"
                :key="option.id">
              </el-option>
            </el-select>
          </base-input>

        </div>

        <div class="form-row">

          <base-input label="Emplacement" class="col-md-6" :error="getError('Emplacement')">
            <el-select filterable class="select-primary"
              placeholder="EMPLACEMENT"
              clearable
              name="Emplacement"
              v-validate="'required'"
              v-model="EmplacementSelects_Query.Emplacement">
              <el-option
                v-for="option in EmplacementSelects_Query.ListEmplacement"
                class="select-primary text-uppercase"
                :value="option.id"
                :label="option.nom"
                :key="option.id">
              </el-option>
            </el-select>
          </base-input>

          <base-input label="Annonceur" class="col-md-6" :error="getError('Annonceur')">
            <el-select filterable class="select-primary"
              placeholder="ANNONCEUR"
              clearable
              name="Annonceur"
              v-validate="'required'"
              v-model="AnnonceursSelects_Query.Annonceurs">
              <el-option
                v-for="option in AnnonceursSelects_Query.ListAnnonceurs"
                class="select-primary text-uppercase"
                :value="option.id"
                :label="option.nom"
                :key="option.id">
              </el-option>
            </el-select>
          </base-input>

        </div>

        <div class="form-row">

          <base-input label="Type" class="col-md-6" :error="getError('Type')">
            <el-select filterable class="select-primary"
              placeholder="TYPE"
              clearable
              name="Type"
              v-validate="'required'"
              v-model="TypeSelects.Type_Add">
              <el-option
                v-for="option in TypeSelects.ListType"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.value">
              </el-option>
            </el-select>
          </base-input>

          <base-input label="Campagne" class="col-md-6" :error="getError('Campagne')">
            <el-select filterable class="select-primary"
              placeholder="CAMPAGNE"
              clearable
              name="Campagne"
              v-validate="'required'"
              v-model="CampagnesSelects_Query.Campagnes">
              <el-option
                v-for="option in ((TypeSelects.Type_Add==='')?[...CampagnesSelects_Query.ListCampagnes_Coreg, ...CampagnesSelects_Query.ListCampagnes_Spons]:
                (TypeSelects.Type_Add==='sponso')?CampagnesSelects_Query.ListCampagnes_Spons : CampagnesSelects_Query.ListCampagnes_Coreg)"
                class="select-primary text-uppercase"
                v-if="option.id_annonceur === AnnonceursSelects_Query.Annonceurs"
                :value="option.id"
                :label="(option.nom.length > 35) ? option.nom.slice(0,35)+'...' : option.nom"
                :key="option.key">
              </el-option>
            </el-select>
          </base-input>

        </div>

        <div class="form-row">
          <base-input label="Leads brut" placeholder="LEADS BRUT"
            class="col-md-6"
            name="leads brut"
            v-model="Repport.Leads_brut"
            :error="getError('leads brut')"
            type="number"
            v-validate="'required'"
          >
          </base-input>
          <base-input label="Leads net" placeholder="LEADS NET"
            class="col-md-6"
            name="leads net"
            v-model="Repport.Leads_net"
            :error="getError('leads net')"
            type="number"
            v-validate="'required'"
          >
          </base-input>
        </div>

        <div class="form-row">
          <base-input label="Prix de lead" placeholder="PRIX DE LEAD"
            class="col-md-6"
            name="prix de lead"
            v-model="Repport.Prix_lead"
            :error="getError('prix de lead')"
            type="number"
            v-validate="'required'"
          >
          </base-input>
          <base-input label="Prix client" placeholder="PRIX CLIENT"
            class="col-md-6"
            name="prix client"
            v-model="Repport.Prix_client"
            :error="getError('prix client')"
            type="number"
            v-validate="'required'"
          >
          </base-input>
        </div>

        <div class="form-row">
          <base-input label="Impression" placeholder="IMPRESSION"
            class="col-md-6"
            name="Impression"
            v-model="Repport.Impression"
            :error="getError('Impression')"
            type="number"
            v-validate="'required'"
          >
          </base-input>
        </div>

        <center>
          <base-button
            v-if="!loaderAddRepport"
            type="info"
            @click="AddRepport"
            >{{ $t("buttons.BtnAdd").toUpperCase() }}
          </base-button>
          <base-button
            v-else
            type="info"
            loading
            >
          </base-button>
        </center>
      </card>
    </modal_selfEvent>
    <!-- END MODAL ADD RAPPORT -->

    <!-- BEGIN MODAL EDIT RAPPORT -->
    <modal_selfEvent
      :show.sync="showEditRapport"
      body-classes="p-0"
      class="modal-listerapport">
      <card type="secondary"
        header-classes="pb-6"
        body-classes="px-lg-6 py-lg-6"
        class="border-0 mb-0">

        <template slot="header">

          <el-tooltip
            content="Quitter"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="showEditRapport = false"
              class="btn-link float-right"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove icon-large"></i>
            </base-button>
          </el-tooltip>

          <el-tooltip
            content="Aperçu"
            effect="light"
            :open-delay="300"
            placement="top"
            v-if="MiltipleRowEdit.length"
          >
            <base-button
              @click.native="ShowRapportsChangers"
              class="btn-link float-left"
              type="warning"
              icon
              :disabled="btn_ShowChangers"
            >
              <i class="el-icon-view icon-Xlarge"></i>
            </base-button>
          </el-tooltip>

          <el-tooltip
            content="Appliquer"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="updateRapports"
              class="btn-link float-left"
              type="success"
              icon
            >
              <i class="tim-icons icon-check-2 icon-Xlarge"></i>
            </base-button>
          </el-tooltip>

        </template>

        <template v-if="MiltipleRowEdit.length">
          <hr>
          <br/>
          <div class="row">
            <div class="col-sm-4 checkbox-radios">
              <base-radio v-model="EditPriceMode" name="1">AUGMENTER LES VALEURS DE</base-radio>
              <base-radio v-model="EditPriceMode" name="2">DIMINUER LES VALEURS DE</base-radio>
              <base-radio v-model="EditPriceMode" name="3">ATTRIBUER LA VALEUR SUIVANTE</base-radio>
            </div>

            <div class="col-sm-4 checkbox-radios">
              <base-input
                :label="(EditPriceMode == '3') ? 'Valeur' : 'Pourcentage'"
                v-model.number="RuleValue"
                type="number">
              </base-input>
            </div>

            <div class="col-sm-4">
              <base-input label="CHAMP">
                <el-select class="select-primary"
                  v-model="FieldSelects.Field">
                  <el-option
                    v-for="option in FieldSelects.ListFields"
                    class="select-primary text-uppercase"
                    :value="option"
                    :label="option"
                    :key="option">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>

          <br/>
          <hr>

          <div class="row">

            <div class="col">
              <base-input>
                <el-select filterable class="select-primary"
                  placeholder="EDITEURS"
                  v-model="EditeursSelects_Query.Editeur_EDIT">
                  <el-option
                    v-for="option in EditeursSelects_Query.ListEditeur"
                    class="select-primary text-uppercase"
                    :value="option.id"
                    :label="option.nom"
                    :key="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col">
              <base-input>
                <el-select filterable class="select-primary"
                  placeholder="SUPPORT"
                  v-model="SupportSelects_Query.Support_EDIT">
                  <el-option
                    v-for="option in SupportSelects_Query.ListSupport"
                    class="select-primary text-uppercase"
                    :value="option.id"
                    :label="option.nom"
                    :key="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col">
              <base-input>
                <el-select filterable class="select-primary"
                  placeholder="EMPLACEMENT"
                  v-model="EmplacementSelects_Query.Emplacement_EDIT">
                  <el-option
                    v-for="option in EmplacementSelects_Query.ListEmplacement"
                    class="select-primary text-uppercase"
                    :value="option.id"
                    :label="option.nom"
                    :key="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col">
              <base-input>
                <el-select filterable class="select-primary"
                  placeholder="ANNONCEUR"
                  v-model="AnnonceursSelects_Query.Annonceurs_EDIT">
                  <el-option
                    v-for="option in AnnonceursSelects_Query.ListAnnonceurs"
                    class="select-primary text-uppercase"
                    :value="option.id"
                    :label="option.nom"
                    :key="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col">
              <base-input>
                <el-select filterable class="select-primary"
                  placeholder="TYPE"
                  v-model="TypeSelects.Type_EDIT">
                  <el-option
                    v-for="option in TypeSelects.ListType"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col">
              <base-input>
                <el-select filterable class="select-primary"
                  placeholder="CAMPAGNE"
                  v-model="CampagnesSelects_Query.Campagnes_EDIT">
                  <el-option
                    v-for="option in ((TypeSelects.Type_EDIT==='')?[...CampagnesSelects_Query.ListCampagnes_Coreg, ...CampagnesSelects_Query.ListCampagnes_Spons]:
                    (TypeSelects.Type_EDIT==='sponso')?CampagnesSelects_Query.ListCampagnes_Spons : CampagnesSelects_Query.ListCampagnes_Coreg)"
                    class="select-primary text-uppercase  "
                    :value="option.id"
                    :label="(option.nom.length > 20) ? option.nom.slice(0,20)+'...' : option.nom"
                    :key="option.key">
                  </el-option>
                </el-select>
              </base-input>
            </div>

          </div>
          <hr>
          <br/>
        </template>

        <el-table :data="RowsEdit" @selection-change="handleSelectionChangeEdit" class="customtable_repportcoreg">

          <el-table-column
            type="selection"
            width="55">
          </el-table-column>

          <el-table-column
            prop= 'ID'
            label= "id"
            sortable
            min-width="100px"
          >
          </el-table-column>

          <el-table-column
            prop= 'Editeur'
            label= "Editeur"
            sortable
            min-width="160"
            show-overflow-tooltip
          >
            <template scope="scope">
              {{ scope.row.Editeur.toUpperCase() }}
            </template>
          </el-table-column>

          <el-table-column
            prop= 'Client'
            label= "Client"
            sortable
            min-width="140"
            show-overflow-tooltip
          >
            <template scope="scope">
              {{ scope.row.Client.toUpperCase() }}
            </template>
          </el-table-column>


          <el-table-column
            prop= 'Campagne'
            label= "Campagne"
            sortable
            min-width="140"
            show-overflow-tooltip
          >
            <template scope="scope">
              {{ scope.row.Campagne.toUpperCase() }}
            </template>
          </el-table-column>


          <el-table-column
            prop= 'Type'
            label= "Type"
            sortable
            min-width="100"
          >
            <template scope="scope">
              {{ scope.row.Type.toUpperCase() }}
            </template>
          </el-table-column>

          <el-table-column
            prop= 'Support'
            label= "Support"
            sortable
            min-width="130"
            show-overflow-tooltip
          >
            <template scope="scope">
              {{ scope.row.Support.toUpperCase() }}
            </template>
          </el-table-column>

          <el-table-column
            prop= 'Emplacement'
            label= "Emplacement"
            sortable
            min-width="150"
            show-overflow-tooltip
          >
            <template scope="scope">
              {{ scope.row.Emplacement.toUpperCase() }}
            </template>
          </el-table-column>

          <el-table-column
            prop= 'lead_net'
            label= "lead net"
            sortable
            min-width="140px"
          >
          </el-table-column>

          <el-table-column
            prop= 'prix_lead'
            label= "prix lead"
            sortable
            min-width="140px"
          >
          </el-table-column>

          <el-table-column
            prop= 'prix_client'
            label= "prix client"
            sortable
            min-width="140px"
          >
          </el-table-column>

        </el-table>

      </card>
    </modal_selfEvent>

    <!-- Mondal Backup -->

    <modal
      :show.sync="ShowBackup"
      body-classes="p-0"
    >
      <card type="secondary"
        header-classes="bg-white pb-6"
        body-classes="px-lg-6 py-lg-6"
        class="border-0 mb-0">

        <div class="form-row">
          <base-input
            class="col-md-12"
            label="Nom Sauvegarde"
            v-model="Backup_name"
          >
          </base-input>
        </div>

        <base-button
          type="info"
          @click.native="TakeBackup"
        >VALIDER</base-button>

        <base-button
          type="info"
          @click.native="ShowBackup = false"
        >ANNULER</base-button>

      </card>
    </modal>

    <!-- Mondal Backup -->

    <!-- Mondal Restauration -->

    <modal
      :show.sync="ShowRestauration"
      body-classes="p-0"
    >
      <card type="secondary"
        header-classes="bg-white pb-6"
        body-classes="px-lg-6 py-lg-6"
        class="border-0 mb-0">

        <div class="form-row">
          <base-input class="col-md-12" label="NOM SAUVEGARDE">
            <el-select filterable class="select-primary"
              placeholder="NOM SAUVEGARDE"
              v-model="BackupSelects.Backup">
              <el-option
                v-for="option in BackupSelects.ListBackup"
                class="select-primary"
                :value="option.nom_restoration"
                :label="option.nom_restoration"
                :key="option.nom_restoration">
              </el-option>
            </el-select>
          </base-input>
        </div>

        <base-button
          type="info"
          @click.native="MakeRestauration"
        >RESTAURER</base-button>

        <base-button
          type="info"
          @click.native="ShowRestauration = false"
        >ANNULER</base-button>

      </card>
    </modal>

    <!-- Mondal Restauration -->

    <fab
      v-if='RowsEdit.length && !$apollo.queries.getRaport.loading'
      position="bottom-left"
      @clear="ClearRows"
      @edit="ShowEditForm"
      @ValideSelection="ValideSelection"
      @delete="DeleteSelection"
      :actions="fabActions"
      main-icon="edit"
      icon-size="medium"
      bg-color="#004F84"
      :class="{'disable_input' : disableMaj}"
    ></fab>

    <fab
      v-if='ListRapports_Filter.length && !$apollo.queries.getRaport.loading'
      position="bottom-right"
      @SelectAll="SelectAll"
      @backup="backup"
      @restauration="restauration"
      :actions="fabActionsSelct"
      main-icon="list"
      icon-size="medium"
      bg-color="#004F84"
    ></fab>

  </div>
</template>

<script>
  import { Select, Option } from 'element-ui'
  import { Table, TableColumn } from 'element-ui';
  import { Modal } from 'src/components'
  import Modal_selfEvent from 'src/components/Modal_selfEvent'
  import gql from 'graphql-tag'
  import fab from 'vue-fab'
  import { BaseRadio } from 'src/components/index';
  import XLSX from 'xlsx'
  import swal from 'sweetalert2';
  import moment from 'moment'


  export default {
    computed: {
      ListRapports_Filter() {
        let list = null
        try {
          list = this.ListRapports
          .filter((element) =>
            element.rapportEditeur.some((rapport) => (rapport.Editeur+"").match(this.EditeursSelects.Editeur) &&
                                                     (rapport.Client+"").match(this.AnnonceursSelects.Annonceurs) &&
                                                     ((this.CampagnesSelects.Campagnes)? rapport.Campagne+"" == this.CampagnesSelects.Campagnes : true) &&
                                                     (rapport.Type+"").match(this.TypeSelects.Type) &&
                                                     (rapport.Support+"").match(this.SupportSelects.Support) &&
                                                     (rapport.Emplacement+"").match(this.EmplacementSelects.Emplacement)
                                        )
          )
          .map(element => {
            return Object.assign({}, element, {rapportEditeur :
              element.rapportEditeur.filter(rapport => (rapport.Editeur+"").match(this.EditeursSelects.Editeur) &&
                                                       (rapport.Client+"").match(this.AnnonceursSelects.Annonceurs) &&
                                                       ((this.CampagnesSelects.Campagnes) ? rapport.Campagne+"" == this.CampagnesSelects.Campagnes : true) &&
                                                       (rapport.Type+"").match(this.TypeSelects.Type) &&
                                                       (rapport.Support+"").match(this.SupportSelects.Support) &&
                                                       (rapport.Emplacement+"").match(this.EmplacementSelects.Emplacement)
                                            )}
                                );

          });
        } catch (error) {
          console.log(error)
        }

        let Impression = 0
        let Lead_brut = 0
        let Lead_net = 0
        let Somme_ht = 0
        let Somme_ttc = 0
        let Somme_ht_c = 0
        let Marge = 0
        list.forEach(rapport => {
          rapport.rapportEditeur.forEach(e => {
            Impression += e.impression
            Lead_brut += e.lead_brut
            Lead_net += e.lead_net
            Somme_ht += e.somme_ht
            Somme_ttc += e.somme_ttc
            Somme_ht_c += e.somme_ht_c
            Marge += e.marge
          });
        });
        this.SumTotal[0].Impression = Impression
        this.SumTotal[0].Lead_brut = Lead_brut
        this.SumTotal[0].Lead_net = Lead_net
        this.SumTotal[0].Somme_ht = Somme_ht
        this.SumTotal[0].Somme_ttc = Somme_ttc
        this.SumTotal[0].Somme_ht_c = Somme_ht_c
        this.SumTotal[0].Marge = Marge
        this.SumTotal[0].Pourcentage = (Somme_ht_c == 0) ? 0 : (Marge / Somme_ht_c) * 100

        this.selecteds = []

        return list

      }
    },
    watch: {
      showEditRapport(value) {
        if (!value) {
          this.ClearRows()
          this.RowsEdit = []
          this.btn_ShowChangers = false
        }
      }
    },
    apollo: {
      getRaport: {
        client: 'AUTH',
        query: require("src/graphql/RapportsCoreg.gql"),
        fetchPolicy: 'network-only',
        skip() {
          return this.SkipQuery;
        },
        result({ data, loader, networkStatus }) {

          data.getRaportCoreg.rapports.forEach(element => {
            if(element.rapportEditeur[element.rapportEditeur.length-1].ID != "-") {
              element.rapportEditeur.push({
                ID: "-",
                Editeur: element.rapportEditeur[0].Editeur,
                Campagne: "-",
                Client: "-",
                Type: "-",
                Support: "-",
                Emplacement: "-",
                impression: 0,
                lead_brut: 0,
                lead_net: 0,
                prix_lead: "-",
                prix_client: "-",
                somme_ht: element.sold.sold,
                somme_ttc: element.sold.soldTTC,
                somme_ht_c: 0,
                marge: 0,
                pourcentage: 0,
                payee: "-",
              })
            }
          });

          this.ListRapports = [...this.ListRapports,...data.getRaportCoreg.rapports]

          if(data.getRaportCoreg.last_editeur != null) {
            this.last_editeur = data.getRaportCoreg.last_editeur
          }
          else {
            this.SkipQuery = true

            // FILLING FILTERS
            let listEditeur = []
            let listAnnonceurs = []
            let listCampagnes = []
            let listSupport = []
            let listEmplacement = []
            this.ListRapports.forEach(element => {
              element.rapportEditeur.forEach(rapport => {
                if (rapport.Editeur != "-")
                  listEditeur.push(rapport.Editeur)
                if (rapport.Client != "-")
                  listAnnonceurs.push(rapport.Client)
                if (rapport.Campagne != "-")
                  listCampagnes.push({ value: rapport.Campagne, client: rapport.Client})
                if (rapport.Support != "-")
                  listSupport.push(rapport.Support)
                if (rapport.Emplacement != "-")
                  listEmplacement.push(rapport.Emplacement)
              });
            });
            this.EditeursSelects.ListEditeur = [...new Set(listEditeur)]
            this.AnnonceursSelects.ListAnnonceurs = [...new Set(listAnnonceurs)]
            // this.CampagnesSelects.ListCampagnes = [...new Set(listCampagnes)]
            this.CampagnesSelects.ListCampagnes = [...new Map(listCampagnes.map(item => [item.value, item])).values()]
            this.SupportSelects.ListSupport = [...new Set(listSupport)]
            this.EmplacementSelects.ListEmplacement = [...new Set(listEmplacement)]
            // FILLING FILTERS
          }
        },
        variables () {
          return {
            annee: this.Rapport.year,
            mois: ((this.Rapport.mounth+"").length==1) ? "0"+this.Rapport.mounth : this.Rapport.mounth,
            last_editeur: this.last_editeur
          }
        },
        update: data => data
      },
      getFilters: {
        client: 'AUTH',
        query: gql`
          query {
            all_annonceur{
              id
              nom
            }
            all_editeurs{
              id
              nom
            }
            all_emplacement{
              id
              nom
            }

            all_supports{
              id
              nom
              id_editeur
            }

            all_coreg{
              id
              nom
              id_annonceur
            }

            all_spons{
              id
              nom
              id_annonceur
            }
        }`,
        fetchPolicy: 'no-cache',
        result({ data, loader, networkStatus }) {
          this.EditeursSelects_Query.ListEditeur = data.all_editeurs
          this.SupportSelects_Query.ListSupport = data.all_supports
          this.EmplacementSelects_Query.ListEmplacement = data.all_emplacement
          this.AnnonceursSelects_Query.ListAnnonceurs = data.all_annonceur

          this.CampagnesSelects_Query.ListCampagnes_Coreg = data.all_coreg
          this.CampagnesSelects_Query.ListCampagnes_Spons = data.all_spons

        },
        update: data => data
      },
      getNameSaveCoreg: {
        client: 'AUTH',
        query: gql`
          query ($annee: String, $mois: String) {
            getNameSaveCoreg(annee: $annee, mois: $mois) {
              nom_restoration
            }
          }
        `,
        skip() {
          return !this.Rapport
        },
        fetchPolicy: 'no-cache',
        result({ data, loader, networkStatus }) {
          this.BackupSelects.ListBackup = data.getNameSaveCoreg
          if (data.getNameSaveCoreg.length)
            this.BackupSelects.Backup = data.getNameSaveCoreg[0].nom_restoration
        },
        variables () {
          return {
            annee: this.Rapport.year,
            mois: ((this.Rapport.mounth+"").length==1) ? "0"+this.Rapport.mounth : this.Rapport.mounth
          }
        },
        update: data => data
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Modal,
      Modal_selfEvent,
      fab,
      BaseRadio
    },
    data() {
      return {
        selecteds: [],
        ShowBackup: false,
        disableMaj: false,
        Backup_name: '',
        fabActions: [
          {
            name: 'clear',
            icon: 'clear',
            tooltip: 'Désélectionner'
          },
          {
            name: 'ValideSelection',
            icon: 'playlist_add_check',
            tooltip: 'Valider la sélection'
          },
          {
            name: 'delete',
            icon: 'delete',
            tooltip: 'Supprimer la sélection'
          },
          {
            name: 'edit',
            icon: 'edit',
            tooltip: 'Editer'
          }
        ],
        fabActionsSelct: [
          {
            name: 'restauration',
            icon: 'backup',
            tooltip: 'Restauration'
          },
          {
            name: 'backup',
            icon: 'save',
            tooltip: 'Prendre une sauvegarde'
          },
          {
            name: 'SelectAll',
            icon: 'list',
            tooltip: 'Selectionner tous'
          }
        ],
        btn_ShowChangers: false,
        showEditRapport: false,
        showAddRapport: false,
        Rapport: null,
        SkipQuery: true,
        last_editeur: null,
        ListRapports: [],
        TypeSelects: {
          Type_Add: '',
          Type_EDIT: '',
          Type: '',
          ListType: [
            { value: "coreg",label: "COREG" },
            { value: "sponso",label: "SPONSO" }
          ]
        },
        EditeursSelects: {
          Editeur: '',
          ListEditeur: []
        },
        AnnonceursSelects: {
          Annonceurs: '',
          ListAnnonceurs: []
        },
        CampagnesSelects: {
          Campagnes: '',
          ListCampagnes: []
        },
        SupportSelects: {
          Support: '',
          ListSupport: []
        },
        EmplacementSelects: {
          Emplacement: '',
          ListEmplacement: []
        },
        EditeursSelects_Query: {
          Editeur: '',
          Editeur_EDIT: '',
          ListEditeur: []
        },
        SupportSelects_Query: {
          Support: '',
          Support_EDIT: '',
          ListSupport: []
        },
        EmplacementSelects_Query: {
          Emplacement: '',
          Emplacement_EDIT: '',
          ListEmplacement: []
        },
        AnnonceursSelects_Query: {
          Annonceurs: '',
          Annonceurs_EDIT: '',
          ListAnnonceurs: []
        },
        CampagnesSelects_Query: {
          Campagnes: '',
          Campagnes_EDIT: '',
          ListCampagnes_Coreg: [],
          ListCampagnes_Spons: []
        },
        Repport: {
          Impression: 0,
          Leads_brut: 0,
          Leads_net: 0,
          Prix_lead: 0,
          Prix_client: 0
        },
        loaderAddRepport: false,
        RowsEdit: [],
        MiltipleRowEdit: [],
        EditPriceMode: '1',
        RuleValue: 0,
        FieldSelects: {
          Field: 'Leads Net',
          ListFields: ['Leads Net','Prix du lead','Prix client']
        },
        SumTotal: [
          { Impression: 0, Lead_brut: 0, Lead_net: 0, Somme_ht: 0, Somme_ttc: 0, Somme_ht_c: 0, Marge: 0 , Pourcentage: 0 }
        ],
        BackupSelects: {
          Backup: '',
          ListBackup: []
        },
        ShowRestauration: false,
        disableinput: false
      }
    },
    methods: {
      DupliquerRow(row) {
        this.EditeursSelects_Query.Editeur = row.id_editeur
        this.SupportSelects_Query.Support = row.id_support
        this.EmplacementSelects_Query.Emplacement = row.id_emplacement
        this.AnnonceursSelects_Query.Annonceurs = row.id_annonceur
        this.TypeSelects.Type_Add = row.Type
        this.CampagnesSelects_Query.Campagnes = row.id_campain
        this.Repport.Leads_brut = row.lead_brut
        this.Repport.Leads_net = row.lead_net
        this.Repport.Prix_lead = row.prix_lead
        this.Repport.Prix_client = row.prix_client
        this.Repport.Impression = row.impression

        this.showAddRapport = true
      },
      async EditRow_onEnter(row) {
        this.disableinput = true
        await this.$apollo.mutate({
          client: "AUTH",
          mutation: require("src/graphql/mutation/UpdateReportCoreg.gql"),
          variables: {
            pk_rapport: row.ID,
            fk_editeur: row.id_editeur,
            fk_annonceur: row.id_annonceur,
            fk_support: row.id_support,
            fk_emplacement: row.id_emplacement,
            fk_campain: row.id_campain,
            editeur: row.Editeur,
            annonceur: row.Client,
            support: row.Support,
            emplacement: row.Emplacement,
            type: row.Type,
            lead_net: row.lead_net,
            prix_lead: row.prix_lead,
            prix_client: row.prix_client,
            campain: row.Campagne
          }
        }).then(response => {
          this.$notify({type: 'success', message: "Rapport N°: "+ row.ID +" a été modifié avec succès" })
          row.somme_ht = row.lead_net * row.prix_lead,
          row.somme_ttc = ((row.lead_net * row.prix_lead) + ((row.lead_net * row.prix_lead) * (20 / 100))),
          row.somme_ht_c = row.lead_net * row.prix_client,
          row.marge = (row.lead_net * row.prix_client)-(row.lead_net * row.prix_lead),
          row.pourcentage = ((row.lead_net * row.prix_client) != 0) ? (((row.lead_net*row.prix_client)-(row.lead_net*row.prix_lead) ) / (row.lead_net*row.prix_client)) * 100 : 0
          this.disableinput = false
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          this.disableinput = false
        })
      },
      SwitchToPerf (){
        const annee = this.Rapport.year
        const moi = ((this.Rapport.mounth+"").length==1) ? "0"+this.Rapport.mounth : this.Rapport.mounth
        const url = "https://wd@2020:@2020_w@d@www.weedoit.fr/reportv2/reports.php?annee="+annee+"&mois="+moi
        window.open(url, '_blank')
        // localStorage.setItem("rapport", JSON.stringify(this.Rapport))
        // this.$router.push({ name: "liste des Rapports" })
      },
      SwitchToPerf_origin() {
        localStorage.setItem("rapport", JSON.stringify(this.Rapport))
        this.$router.push({ name: "liste des Rapports" })
      },
      async DeleteSelection() {
        let IDrow = []
        for (let i = 0; i < this.RowsEdit.length; i++) {
          await this.$apollo.mutate({
            client: "AUTH",
            mutation: require("src/graphql/mutation/DeleteReportCoreg.gql"),
            variables: {
              pk_rapport: this.RowsEdit[i].ID
            }
          }).then(response => {
            this.$notify({type: 'success', message: "Rapport N° "+ this.RowsEdit[i].ID +" a été supprimer" })
            IDrow.push(this.RowsEdit[i].ID)
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errordeleting") })
            console.log(error)
          })
        }

        this.ListRapports.forEach(rapports => {
          rapports.rapportEditeur.forEach((value, index) => {
            for (let i = 0; i < IDrow.length; i++) {
              if(IDrow[i] == value.ID){
                rapports.rapportEditeur.splice(index, 1)
              }
            }
          })
        });

      },
      Reset() {
        this.EditeursSelects.Editeur = ""
        this.AnnonceursSelects.Annonceurs = ""
        this.CampagnesSelects.Campagnes = ""
        this.SupportSelects.Support = ""
        this.EmplacementSelects.Emplacement = ""
        this.TypeSelects.Type = ""
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      getSummaries(param) {
        const { columns, data } = param;

        let somme_ttc = 0
        let somme_ht = 0
        let impression = 0
        let lead_brut = 0
        let lead_net = 0
        let somme_ht_c = 0
        let marge = 0
        let pourcentage
        data.forEach(rapport => {
          impression += rapport.impression
          lead_brut += rapport.lead_brut
          lead_net += rapport.lead_net
          somme_ht += rapport.somme_ht
          somme_ttc += rapport.somme_ttc
          somme_ht_c += rapport.somme_ht_c
          marge += rapport.marge
          pourcentage += rapport.pourcentage
        });

        const sums = [];
        columns.forEach((column, index) => {
          if (index === 7) {
            sums[index] = this.formatThounsends(impression);
            return;
          }
          else if( index === 8) {
            sums[index] = this.formatThounsends(lead_brut)
            return;
          }
          else if( index === 9) {
            sums[index] = this.formatThounsends(lead_net)
            return;
          }
          else if( index === 10) {
            sums[index] = this.formatThounsends(somme_ht,true,2) + ' €'
          }
          else if( index === 11) {
            sums[index] = this.formatThounsends(somme_ttc,true,2) + ' €'
          }
          else if( index === 12) {
            sums[index] = this.formatThounsends(somme_ht_c,true,2) + ' €'
          }
          else if( index === 13) {
            sums[index] = this.formatThounsends(marge,true,2) + ' €'
          }
          else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      handleSelectionChange(index, row) {
        this.RowsEdit = []
        this.selecteds[index] = row
        let combined = []
        this.selecteds.forEach(element => {
          combined = [...combined, ...element];
        });
        combined.forEach(element => {
          if(element.ID != '-' && !this.RowsEdit.find(e => e.ID === element.ID) ) {
            this.RowsEdit.push(
              {
                ID: element.ID,
                Editeur: element.Editeur,
                Client: element.Client,
                Campagne: element.Campagne,
                Type: element.Type,
                Support: element.Support,
                Emplacement: element.Emplacement,
                lead_net: element.lead_net,
                prix_lead: element.prix_lead,
                prix_client: element.prix_client,
                id_editeur: element.id_editeur,
                id_annonceur: element.id_annonceur,
                id_support: element.id_support,
                id_emplacement: element.id_emplacement,
                id_campain: element.id_campain
              }
            )
          }
        });

      },
      handleSelectionChangeEdit(row) {
        if (row.length) {
          this.MiltipleRowEdit = row
        }else {
          this.MiltipleRowEdit = []
        }
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      async AddRepport() {
        let validate
        await this.$validator.validateAll().then(isValid => {
          validate = isValid
        })
        if (!validate)
          return

        this.loaderAddRepport = true



        this.$apollo.mutate({
          client: "AUTH",
          mutation: require("src/graphql/mutation/AddReportCoreg.gql"),
          variables: {
            fk_editeur: this.EditeursSelects_Query.Editeur,
            fk_annonceur: this.AnnonceursSelects_Query.Annonceurs,
            fk_support: this.SupportSelects_Query.Support,
            fk_emplacement: this.EmplacementSelects_Query.Emplacement,
            fk_campain: this.CampagnesSelects_Query.Campagnes,
            editeur: this.EditeursSelects_Query.ListEditeur.find(x => x.id === this.EditeursSelects_Query.Editeur).nom,
            annonceur: this.AnnonceursSelects_Query.ListAnnonceurs.find(x => x.id === this.AnnonceursSelects_Query.Annonceurs).nom,
            support: this.SupportSelects_Query.ListSupport.find(x => x.id === this.SupportSelects_Query.Support).nom,
            emplacement: this.EmplacementSelects_Query.ListEmplacement.find(x => x.id === this.EmplacementSelects_Query.Emplacement).nom,
            type: this.TypeSelects.Type_Add,
            impression: this.Repport.Impression,
            lead_brut: this.Repport.Leads_brut,
            lead_net: this.Repport.Leads_net,
            prix_lead: this.Repport.Prix_lead,
            prix_client: this.Repport.Prix_client,
            campain: (this.TypeSelects.Type_Add == "sponso") ? this.CampagnesSelects_Query.ListCampagnes_Spons.find(x => x.id === this.CampagnesSelects_Query.Campagnes).nom : this.CampagnesSelects_Query.ListCampagnes_Coreg.find(x => x.id === this.CampagnesSelects_Query.Campagnes).nom,
            mois: ((this.Rapport.mounth+"").length==1) ? "0"+this.Rapport.mounth : this.Rapport.mounth,
            annee: this.Rapport.year
          }
        }).then(response => {
          this.$notify({type: 'success', message: this.$t("apiresponse.reportcreated") })

          // ADD row  to table if editeur exist
          let EditeurFound = []
          this.ListRapports.forEach((rapport,key) => {
            rapport.rapportEditeur.forEach((editeur,key2) => {
              if (editeur.id_editeur === this.EditeursSelects_Query.Editeur) {
                EditeurFound.push(
                  {position: key}
                )
              }
            });
          });
          if(EditeurFound.length) {
            this.ListRapports[EditeurFound[0].position].rapportEditeur.push(
              {
                ID: response.data.add_report_coreg.pk_rapport,
                Editeur: response.data.add_report_coreg.editeur,
                Campagne: response.data.add_report_coreg.campain,
                Client: response.data.add_report_coreg.annonceur,
                Type: response.data.add_report_coreg.type,
                Support: response.data.add_report_coreg.support,
                Emplacement: response.data.add_report_coreg.emplacement,
                impression: response.data.add_report_coreg.impression,
                lead_brut: response.data.add_report_coreg.lead_brut,
                lead_net: response.data.add_report_coreg.lead_net,
                prix_lead: response.data.add_report_coreg.prix_lead,
                somme_ht: response.data.add_report_coreg.lead_net * response.data.add_report_coreg.prix_lead,
                somme_ttc: ((response.data.add_report_coreg.lead_net * response.data.add_report_coreg.prix_lead) + ((response.data.add_report_coreg.lead_net * response.data.add_report_coreg.prix_lead) * (20 / 100))),
                prix_client: response.data.add_report_coreg.prix_client,
                somme_ht_c: response.data.add_report_coreg.lead_net * response.data.add_report_coreg.prix_client,
                marge: (response.data.add_report_coreg.lead_net * response.data.add_report_coreg.prix_client)-(response.data.add_report_coreg.lead_net * response.data.add_report_coreg.prix_lead),
                pourcentage: ((response.data.add_report_coreg.lead_net * response.data.add_report_coreg.prix_client) != 0) ? (((response.data.add_report_coreg.lead_net*response.data.add_report_coreg.prix_client)-(response.data.add_report_coreg.lead_net*response.data.add_report_coreg.prix_lead) ) / (response.data.add_report_coreg.lead_net*response.data.add_report_coreg.prix_client)) * 100 : 0,
                payee: "-",
                id_editeur: response.data.add_report_coreg.fk_editeur,
                id_annonceur: response.data.add_report_coreg.fk_annonceur,
                id_support: response.data.add_report_coreg.fk_support,
                id_emplacement: response.data.add_report_coreg.fk_emplacement,
                id_campain: response.data.add_report_coreg.fk_campain
              }
            )
          }
          // ADD row to table if editeur exist
          this.showAddRapport = false
          this.loaderAddRepport = false
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
          this.loaderAddRepport = false
        })

      },
      async updateRapports() {
        if (this.RowsEdit.length) {
          // if(this.MiltipleRowEdit.length)
          //   this.ShowRapportsChangers()
          swal.showLoading()
          let apiresponse = true
          for (let i = 0; i < this.RowsEdit.length; i++) {
            console.log(this.RowsEdit[i])
            await this.$apollo.mutate({
              client: "AUTH",
              mutation: require("src/graphql/mutation/UpdateReportCoreg.gql"),
              variables: {
                pk_rapport: this.RowsEdit[i].ID,
                fk_editeur: this.RowsEdit[i].id_editeur,
                fk_annonceur: this.RowsEdit[i].id_annonceur,
                fk_support: this.RowsEdit[i].id_support,
                fk_emplacement: this.RowsEdit[i].id_emplacement,
                fk_campain: this.RowsEdit[i].id_campain,
                editeur: this.RowsEdit[i].Editeur,
                annonceur: this.RowsEdit[i].Client,
                support: this.RowsEdit[i].Support,
                emplacement: this.RowsEdit[i].Emplacement,
                type: this.RowsEdit[i].Type,
                lead_net: this.RowsEdit[i].lead_net,
                prix_lead: this.RowsEdit[i].prix_lead,
                prix_client: this.RowsEdit[i].prix_client,
                campain: this.RowsEdit[i].Campagne
              }
            }).then(response => {
            }).catch(error => {
              apiresponse = false
            })
          }

          this.ListRapports.forEach((value, index) => {
            value.rapportEditeur.forEach(rapport => {
              for (let i = 0; i < this.RowsEdit.length; i++) {
                if(rapport.ID == this.RowsEdit[i].ID) {
                  rapport.Editeur = this.RowsEdit[i].Editeur
                  rapport.Campagne = this.RowsEdit[i].Campagne
                  rapport.Client = this.RowsEdit[i].Client
                  rapport.Type = this.RowsEdit[i].Type
                  rapport.Support = this.RowsEdit[i].Support
                  rapport.lead_net = parseInt(this.RowsEdit[i].lead_net),
                  rapport.prix_lead = parseFloat(this.RowsEdit[i].prix_lead),
                  rapport.prix_client = parseFloat(this.RowsEdit[i].prix_client),
                  rapport.somme_ht = rapport.lead_net * rapport.prix_lead,
                  rapport.somme_ttc = ((rapport.lead_net * rapport.prix_lead) + ((rapport.lead_net * rapport.prix_lead) * (20 / 100))),
                  rapport.somme_ht_c = rapport.lead_net * rapport.prix_client,
                  rapport.marge = (rapport.lead_net * rapport.prix_client)-(rapport.lead_net * rapport.prix_lead),
                  rapport.pourcentage = ((rapport.lead_net * rapport.prix_client) != 0) ? (((rapport.lead_net*rapport.prix_client)-(rapport.lead_net*rapport.prix_lead) ) / (rapport.lead_net*rapport.prix_client)) * 100 : 0
                  rapport.id_editeur = this.RowsEdit[i].id_editeur,
                  rapport.id_annonceur = this.RowsEdit[i].id_annonceur,
                  rapport.id_support = this.RowsEdit[i].id_support,
                  rapport.id_emplacement = this.RowsEdit[i].id_emplacement,
                  rapport.id_campain = this.RowsEdit[i].id_campain

                  break
                }
              }
            });
          });
          swal.close()
          if (apiresponse) {
            this.$notify({type: 'success', message: "Rapports ont été modifiés avec succès" })
          }
          else {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          }
          this.showEditRapport = false
        }
      },
      ShowRapportsChangers() {
        if (this.MiltipleRowEdit.length) {
          this.btn_ShowChangers = true
        }
        this.MiltipleRowEdit.forEach(rapports => {
          if (this.FieldSelects.Field == 'Leads Net') {
            if (this.EditPriceMode == '1') {
              if (this.RuleValue)
                rapports.lead_net = Math.round(rapports.lead_net * (1 + (this.RuleValue) / 100))
            }
            else if(this.EditPriceMode == '2') {
              if (this.RuleValue)
                rapports.lead_net = Math.round(rapports.lead_net * (1 - (this.RuleValue) / 100))
            }
            else if(this.EditPriceMode == '3') {
              rapports.lead_net = this.RuleValue
            }
          }
          if (this.FieldSelects.Field == 'Prix du lead') {
            if (this.EditPriceMode == '1') {
              if (this.RuleValue)
                rapports.prix_lead = (rapports.prix_lead * (1 + (this.RuleValue) / 100)).toFixed(3)
            }
            else if(this.EditPriceMode == '2') {
              if (this.RuleValue)
                rapports.prix_lead = (rapports.prix_lead * (1 - (this.RuleValue) / 100)).toFixed(3)
            }
            else if(this.EditPriceMode == '3') {
              rapports.prix_lead = this.RuleValue
            }
          }
          if (this.FieldSelects.Field == 'Prix client') {
            if (this.EditPriceMode == '1') {
              if (this.RuleValue)
                rapports.prix_client = (rapports.prix_client * (1 + (this.RuleValue) / 100)).toFixed(3)
            }
            else if(this.EditPriceMode == '2') {
              if (this.RuleValue)
                rapports.prix_client = (rapports.prix_client * (1 - (this.RuleValue) / 100)).toFixed(3)
            }
            else if(this.EditPriceMode == '3') {
              rapports.prix_client = this.RuleValue
            }
          }

          if (this.EditeursSelects_Query.Editeur_EDIT != "") {
            rapports.Editeur = this.EditeursSelects_Query.ListEditeur.find(x => x.id === this.EditeursSelects_Query.Editeur_EDIT).nom
            rapports.fk_editeur = this.EditeursSelects_Query.Editeur_EDIT
          }

          if (this.SupportSelects_Query.Support_EDIT != "") {
            rapports.Support = this.SupportSelects_Query.ListSupport.find(x => x.id === this.SupportSelects_Query.Support_EDIT).nom
            rapports.fk_support = this.SupportSelects_Query.Support_EDIT
          }

          if (this.EmplacementSelects_Query.Emplacement_EDIT != "") {
            rapports.Emplacement = this.EmplacementSelects_Query.ListEmplacement.find(x => x.id === this.EmplacementSelects_Query.Emplacement_EDIT).nom
            rapports.fk_emplacement = this.EmplacementSelects_Query.Emplacement_EDIT
          }

          if (this.AnnonceursSelects_Query.Annonceurs_EDIT != "") {
            rapports.Client = this.AnnonceursSelects_Query.ListAnnonceurs.find(x => x.id === this.AnnonceursSelects_Query.Annonceurs_EDIT).nom
            rapports.fk_annonceur = this.AnnonceursSelects_Query.Annonceurs_EDIT
          }

          if (this.TypeSelects.Type_EDIT != "") {
            rapports.Type = this.TypeSelects.Type_EDIT
          }

          if (this.CampagnesSelects_Query.Campagnes_EDIT != "") {
            rapports.Campagne = (this.TypeSelects.Type_EDIT == "sponso") ? this.CampagnesSelects_Query.ListCampagnes_Spons.find(x => x.id === this.CampagnesSelects_Query.Campagnes_EDIT).nom : this.CampagnesSelects_Query.ListCampagnes_Coreg.find(x => x.id === this.CampagnesSelects_Query.Campagnes_EDIT).nom,
            rapports.fk_campain = this.CampagnesSelects_Query.Campagnes_EDIT
          }
        })
      },
      SelectAll() {
        for (let i = 0; i < this.ListRapports_Filter.length; i++) {
          for (let j = 0; j < this.ListRapports_Filter[i].rapportEditeur.length; j++) {
            let methods = 'this.$refs.table' + this.ListRapports_Filter[i].rapportEditeur[0].id_editeur +'[0].toggleRowSelection(this.ListRapports_Filter[i].rapportEditeur[j])'
            try {
              eval(methods);
            } catch (error) { }
          }
        }
      },
      async ValideSelection() {
        var uniqueArray = this.removeDuplicates(this.RowsEdit, "id_editeur")

        for (let i = 0; i < uniqueArray.length; i++) {
          await this.$apollo.mutate({
            client: "AUTH",
            mutation: require("src/graphql/mutation/Val_Cancel_RapportCoreg.gql"),
            variables: {
              action: "valider",
              id_editeur: uniqueArray[i].id_editeur,
              mois: this.Rapport.mounth,
              annee: this.Rapport.year,

            }
          }).then(response => {
            this.$notify({type: 'success', message: "État rapport de "+uniqueArray[i].Editeur + " a été modifié" })
            this.ListRapports_Filter.forEach(element => {
              if (this.getSommeHtEditeur(element.rapportEditeur[0].id_editeur) < 50) {
                element.sold.etat = "Revenus reportes"
              }
              else {
                element.sold.etat = "AF emis"
              }
            });
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }
      },
      removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject  = {};

        for(var i in originalArray) {
          lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        for(i in lookupObject) {
          newArray.push(lookupObject[i]);
        }
        return newArray;
      },
      ClearRows(){
        for (let i = 0; i < this.ListRapports.length; i++) {
          let methods = 'this.$refs.table'+this.ListRapports[i].rapportEditeur[0].id_editeur +'[0].clearSelection()'
          try {
            eval(methods);
          } catch (error) {}
        }
        this.selecteds = []
      },
      ShowEditForm(){
        this.showEditRapport = true
      },
      getSommeHtEditeur(id) {
        let somme = 0
        this.ListRapports.forEach(element => {
          element.rapportEditeur.forEach(rapport => {
            if(rapport.id_editeur === id) {
              somme += rapport.somme_ht
            }
          });
        })
        return somme
      },
      async GenrateRepport(id_editeur,stat,action) {
        await this.$apollo.mutate({
          client: "AUTH",
          mutation: require("src/graphql/mutation/Val_Cancel_RapportCoreg.gql"),
          variables: {
            action: action,
            id_editeur: id_editeur,
            mois: this.Rapport.mounth,
            annee: this.Rapport.year
          }
        }).then(response => {
          this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
          if (action === 'annuler') {
            stat.etat = "10"
          }
          else if (action === 'valider') {
            if (this.getSommeHtEditeur(id_editeur) <= 50) {
              stat.etat = "Revenus reportes"
            }
            else {
              stat.etat = "AF emis"
            }
          }
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
        })
      },
      backup() {
        this.ShowBackup = true
      },
      async TakeBackup() {
        await this.$apollo.mutate({
          client: "AUTH",
          mutation: require("src/graphql/mutation/SaveReportCoreg.gql"),
          variables: {
            mois: this.Rapport.mounth,
            annee: this.Rapport.year,
            nom_sauvegarde: this.Backup_name
          }
        }).then(response => {
          this.$apollo.queries.getNameSaveCoreg.refresh()
          this.$notify({type: 'success', message: this.$t("apiresponse.backupcreated") })
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        })
      },
      restauration() {
        this.ShowRestauration = true
      },
      async MakeRestauration() {
        await this.$apollo.mutate({
          client: "AUTH",
          mutation: gql`
            mutation ($nom_sauvegarde: String) {
              restauration_rapport_coreg (nom_sauvegarde: $nom_sauvegarde) {
                message
              }
            }
          `,
          variables: {
            nom_sauvegarde: this.BackupSelects.Backup
          },
        }).then(response => {
          this.$notify({type: 'success', message: this.$t("apiresponse.restoration") })
          this.ListRapports = []
          this.last_editeur = null
          this.SkipQuery = false
          this.$apollo.queries.getRaport.refresh()
          this.ShowRestauration = false
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.restorationerror") })
        })
      },
      exportcsv() {
        if (this.ListRapports_Filter.length) {
          let data_export = []
          this.ListRapports_Filter.forEach(repoort => {
            data_export = data_export.concat(repoort.rapportEditeur.filter(function( obj ) {
              delete obj.__typename
              delete obj.id_editeur
              delete obj.id_campain
              delete obj.id_support
              delete obj.id_emplacement
              delete obj.id_annonceur
              return obj.ID !== '-';
            }))
          });
          const fileName = 'rapport_mensuel.xlsx'
          var Sheet_data = XLSX.utils.json_to_sheet(data_export)
          var wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, Sheet_data, 'rapport_mensuel')
          // wb.Sheets.WEEDOMARKET.F1.v = "Volume de leads collectes"
          XLSX.writeFile(wb, fileName)
        }
      },
      SwitchToMongustave() {
        let routeData = this.$router.resolve({
          name: "liste des Rapports Mon Gustave"
        });
        window.open(routeData.href, "_blank");
      }
    },
    created() {
      if((this.Rapport = JSON.parse(localStorage.getItem("rapport"))) == null) {
        this.$router.push({ name: "Globale Reports" })
        return
      }

      const start = moment(this.Rapport.year+"-"+this.Rapport.mounth+"-01");
      const end = moment();
      const diffMonth = end.diff(start, 'months', true);

      this.disableMaj = parseInt(diffMonth) > 1;

      // localStorage.removeItem("rapport")
      this.SkipQuery = false
    },
  };
</script>

<style scoped>
  .lablelTabel {
    font-weight: 600;
    font-size: 0.99925rem;
    padding-top: 17px;
    padding-right: 17px;
    text-align: right;
  }

  .tab-title {
    font-size: 0.8rem
  }

  .icon-large {
    font-size: 20px !important
  }

  .disable_input {
    color: currentColor;
    cursor: not-allowed;
    opacity: 0.5;
    text-decoration: none;
    pointer-events: none;
  }
</style>

<style>
  .el-table__row .form-control {
    background-color: transparent;
  }
  .customtable_repportcoreg .cell{
    word-break: break-word;
  }
  .icon-Xlarge {
    font-size: 35px !important;
  }
</style>
