<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="col-12">
          <div class="row">
            <div class="col-sm-6 text-left">
              <h2 class="card-title"> {{ $t("titlePages.listClients") }}</h2>
            </div>
            <div class="col-sm-6">
              <div class="btn-group btn-group-toggle float-right mb-3" data-toggle="buttons">
              <base-button
                class="btn btn-round btn-just-icon btn-info"
                @click.native="openAddMember"
              >{{ $t("buttons.BtnAddMember").toUpperCase() }}</base-button>
            </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6">
                <base-input>
                  <el-select class="select-primary"
                    placeholder="SÉLECTIONNER LES CRITÈRES"
                    v-model="CriteresSelects.Criteres">
                    <el-option 
                      v-for="option in CriteresSelects.ListCriteres"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <base-input type="text" v-model="keyword" @keyup.enter="trigger" placeholder="MOTS CLÉS"/>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <base-input>
                  <el-select multiple class="select-primary"
                    placeholder="THÉMATIQUE"
                    filterable
                    :disabled="$apollo.queries.listcategories.loading"
                    v-model="ThematiqueSelects.Thematique">
                    <el-option 
                      v-for="option in ThematiqueSelects.ListThematique"
                      class="select-primary"
                      :value="option.value"
                      :label="option.categorie.toUpperCase()"
                      :key="option.categorie">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6">
                <base-input>
                  <el-select filterable class="select-primary"
                    placeholder="CATÉGORIE"
                    v-model="CategorieSelects.Categorie">
                    <el-option 
                      v-for="option in CategorieSelects.ListCategorie"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <!-- <div class="col-lg-4 col-md-4 col-sm-6">
                <base-input>
                  <el-select class="select-primary"
                    placeholder="INACTIF DEPUIS"
                    v-model="InactifSelects.Inactif">
                    <el-option 
                      v-for="option in InactifSelects.ListInactif"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div> -->
              <div class="col-lg-4 col-md-4 col-sm-6">
                <base-input>
                  <el-select class="select-primary"
                    placeholder="STATUT"
                    v-model="StatutSelects.Statut">
                    <el-option 
                      v-for="option in StatutSelects.ListStatut"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <base-input>
                  <el-select filterable class="select-primary"
                    placeholder="SUPPORT DE DIFFUSION"
                    v-model="SupportDiffusionSelects.SupportDiffusion">
                    <el-option 
                      v-for="option in SupportDiffusionSelects.ListSupportDiffusion"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <base-input>
                  <el-select filterable class="select-primary"
                    :placeholder="$t('Filter.label.allStatutes')"
                    v-model="PaysBasesSelects.PaysBase">
                    <el-option
                      class="select-primary"
                      value=""
                      :label="$t('Filter.label.allStatutes')">
                    </el-option>
                    <el-option 
                      v-for="option in PaysBasesSelects.ListPaysBases"
                      class="select-primary"
                      :value="option.pays"
                      :label="option.pays.toUpperCase()"
                      :key="option.pays">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <base-input>
                  <el-select class="select-primary"
                    placeholder="SERVICE"
                    v-model="ServiceSelects.Service">
                    <el-option 
                      v-for="option in ServiceSelects.ListService"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <base-input>
                  <el-select class="select-primary"
                    placeholder="ÉTAT"
                    v-model="etatSelects.etat">
                    <el-option 
                      v-for="option in etatSelects.ListEtats"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.value">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6">
                <base-radio inline name="af" class="mb-3" v-model="law">
                  {{ $t("inputs.Law.Affiliates").toUpperCase() }}
                </base-radio>
                <base-radio inline name="an" class="mb-3" v-model="law">
                  {{ $t("inputs.Law.Advertiser").toUpperCase() }}
                </base-radio>
                <base-radio inline name="tm" class="mb-3" v-model="law">
                  TM
                </base-radio>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <base-checkbox inline class="mb-3" v-model="Geoloc">
                  GÉOLOC
                </base-checkbox>
                <base-checkbox inline class="mb-3" v-model="Spam">
                  FRAUDE
                </base-checkbox>
              </div>
            </div>
            <div class="col-md-12 float-right">
              <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                <button
                  @click="resetFilter"
                  class="btn btn-round btn-just-icon btn-primary mb-2 pull-right"
                >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
                <base-button 
                  v-if="$apollo.queries.listmembers.loading" 
                  loading 
                  round 
                  class="btn btn-round btn-just-icon btn-primary mb-2"
                >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                <button 
                  v-else @click="Rechercher"
                  class="btn btn-round btn-just-icon btn-primary mb-2"
                >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="col-xl-1 col-6">
                  <base-input>
                    <el-select
                      class="select-primary mb-3 pagination-select"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-xl-11 col-6">
                  <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                </div>
              </div>
              <el-table :data="queriedData">
                <!-- <el-table-column
                  v-if="showAddress"
                  key="1"
                >
                </el-table-column> -->
                <el-table-column
                  width="55"
                >
                  <template scope="scope">
                    <template v-if="scope.row.roles[0].id_client_myaffile && checkRole(scope.row.roles, 'af')">
                      <ApolloQuery
                        :query="gql => gql`
                          query ($id_client: ID!) {
                            getStatus_Editeur (id_client: $id_client)  {
                              statut_editeur
                            }
                          }
                        `"
                        :variables="{ 
                          id_client: scope.row.roles[0].id_client_myaffile
                        }"
                      >
                        <template v-slot="{ result: { error, data } }">
                          <div v-if="data" class="status_photo mr-2 float-left">
                            <img alt="GOLD" v-if="data.getStatus_Editeur.statut_editeur == 'GOLD'" class="img-fluid" src="/img/statut_editeur_gold.png" />
                            <img alt="SILVER" v-if="data.getStatus_Editeur.statut_editeur == 'SILVER'" class="img-fluid" src="/img/statut_editeur_silver.png" />
                            <img alt="BRONZE" v-if="data.getStatus_Editeur.statut_editeur == 'BRONZE'" class="img-fluid" src="/img/statut_editeur_bronze.png" />
                          </div>
                          <div v-else-if="error" class="error apollo"></div>
                          
                        </template>
                      </ApolloQuery>

                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="society"
                  :label="$t('table.columns.Society')"
                  class="table-striped"
                  :min-width="220"
                  sortable
                  show-overflow-tooltip
                >
                <template scope="scope">
                  <div @click="showFicheEditeur(scope.row.id)">
                    <a style="cursor: pointer;" class="nav-link">{{ scope.row.society.toUpperCase() }}</a>
                  </div>
                </template>
                </el-table-column>
                
                <el-table-column
                  prop="nbr_plaintes"
                  :label="$t('table.columns.Fraud')"
                  class="table-striped"
                  :min-width="90"
                >
                  <template scope="scope">
                    <el-tooltip
                      content="FRAUDE"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                    <base-button
                      class="edit btn-link"
                      :type="scope.row.nbr_plaintes == 0 ? 'success': 'danger'"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-alert-circle-exc"></i>
                    </base-button>
                    </el-tooltip>
                    <template v-if="scope.row.nbr_plaintes != 0">{{ scope.row.nbr_plaintes }}</template>
                  </template>
                </el-table-column>
                

                <el-table-column
                  prop="date_creation_france"
                  :label="$t('table.columns.CreationDate')"
                  class="table-striped"
                  :min-width="170"
                  sortable
                >
                </el-table-column>
                <el-table-column
                  prop=""
                  :label="$t('table.columns.Platform')"
                  :min-width="180"
                  class="table-striped"
                >
                  <template scope="scope">
                    <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.roles[0].id_client_myaffile || (scope.row.roles[1] ? scope.row.roles[1].id_client_myaffile : false)">
                      <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </button>
                    <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.roles[0].id_client_mycoreg || (scope.row.roles[1] ? scope.row.roles[1].id_client_mycoreg : false)">
                      <svg version="1.1" id="Calque_1" fill="#54c4d5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </button>
                    <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.roles[0].id_client_datadeal || (scope.row.roles[1] ? scope.row.roles[1].id_client_datadeal : false)">
                      <svg version="1.1" id="Calque_1" fill="#f3ac9a" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </button>
                    <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.roles[0].id_client_leadmarket || (scope.row.roles[1] ? scope.row.roles[1].id_client_mycoreg : false)">
                      <svg version="1.1" id="Calque_1" fill="#588bbd" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </button>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="$t('table.columns.Setting')"
                  :min-width="180">
                  <div slot-scope="props">
                    <div v-if="checkRole(props.row.roles, 'af') && (props.row.roles[0].id_client_myaffile || (props.row.roles[1] ? props.row.roles[1].id_client_myaffile : false))">
                      <el-tooltip
                        content="AJOUTER UNE CAMPAGNE"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                      <base-button
                        @click.native="openAddCompagne(props.row)"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-simple-add"></i>
                      </base-button>
                      </el-tooltip>
                      <el-tooltip
                        content="LA LISTE DES CAMPAGNES"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                      <base-button
                        @click.native="showCampagnes(props.row.roles)"
                        class="like btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-bullet-list-67"></i>
                      </base-button>
                      </el-tooltip>
                      <el-tooltip
                        content="LA LISTE DES SUPPORTS"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                      <base-button
                        @click.native="showSupports(props.row)"
                        class="like btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-molecule-40"></i>
                      </base-button>
                      </el-tooltip>

                      <el-tooltip
                        content="STATISTIQUES"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                      <base-button
                        @click.native="showStats(props.row.roles, props.row.society)"
                        class="edit btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-chart-bar-32"></i>
                      </base-button>
                      </el-tooltip>
                      <el-tooltip
                        content="APPEL A FACTURE"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                      <base-button
                        @click.native="showAAF(props.row)"
                        class="like btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="el-icon-document"></i>
                      </base-button>
                      </el-tooltip>
                    </div>
                    <div v-if="checkRole(props.row.roles, 'an')">
                      <el-tooltip
                        content="CREER UN OI"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <base-button
                          @click.native="openPageOI(props.row)"
                          class="edit btn-link"
                          type="info"
                          size="sm"
                          icon
                        >
                          <i class="tim-icons icon-simple-add"></i>
                        </base-button>
                      </el-tooltip>
                      <el-tooltip
                        content="UPLOAD CONTRAT MG"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                        v-if="props.row.roles[0].id_client_leadmarket"
                      >
                        <base-button
                          @click.native="openUploadOI(props.row)"
                          class="edit btn-link"
                          type="info"
                          size="sm"
                          icon
                        >
                          <i class="tim-icons icon-cloud-upload-94"></i>
                        </base-button>
                      </el-tooltip>
                      <el-tooltip
                        content="LA LISTE DES OI"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <base-button
                          @click.native="openListOI(props.row.roles , props.row.id)"
                          class="like btn-link"
                          type="info"
                          size="sm"
                          icon
                        >
                          <i class="tim-icons icon-bullet-list-67"></i>
                        </base-button>
                      </el-tooltip>
                    </div>
                  </div>
                </el-table-column>
                <el-table-column 
                  :min-width="150" 
                  label="Profil">
                  <div slot-scope="props">
                    <el-tooltip
                      content="MODIFIER"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                    <base-button
                      @click.native="editMember(props.$index, props.row)"
                      class="edit btn-link"
                      type="warning"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                    </el-tooltip>
                    <el-tooltip
                      content="SUPPRIMER"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                    <base-button
                      v-if="props.row.state == 'valide' || props.row.state == 'pause'"
                      @click.native="changeEtatMember(props.$index, props.row, 'supprime')"
                      class="like btn-link"
                      type="danger"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons el-icon-delete"></i>
                    </base-button>
                    </el-tooltip>
                    <el-tooltip
                      content="VALIDER"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                    <base-button
                      v-if="props.row.state == 'supprime'"
                      @click.native="changeEtatMember(props.$index, props.row, 'valide')"
                      class="like btn-link"
                      type="success"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons el-icon-check"></i>
                    </base-button>
                    </el-tooltip>
                    <el-tooltip
                      content="VALIDER"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                    <base-button
                      v-if="props.row.state == 'pause'"
                      @click.native="changeEtatMember(props.$index, props.row, 'valide')"
                      class="like btn-link"
                      type="success"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons el-icon-check"></i>
                    </base-button>
                    </el-tooltip>
                    <el-tooltip
                      content="SE CONNECTER AVEC CE COMPTE"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                    <base-button
                      v-if="checkLaw == 'ad'"
                      @click.native="viewMember(props.row)"
                      class="btn-link"
                      type="success"
                      size="sm"
                      icon
                    >
                     <i class="fas fa-link"></i>
                    </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
            
          </div>
        </div>
      </div>
    </div>



      <modal :show.sync="showExtractModal"
        body-classes="p-0" 
        class="modal-listbase">
        <card type="secondary"
          header-classes="bg-white pb-6"
          body-classes="px-lg-10 py-lg-10"
          class="border-0 mb-0">
          
            <base-button style="float: right;" @click.native="showExtractModal = false" class="like btn-link" type="danger" size="sm" icon>
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
            <center>
              <h4>{{ 'N° DU COMPTE : ' + user_id }}</h4>
              <img 
                class="avatar" 
                style="width: 124px; height: 124px;" 
                :src="'https://weedoit-media.s3.eu-central-1.amazonaws.com/' + dataFicheMembre.image"
                @error="imageLoadError"
                alt="..." 
              />
            </center>
            <hr />    
                        
            <div class="form-row">
              <label class="col-md-6"><b>NOM : </b>{{ dataFicheMembre.last_name }} </label>
              <label class="col-md-6"><b>PRENOM : </b>{{ dataFicheMembre.first_name }}</label>
            </div>
            <div class="form-row" v-if="dataFicheMembre.roles">
              <label class="col-md-12"><b>ROLES : </b>
                <span v-if="checkRole(dataFicheMembre.roles, 'an')">{{getTypeClient('an')}}</span>
                <span v-if="checkRole(dataFicheMembre.roles, 'af')">{{ ' ' + getTypeClient('af')}}</span>
                <span v-if="checkRole(dataFicheMembre.roles, 'ad')">{{ ' ' + getTypeClient('ad')}}</span>
                <span v-if="checkRole(dataFicheMembre.roles, 'tm')">{{ ' ' + getTypeClient('ma')}}</span>
                <span v-if="checkRole(dataFicheMembre.roles, 'tem')">{{ ' ' + getTypeClient('ma')}}</span>
                <span v-if="checkRole(dataFicheMembre.roles, 'ma')">{{ ' ' + getTypeClient('ma')}}</span>
              </label>
            </div>
            <div class="form-row">
              <label class="col-md-6"><b>SOCIÉTÉ :</b> {{ dataFicheMembre.society }}</label>
              <label class="col-md-6"><b>FONCTION : </b>{{ dataFicheMembre.function }}</label>
            </div>
            <div class="form-row">
              <label class="col-md-6"><b>ADRESSE : </b>{{ dataFicheMembre.address }}</label>
              <label class="col-md-6"><b>VILLE : </b>{{ dataFicheMembre.city }}</label>
            </div>
            <div class="form-row">
              <label class="col-md-6"><b>CODE POSTAL : </b>{{ dataFicheMembre.postal_code }}</label>
              <label class="col-md-6"><b>PAYS : </b>{{ dataFicheMembre.country }}</label>
            </div>
            <div class="form-row">
              <label class="col-md-6"><b>SECTEUR D'ACTIVITÉ : </b>{{ dataFicheMembre.activity_area }}</label>
              <label class="col-md-6"><b>TELEPHONE : </b>{{ dataFicheMembre.phone }}</label>
            </div>
            <div class="form-row">
              <label class="col-md-6"><b>EMAIL : </b>{{ dataFicheMembre.email }}</label>
              <label class="col-md-6"><b>SKYPE : </b>{{ dataFicheMembre.skype }}</label>
            </div>
            <div class="form-row">
              <label class="col-md-6"><b>RCS : </b>{{ dataFicheMembre.rcs }}</label>
              <label class="col-md-6"><b>TVA INTRACOMMUNAUTAIRE : </b>{{ dataFicheMembre.tva_intracommunautaire }}</label>
            </div>
            <div class="form-row">
              <label class="col-md-6"><b>COMMENTAIRE : </b>{{ dataFicheMembre.comment }}</label>
            </div>
            <hr>
            <h3>SITE WEB</h3>
            <hr>

            <div class="form-row">
              <label class="col-md-6"><b>NOM : </b>{{ dataSiteweb.nom_siteweb }}</label>
              <label class="col-md-6"><b>DESCRIPTION : </b>{{ dataSiteweb.description_siteweb }}</label>
            </div>
            <div class="form-row">
              <label class="col-md-6"><b>URL DU SITE : </b>{{ dataFicheMembre.url_site }}</label>
              <label class="col-md-6"><b>RÉPUTATION : </b>{{ dataSiteweb.skype }}</label>
            </div>
            <div class="form-row">
              <label class="col-md-6"><b>THÉMATIQUES : </b>{{ dataFicheMembre.thematic }}</label>
              <label class="col-md-6"><b>TYPE : </b>{{ dataFicheMembre.type_of_broadcast }}</label>
            </div>
            <div class="form-row">
              <label class="col-md-6"><b>LANGUE : </b>{{ dataSiteweb.langue }}</label>
              <label class="col-md-6"><b>VOLUME GLOBAL : </b>{{ dataFicheMembre.Volume }}</label>
            </div>

            <hr>
            <div class="text-center"><u><strong><span @click="showBases = !showBases">LISTE DES SUPPORTS</span></strong></u></div>
            <hr>
        
            <el-table :data="dataBases" v-if="showBases">
              <el-table-column min-width="50" type="index" label="#"></el-table-column>
              <el-table-column min-width="180" prop="nom_support" label="SUPPORT"></el-table-column>
              <el-table-column min-width="100" prop="audience" label="audience"></el-table-column>
              <el-table-column min-width="120" prop="pays" label="Pays"></el-table-column>
            </el-table>
            
            <hr>
            <h4>Emailing</h4>
            <div class="form-row">
              <label class="col-md-8">Diffusez-vous une newsletter ?</label>
              <base-switch
                :value="(dataSiteweb.emailing_1==0)?false:true"
                type="primary"
              ></base-switch>
            </div>
            <div class="form-row">
              <label class="col-md-8">Envoyez-vous des emails dédiés ?</label>
              <base-switch
                :value="(dataSiteweb.emailing_2==0)?false:true"
                type="primary"
              ></base-switch>
            </div>
            <div class="form-row">
              <label class="col-md-8">Pouvez-vous envoyer des emailings ciblés ?</label>
              <base-switch
                :value="(dataSiteweb.emailing_3==0)?false:true"
                type="primary"
              ></base-switch>
            </div>
            <div class="form-row">
              <label class="col-md-8">Pouvez-vous envoyer des emailings  géolocalisés ?</label>
              <base-switch
                :value="(dataSiteweb.emailing_4==0)?false:true"
                type="primary"
              ></base-switch>
            </div>
            <hr>
            <h4>Divers</h4>
            <div class="form-row">
              <label class="col-md-8">Pratiquez-vous l'incentive ?</label>
              <base-switch
                :value="(dataSiteweb.divers_1==0)?false:true"
                type="primary"
              ></base-switch>
            </div>
            <div class="form-row">
              <label class="col-md-8">Pratiquez-vous le clic de validation ?</label>
              <base-switch
                :value="(dataSiteweb.divers_2==0)?false:true"
                type="primary"
              ></base-switch>
            </div>
            <div class="form-row">
              <label class="col-md-8">Pratiquez-vous la co-registration ?</label>
              <base-switch
                :value="(dataSiteweb.divers_3==0)?false:true"
                type="primary"
              ></base-switch>
            </div>
            <div class="form-row">
              <label class="col-md-8">Pratiquez-vous la marque blanche ?</label>
              <base-switch
                :value="(dataSiteweb.divers_4==0)?false:true"
                type="primary"
              ></base-switch>
            </div>
            <div class="form-row">
              <label class="col-md-8">Pratiquez-vous le site-under ?</label>
              <base-switch
                :value="(dataSiteweb.divers_5==0)?false:true"
                type="primary"
              ></base-switch>
            </div>
            <div class="form-row">
              <label class="col-md-8">Pratiquez-vous d'autres procédés de diffusion publicitaire ?</label>
              <base-switch
                :value="(dataSiteweb.divers_6==0)?false:true"
                type="primary"
              ></base-switch>
            </div>
            <hr>
        </card>
      </modal>
      <modal_selfEvent :show.sync="ShowUploadOI" body-classes="p-0" modal-classes="modal-dialog-centered modal-sm">
        <card type="secondary" header-classes="bg-white pb-5" body-classes="px-lg-5 py-lg-5" class="border-0 mb-0">
          <el-tooltip
              content="Quitter"
              effect="light"
              :open-delay="300"
              placement="top"
            >
            <base-button
              @click.native="closepopItemUploadOI"
              class="btn-link float-right"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove icon-large"></i>
            </base-button>
          </el-tooltip>
          <h4 class="card-title">UPLOAD CONTRAT MG</h4>
          <template v-if="ShowUploadOI">
            <form role="form" class="text-center" >
                <base-input label="">
                  <image-upload
                    @change="UploadOI"
                    select-text="SÉLECTIONNER FICHIER PDF">
                  </image-upload>
                </base-input>
              <div>
                <base-button v-if="!update_loader" type="info" @click="UploadOIOnServ" class="my-4">{{ $t("buttons.BtnAdd").toUpperCase() }}</base-button>
                <base-button v-else type="info" loading class="my-4"></base-button>
              </div>
            </form>
          </template>
      </card>
      </modal_selfEvent>

  </div>
</template>

<script>
import {Select, Option ,DatePicker} from 'element-ui'
import { Table, TableColumn } from 'element-ui'
import { BasePagination } from 'src/components'
import { BaseRadio, BaseSwitch } from 'src/components/index'
import Modal_selfEvent from 'src/components/Modal_selfEvent'
import {ImageUpload} from 'src/components'
import gql from 'graphql-tag'
import Fuse from 'fuse.js';
import {Modal} from 'src/components'
import swal from 'sweetalert2'
import { Thematiques } from "src/pages/Filters/Thematiques";

export default {
   data() {
    return {
      user_id: null,
      showBases: false,
      update_loader:false,
      dataFicheMembre: {},
      dataSiteweb: {},
      dataBases: {},
      showExtractModal: false,
      skiplistMember: true,
      Spam: false,
      oi_pdf: null,
      Geoloc: false,
      ShowUploadOI: false,
      idCreateurUploadOI: null,
      idClientUploadOI: null,
      societyUploadOI:null,
      law: '',
      checkLaw :JSON.parse(localStorage.getItem('utilisateur')).law,
      keyword: '',
      Categorie: '',
      CriteresSelects: {
        Criteres: 'soc',
        ListCriteres: [
          {value: 'all', label: this.$t("Filter.label.allCriteria")},
          {value: 'soc', label:'Société'},
          {value: 'log', label:'Login'},
          {value: 'sub', label:'Subid'},
          {value: 'id', label:'ID'},
          {value: 'idt', label:'IDENTIFIANT'},
          {value: 'tag', label:'Tag de campagne'},
          {value: 'nom', label:'Nom ou prénom'},
          {value: 'web', label:'Site web'},
        ]
      },
      ThematiqueSelects: {
        Thematique: '',
        ListThematique: Thematiques
      },
      PaysBasesSelects: {
        PaysBase: '',
        ListPaysBases: []
      },
      ServiceSelects: {
        Service: 'all',
        ListService: [
          {value: 'all', label: this.$t("Filter.label.allServices")},
          {value: 'myaffile', label:'WEEDO PERF'},
          {value: 'mycoreg', label:'Weedo Coreg'},
          {value: 'datadeal', label:'Weedo Data'},
          {value: 'leadmarket', label:'Weedo Market'},
          // {value: 'mongustave', label:'Comparateur Mongustave'}
        ]
      },
      etatSelects: {
        etat: 'valide',
        ListEtats: [
          {value: 'valide', label:'VALIDE'},
          {value: 'pause', label:'EN ATTENTE'},
          {value: 'supprime', label:'SUPPRIMÉ'}
        ]
      },
      CategorieSelects: {
        Categorie: '',
        ListCategorie: [
          {value: '', label: this.$t("Filter.label.allCategories")},
          {value: 'A surveiller', label:'A surveiller'},
          {value: 'b to b', label:'B to B'},
          {value: 'Blog', label:'Blog'},
          {value: 'Cashback', label:'Cashback'},
          {value: 'Codes promos', label:'Codes promos'},
          {value: 'Coreg', label:'Coreg'},
          {value: 'Display', label:'Display'},
          {value: 'Incentive', label:'Incentive'},
          {value: 'Emailing mega base', label:'Emailing mega base'},
          {value: 'mobile', label:'mobile'},
          {value: 'plateforme', label:'plateforme'},
          {value: 'Réseau social', label:'Réseau social'},
          {value: 'Siteunder', label:'Siteunder'}
        ]
      },
      InactifSelects: {
        Inactif: '',
        ListInactif: [
          {value: 'all', label:'Pas de filtre'},
          {value: '0', label:'Ce mois'},
          {value: '1', label:'1 mois'},
          {value: '2', label:'2 mois'},
          {value: '3', label:'3 mois'},
          {value: '4', label:'4 mois'},
          {value: '5', label:'5 mois'},
          {value: '6', label:'6 mois et plus'}
        ]
      },
      StatutSelects: {
        Statut: 'all',
        ListStatut: [
          {value: 'all', label: this.$t("Filter.label.allStatutes")},
          {value: 'GOLD', label:'GOLD'},
          {value: 'SILVER', label:'SILVER'},
          {value: 'BRONZE', label:'BRONZE'},
          {value: 'REFUSE', label:'REFUSE'},
          {value: 'EXCLU', label:'EXCLU'},
          {value: 'OTHER', label:'AUTRE'}

        ]
      },
      SupportDiffusionSelects: {
        SupportDiffusion: '',
        ListSupportDiffusion: [
          {value: '', label: this.$t("Filter.label.allBroadcastSupport")},
          // Start New Filters
          // {value: 'Avantages consommateurs', label:'Avantages consommateurs'},
          // {value: 'Content & Influence marketing', label:'Content & Influence marketing'},
          // {value: 'Email Marketing', label:'Email Marketing'},
          // {value: 'Emulation', label:'Emulation'},
          // {value: 'Native', label:'Native'},
          // {value: 'Push notification', label:'Push notification'},
          // {value: 'Remarketing', label:'Remarketing'},
          // {value: 'Reseau sociaux', label:'Reseau sociaux'},
          // {value: 'Search', label:'Search'},
          // End New Filters
          // {value: 'Mailing', label:'Mailing'},
          // {value: 'Display', label:'Display'},
          // {value: 'Coreg', label:'Coreg'},
          // {value: 'Iframe', label:'Iframe'},
          // {value: 'Siteunder', label:'Siteunder'},
          // {value: 'Mobile', label:'Mobile'},
          // {value: 'Incentive', label:'Incentive'},
          {value: '1', label:'APPLI MOBILE'},
          {value: '2', label:'BASE EMAILS'},
          {value: '3', label:'BASE SMS'},
          {value: '4', label:'COMPARATEUR DE PRIX'},
          {value: '5', label:'DISPLAY'},
          {value: '6', label:'EMULATION'},
          {value: '7', label:'FACEBOOK'},
          {value: '8', label:'INSTAGRAM'},
          {value: '9', label:'LINKEDIN'},
          {value: '10', label:'PUSH NOTIFS'},
          {value: '11', label:'RETARGETING'},
          {value: '12', label:'SNAPCHAT'},
          {value: '13', label:'SEO/SEA'},
          {value: '14', label:'TWITTER'},
          {value: '15', label:'YOUTUBE'}
        ]
      },
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['societe_client'],
      tableData: [],
      searchedData: [],
      fuseSearch: null
    }
  },
  components: {
    BasePagination,
    BaseSwitch,
    ImageUpload,
    BaseRadio,
    Modal_selfEvent,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    Modal
  },
  apollo: {
    listcategories : { 
      query: gql` query {
        # thematique_categories{
        #   categorie
        # },
        pays_bases{
          pays
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.PaysBasesSelects.ListPaysBases = data.pays_bases
      },
      update: data => data
    },
    listmembers: {
      query: gql` query (
          $thematique: [String],
          $category: String, 
          $geoloc: Boolean, 
          $spam: Boolean, 
          $etat: String,
          $pays_base: String,
          $droit: String,
          $critere: String,
          $keyword: String,
          $statut: String
          $service: String
          $support_diffusion: String
        ) {
        filterclients (
          thematique: $thematique,
          droit: $droit,
          geoloc: $geoloc,
          spam: $spam,
          category: $category,
          inactif: null,
          statut: $statut,
          service: $service,
          support_diffusion: $support_diffusion,
          etat: $etat,
          pays_base: $pays_base,
          critere: $critere,
          keyword: $keyword
        )
        {
          id
          society
          nbr_plaintes
          #datecreation_client
          date_creation_france
          first_name
          last_name
          law
          state
          email
          roles{
            id_utilisateur
            id_client_myaffile
            id_client_mycoreg
            id_client_datadeal
            id_client_leadmarket
            droit
          }
        }
      }`,
      fetchPolicy: 'network-only',
      variables () {
        return {
          critere: this.CriteresSelects.Criteres,
          keyword: this.keyword,
          thematique: this.ThematiqueSelects.Thematique,
          category: this.CategorieSelects.Categorie,
          statut: this.StatutSelects.Statut,
          support_diffusion: this.SupportDiffusionSelects.SupportDiffusion,
          pays_base: this.PaysBasesSelects.PaysBase,
          etat: this.etatSelects.etat,
          droit: this.law,
          geoloc: this.Geoloc,
          spam: this.Spam,
          identifiant: this.identifiant,
          service: this.ServiceSelects.Service
          //droit: this.lawArr,
        }
      },
      result({ data, loader, networkStatus }) {
        this.tableData = data.filterclients
        this.mount()
        this.skiplistMember = true
      },
      update: data => data.filterclients,
      skip() {
        return this.skiplistMember
      },
    },
    client: {
      query: gql` query ($utilisateur_id: ID) 
      {
        show_fiche_membre_data(utilisateur_id: $utilisateur_id)
        {
            utilisateur{
              id
              first_name
              last_name
              image
              society
              email
              law
              state
              profile_account
              activity_area
              function
              address
              postal_code
              city
              country
              tva_intracommunautaire
              phone
              code_pays
              indicatif
              mobile
              skype
              rcs
              language
              thematic
              url_site
              type_of_broadcast
              Volume
              geographical_area
              Newsletter
              dedicated_email
              target_email
              geolocated_email
              incentive
              click_validation
              co_registration
              white_mark
              site_under
              autres_procedes_diffusion
              prenom_client
              api_token
              id_clien_myaffil
              id_clien_mycoreg
              id_clien_datadeal
              id_clien_leadmarket
              comment
              roles{
                id_utilisateur
                id_client_myaffile
                droit
              }
            }
            siteweb{
              url_siteweb
              maj_reputation
              reputation
              nom_siteweb
              description_siteweb
              theme
              type_site
              langue
              volume_base
              emailing_1
              emailing_2
              emailing_3
              emailing_4
              emailing_5
              divers_1
              divers_2
              divers_3
              divers_4
              divers_5
              divers_6
            }
            liste_supports{
              id_support
              nom_support
              pays
              audience
              id_client
              etat
            }
        }
      }`,
      update: data => data,
      result({ data, loader, networkStatus }) {
        if(data.show_fiche_membre_data){
          this.dataFicheMembre = data.show_fiche_membre_data[0].utilisateur || {}
          this.dataSiteweb = data.show_fiche_membre_data[0].siteweb || {}
          this.dataBases = data.show_fiche_membre_data[0].liste_supports || {}
        }
      },
      variables() {
        return {
          utilisateur_id: this.utilisateur_id
        }
      }
    },
  },
  methods: {
    editMember(index, row){
      let routeData = this.$router.resolve({ name:'member', params: {utilisateur_id: row.id} });
      window.open(routeData.href, '_blank');
    },
    changeEtatMember(index, row, state){
      swal.fire({
        // title: 'Êtes-vous sûr?',
        text: "Vous êtes en train de changer l'état de ce membre!",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'Changer',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          this.$apollo.mutate({
            client: 'AUTH',
            mutation: gql`mutation (
              $id_client: Int
              $etat: String
              $login_sup: String
            ) {
              change_etat_editeur(
                id_client: $id_client
                etat: $etat
                login_sup: $login_sup
              )
              {
                state
              }
            }`,
            variables: {
              id_client: row.id,
              etat: state,
              email_sup: JSON.parse(localStorage.getItem('utilisateur')).email
            },
          }).then(data => {
            var message = ''
            if(data){
              this.tableData.forEach(rows => {
                if(row == rows){
                  this.tableData.splice(index, 1)
                }
              });

              if(state == 'supprime'){
                message = 'L\'etat du membre est supprimé.'
              }else if(state == 'valide'){
                message = 'L\'etat du membre est validé.'
              }else{
                message = 'L\'etat du membre est en pause.'
              }
            }
            this.$notify({type: "success", verticalAlign: 'top', horizontalAlign: 'right', message: message, duration: 5000})
          }).catch((error) => {
            // Error
            console.error(error)
          })
        }
      })
    },
    viewMember(row){
      console.log(row);
      swal.fire({
        // title: 'Êtes-vous sûr?',
        text: "Attention : vous allez se connecter avec le compte de "+ row.society + ". Toutes les actions que vous allez faire seront enregistrées sur ce compte.",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'se connecter avec ce compte',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          this.$apollo.query({
            client: 'AUTH',
            query: gql`query ($id: Int) 
            {
              userFind(id: $id)
              {
                  id
                  last_name
                  first_name
                  society
                  email
                  law
                  state
                  profile_account
                  activity_area
                  orias
                  function
                  address
                  postal_code
                  city
                  country
                  tva_intracommunautaire
                  phone
                  mobile
                  skype
                  rcs
                  language
                  thematic
                  url_site
                  type_of_broadcast
                  Volume
                  geographical_area
                  login_sup
                  bloque
                  Newsletter
                  dedicated_email
                  target_email
                  geolocated_email
                  incentive
                  click_validation
                  co_registration
                  white_mark
                  site_under
                  autres_procedes_diffusion
                  created_at
                  updated_at
                  code_pays
                  indicatif
                  image
                  api_token
                  date_connexion
                  ip_connexion
                  id_clien_myaffil
                  id_clien_mycoreg
                  id_clien_datadeal
                  id_clien_leadmarket
                  duplicated
                  multi_droit
              }
            }`,
            variables: {
              id: row.id
            },
          }).then(data => {
            var message = ''
            if(data){
              localStorage.setItem("user_origine_id",JSON.parse(localStorage.getItem('utilisateur')).id)
              data.data.userFind.image = (data.data.userFind.image) ? 'https://weedoit-media.s3.eu-central-1.amazonaws.com/' + data.data.userFind.image : null
              localStorage.setItem('utilisateur',JSON.stringify(data.data.userFind))
              location.replace('/')
            }
          }).catch((error) => {
            // Error
            console.error(error)
          })
        }
      })
    },
    resetFilter(){
      this.CriteresSelects.Criteres = 'all'
      this.ServiceSelects.Service = 'all'
      this.keyword = ''
      this.ThematiqueSelects.Thematique = ''
      this.CategorieSelects.Categorie = ''
      this.StatutSelects.Statut = 'all'
      this.SupportDiffusionSelects.SupportDiffusion = ''
      this.PaysBasesSelects.PaysBase = ''
      this.etatSelects.etat = ''
      this.law = ''
      this.Geoloc = false
      this.Spam = false
    },
    getClientIDFromRoles(arr_roles, role){
      if(arr_roles.hasOwnProperty(0) && arr_roles[0].droit == role){
        return arr_roles[0].id_client_myaffile
      }
      if(arr_roles.hasOwnProperty(1) && arr_roles[1].droit == role){
        return arr_roles[1].id_client_myaffile
      }
      return null
    },
    openPageOI(row) {
      let id_annonceur = this.getClientIDFromRoles(row.roles, 'an')
      let routeData = this.$router.resolve({ name:'add_oi', params: {id_utilisateur: row.id, id_annonceur: id_annonceur} });
      window.open(routeData.href, '_blank');
    },
    openListOI(roles, id_utilisateur) {
      let id_annonceur = this.getClientIDFromRoles(roles, 'an')
      let routeData = this.$router.resolve({ name:'liste de OI disponible Admin', params: {id_annonceur: id_utilisateur} });
      window.open(routeData.href, '_blank');
    },
    openAddCompagne(row){
      localStorage.setItem("add_campagne_id_utilisateur", row.id)
      localStorage.setItem("add_campagne_id_client", this.getClientIDFromRoles(row.roles, 'af'))
      let routeData = this.$router.resolve({ name:'add_campagne'});
      window.open(routeData.href, '_blank');
    },
    showSupports(row){
      let id_utilisateur = row.id
      let routeData = this.$router.resolve({ name:'list_supports', params: {id_utilisateur: id_utilisateur} });
      window.open(routeData.href, '_blank');
    },
    showAAF(row){
      let id_utilisateur = row.id
      let id_editeur = this.getClientIDFromRoles(row.roles, 'af')
      let routeData = this.$router.resolve({ name:'Appel a Facture Editeur-Admin', params: {id_utilisateur: id_utilisateur, id_editeur: id_editeur} });
      window.open(routeData.href, '_blank');
    },
    closepopItemUploadOI(){
        this.ShowUploadOI = false
        this.idCreateurUploadOI = null;
        this.idClientUploadOI = null;
        this.societyUploadOI = null;
        this.oi_pdf = null;
    },
    openUploadOI(row){
      this.idCreateurUploadOI = JSON.parse(localStorage.getItem('utilisateur')).id
      this.idClientUploadOI = row.id
      this.societyUploadOI = row.society
      console.log('idCreateurUploadOI ',this.idCreateurUploadOI,' idClientUploadOI ', this.idClientUploadOI,' societyUploadOI ',this.societyUploadOI);
      this.ShowUploadOI = true
    },
    UploadOI(file) {
      this.oi_pdf = file;
    },
    UploadOIOnServ() {
      this.update_loader = true;
      let accessToken = localStorage.getItem("token_jwt");
      let formData = new FormData()
      formData.append('file', this.oi_pdf)
      formData.append('id_client', this.idClientUploadOI)
      formData.append('societe', this.societyUploadOI)
      formData.append('id_createur', this.idCreateurUploadOI)
      this.axios.post("https://api-auth-plf.wee-do-it.net/api/v1/uploadoi_mongustave", formData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        this.$notify({
          type: "success",
          message: "le OI ajouté avec succes",
        });
        this.update_loader = false;
        this.update_end = true;
        this.$router.push({name: "liste de OI disponible Admin"})
      })
      .catch((e) => {
        this.$notify({
          type: "warning",
          message: "le fichier n'est pas pdf",
        });
        console.log("error occured");
      });
    },
    showCampagnes(roles){
      let id_client = this.getClientIDFromRoles(roles, 'af')
      if (this.CriteresSelects.Criteres == 'tag')
        localStorage.setItem("tag", this.keyword)
      let routeData = this.$router.resolve({ name:'liste_campagnes', params: {id_client: id_client} });
      window.open(routeData.href, '_blank');
    },
    trigger () {
    	this.skiplistMember = false
    },
    Rechercher() {
      this.skiplistMember = false
    },
    mount() {
      this.fuseSearch = new Fuse(this.tableData, {
        keys: ['societe_client'],
        threshold: 0.3
      });
    },
    showFicheEditeur(utilisateur_id){
      this.user_id = utilisateur_id
      this.showExtractModal = true
      this.$apollo.queries.client.refetch({
        utilisateur_id: utilisateur_id
      })
    },
    showStats(roles, society) {
      let id_client = this.getClientIDFromRoles(roles, 'af')
      let routeData = this.$router.resolve({ name:'stats_globales_editeur', params: {id_client: id_client, society: society} });
      window.open(routeData.href, '_blank');
    },
    getTypeClient(droit){
        switch (droit) {
          case 'an':
              return "ANNONCEUR";
              break;
          case 'af':
              return "EDITEUR";
              break;
          case 'ma':
              return "TRAFFIC MANAGER";
              break;
          case 'ad':
              return "ADMIN";
              break;	
        }
      },
      etat(value){
        if (value==1){
          return "http://www.weedoit.fr/tracking/img/icones/valide.jpg";
        }else{
          return "http://www.weedoit.fr/tracking/img/icones/erreur.jpg";
        }
    },
    checkRole(roles, role){
      if(roles.length > 0){
        if(role == 'af'){
          if(roles.hasOwnProperty(0) && roles[0].droit == 'af'){
            return true;
          }
          if(roles.hasOwnProperty(1) && roles[1].droit == 'af'){
            return true;
          }
        }else if(role == 'an'){
          if(roles.hasOwnProperty(0) && roles[0].droit == 'an'){
            return true;
          }
          if(roles.hasOwnProperty(1) && roles[1].droit == 'an'){
            return true;
          }
        }else if(role == 'ad'){
          if(roles.hasOwnProperty(0) && roles[0].droit == 'ad'){
            return true;
          }
          if(roles.hasOwnProperty(1) && roles[1].droit == 'ad'){
            return true;
          }
        }else if(role == 'ma'){
          if(roles.hasOwnProperty(0) && roles[0].droit == 'ma'){
            return true;
          }
          if(roles.hasOwnProperty(1) && roles[1].droit == 'ma'){
            return true;
          }
        }else if(role == 'tm' || role == 'tem'){
          if(roles.hasOwnProperty(0) && (roles[0].droit == 'tm' || roles[0].droit == 'tem')){
            return true;
          }
          if(roles.hasOwnProperty(1) && (roles[1].droit == 'tm' || roles[1].droit == 'tem')){
            return true;
          }
        }
      }
      return false;
    },
    imageLoadError(event) {
      event.target.src = "/img/placeholder.jpg";
    },
    openAddMember(){
      let routeData = this.$router.resolve({ name:'member'});
      window.open(routeData.href, '_blank');
    }
  },
  watch: {
    searchQuery(value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    }
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  created() {
    if (localStorage.getItem("ShowWaitingMembers")) {
      localStorage.removeItem("ShowWaitingMembers")
      this.etatSelects.etat = "pause"
      this.skiplistMember = false  
    }
  },
}
</script>

<style scoped>
  .status_photo {
    height: 30px;
    width: 30px;
    margin: 0 auto;
  }
  .status_photo img {
    width: 100%;
  }
</style>