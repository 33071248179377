<template>
  <div class="content">
    <h3>LEADS TELETECH</h3>
    
    <div class="card">
      <div class="card-body">
        <div class="row">
          <base-input class="col-md-3">
            <el-date-picker v-model="StartDate" :clearable="false" :disabled="$apollo.queries.getVoneoLivraisonStats.loading" value-format="yyyy-MM-dd" type="date" :placeholder="$t('table.columns.Startdate').toUpperCase()"></el-date-picker>
          </base-input>
          <base-input class="col-md-3">
            <el-date-picker v-model="EndnDate" :clearable="false" :disabled="$apollo.queries.getVoneoLivraisonStats.loading" value-format="yyyy-MM-dd" type="date" :placeholder="$t('table.columns.Enddate').toUpperCase()"></el-date-picker>
          </base-input>
          <base-input class="col-md-3">
            <el-select filterable class="select-primary"
              v-model="ProduitSelects.Produit"
              :disabled="$apollo.queries.getVoneoLivraisonStats.loading"
              clearable
              placeholder="PRODUIT"
            >
              <el-option 
                v-for="option in ProduitSelects.ListProduit"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.value">
              </el-option>
            </el-select>
          </base-input>
          <base-input class="col-md-3">
            <el-select filterable class="select-primary"
              v-model="GameSelects.Game"
              :disabled="$apollo.queries.getVoneoLivraisonStats.loading"
              clearable
              placeholder="GAMME"  
            >
              <el-option 
                v-for="option in GameSelects.ListGame"
                class="select-primary"
                :value="option.value"
                :label="option.label"
                :key="option.value">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <!-- <div class="col-xs-4 text-right">
          <base-button 
            v-if="$apollo.queries.getVoneoLivraisonStats.loading" 
            loading 
            class="btn btn-default" 
          >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
          <button 
            v-else
            class="btn btn-default" 
            @click="search"
          >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
        </div> -->
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-xl-1 col-sm-6">
            <base-input>
              <el-select
                class="select-primary pagination-select"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col-xl-7 col-sm-6">
            <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
          </div>
          <div class="col-lg-2">
            <base-button type="market" size="sm" @click="DownloadCSV('succes')" :disabled="loadcsv">
              <i class="tim-icons icon-cloud-download-93"></i> MER SUCCÈS 
            </base-button>
          </div>
          <div class="col-lg-2">
            <base-button type="danger" size="sm" @click="DownloadCSV('echec')" :disabled="loadcsv">
              <i class="tim-icons icon-cloud-download-93"></i> MER REFUS 
            </base-button>
          </div>
        </div> 
        <el-table :data="filterResult.slice(from, to)" v-loading="$apollo.queries.getVoneoLivraisonStats.loading" row-class-name="booking-table-rows" class="customtable_cmd" show-summary :summary-method="getSummaries">
          <el-table-column
            prop='produit'
            label="produit"
            minWidth='100'
            show-overflow-tooltip
            sortable
          >
            <template scope="scope">
              <i :class="icons[scope.row.produit] + ' icon-large'"></i>
            </template>
          </el-table-column>
          <el-table-column
            prop='gamme'
            label="gamme"
            minWidth='100'
            show-overflow-tooltip
            sortable
          >
          </el-table-column>
          <el-table-column
            prop='status'
            label="status"
            minWidth='100'
            show-overflow-tooltip
            sortable
          >
          </el-table-column>
          <!-- <el-table-column
            prop='date'
            label="Date d'envoi"
            minWidth='100'
            show-overflow-tooltip
            sortable
          >
          </el-table-column> -->
          <!-- <el-table-column
            prop='client'
            label="type"
            minWidth='100'
            show-overflow-tooltip
            sortable
          >
          </el-table-column> -->
          <el-table-column
            prop='volume'
            label="Volume envoyé"
            minWidth='100'
            show-overflow-tooltip
            sortable
          >
            <template scope="props">
              {{ formatThounsends(props.row.volume, true, 0)}}
            </template>
          </el-table-column>
        </el-table>
        <div
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
            </p>
          </div>
          <base-pagination
            type="market"
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          >
          </base-pagination>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import { Select, Option, DatePicker } from 'element-ui'
  import { Table, TableColumn } from 'element-ui'
  import { BasePagination } from 'src/components';
  import gql from 'graphql-tag'
  import moment from 'moment'
  
  let today = new Date()
  
  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      BasePagination
    },
    apollo: {
      getVoneoLivraisonStats: {
        client: 'MARKET',
        query: gql` query {
          getVoneoLivraisonStats{
            traces
          }
        }`,
        result({ data, loader, networkStatus }) {
          this.Results = []
          let elemets = []
          let object = {}
          data.getVoneoLivraisonStats.forEach(ele => {
            elemets = ele.traces.split("-");
            object = {
              "gamme" : (elemets[0].toLowerCase() === 'gold') ? 'chaud' : elemets[0].toLowerCase(), 
              "client" :elemets[1], 
              "status": elemets[2], 
              "produit": elemets[3], 
              "date_time": elemets[4] + "-" + elemets[5] + "-" + elemets[6]
            };
            object.date = object.date_time.split(" ")[0]

            this.Results.push(object);
          });
          this.Results.sort((a, b) => a.produit - b.produit )
        },
        fetchPolicy: 'network-only',
        update: data => data.getVoneoLivraisonStats
      }
    },
    data() {
      return {
        loadcsv: false,
        pagination: {
          perPage: 250,
          currentPage: 1,
          perPageOptions: [100, 250, 500, 1000, 1500],
          total: 0
        },
        Results: [],
        StartDate: moment().clone().startOf('month').format('YYYY-MM-DD'),
        EndnDate: moment().format('YYYY-MM-DD'),
        icons: {
          '1' : 'fas fa-heartbeat', 
          '2' : 'fas fa-car', 
          '3' : 'fas fa-home', 
          '4' : 'fas fa-paw', 
          '8' : 'fas fa-people-arrows', 
          '12' : 'tim-icons icon-bank'
        },
        GameSelects: {
          Game: '',
          ListGame: [
            { value: 'chaud', label: 'CHAUD' },
            { value: 'froid', label: 'FROID' },
            // { value: 'gold', label: 'GOLD' },
          ]
        },
        ProduitSelects: {
          Produit: '',
          ListProduit: [
            { value: '4', label: 'ANIMAUX' },
            { value: '2', label: 'AUTO' },
            { value: '12', label: 'EMPRUNTEUR' },
            { value: '3', label: 'MRH' },
            { value: '1', label: 'SANTE' },
          ]
        },
      }
    },
    methods: {
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
        value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      getSummaries(param) {
        const { columns, data } = param;
        let somme_volume = 0
        data.forEach(ele => {
          somme_volume += ele.volume
        });
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = "";
            return;
          } else if (index === 3) {
            sums[index] = this.formatThounsends(somme_volume);
            return;
          } else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      DownloadCSV(type) {
        const url_api = `https://apileadmarket.wee-do-it.net/api/export-voneo-data?produit=${this.ProduitSelects.Produit}&date_debut=${this.StartDate}&date_fin=${this.EndnDate}&gamme=${this.GameSelects.Game}&return=${type}`
        
        const link = document.createElement('a');
        link.href = url_api;
        document.body.appendChild(link);
        link.click();
      }
    },
    computed: {
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      },
      total() {
        return this.filterResult.length
      },
      filterResult() {

        let filterResult = this.Results.filter(x => {

          if (this.StartDate && this.EndnDate && !moment(x.date).isBetween(this.StartDate, this.EndnDate, undefined, '[]')) {
            return false;
          } else if (this.StartDate && !this.EndnDate && !moment(x.date).isSame(this.StartDate)) {
            return false;
          } else if (!this.StartDate && this.EndnDate && !moment(x.date).isSame(this.EndnDate)) {
            return false;
          }
          
          if (this.ProduitSelects.Produit && x.produit != this.ProduitSelects.Produit) {
            return false
          }

          if (this.GameSelects.Game && x.gamme != this.GameSelects.Game) {
            return false
          }

          return true
        });

        return alasql('SELECT *,COUNT(gamme) as volume from ? GROUP BY produit,gamme,status',[filterResult]);
      }
    },
  }
  
</script>
  
<style>
  .customtable_cmd .cell{
    word-break: break-word;
  }

  .booking-table-rows {
    text-transform: uppercase;
  }
</style>