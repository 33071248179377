<template>
  <card>
    <div class="row justify-content-center mt-5">
      <template slot="header">
        <h5 class="description">{{ $t('labels.SignUpHint')}}</h5>
        <base-alert type="default" dismissible v-show="ereurs.length">
          <div v-for="(error, index) in ereurs" :key="index">
            <li>{{error}}</li>
          </div>
        </base-alert>
      </template>

      <div class="col-sm-5">
        <base-input
          name="email"
          required
          :placeholder="$t('inputs.Email').toUpperCase()"
          v-model="model.email"
          v-validate="modelValidations.email"
          type="email"
          :error="getError('email')"
          addon-left-icon="tim-icons icon-email-85"
          :disabled="emailDisable"
        ></base-input>
      </div>

      <div class="col-sm-5">
        <base-input
          v-validate="modelValidations.password"
          name="password"
          :error="getError('password')"
          v-model="model.password"
          type="password"
          :placeholder="$t('inputs.Password').toUpperCase()"
          addon-left-icon="tim-icons icon-lock-circle"
          data-vv-as="mot de passe"
          :disabled="passwordDisable"
        ></base-input>
      </div>

      <el-popover trigger="hover" placement="top">
        <div>
          <h3 class="popover-header">Popover on Top</h3>
          <div class="popover-body">Here will be some very useful information about his popover.</div>
        </div>
        <div class="col-sm-10">
          <base-input
            v-validate="modelValidations.password"
            name="password"
            :error="getError('password')"
            v-model="model.password"
            type="password"
            :placeholder="$t('inputs.Password')"
            addon-left-icon="tim-icons icon-lock-circle"
            data-vv-as="mot de passe"
            slot="reference"
          ></base-input>
        </div>
      </el-popover>
    </div>
    <!-- row 3 -->
    <div class="row justify-content-center">
      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.Society') + ' *'"
          name="society"
          v-model="model.society"
          :error="getError('society')"
          v-validate="'required|max:60'"
          :data-vv-as="$t('inputs.Society')"
        ></base-input>
      </div>

      <div class="col-sm-5">
        <base-input :label="$t('inputs.Activity')  + ' *'" :error="getError('activity')">
          <el-select
            v-model="model.activity"
            placeholder="CHOISIR"
            class="select-primary"
            name="activity"
            v-validate="'required|max:60|min:3'"
            :data-vv-as="$t('inputs.Activity')"
            filterable
          >
            <el-option v-for="a in activity" class="select-primary" :label="a.toUpperCase()" :value="a" :key="a"></el-option>
          </el-select>
        </base-input>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-sm-10 mt-3">
        <center>
          <base-checkbox
            inline
            :disabled="roles.length == 2 || roles[0].droit == 'af' || roles[0].droit == 'ad' || roles[0].droit == 'tem'|| roles[0].droit == 'ma'"
            class="mb-2"
            v-model="model.affilie"
          >{{ $t('inputs.Law.Affiliates').toUpperCase() }}</base-checkbox>
          <base-checkbox
            inline
            :disabled="roles.length == 2 || roles[0].droit == 'an' || roles[0].droit == 'ad' || roles[0].droit == 'tem'|| roles[0].droit == 'ma'"
            class="mb-2"
            v-model="model.annonceur"
          >{{ $t('inputs.Law.Advertiser').toUpperCase() }}</base-checkbox>
          <base-checkbox
            inline
            :disabled="roles.length == 2 || roles[0].droit == 'an' || roles[0].droit == 'ad' || roles[0].droit == 'tem'|| roles[0].droit == 'ma'"
            class="mb-2"
            v-model="model.agence_media"
          >AGENCE MÉDIA</base-checkbox>
        </center>
      </div>
      <!-- <div class="col-sm-5">
        <center>
          <label
            class="error"
            style="color:#ec250d"
            v-show="showErrorLaw"
          >Le champ droit est obligatoire</label>
          <br />
        </center>
      </div> -->
    </div>&nbsp;
    <div class="row justify-content-center">
      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.Lastname') + ' *'"
          name="lastname"
          :placeholder="$t('inputs.Lastname').toUpperCase()"
          v-model="model.lastname"
          :error="getError('lastname')"
          v-validate="'required|max:60|min:3'"
          addon-left-icon="tim-icons icon-single-02"
          :data-vv-as="$t('inputs.Lastname')"
        ></base-input>
      </div>

      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.Firstname') + ' *'"
          name="firstname"
          :placeholder="$t('inputs.Firstname').toUpperCase()"
          v-model="model.firstname"
          :error="getError('firstname')"
          v-validate="'required|max:60|min:3'"
          addon-left-icon="tim-icons icon-caps-small"
          :data-vv-as="$t('inputs.Firstname')"
        ></base-input>
      </div>

      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.Function') + ' *'"
          name="function"
          v-model="model.function"
          :error="getError('function')"
          v-validate="'required|max:60|min:3'"
          :data-vv-as="$t('inputs.Function')"
        ></base-input>
      </div>

      <div class="col-sm-5">
        <label>{{ $t('inputs.Address').toUpperCase() + " *" }}</label>
        <vue-google-autocomplete
          name="address"
          id="from_address"
          class="form-control"
          :placeholder="$t('inputs.Address').toUpperCase()"
          v-model="model.address"
          v-validate="'required'"
          v-on:placechanged="getFromAddress"
          :error="showErrorAddress"
          v-bind:style="showErrorAddress ? 'border-color:#ec250d' : ''"
          @change="checkAddress()"
        ></vue-google-autocomplete>
        <label
          class="error"
          style="color:#ec250d"
          v-show="showErrorAddress"
        >Le champ adresse est obligatoire</label>
      </div>

      <div class="col-sm-5">
        <base-input
          type="number"
          name="zipcode"
          :label="$t('inputs.zipcode')"
          required
          v-model="model.zipcode"
          :error="getError('zipcode')"
          v-validate="'required|max:60|min:4'"
          :data-vv-as="$t('inputs.zipcode')"
        ></base-input>
      </div>

      <div class="col-sm-5">
        <base-input
          name="city"
          :label="$t('inputs.City')"
          required
          v-model="model.city"
          :error="getError('city')"
          v-validate="'required|min:3|max:60'"
          :data-vv-as="$t('inputs.City')"
        ></base-input>
      </div>

      <div class="col-sm-5">
        <base-input :label="$t('inputs.Country') + ' *'" :error="getError('country')">
          <el-select
            v-model="model.country"
            filterable
            class="select-primary"
            name="country"
            v-validate="'required|max:60|min:3'"
            :placeholder="$t('inputs.SelectCountry').toUpperCase()"
            :data-vv-as="$t('inputs.Country')"
          >
            <el-option
              v-for="country in countryOptions"
              class="select-primary"
              :label="country.toUpperCase()"
              :value="country"
              :key="country"
            ></el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-sm-5">
        <base-input
          :label="$t('inputs.TvaIntra')"
          name="tva"
          v-model="model.tva"
          :data-vv-as="$t('inputs.TvaIntra')"
        ></base-input>
      </div>

      <div class="col-sm-5">
        <label>{{ $t('inputs.PhoneNumber').toUpperCase() + " *"}}</label>
        <vue-phone-number-input
          name="mobile"
          valid-color="#54347C"
          :no-example="true"
          :dark="true"
          :border-radius="8"
          dark-color="#000"
          error-color="#ec250d"
          :only-countries="this.countrysCode"
          :default-country-code="model.cMobile"
          :translations="{
            countrySelectorLabel: $t('inputs.CountryCode'),
            phoneNumberLabel: $t('inputs.PhoneNumber').toUpperCase(),
            }"
          color="#988fc3"
          :error="showErrorMobile"
          v-validate="'required'"
          v-model="model.mobile"
          @update="onUpdate"
        ></vue-phone-number-input>
        <label
          class="error"
          style="color:#ec250d"
          v-show="showErrorMobile"
        >Le champ numéro de téléphone est obligatoire</label>
        <div class="input-group"></div>
      </div>

      <div class="col-sm-5">
        <base-input
          label="Skype"
          name="skype"
          :error="getError('skype')"
          v-validate="'min:3'"
          v-model="model.skype"
        ></base-input>
      </div>

      <div class="col-sm-5">
        <base-input :label="$t('inputs.Language') + ' *'" :error="getError('langue')">
          <el-select
            v-model="model.langue"
            class="select-primary"
            name="langue"
            v-validate="'required|min:2'"
            :placeholder="$t('inputs.SelectLang').toUpperCase()"
            :data-vv-as="$t('inputs.Language')"
          >
            <el-option
              v-for="l in languageOptions"
              class="select-primary"
              :label="l.toUpperCase()"
              :value="l"
              :key="l"
            ></el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-sm-5" v-if="model.affilie">
        <base-input :label="$t('inputs.Themes')" :error="getError('thématiques')">
          <el-select
            v-model="model.thematic"
            name="thématiques"
            v-validate="'min:2'"
            class="select-primary"
            :placeholder="$t('inputs.Themes').toUpperCase()"
            :data-vv-as="$t('inputs.Themes')"
          >
            <el-option v-for="t in thematics" class="select-primary" :label="t.toUpperCase()" :value="t" :key="t"></el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-sm-5" v-if="model.affilie">
        <base-input
          type="url"
          :label="$t('inputs.UrlSite')"
          name="URL"
          v-model="model.url_site"
          :error="getError('URL')"
          v-validate="'url|min:5'"
        ></base-input>
      </div>

      <div class="col-sm-5" v-if="model.affilie">
        <base-input :label="$t('inputs.DiffusionType')" :error="getError('diffusion')">
          <el-select
            v-model="model.type_of_broadcast"
            class="select-primary"
            name="Type_de_diffusion"
            :placeholder="$t('inputs.DiffusionType').toUpperCase()"
            :data-vv-as="$t('inputs.DiffusionType')"
          >
            <el-option
              v-for="(value, key) in DiffusionTypeList"
              class="select-primary"
              :label="value.toUpperCase()"
              :value="value"
              :key="key"
            ></el-option>
          </el-select>
        </base-input>
      </div>

      <div class="col-sm-5">
        <base-input
          label="RCS *"
          name="rcs"
          v-validate="'required|min:2'"
          v-model="model.rcs"
          :error="getError('rcs')"
        ></base-input>
      </div>

      <div class="col-sm-5">
        <base-input
          type="number"
          label="Volume"
          name="volume"
          v-model="model.volume"
        ></base-input>
      </div>

      <div class="col-sm-5">
        <transition name="fade">
          <base-input
            label="Orias"
            name="orias"
            required
            v-model="model.orias"
            :error="getError('orias')"
            v-validate="'required|min:2'"
            v-if="(model.annonceur || model.agence_media) && model.activity == 'Assurances'"
          ></base-input>
        </transition>
      </div>
      <div class="col-sm-5"></div>
      <div class="col-sm-10" v-if="model.affilie">
        <div class="row">
          <base-input label="commentaire" class="col-sm-6">
            <textarea v-model="model.comment" class="form-control" rows="3"
            ></textarea>
          </base-input>

          <div class="col-md-6">
            <base-input label="STATUS EDITEUR">
              <el-select
                v-model="statut_compte"
                class="select-primary"
                name="Statut_compte"
                placeholder="STATUS EDITEUR"
                data-vv-as="STATUS EDITEUR"
              >
                <el-option
                  v-for="(statut, key) in statut_compte_options"
                  class="select-primary"
                  :label="statut.name.toUpperCase()"
                  :value="statut.value"
                  :key="key"
                ></el-option>
              </el-select>
            </base-input>
          </div>
        </div>
      </div>

      <template v-if="ClientWeedoMarket">
        <br />
        <div class="col-sm-10">
          <h3 class="info-text">PARAMÈTRES CLIENT WEEDO MARKET</h3>
        </div>

        <div class="col-sm-5">
          <base-input label="CHARGÉE D'AFFAIRE">
            <el-select
              v-model="ParamsClientWM.CA"
              class="select-primary"
            >
              <el-option
                v-for="option in chargesAffaires"
                class="select-primary"
                :label="option.nom.toUpperCase()"
                :value="option.pk_charge_affaire"
                :key="option.pk_charge_affaire"
              ></el-option>
            </el-select>
          </base-input>
        </div>

        <div class="col-sm-5">
          <base-input label="PROVENANCE CLIENT">
            <el-select
              v-model="ParamsClientWM.provenance"
              class="select-primary"
            >
              <el-option
                v-for="option in provenances"
                class="select-primary"
                :label="option.nom.toUpperCase()"
                :value="option.pk_provenance_client"
                :key="option.pk_provenance_client"
              ></el-option>
            </el-select>
          </base-input>
        </div>

        <div class="col-sm-10 mt-3">
          <center>
            <base-checkbox
              inline
              class="mb-2"
              v-model="ParamsClientWM.acces_destockage"
            >AUTORISER À COMMANDER DU DESTOCKAGE</base-checkbox>
            <base-checkbox
              inline
              class="mb-2"
              v-model="ParamsClientWM.ne_pas_contacter"
            >NE PAS CONTACTER</base-checkbox>
            <base-checkbox
              :disabled="!disable_leads_temps"
              inline
              class="mb-2"
              v-model="ParamsClientWM.leads_temps_reel"
            >LEADS EN TEMPS RÉEL</base-checkbox>
            <base-checkbox
              inline
              class="mb-2"
              v-model="ParamsClientWM.interdit_a_commander"
            >INTERDIT À PASSER DES COMMANDES</base-checkbox>
          </center>
        </div>

      </template>
    </div>&nbsp;


    <hr>

    &nbsp;
    <div class="row justify-content-center">
      <div class="col-sm-10">
        <h2 class="info-text">{{ $t('labels.YourPreferences').toUpperCase() }}</h2>
      </div>
      <div class="col-sm-10">
        <h4>EMAILLING</h4>
        <div class="row mb-3">
          <div class="col-md-6">
            <p class="category">{{ $t('inputs.Emailling.NewsLetter').toUpperCase() }}</p>
            <base-switch v-model="model.newsletter" type="primary" on-text="ON" off-text="OFF"></base-switch>
          </div>
          <div class="col-md-6">
            <p class="category">{{ $t('inputs.Emailling.DedicatedEmail').toUpperCase() }}</p>
            <base-switch v-model="model.emails_dedies" type="primary" on-text="ON" off-text="OFF"></base-switch>
          </div>
          <div class="col-md-6">
            <p class="category">{{ $t('inputs.Emailling.TargetEmail').toUpperCase() }}</p>
            <base-switch
              v-model="model.emailings_cibles"
              type="primary"
              on-text="ON"
              off-text="OFF"
            ></base-switch>
          </div>
          <div class="col-md-6">
            <p class="category">{{ $t('inputs.Emailling.GeolocatedEmail').toUpperCase() }}</p>
            <base-switch
              v-model="model.emailings_geolocalises"
              type="primary"
              on-text="ON"
              off-text="OFF"
            ></base-switch>
          </div>
        </div>
        <h4>DIVERS</h4>
        <div class="row mb-3">
          <div class="col-md-6">
            <p class="category">{{ $t('inputs.Diverse.Incentive').toUpperCase() }}</p>
            <base-switch v-model="model.incentive" type="primary" on-text="ON" off-text="OFF"></base-switch>
          </div>
          <div class="col-md-6">
            <p class="category">{{ $t('inputs.Diverse.ClickValidation').toUpperCase() }}</p>
            <base-switch v-model="model.clic_validation" type="primary" on-text="ON" off-text="OFF"></base-switch>
          </div>
          <div class="col-md-6">
            <p class="category">{{ $t('inputs.Diverse.CoRegistration').toUpperCase() }}</p>
            <base-switch v-model="model.co_registration" type="primary" on-text="ON" off-text="OFF"></base-switch>
          </div>
          <div class="col-md-6">
            <p class="category">{{ $t('inputs.Diverse.WhiteMark').toUpperCase() }}</p>
            <base-switch v-model="model.marque_blanche" type="primary" on-text="ON" off-text="OFF"></base-switch>
          </div>
          <div class="col-md-6">
            <p class="category">{{ $t('inputs.Diverse.site_under').toUpperCase() }}</p>
            <base-switch v-model="model.site_under" type="primary" on-text="ON" off-text="OFF"></base-switch>
          </div>
          <div class="col-md-6">
            <p class="category">{{ $t('inputs.Diverse.autres').toUpperCase() }}</p>
            <base-switch v-model="model.autres" type="primary" on-text="ON" off-text="OFF"></base-switch>
          </div>
        </div>
      </div>
    </div>

    <hr>

    &nbsp;
    <div class="row justify-content-center">
      <div class="col-lg-10 text-center">
        <image-upload type="avatar" @change="onImageChange" :src="model.imageUrl"></image-upload>
      </div>
      <label class="error" style="color:#ec250d">{{imgerror}}</label>
    </div>

    <div class="row justify-content-center">
      <div class="col-sm-10">* {{ $t('labels.Requiredfields') }}</div>
    </div>
    <!--update password and email-->
    <div class="tm-admin-btns" v-show="showUpdateBtn && (currentUser === 's.radoine@Weedoit.fr' || currentUser === 'o.hassini@weedoit.fr' || currentUser === 'n.khallouki@weedoit.fr' || currentUser === 'a.gorani@weedoit.fr' || currentUser === 'k.ghalem@weedoit.fr')">
        <!-- alerte set password and email error -->
          <base-alert type="default" class="my-2" dismissible v-if="ereursUpdate.length">
            <span>
              <template >
                {{ ereursUpdate[0] }}
              </template>
            </span>
          </base-alert>
        <!-- alerte set password and email error -->
        <div class="col text-center py-2">
          <base-button simple type="info" class="text-uppercase" v-on:click="openModal()">Modifier email/mot de passe</base-button>
        </div>
    </div>

    <div class="row mb-10 justify-content-center">
      <base-button v-if="!loading" type="secondary" @click="manageMember">VALIDER</base-button>
      <base-button v-else loading type="secondary">{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
    </div>
    <!-- modal change password/email -->
     <Modal_selfEvent
      :show.sync="showModal"
      body-classes="p-0"
      modalClasses="modal-dialog modal-dialog-centered"
      data-keyboard="false" data-backdrop="static"
      class="modal-listbase custom-width">
        <card ype="secondary"
        header-classes="bg-white pb-6"
        body-classes="px-lg-6 py-lg-6"
        class="border-0 mb-0 fixed-height">
         <base-button style="float: right;" @click.native="closeModal()" class="like btn-link" type="danger" size="sm" icon>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>
          <div class="row h-100 custom-padding" v-if="showBtns">
            <div class="col-md-6 d-flex flex-column justify-content-center align-items-center border-right border-secondary">
              <base-button  simple type="info" class="text-uppercase" v-on:click="updatePassword()">{{ $t('buttons.BtnModifyPassword') }}</base-button>
            </div>
            <div class="col-md-6 d-flex flex-column justify-content-center align-items-center">
              <base-button simple type="info" class="text-uppercase" v-on:click="showForm()">{{ $t('buttons.BtnModifyEmail') }}</base-button>
            </div>
          </div>
          <div class="row h-100 custom-padding" v-else>
            <div class="col d-flex flex-column justify-content-center align-items-center">
              <!-- Centered button within the row -->
                <base-button loading simple type="info" class="text-uppercase border-0" v-if="loadingResponse"> Chargement en cours</base-button>
                <h5 class="description text-center h4" v-if="showPassword"> Le nouveau mot de passe est : <p class="text-danger">{{ newPassword }}</p></h5>
                <form v-if="showEmailForm" class="w-100" @submit.prevent="validatedEmail">
                  <div class="row">
                    <div class="col-md-12 px-3">
                       <base-input
                        name="newEmail"
                        required
                        :placeholder="$t('inputs.Email').toUpperCase()"
                        v-model="model.newEmail"
                        v-validate="modelValidations.email"
                        type="email"
                        :error="getError('newEmail')"
                        :data-vv-as="$t('inputs.Email')"
                        addon-left-icon="tim-icons icon-email-85"
                      ></base-input>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 d-flex justify-content-center">
                      <base-button simple native-type="submit" type="info" class="text-uppercase">{{ $t('buttons.BtnModifyEmail') }}</base-button>
                    </div>
                  </div>

              </form>
              <div v-if="showCredential" class="w-100">
              <h5 class="description text-center h4" >
                <p class="text-light"> La nouvelle adresse email  : <u>{{ credentials.email }}</u></p>
                <p class="text-light"> Le mot de passe associé : <span class="text-danger">{{ credentials.password }}</span></p>
              </h5>
              <div class="mt-4" v-if="alertMessages">
                <base-alert type="default" class="my-2" v-for="(alert, index) in alertMessages" :key="index" >
                    <span>
                        <template>
                            Échec de mise à jour d'email pour le compte <span class="text-uppercase">{{alert.plt}}</span> N° {{alert.id_client}}, l'adresse email existe déja.
                        </template>
                    </span>
               </base-alert>
              </div>
               </div>
            </div>
          </div>
        </card>
    </Modal_selfEvent>
    <!-- modal change password/email -->
  </card>
</template>
<script>
import gql from "graphql-tag";
import { Select, Option } from "element-ui";
import {
  ImageUpload,
  SimpleWizard,
  WizardTab,
  BaseAlert
} from "src/components";
import { BaseRadio } from "src/components/index";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { Popover } from "element-ui";
import { BaseSwitch } from "src/components/index";
import { config } from "src/cognito_conf.js";
var AMAZON_IDENTITY = require("amazon-cognito-identity-js");
import { Thematiques } from "src/pages/Filters/Thematiques";
import {Modal} from 'src/components';
import Modal_selfEvent from 'src/components/Modal_selfEvent'



export default {
  components: {
    BaseSwitch,
    VueGoogleAutocomplete,
    ImageUpload,
    BaseRadio,
    BaseAlert,
    [Select.name]: Select,
    [Option.name]: Option,
    [Popover.name]: Popover,
    Modal,
    Modal_selfEvent
  },
  apollo: {
    provenances: {
      client: 'MARKET',
      query: gql` query {
        provenances {
          pk_provenance_client
          nom
        }
      }`,
    },
    chargesAffaires: {
      client: 'MARKET',
      query: gql` query {
        chargesAffaires {
          pk_charge_affaire
          nom
        }
      }`,
    },
    showMembreData: {
      client: "AUTH",
      query: gql`query($utilisateur_id: ID) {
        showMembreData(utilisateur_id: $utilisateur_id) {
          utilisateur{
            id
            first_name
            last_name
            society
            email
            language
            orias
            postal_code
            city
            country
            last_name
            first_name
            mobile
            activity_area
            address
            function
            tva_intracommunautaire
            skype
            thematic
            url_site
            rcs
            Volume
            image
            type_of_broadcast
            Newsletter
            dedicated_email
            target_email
            geolocated_email
            incentive
            click_validation
            co_registration
            white_mark
            site_under
            autres_procedes_diffusion
            code_pays
            indicatif
            phone
            comment
            statut_compte
          }
          roles{
            id_role
            id_utilisateur
            id_client_myaffile
            id_client_leadmarket
            droit
          }
          annonceur{
            pk_annonceur
            fk_charge_affaire
            ne_pas_contacter
            acces_destockage
            interdit_a_commander
            fk_provenance_client
            leads_temps_reel
          }
        }
      }`,
      fetchPolicy: 'network-only',
      update: data => data,
      result({ data, loader, networkStatus }) {
        if(this.$route.params.utilisateur_id){
          this.emailDisable = true
          this.passwordDisable = true

          if(data.showMembreData[0].utilisateur)
          {
            this.utilisateur = data.showMembreData[0].utilisateur
          }

          if(data.showMembreData[0].roles.length>0)
          {
            this.roles = data.showMembreData[0].roles
          }
          this.initialiseModel()
          // check client WEEDO MARKET
          data.showMembreData[0].roles.forEach(element => {
            if(element.droit == 'an' && element.id_client_leadmarket != 0) {
              this.ClientWeedoMarket = true
              if (data.showMembreData[0].annonceur) {
                this.ParamsClientWM.CA = data.showMembreData[0].annonceur.fk_charge_affaire
                this.ParamsClientWM.provenance = data.showMembreData[0].annonceur.fk_provenance_client
                this.ParamsClientWM.acces_destockage = data.showMembreData[0].annonceur.acces_destockage
                this.ParamsClientWM.ne_pas_contacter = data.showMembreData[0].annonceur.ne_pas_contacter
                this.ParamsClientWM.leads_temps_reel = data.showMembreData[0].annonceur.leads_temps_reel
                this.ParamsClientWM.interdit_a_commander = data.showMembreData[0].annonceur.interdit_a_commander
              }
            }
          });
        }
      },
      variables() {
        return {
          utilisateur_id: this.$route.params.utilisateur_id
        };
      }
    }
  },
  data() {
    return {
      disable_leads_temps: JSON.parse(localStorage.getItem('utilisateur')).id === 39 ||
                   JSON.parse(localStorage.getItem('utilisateur')).id === 253,
      ClientWeedoMarket: false,
      ParamsClientWM: {
        CA: '',
        provenance: '',
        acces_destockage: false,
        ne_pas_contacter: false,
        leads_temps_reel: false,
        interdit_a_commander: false,
      },
      loading: false,
      //url_api: 'http://localhost:9000/api/v1/', //locale
      url_api: 'https://api-auth-plf.wee-do-it.net/api/v1/',
      utilisateur: {},
      roles: [
        { droit : "" }
      ],
      ereurs: [],
      emailDisable: false,
      passwordDisable: false,
      modelValidations: {
        email: {
          required: true,
          email: true,
          max: 60,
          min: 5
        },
        password: {
          required: true,
          password: true
        }
      },
      // showErrorLaw: false,
      showErrorMobile: false,
      showErrorAddress: false,
      imgerror: "",
      countrysCode: [
        "FR",
        "MA",
        "IT",
        "US",
        "SE",
        "DK",
        "DE",
        "NL",
        "CH",
        "LU",
        "GB",
        "BR",
        "NO",
        "AE",
        "BE",
        "PT",
        "FI",
        "ZA",
        "ES",
        "AU",
        "PL",
        "NZ",
        "HK",
        "MT",
        "MG",
        "MU",
        "TN",
        "DZ",
        "IE",
        "SC",
        "AL",
        "UA",
        "UY",
        "EE",
        "IL"
      ],
      statut_compte: null,
      statut_compte_options: [
        {
          name: 'non statuer',
          value: null
        },
        {
          name: 'vip',
          value: 'vip'
        },
        {
          name: 'à surveiller',
          value: 'monitor'
        }
      ],
      model: {
        email: "",
        password: "",
        lastname: "",
        firstname: "",
        mobile: "",
        cMobile: "FR",
        indicatif: null,
        address: "",
        affilie: false,
        annonceur: false,
        agence_media: false,
        langue: "",
        orias: "",
        zipcode: "",
        city: "",
        country: "",
        society: "",
        activity: "",
        function: "",
        skype: "",
        tva: "",
        rcs: "",
        type_of_broadcast: "",
        url_site: "",
        thematic: "",
        volume: "",
        comment: "",
        image: null,
        imageUrl: '',
        newsletter: false,
        emails_dedies: false,
        emailings_cibles: false,
        emailings_geolocalises: false,
        incentive: false,
        clic_validation: false,
        co_registration: false,
        marque_blanche: false,
        site_under: false,
        autres: false
      },
      DiffusionTypeList: [
        "Avantages consommateurs",
        "Content & Influence marketing",
        "Display",
        "Email Marketing",
        "Emulation",
        "Génération de leads",
        "Iframe",
        "Mailing",
        "Mobile",
        "Native",
        "Push notification",
        "Remarketing",
        "Reseau sociaux",
        "Search",
        "SMS"
      ],
      thematics: Thematiques.map(function (th) {
        return th.categorie;
      }),
      languageOptions: ["Français", "Anglais", "Espagnol", "Italienne"],
      countryOptions: [
        'France',
        'Afrique du Sud',
        'Allemagne',
        'Belgique',
        'Brésil',
        'Danemark',
        'Espagne',
        'États-Unis',
        'Finlande',
        'Australie',
        'Italie',
        'Maroc',
        'Madagascar',
        'Malte',
        'Norvège',
        'Nouvelle-Zélande',
        'Pays-Bas',
        'Pologne',
        'Portugal',
        'Royaume-Uni',
        'Suède',
        'Suisse',
        'Canada',
        'Luxembourg',
        'Émirats Arabe unis',
        'Hong Kong',
        'Gibraltar',
        'Ile Maurice',
        'Tunisie',
        'Algérie',
        'Irlande',
        'Seychelles',
        'Albanie',
        'Ukraine',
        'Uruguay',
        'Estonie',
        'Israël'
      ],
      activity: [
        "Accessoires de mode",
        "Accessoires électroniques",
        "Achat groupé",
        "Agences de voyage",
        "Animaux",
        "Appareils électroménagers",
        "Assurances",
        "Audiovisuel",
        "Autocars",
        "Automobiles",
        "Bébé & Nouveau-né",
        "Biens de consommation courante",
        "Billetterie",
        "Bricolage",
        "Cadeaux & Fleurs",
        "Cartes de crédit",
        "Cartes prépayées",
        "Chaussures",
        "Compagnies aériennes",
        "Comptes courants",
        "Crédits immobiliers",
        "Croisières & Ferries",
        "Distribution de biens culturels et de loisirs",
        "Distribution de produits électroniques",
        "Éducation, Formation & Recrutement",
        "Énergie & Eau",
        "Épargnes & Placements",
        "Équipements sportifs",
        "Érotisme",
        "Forfaits mobile",
        "Fournisseurs d'accès Internet",
        "Fournitures de bureau",
        "Gadgets",
        "Génération de prospects",
        "Grands magasins",
        "Habillement",
        "Habillement Enfant",
        "Habillement Femme",
        "Habillement Homme",
        "Haut débit mobile",
        "Hébergement de sites Internet",
        "Hôtels & Hébergements",
        "Immobilier",
        "Jeux & Jouets",
        "Jeux en ligne",
        "Jeux vidéo & PC",
        "Jeux-concours",
        "Joaillerie",
        "Lingerie",
        "Livres & Abonnements",
        "Location de voitures",
        "Maison & Jardin",
        "Matériel photographique",
        "Mobilier & Ameublement",
        "Musique & DVD",
        "Œuvres caritatives",
        "Opérateurs Câble & Satellite",
        "Opérateurs de réseaux",
        "Ordinateurs",
        "Prêts",
        "Produits écologiques",
        "Produits pharmaceutiques",
        "Rencontres",
        "Santé & Beauté",
        "Séjours locaux",
        "Services commerciaux (B2B)",
        "Services de Télécommunication B2B",
        "Services d'impression & Photos",
        "Services Utilitaires B2B",
        "Stationnement & Transferts aéroport",
        "Téléchargements de logiciels",
        "Téléchargements de Musique & Jeux",
        "Téléchargements mobiles",
        "Tourisme & Attractions",
        "Trains",
        "TV numérique & Vidéo à la Demande",
        "Vêtements de sport",
        "Vin, Spiritueux & Tabac",
        "Voyance"
      ],
      currentUser:'',
      loadingUpdatePassword:false,
      showModal:false,
      newPassword:'',
      userEmail:'',
      ereursUpdate:[],
      loadingResponse:false,
      showBtns:true,
      showPassword:false,
      showEmailForm:false,
      showCredential:false,
      credentials:{},
      showUpdateBtn:false,
      alertMessages:[],
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    checkAddress() {
      if (document.getElementById("from_address").value == "") {
        this.showErrorAddress = true;
      } else {
        this.model.address = document.getElementById("from_address").value
        this.showErrorAddress = false;
      }
    },
    onImageChange(file) {
      this.imgerror = "";
      if (file == null) return;
      if (file.type == "image/png" || file.type == "image/jpeg") {
        this.model.image = file;
        return;
      }
      this.model.image = null;
      this.imgerror = "le type d'image doit être : png | jpeg";
    },
    onUpdate(payload) {
      this.model.cMobile = payload.countryCode;
      this.model.indicatif = payload.countryCallingCode;
    },
    validate() {
      if (this.model.mobile == "") {
        this.showErrorMobile = true;
      } else {
        this.showErrorMobile = false;
      }
      // if (
      //   this.model.affilie == false &&
      //   this.model.annonceur == false &&
      //   this.model.agence_media == false
      // ) {
      //   this.showErrorLaw = true;
      //   return false;
      // } else {
      //   this.showErrorLaw = false;
      // }
      if (this.model.address == "") {
        this.showErrorAddress = true;
      } else {
        this.showErrorAddress = false;
      }
      return this.$validator.validateAll().then(res => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    },
    getFromAddress(from_address, placeResultData, id) {
      this.model.address = document.getElementById("from_address").value;
      this.compliteAddress = from_address;
      this.model.zipcode = this.compliteAddress.postal_code;
      this.model.city = this.compliteAddress.locality;
      this.model.country = this.compliteAddress.country;
    },
    getToAddress(to_address, placeResultData, id) {
      this.to_address = to_address;
    },
    async manageMember() {
      let validate;
      validate = await this.validate();
      if (!validate) {
        this.$notify({
          type: "warning",
          verticalAlign: "top",
          horizontalAlign: "right",
          message:
            "S'il vous plaît remplir les champs obligatoire du formulaire",
          duration: 5000
        });
        return;
      }
      let formData = new FormData();
      let law = ""

      if(this.roles.length == 2) {
        law = this.roles[1].droit
      }
      else {
        if (this.roles[0].droit == "an") {
          law = "an"
          if (this.model.affilie)
            law = "af"
        }
        else if (this.roles[0].droit == "af") {
          law = "af"
          if (this.model.annonceur || this.model.agence_media)
            law = "an"
        }
        else if (this.roles[0].droit == "ad") {
          law = "ad"
        }
        else if (this.roles[0].droit == "tm") {
          law = "tm"
        }
        else if (this.roles[0].droit == "ma") {
          law = "ma"
        }
        else if (this.roles[0].droit == "tem") {
          law = "tem"
        }
        else {
          if(this.model.affilie)
            law += "af,"
          if(this.model.annonceur || this.model.agence_media)
            law += "an,"

          law = law.substring(0, law.length - 1)
        }
      }

      formData.append("etat", true);
      formData.append("first_name", this.model.firstname);
      formData.append("last_name", this.model.lastname);
      formData.append("email", this.model.email);
      formData.append("society", this.model.society);
      formData.append("activity_area", this.model.activity);
      formData.append("address", this.model.address);
      formData.append("comment", this.model.comment);
      formData.append("city", this.model.city);
      formData.append("country", this.model.country);
      formData.append("function", this.model.function);
      formData.append("law", law);
      formData.append("phone", this.model.mobile);
      formData.append("postal_code", this.model.zipcode);
      formData.append("code_pays", this.model.cMobile);
      formData.append("indicatif", this.model.indicatif);
      formData.append("image", this.model.image);
      formData.append("language", this.model.langue);
      formData.append("orias", this.model.orias);
      formData.append("skype", this.model.skype);
      formData.append("tva_intracommunautaire", this.model.tva);
      formData.append("rcs", this.model.rcs);
      formData.append("type_of_broadcast", this.model.type_of_broadcast);
      formData.append("url_site", this.model.url_site);
      formData.append("thematic", this.model.thematic);
      formData.append("volume", this.model.volume);
      formData.append("newsletter", this.model.newsletter == true ? 1 : 0);
      formData.append("dedicated_email", this.model.emails_dedies == true ? 1 : 0);
      formData.append("target_email", this.model.emailings_cibles == true ? 1 : 0);
      formData.append("geolocated_email", this.model.emailings_geolocalises == true ? 1 : 0);
      formData.append("incentive", this.model.incentive == true ? 1 : 0);
      formData.append("click_validation", this.model.clic_validation == true ? 1 : 0);
      formData.append("co_registration", this.model.co_registration == true ? 1 : 0);
      formData.append("white_mark", this.model.marque_blanche == true ? 1 : 0);
      formData.append("site_under", this.model.site_under == true ? 1 : 0);
      formData.append("autres_procedes_diffusion", this.model.autres == true ? 1 : 0);

      // statut_compte editeur
      formData.append("statut_compte", this.statut_compte);

      // params Client WEEDO MARKET

      if (this.ClientWeedoMarket) {
        formData.append("charge_affaire_id", this.ParamsClientWM.CA || '');
        formData.append("provenance_client_id", this.ParamsClientWM.provenance || '');
        formData.append("acces_destockage", (this.ParamsClientWM.acces_destockage == true) ? 1 : 0);
        formData.append("ne_pas_contacter", (this.ParamsClientWM.ne_pas_contacter == true) ? 1 : 0);
        formData.append("leads_temps_reel", (this.ParamsClientWM.leads_temps_reel == true) ? 1 : 0);
        formData.append("interdit_a_commander", (this.ParamsClientWM.interdit_a_commander == true) ? 1 : 0);
      }

      let token = localStorage.getItem("token_jwt")

      if(this.$route.params.utilisateur_id){
        formData.append('id', this.utilisateur.id)
        this.updateMembre(formData, token)
        this.updateImg(formData, token)
      }else{
        this.addMembre(formData, token)
      }

    },
    addMembre(formData) {
      this.loading = true
      var username = this.model.email
      var password = this.model.password
      var poolData = {
        UserPoolId: config.cognito.userPoolId,
        ClientId: config.cognito.clientId
      };
      var userPool = new AMAZON_IDENTITY.CognitoUserPool(poolData);
      var attributeList = [];
      var dataEmail = {
        Name: "email",
        Value: this.model.email
      };
      var dataPersonalName = {
        Name: "family_name",
        Value: this.model.lastname
      };
      var dataPhoneNumber = {
        Name: "phone_number",
        Value: this.model.rMobile
      };

      var attributeEmail = new AMAZON_IDENTITY.CognitoUserAttribute(dataEmail);
      var attributePersonalName = new AMAZON_IDENTITY.CognitoUserAttribute(
        dataPersonalName
      );
      var attributePhoneNumber = new AMAZON_IDENTITY.CognitoUserAttribute(
        dataPhoneNumber
      );
      console.log('...1');
      attributeList.push(attributeEmail);
      attributeList.push(attributePersonalName);
      attributeList.push(attributePhoneNumber);
      userPool.signUp(
        username,
        password,
        attributeList,
        null,
        async function(err, result) {
          if (err) {
            let messageerr = ""
            if (err.message.includes("Password did not conform")) {
              this.ereurs.push(this.translatemsg("PasswordNotConfirm"));
              messageerr = this.translatemsg("PasswordNotConfirm")
            } else {
              this.ereurs.push(this.translatemsg(err.message));
              messageerr = this.translatemsg(err.message)
            }
            this.$notify({
              type: "warning",
              verticalAlign: "top",
              horizontalAlign: "right",
              message: messageerr,
              duration: 5000
            });
            this.loading = false
            this.$store.commit("setendregistration", false);
            return;
          }
          console.log('...2');

          await this.axios.post(this.url_api+'inscription', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }).then(response => {
              console.log('...3');
              //this.initialiseModel();
              this.loading = false
              this.$notify({
                type: "success",
                verticalAlign: "top",
                horizontalAlign: "right",
                message: "Le membre a été bien ajouté.",
                duration: 5000
              });
              console.log(response.data);
            }).catch(err => {
              console.log(err);
              this.loading = false
            });
        }.bind(this)
      );

      return false;
    },
    updateMembre(formData, token){
      this.loading = true
      this.axios.post(this.url_api+'update_profil', formData,{
        headers:
        {
          'Content-Type': 'multipart/form-data',
          'authorization': `Bearer ${token}`
        }
      }).then(response => {
        this.$notify({type: 'success', verticalAlign: 'top', horizontalAlign: 'right', message: 'Le membre a été bien modifié.'});
        this.loading = false
      })
      .catch(err => {
        console.log(err);
        this.loading = false
      });
    },
    initialiseModel() {

      this.roles.forEach(element => {
        if(element.droit == 'af'){
          this.model.affilie = true
        }else if(element.droit == 'an'){
          this.model.annonceur = true
          this.model.agence_media = true
        }
      });

      this.model = {
        email: this.utilisateur.email || "",
        password: "kjhvksAZ98*#_",
        lastname: this.utilisateur.last_name || "",
        firstname: this.utilisateur.first_name || "",
        mobile: this.utilisateur.phone || "",
        cMobile: this.utilisateur.code_pays.trim() || "FR",
        indicatif: this.utilisateur.indicatif.trim() || "33",
        address: this.utilisateur.address || "",
        comment: this.utilisateur.comment || "",
        affilie: this.model.affilie || false,
        annonceur: this.model.annonceur || false,
        agence_media: this.model.agence_media || false,
        langue: this.utilisateur.language || "",
        orias: this.utilisateur.orias || "",
        zipcode: this.utilisateur.postal_code || "",
        city: this.utilisateur.city || "",
        country: this.utilisateur.country || "",
        society: this.utilisateur.society || "",
        activity: this.utilisateur.activity_area || "",
        function: this.utilisateur.function || "",
        skype: this.utilisateur.skype || "",
        tva: this.utilisateur.tva_intracommunautaire || "",
        rcs: this.utilisateur.rcs || "",
        type_of_broadcast: this.utilisateur.type_of_broadcast || "",
        url_site: this.utilisateur.url_site || "",
        thematic: this.utilisateur.thematic || "",
        volume: this.utilisateur.Volume || "",
        image: "https://weedoit-media.s3.eu-central-1.amazonaws.com/" + (this.utilisateur.image || ""),
        imageUrl: this.utilisateur.image ? "https://weedoit-media.s3.eu-central-1.amazonaws.com/"+ this.utilisateur.image : "",
        newsletter: this.utilisateur.Newsletter || false,
        emails_dedies: this.utilisateur.dedicated_email || false,
        emailings_cibles: this.utilisateur.target_email || false,
        emailings_geolocalises: this.utilisateur.geolocated_email || false,
        incentive: this.utilisateur.incentive || false,
        clic_validation: this.utilisateur.click_validation || false,
        co_registration: this.utilisateur.co_registration || false,
        marque_blanche: this.utilisateur.white_mark || false,
        site_under: this.utilisateur.site_under || false,
        autres: this.utilisateur.autres_procedes_diffusion || false
      };

      this.statut_compte = this.utilisateur.statut_compte;
    },
    updateImg(formData, token){
      if(typeof this.model.image['type'] != "undefined"){
        this.axios
          .post(this.url_api+'update_img', formData,{
              headers: {
                'Content-Type': 'multipart/form-data',
                'authorization': `Bearer ${token}`
                }
          })
          .then(response => {
            console.log(response.data);
          })
          .catch(err => {
            if (err.response.status == "401") {
              this.$store.commit('signOut')
              location.reload()
            }
          });
      }
      return false
    },
    translatemsg(msg) {
      switch (msg) {
        case "Invalid phone number format.":
          return this.$t("errors.InvalidePhoneNumber");
        case "User already exists":
          return this.$t("errors.UserExistsException");
        case "An account with the given email already exists.":
          return this.$t("errors.EmailExistsException");
        case "PasswordNotConfirm":
          return this.$t("errors.InvalidPasswordException");
        //cognito set password
        case "UserNotFoundException":
          return this.$t('errors.UserNotFoundException');
        case "LimitExceededException":
          return this.$t('errors.LimitExceededException');
        case "UsernameExistsException":
          return this.$t('errors.UsernameExistsException');
        case "emailAlreadyExists":
          return this.$t('errors.emailAlreadyExists');
      }
      return "Erreur inconnus";
    },
    updatePassword(){
      this.loadingResponse = true
      this.showBtns = false
      this.ereursUpdate = []
      console.log('update password ...')
      let formData = new FormData()
      formData.append('email', this.model.email)
      this.axios
           .post("https://api-auth-plf.wee-do-it.net/api/v1/set-user-password",formData,{
            headers: {
              'authorization': `Bearer ${this.token}`
            }
          })
          .then(response => {
            // this.newPassword = response.data.response.credentials.password
            this.newPassword = response.data.response.credentials.password
            this.userEmail = response.data.response.credentials.email
            this.loadingResponse = false
            this.showPassword = true
          })
          .catch(error => {
            this.loadingResponse = false
            this.showModal = false
            console.log(error.response.data)
            this.ereursUpdate.push(this.translatemsg(error.response.data.message))
          });
    },
    showForm(){
      this.showBtns = false
      this.showEmailForm = true
    },
    validatedEmail(){
          this.$validator.validate('newEmail').then(isValid => {
            if(isValid){
              this.updateEmail()
            }
          });
        },
    updateEmail(){
      console.log('update email ...')
      this.loadingResponse = true
      this.showEmailForm = false
      this.ereursUpdate = []
      let formData = new FormData()
      formData.append('oldEmail', this.model.email)
      formData.append('newEmail', this.model.newEmail)
      this.axios
            .post("https://api-auth-plf.wee-do-it.net/api/v1/update-user-email",formData,{
           //.post("http://127.0.0.1:8000/api/v1/update-user-email",formData,{
            headers: {
              'authorization': `Bearer ${this.token}`
            }
          })
          .then(response => {
            console.log('response ...')
            this.loadingResponse = false
            this.showEmailForm = false
            this.showCredential = true
            this.credentials = response.data.response.credentials
            this.alertMessages = response.data.response.errors;

          })
          .catch(error => {
            console.log('error ...')
            console.log(error)
            this.loadingResponse = false
            this.showModal = false
            this.ereursUpdate.push(this.translatemsg(error.response.data.message))
          });
    },
    openModal(){
      this.showModal = true;
      this.showBtns = true;
      this.loadingResponse = false;
      this.showPassword = false;
      this.showEmailForm = false;
      this.showCredential = false;
      this.credentials = {};
      this.ereursUpdate = [];
      this.model.newEmail = '';
    },
    closeModal() {
      this.showModal = false
    }
  },
  created(){
    this.token = localStorage.getItem("token_jwt")
    let utilisateur = JSON.parse(localStorage.getItem('utilisateur'))
    this.currentUser = utilisateur.email
    if(this.$route.params.utilisateur_id){
      this.showUpdateBtn = true
    }
  },
};
</script>
<style scoped>
  .custom-padding{
    padding: 80px 30px;
  }
  .modal.show {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
  .custom-width >>> .modal-dialog {
    max-width: 950px !important;
}
</style>
