<template>
  <div class="chat-gpt-layout">
    <div class="content">
      <div class="chat-area">
        <div v-for="message in chatMessages" :key="message.id" class="message">
          <strong>{{ message.sender }}: </strong>
          <i v-if="message.loading" class="fas fa-spinner fa-spin"></i>
          <span v-else>
            <span v-if="message.sender == 'Assistant'" class="pre-line">{{ message.text }}</span>
            <span v-if="message.sender == 'User'"><b>{{ message.text }}</b></span>
          </span>
        </div>
      </div>
      <div class="text-input">
        <div class="row w-100">
          <div class="col-lg-10 col-md-10 col-sm-12 p-0">
            <textarea
              type="text"
              v-model="newMessage"
              rows="3"
              placeholder="Saisissez votre question..."
              class="w-100 h-100"
            ></textarea>
          </div>
          <div class="col-lg-2 col-md-2 col-sm-12 d-flex align-items-center justify-content-center">
            <base-button
              v-if="!loading"
              :disabled="newMessage.length == 0"
              class="text-uppercase mx-2"
              type="info"
              v-on:click="sendMessage"
            >
              Envoyer
            </base-button>
            <base-button
              v-else
              loading
              type="info"
              class="text-uppercase"
            >
              Envoi
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseButton } from 'src/components';

export default {
  data() {
    return {
      chatMessages: [
        { id: 1, sender: 'Assistant', text: "Bonjour ! Comment puis-je vous aider aujourd'hui ?" },
      ],
      newMessage: '',
      loading: false
    };
  },
  components: {
    BaseButton,
  },
  methods: {
    sendMessage() {
        this.loading = true

        this.chatMessages.push({ id: this.chatMessages.length + 1, sender: 'User', text: this.newMessage });

        // Loading text
        this.chatMessages.push({ id: this.chatMessages.length + 1, sender: 'Assistant', text: '...', loading: true })
        const loadingMessageIndex = (this.chatMessages).length - 1
        const formData = {
            question: `${this.newMessage}`.replace(/\?$/, ''),
            email: JSON.parse(localStorage.getItem('utilisateur')).email,
            token: localStorage.getItem("token_jwt"),
            // Add other form data if needed
        };

        fetch("https://viq15n7xr7.execute-api.eu-central-1.amazonaws.com/prod", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Add other headers if needed
            },
            body: JSON.stringify(formData),
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => {
            this.loading = false;
            let response = JSON.parse(data.body);
           /*  console.log(response)
            console.log(response.result) */

            this.removeMessageLoading(loadingMessageIndex)
            this.setMessageText(loadingMessageIndex, response.result)
        })
        .catch(error => {
            // Handle errors
            this.loading = false;
            this.removeMessageLoading(loadingMessageIndex)
            this.setMessageText(loadingMessageIndex, "Unexpected error")

            console.error(error);
        });
        this.newMessage = '';
    },
    setMessageText(messageIndex, text) {
      this.chatMessages[messageIndex].text = text
    },
    removeMessageLoading(messageIndex) {
      this.chatMessages[messageIndex].loading = false
    }
  }
}
</script>

<style scoped>
/* Component-specific styles here */
.chat-gpt-layout {
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Take up remaining height */
}

.chat-area {
  background-color: #f0f0f0;
  padding: 20px;
  overflow-y: auto;
  flex-grow: 1; /* Take up remaining height */
  line-height: 1.4 !important;
}

.text-input {
  width: 100%;
  background-color: #f0f0f0;
  padding: 20px; /* Adjust padding for smaller screens */
  display: flex;
  flex-direction: column; /* Stack items vertically on small screens */
  align-items: center;
}

.text-input textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px; /* Add some spacing between textarea and button */
}

@media (min-width: 576px) {
  /* Apply styles for screens wider than 576px (sm) */
  .text-input {
    padding: 50px; /* Restore original padding for larger screens */
    flex-direction: row; /* Align items horizontally for larger screens */
    justify-content: space-between; /* Add space between textarea and button */
  }

  .text-input textarea {
    width: 70%; /* Adjust width for larger screens */
  }
}
.pre-line {
    white-space: pre-line;
  }
.message {
  margin-bottom: 10px; /* Optional: Add margin between messages */
}
</style>
