<template>
  <div>
    <!-- MONGUSTAVE -->
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <span slot="header" class="badge-pill badge-mongustave tab-title" style="font-weight: bold;">
            MONGUSTAVE
          </span>
          <div class="row w-100" style="padding: 22px;">
            <div class="col-12">
                        <div  v-loading="loading"></div>
            </div>
          </div>

          <el-table show-summary :summary-method="getSummaries_mg" :data="dataCaPlf"  row-class-name="booking-table-rows">
            <el-table-column
              label="Mois"
              prop="mois"
            >
            </el-table-column>
            <el-table-column
              label="CA"
              prop="ca_MG"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.ca_MG, true, 2) + ' €' }}
              </template>
            </el-table-column>
            <el-table-column
              label="CLIENTS"
              prop="clt_MG"
            >
            </el-table-column>
            <el-table-column
              label="CA MOYEN"
              prop="ca_MG"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.clt_MG != 0 ? scope.row.ca_MG/scope.row.clt_MG : 0, true, 2) + ' €' }}
              </template>
            </el-table-column>
          </el-table>

        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <span slot="header" class="badge-pill badge-mongustave tab-title" style="font-weight: bold;">
            MONGUSTAVE
          </span>
          <div class="row w-100" style="padding: 22px;">
            <div class="col-12">
                        <div  v-loading="loading"></div>
            </div>
          </div>
          <br/>
          <pie-chart
            :chart-data="dataChart.MG"
            :extra-options="pieChart.extraOptions"
            :height="250"
          >
          </pie-chart>
        </div>
      </div>
    </div>
    <!-- WEEDO PERF -->
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <span slot="header" class="badge-pill badge-info tab-title" style="font-weight: bold;">
            WEEDO PERF
          </span>
          <div class="row w-100" style="padding: 22px;">
            <div class="col-12">
                        <div  v-loading="loading"></div>
            </div>
          </div>
          <el-table show-summary :summary-method="getSummaries_perf" :data="dataCaPlf" row-class-name="booking-table-rows">
            <el-table-column
              label="Mois"
              prop="mois"
            >
            </el-table-column>
            <el-table-column
              label="CA"
              prop="ca_perf"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.ca_perf, true, 2) + ' €' }}
              </template>
            </el-table-column>
            <el-table-column
              label="CLIENTS"
              prop="clt_WP"
            >
            </el-table-column>
            <el-table-column
              label="CA MOYEN"
              prop="ca_perf"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.clt_WP != 0 ? scope.row.ca_perf/scope.row.clt_WP : 0, true, 2) + ' €' }}
              </template>
            </el-table-column>
          </el-table>

        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <span slot="header" class="badge-pill badge-info tab-title" style="font-weight: bold;">
            CA WEEDO PERF
          </span>
          <div class="row w-100" style="padding: 22px;">
            <div class="col-12">
                        <div  v-loading="loading"></div>
            </div>
          </div>
          <br/>
          <pie-chart
            :chart-data="dataChart.WP"
            :extra-options="pieChart.extraOptions"
            :height="250"
          >
          </pie-chart>
        </div>
      </div>
    </div>
    <!-- WEEDO MARKET -->
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
            WEEDO MARKET
          </span>
          <div class="row w-100" style="padding: 22px;">
            <div class="col-12">
                        <div  v-loading="loading"></div>
            </div>
          </div>
          <el-table show-summary :summary-method="getSummaries_market" :data="dataCaPlf" row-class-name="booking-table-rows">
            <el-table-column
              label="Mois"
              prop="mois"
            >
            </el-table-column>
            <el-table-column
              label="CA"
              prop="ca_LM"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.ca_LM, true, 2) + ' €' }}
              </template>
            </el-table-column>
            <el-table-column
              label="CLIENTS"
              prop="clt_WM"
            >
            </el-table-column>
            <el-table-column
              label="CA MOYEN"
              prop="ca_LM"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.clt_WM != 0 ? scope.row.ca_LM/scope.row.clt_WM : 0, true, 2) + ' €' }}
              </template>
            </el-table-column>
          </el-table>

        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <span slot="header" class="badge-pill badge-market tab-title" style="font-weight: bold;">
            CA WEEDO MARKET
          </span>
          <div class="row w-100" style="padding: 22px;">
            <div class="col-12">
                        <div  v-loading="loading"></div>
            </div>
          </div>
          <br/>
          <pie-chart
            :chart-data="dataChart.WM"
            :extra-options="pieChart.extraOptions"
            :height="250"
          >
          </pie-chart>
        </div>
      </div>
    </div>
    <!-- WEEDO COREG -->
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <span slot="header" class="badge-pill badge-coreg tab-title" style="font-weight: bold;">
            WEEDO COREG
          </span>
          <div class="row w-100" style="padding: 22px;">
            <div class="col-12">
                        <div  v-loading="loading"></div>
            </div>
          </div>
          <el-table show-summary :summary-method="getSummaries_coreg" :data="dataCaPlf"  row-class-name="booking-table-rows">
            <el-table-column
              label="Mois"
              prop="mois"
            >
            </el-table-column>
            <el-table-column
              label="CA"
              prop="ca_LM"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.ca_coreg, true, 2) + ' €' }}
              </template>
            </el-table-column>
            <el-table-column
              label="CLIENTS"
              prop="clt_WC"
            >
            </el-table-column>
            <el-table-column
              label="CA MOYEN"
              prop="ca_LM"
            >
              <template scope="scope">
                {{ formatThounsends(scope.row.clt_WC != 0 ? scope.row.ca_coreg/scope.row.clt_WC : 0, true, 2) + ' €' }}
              </template>
            </el-table-column>
          </el-table>

        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <span slot="header" class="badge-pill badge-coreg tab-title" style="font-weight: bold;">
            CA WEEDO COREG
          </span>
          <div class="row w-100" style="padding: 22px;">
            <div class="col-12">
                        <div  v-loading="loading"></div>
            </div>
          </div>
          <br/>
          <pie-chart
            :chart-data="dataChart.WC"
            :extra-options="pieChart.extraOptions"
            :height="250"
          >
          </pie-chart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { Table, TableColumn } from 'element-ui'
  import PieChart from 'src/components/Charts/PieChart';
  import * as chartConfigs from '@/components/Charts/config';

  export default {
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      PieChart
    },
    props: ['dataCaPlf', 'dataChart', 'loading'],
    data() {
      return {
        renderChart_Chart_WM: false,
        showChart: true,
        pieChart: {
          chartData: {},
          extraOptions: {
            ...chartConfigs.pieChartOptions,
            legend: {
              display: true,
              position: 'bottom',
              align: 'start',
              labels: {
                boxWidth: 15,
                fontSize: 12
              }
            },
            // cutoutPercentage: 60,
            tooltips: {
              callbacks: {
                title: function(tooltipItem, data) {
                  return data['labels'][tooltipItem[0]['index']];
                },
                label: function(tooltipItem, data) {
                  return data['datasets'][0]['data'][tooltipItem['index']].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " €";
                },
                afterLabel: function(tooltipItem, data) {
                  var dataset = data['datasets'][0];

                  var percent = ((dataset['data'][tooltipItem['index']] / dataset["_meta"][Object.keys(dataset["_meta"])[0]].total) * 100).toFixed(1);
                  return '(' + percent + '%)';
                }
              },
              backgroundColor: '#FFF',
              titleFontSize: 16,
              titleFontColor: '#0066ff',
              bodyFontColor: '#000',
              bodyFontSize: 14,
              displayColors: false
            },
            // responsive: true,
          }
        },
      }
    },
    methods: {
      getMonthStr(nb) {
        return moment(nb, 'MM').locale('fr').format('MMMM');
      },
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      getSummaries_mg(param) {
        const { columns, data } = param;
        const sums = [];
        let sum_ca = 0
        let sum_clt = 0
        data.forEach(element => {
          sum_ca += element.ca_MG
          sum_clt += element.clt_MG
        });
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '';
            return;
          }
          else if( index === 1) {
            sums[index] = this.formatThounsends(sum_ca,true,2) + ' €';
            return;
          }
          else if( index === 2) {
            sums[index] = this.formatThounsends(sum_clt,true,0);
            return;
          }
          else if( index === 3) {
            sums[index] = this.formatThounsends(sum_clt!=0 ? sum_ca/sum_clt : 0,true,2) + ' €';
            return;
          }
          else {
            sums[index] = '';
            return;
          }
        });
        return sums;
      },
      getSummaries_perf(param) {
        const { columns, data } = param;
        const sums = [];
        let sum_ca = 0
        let sum_clt = 0
        data.forEach(element => {
          sum_ca += element.ca_perf
          sum_clt += element.clt_WP
        });
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '';
            return;
          }
          else if( index === 1) {
            sums[index] = this.formatThounsends(sum_ca,true,2) + ' €';
            return;
          }
          else if( index === 2) {
            sums[index] = this.formatThounsends(sum_clt,true,0);
            return;
          }
          else if( index === 3) {
            sums[index] = this.formatThounsends(sum_clt!=0 ? sum_ca/sum_clt : 0,true,2) + ' €';
            return;
          }
          else {
            sums[index] = '';
            return;
          }
        });
        return sums;
      },
      getSummaries_market(param) {
        const { columns, data } = param;
        const sums = [];
        let sum_ca = 0
        let sum_clt = 0
        data.forEach(element => {
          sum_ca += element.ca_LM
          sum_clt += element.clt_WM
        });
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '';
            return;
          }
          else if( index === 1) {
            sums[index] = this.formatThounsends(sum_ca,true,2) + ' €';
            return;
          }
          else if( index === 2) {
            sums[index] = this.formatThounsends(sum_clt,true,0);
            return;
          }
          else if( index === 3) {
            sums[index] = this.formatThounsends(sum_clt!=0 ? sum_ca/sum_clt : 0,true,2) + ' €';
            return;
          }
          else {
            sums[index] = '';
            return;
          }
        });
        return sums;
      },
      getSummaries_coreg(param) {
        const { columns, data } = param;
        const sums = [];
        let sum_ca = 0
        let sum_clt = 0
        data.forEach(element => {
          sum_ca += element.ca_coreg
          sum_clt += element.clt_WC
        });
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '';
            return;
          }
          else if( index === 1) {
            sums[index] = this.formatThounsends(sum_ca,true,2) + ' €';
            return;
          }
          else if( index === 2) {
            sums[index] = this.formatThounsends(sum_clt,true,0);
            return;
          }
          else if( index === 3) {
            sums[index] = this.formatThounsends(sum_clt!=0 ? sum_ca/sum_clt : 0,true,2) + ' €';
            return;
          }
          else {
            sums[index] = '';
            return;
          }
        });
        return sums;
      },
    }
  }

</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
</style>
