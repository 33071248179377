<template>
  <div class="col-12 background-repoort">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="col-12">
          <center>
            <div class="row">
              <div class="col-md-12">
                <div
                  class="btn-group btn-group-toggle"
                  data-toggle="buttons"
                >
                  <label :disabled="this.$route.params.platform == 'coreg'" class="btn btn-sm btn-info btn-simple" :class="(ShowPerf)? 'active' : ''">
                    <input
                      type="radio"
                      @click="ShowPerf = true"
                      name="options"
                      autocomplete="off"
                    />
                    <span class="d-none d-sm-block" style="width: 150px;"> <img src="/img/weedo-it-logo-blanc.png" alt="" /> WEEDO PERF</span>
                    <span class="d-block d-sm-none">
                      <img src="/img/weedo-it-logo-blanc.png" alt="" />
                    </span>
                  </label>
                  <label :disabled="this.$route.params.platform == 'perf'" class="btn btn-sm btn-coreg btn-simple" :class="(!ShowPerf)? 'active' : ''">
                    <input
                      type="radio"
                      @click="ShowPerf = false"
                      name="options"
                      autocomplete="off"
                    />
                    <span class="d-none d-sm-block" style="width: 150px;"> <img src="/img/weedo-it-logo-blanc.png" alt="" /> WEEDO COREG</span>
                    <span class="d-block d-sm-none">
                      <img src="/img/weedo-it-logo-blanc.png" alt="" />
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </center>
        </div>
        <div class="col-md-12 float-right">
          <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
            <base-button 
              round 
              class="btn btn-round btn-just-icon btn-info mb-2"
              @click.native="ShowAddAdvance = true"
            >AJOUTER AVANCE</base-button>
          </div>
        </div>
      </div>
      <template>
        <!-- LISTE AVANCES PERF -->
        <template v-if="ShowPerf">
          <div class="card col-xl-5 col-lg-5 col-md-5" style="float: none; margin: 0 auto;">
            <div class="card-body">
              <h4 slot="header" class="title">LISTE AVANCES DU CLIENT {{ this.$route.params.client }}</h4>

              <el-table :data="ListAvance_PERF">
                <el-table-column
                  prop= 'montant'
                  label= "Montant"
                  sortable
                  min-width="100px"
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.montant,true,2) + ' €' }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop= 'date'
                  label= "Date"
                  sortable
                  min-width="100px"
                >
                </el-table-column>

                <el-table-column
                  prop= 'date_modification'
                  label= "Date"
                  sortable
                  min-width="100px"
                >
                </el-table-column>

                <el-table-column :min-width="100" align="right" label="Actions">
                  <div slot-scope="props">
                    <el-tooltip
                      content="Modifier"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="PrepareUpdateAdvance(props.row,'perf')"
                        class="btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                        content="Supprimer"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                      <base-button
                        @click.native="DeleteAdvance(props.row.id,'perf')"
                        class="btn-link"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="fa fa-times"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>

              </el-table>
              <div class="d-flex justify-content-end">
                <span slot="header" class="badge badge-pill badge-info tab-title lablelTabel">
                  Total Avances :  {{ formatThounsends(total_avance_perf,true,2) + ' €' }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <!-- LISTE AVANCES COREG -->
        <template v-else>
          <div class="card col-xl-5 col-lg-5 col-md-5" style="float: none; margin: 0 auto;">
            <div class="card-body">
              <h4 slot="header" class="title">LISTE AVANCES DU CLIENT {{ this.$route.params.client }}</h4>

              <el-table :data="ListAvance_COREG">

                <el-table-column
                  prop= 'montant'
                  label= "Montant"
                  sortable
                  min-width="100px"
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.montant,true,2) + ' €' }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop= 'date'
                  label= "Date"
                  sortable
                  min-width="100px"
                >
                </el-table-column>

                <el-table-column
                  prop= 'date_modification'
                  label= "Date"
                  sortable
                  min-width="100px"
                >
                </el-table-column>

                <el-table-column :min-width="100" align="right" label="Actions">
                  <div slot-scope="props">
                    <el-tooltip
                      content="Modifier"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="PrepareUpdateAdvance(props.row,'coreg')"
                        class="btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-pencil"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                        content="Supprimer"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                      <base-button
                        @click.native="DeleteAdvance(props.row.id,'coreg')"
                        class="btn-link"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="fa fa-times"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>

              </el-table>
              <div class="d-flex justify-content-end">
                <span slot="header" class="badge badge-pill badge-coreg tab-title lablelTabel">
                  Total Avances :  {{ formatThounsends(total_avance_coreg,true,2) + ' €' }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <!-- TABLE PERF -->
        <template v-if="ShowPerf && ListFactures_PERF.facture">
          <div class="card col-xl-5 col-lg-5 col-md-5" style="float: none; margin: 0 auto;">
            <div class="card-body">
              <h4 slot="header" class="title">FACTURES DE {{ this.$route.params.client }}</h4>
              <el-table :data="ListFactures_PERF.facture">
                <el-table-column
                  prop= 'num_facture'
                  label= "N° Facture"
                  sortable
                  min-width="100px"
                >
                </el-table-column>

                <el-table-column
                  prop= 'montant'
                  label= "montant"
                  sortable
                  min-width="100px"
                >
                 <template scope="scope">
                   {{ scope.row.montant + ' €' }}
                 </template>
                </el-table-column>

              </el-table>

              <div class="d-flex justify-content-end">
                <span slot="header" class="badge badge-pill badge-info tab-title lablelTabel">
                  Solde actuel : {{ formatThounsends(ListFactures_PERF.solde_actuel,true,2) + " €" }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <!-- END TABLE PERF -->

        <!-- TABLE COREG -->
          <template v-else-if="!ShowPerf && ListFactures_COREG.facture">
            <div class="card col-xl-5 col-lg-5 col-md-5" style="float: none; margin: 0 auto;">
              <div class="card-body">
                <h4 slot="header" class="title">FACTURES DE {{ this.$route.params.client }}</h4>
                <el-table :data="ListFactures_COREG.facture">

                  <el-table-column
                    prop= 'num_facture'
                    label= "N° Facture"
                    sortable
                    min-width="100px"
                  >
                  </el-table-column>

                  <el-table-column
                    prop= 'montant'
                    label= "montant"
                    sortable
                    min-width="100px"
                  >
                    <template scope="scope">
                      {{ scope.row.montant + ' €' }}
                    </template>
                  </el-table-column>

                </el-table>

                <div class="d-flex justify-content-end">
                  <span slot="header" class="badge badge-pill badge-coreg tab-title lablelTabel">
                    Solde actuel : {{ formatThounsends(ListFactures_COREG.solde_actuel,true,2) + " €" }}
                  </span>
                </div>
              </div>
            </div>
          </template>
        <!-- END TABLE COREG -->
      </template>

      <!-- MODAL Add Advance -->
      <modal
        :show.sync="ShowAddAdvance"
        body-classes="p-0"
      >
        <card type="secondary"
          header-classes="bg-white pb-6"
          body-classes="px-lg-6 py-lg-6"
          class="border-0 mb-0">

          <div class="form-row">
            <base-input
              class="col-md-12"
              label="Montant"
              type="number"
              v-model="Avance.Montant"
              :error="getError('Montant')"
              v-validate="'required'"
              name="Montant"
            >
            </base-input>
          </div>

          <div class="form-row">
            <base-input
              class="col-md-12"
              label="date"
              :error="getError('Date')"
            >
            <el-date-picker 
              name="Date"
              v-validate="'required'"
              type="date" 
              value-format="yyyy-MM-dd"
              v-model="Avance.Date"
            ></el-date-picker>
            </base-input>
          </div>
          <template v-if="this.$route.params.platform == 'multi'">
            <div class="form-row">
              <base-input class="col-md-12" label="Platform" :error="getError('Platform')">
                <el-select filterable class="select-primary"
                  placeholder="PLATFORM"
                  clearable
                  name="Platform"
                  v-validate="'required'"
                  v-model="PlatformSelects.Platform">
                  <el-option 
                    v-for="option in PlatformSelects.ListPlatform"
                    class="select-primary"
                    :value="option"
                    :label="option"
                    :key="option">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </template>

          <base-button
            type="info"
            @click.native="AddAdvance"
          >VALIDER</base-button>
          <base-button
            type="info"
            @click.native="ShowAddAdvance = false"
          >ANNULER</base-button>
        </card>
      </modal>

      <!-- MODAL UPDATE ADVANCE -->
      <modal
        :show.sync="ShowEditAdvance"
        body-classes="p-0"
      >
        <card type="secondary"
          header-classes="bg-white pb-6"
          body-classes="px-lg-6 py-lg-6"
          class="border-0 mb-0">

          <div class="form-row">
            <base-input
              class="col-md-12"
              label="Montant"
              type="number"
              v-model="Edit_Avance.Montant"
              :error="getError('Montant2')"
              v-validate="'required'"
              name="Montant2"
            >
            </base-input>
          </div>

          <base-button
            type="info"
            @click.native="UpdateAdvance"
          >VALIDER</base-button>

          <base-button
            type="info"
            @click.native="ShowEditAdvance = false"
          >ANNULER</base-button>

        </card>
      </modal>
    </div>
  </div>
</template>

<script>
  import { Select, Option, Table, TableColumn, DatePicker } from 'element-ui'
  import gql from 'graphql-tag'
  import {Modal} from 'src/components'

  export default {
    apollo: {
      Factures_PERF: {
        client: 'AUTH',
        query: gql` query ($id_client_perf: Int) {
          getSoldeByClientPerf(id_client_perf: $id_client_perf){
            facture{
              num_facture
              montant
            }
            solde_actuel
          }
        }`,
        result({ data, loader, networkStatus }) {
          this.ListFactures_PERF = data.getSoldeByClientPerf[0]
        },
        variables () {
          return {
            id_client_perf: this.$route.params.id_perf
          }
        },
        skip() {
          return this.$route.params.platform == "coreg"
        },
        update: data => data
      },
      Factures_COREG: {
        client: 'AUTH',
        query: gql` query ($id_client_coreg: Int) {
          getSoldeByClientCoreg(id_client_coreg:$id_client_coreg){
            facture{
              num_facture
              montant
            }
            solde_actuel
          }
        }`,
        result({ data, loader, networkStatus }) {
          this.ListFactures_COREG = data.getSoldeByClientCoreg[0]
        },
        skip() {
          return this.$route.params.platform == "perf"
        },
        variables () {
          return {
            id_client_coreg: this.$route.params.id_coreg
          }
        },
        update: data => data
      },
      getAvanceByClientPerf: {
        client: 'AUTH',
        query: gql` query ($id_client_perf: Int) {
          getAvanceByClientPerf(id_client_perf:$id_client_perf){
            id
            montant
            date
            date_modification
          }
        }`,
        fetchPolicy: 'network-only',
        variables () {
          return {
            id_client_perf: this.$route.params.id_perf
          }
        },
        result({ data, loader, networkStatus }) {
          this.ListAvance_PERF = data.getAvanceByClientPerf
          let total = 0
          this.ListAvance_PERF.forEach(element => {
            total += element.montant
          });
          this.total_avance_perf = total
        }
      },
      getAvanceByClientCoreg: {
        client: 'AUTH',
        query: gql` query ($id_client_coreg: Int) {
          getAvanceByClientCoreg(id_client_coreg:$id_client_coreg){
            id
            montant
            date
            date_modification
          }
        }`,
        fetchPolicy: 'network-only',
        variables () {
          return {
            id_client_coreg: this.$route.params.id_coreg
          }
        },
        result({ data, loader, networkStatus }) {
          this.ListAvance_COREG = data.getAvanceByClientCoreg
          let total = 0
          this.ListAvance_COREG.forEach(element => {
            total += element.montant
          });
          this.total_avance_coreg = total
        }
      }
    },
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Modal,
      [DatePicker.name]: DatePicker,
      [Select.name]: Select,
      [Option.name]: Option
    },
    data() {
      return {
        ListFactures_PERF: [],
        ListFactures_COREG: [],
        ListAvance_PERF: [],
        ListAvance_COREG: [],
        ShowPerf: true,
        ShowAddAdvance: false,
        ShowEditAdvance: false,
        total_avance_coreg: 0,
        total_avance_perf: 0,
        Avance: {
          Montant: 0,
          Date: ''
        },
        Edit_Avance: {
          Montant: 0,
          id_avance: '',
          platform: ''
        },
        PlatformSelects: {
          Platform: '',
          ListPlatform: ["WEEDO PERF","WEEDO COREG"]
        }
      }
    },
    methods: {
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      async AddAdvance() {
        let validate
        await this.$validator.validateAll().then(isValid => {
          validate = isValid
        })
        if (!validate)
          return

        if (this.PlatformSelects.Platform == "") {
          if (this.$route.params.platform == "perf")
            this.addSoldePerf()
          else if (this.$route.params.platform == "coreg")
            this.addSoldeCoreg()
        }
        else if (this.PlatformSelects.Platform == "WEEDO PERF") {
          this.addSoldePerf()
        }
        else if (this.PlatformSelects.Platform == "WEEDO COREG") {
          this.addSoldeCoreg()
        }
        
      },
      addSoldePerf() {
        this.$apollo.mutate({
          client: "AUTH",
          mutation: require("src/graphql/mutation/addSoldePerf.gql"),
          variables: {
            id_client: this.$route.params.id_perf,
            montant: this.Avance.Montant,
            dateAvance: this.Avance.Date,
            id_facture : this.$route.params.id_facture_perf
          }
        }).then(response => {
          this.$apollo.queries.getAvanceByClientPerf.refresh()
          this.$notify({type: 'success', message: this.$t("apiresponse.advanceamountcreated") })
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        })
      },
      addSoldeCoreg() {
        this.$apollo.mutate({
          client: "AUTH",
          mutation: require("src/graphql/mutation/addSoldeCoreg.gql"),
          variables: {
            id_client: this.$route.params.id_coreg,
            montant: this.Avance.Montant,
            dateAvance: this.Avance.Date,
            id_facture : this.$route.params.id_facture_coreg
          }
        }).then(response => {
          this.$apollo.queries.getAvanceByClientCoreg.refresh()
          this.$notify({type: 'success', message: this.$t("apiresponse.advanceamountcreated") })
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        })
      },
      DeleteAdvance(id_avance, platform) {
        if(platform == 'perf') {
          this.$apollo.mutate({
            client: "AUTH",
            mutation: require("src/graphql/mutation/deleteSoldePerf.gql"),
            variables: {
              id_avance: id_avance
            }
          }).then(response => {
            this.$apollo.queries.getAvanceByClientPerf.refresh()
            this.$notify({type: 'success', message: this.$t("apiresponse.advanceamountupdated") })
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }
        else if (platform == 'coreg'){
          this.$apollo.mutate({
            client: "AUTH",
            mutation: require("src/graphql/mutation/deleteSoldeCoreg.gql"),
            variables: {
              id_avance: id_avance
            }
          }).then(response => {
            this.$apollo.queries.getAvanceByClientCoreg.refresh()
            this.$notify({type: 'success', message: this.$t("apiresponse.advanceamountupdated")  })
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      PrepareUpdateAdvance(row,platform) {
        this.Edit_Avance.Montant = row.montant
        this.Edit_Avance.id_avance = row.id
        this.Edit_Avance.platform = platform
        this.ShowEditAdvance = true
      },
      async UpdateAdvance() {
        if (this.Edit_Avance.platform == "perf") {
          this.$apollo.mutate({
            client: "AUTH",
            mutation: require("src/graphql/mutation/editSoldePerf.gql"),
            variables: {
              id_avance: this.Edit_Avance.id_avance,
              montant: this.Edit_Avance.Montant
            }
          }).then(response => {
            this.$apollo.queries.getAvanceByClientPerf.refresh()
            this.$notify({type: 'success', message: this.$t("apiresponse.advanceamountdeleted") })
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }
        else if (this.Edit_Avance.platform == "coreg") {
          this.$apollo.mutate({
            client: "AUTH",
            mutation: require("src/graphql/mutation/editSoldeCoreg.gql"),
            variables: {
              id_avance: this.Edit_Avance.id_avance,
              montant: this.Edit_Avance.Montant
            }
          }).then(response => {
            this.$apollo.queries.getAvanceByClientCoreg.refresh()
            this.$notify({type: 'success', message: this.$t("apiresponse.advanceamountdeleted") })
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdaten") })
          })
        }
      }
    },
    created() {
      if (this.$route.params.platform == 'coreg')
        this.ShowPerf = false
    },
  }
</script>

<style scoped>
  .lablelTabel {
    font-weight: 600;
    font-size: 1.1rem;
    text-align: right;
  }
</style>