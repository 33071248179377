<template>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card>
            <div class="row">
              <h1 class="col-lg-10">{{ report.month_label + " " + report.year }}</h1>
              <base-button
                @click.native="report.year -= 1"
                class="btn-link"
                type="info"
                size="sm"
                icon
              >
                <i class="el-icon-d-arrow-left"></i>
              </base-button>
              <base-button
                @click.native="report.mounth -= 1"
                class="btn-link"
                type="info"
                size="sm"
                icon
              >
                <i class="el-icon-arrow-left"></i>
              </base-button>
              <base-button
                @click.native="report.mounth += 1"
                class="btn-link"
                type="info"
                size="sm"
                icon
                :disabled="(report.Current.getMonth() + 1) == report.mounth && report.Current.getFullYear() == report.year"
              >
                <i class="el-icon-arrow-right"></i>
              </base-button>
              <base-button
                @click.native="report.year += 1"
                class="btn-link"
                type="info"
                size="sm"
                icon
                :disabled="report.Current.getFullYear() == report.year"
              >
                <i class="el-icon-d-arrow-right"></i>
              </base-button>
            </div>

          </card>
        </div>

        <time-line>

          <time-line-item
            inverted
            badge-type="warning"
            badge-icon="tim-icons icon-app"
          >
            <base-button 
              slot="header" 
              type="warning" 
              @click="OpenRapport" 
              :disabled="rapportsNotAllowed.includes(id_utilisateur)"
            >RAPPORTS</base-button>
          </time-line-item>

          <time-line-item
            badge-type="success"
            badge-icon="tim-icons icon-money-coins"
          >
            <base-button 
              slot="header" 
              type="success" 
              @click="OpenPayment"
              :disabled="paimentsNotAllowed.includes(id_utilisateur)"
            >PAIEMENTS</base-button>
          </time-line-item>
          <time-line-item
            inverted
            badge-type="info"
            badge-icon="tim-icons icon-wallet-43"
          >
            <label slot="header">FACTURES</label>
            <base-button 
              slot="content" 
              round 
              type="info" 
              @click="OpenFacture" 
              size="sm" style="background: #EEEEEE !important;"
              :disabled="facturePerfCoregNotAllowed.includes(id_utilisateur)"
            >
              <img src="/img/logo-perf.png" title="PERF & COREG" alt="" class="float-left" />
              <img src="/img/logo-coreg.png" title="PERF & COREG" alt="" class="float-left" />
            </base-button>
            <base-button slot="content" round icon type="info" @click="OpenFacture_mg">
              <img src="/img/ico-mongustave.png" title="MONGUSTAVE" class="float-left" />
            </base-button>
            <base-button slot="content" round icon type="market" @click="OpenFacture_lm">
              <img src="/img/weedo-it-logo-blanc.png" title="WEEDO MARKET" alt="" class="float-left" />
            </base-button>
            <base-button 
              slot="content" 
              round 
              icon 
              type="market" 
              @click="OpenFactureAvoir"
              :disabled="factureAvoirNotAllowed.includes(id_utilisateur)"
            >
              <img src="/img/facture-avoir.png" title="LES FACTURES D'AVOIR" alt="" class="float-left" />
            </base-button>
          </time-line-item>

        </time-line>
      </div>
    </div>
</template>

<script>
  import { TimeLine, TimeLineItem } from 'src/components';

  let months = [];

  let current_date = new Date()

  export default {
    components: {
      TimeLine,
      TimeLineItem
    },
    watch: {
      "report.mounth"(value) {
        if (value == 0) {
          this.report.year -= 1
          this.report.mounth = 12
        }
        else if (value == 13) {
          this.report.year += 1
          this.report.mounth = 1
        }
        else {
          this.report.month_label = months[this.report.mounth - 1]
        }
      },
      "report.year"(value) {
        if(value == current_date.getFullYear()) {
          if(this.report.mounth > (current_date.getMonth()+1)) {
            this.report.mounth = current_date.getMonth() + 1
          }
        }
      }
    },
    data() {
      return {
        report: {
          year: current_date.getFullYear(),
          mounth: (current_date.getMonth() + 1),
          month_label: months[current_date.getMonth()],
          Current: current_date
        },
        id_utilisateur: parseInt(JSON.parse(localStorage.getItem("utilisateur")).id),
        rapportsNotAllowed: [2047],
        paimentsNotAllowed: [2047],
        facturePerfCoregNotAllowed: [2047],
        factureAvoirNotAllowed: [2047],
      }
    },
    methods: {
      OpenRapport() {
        localStorage.setItem("rapport", JSON.stringify(this.report))
        this.$router.push({ name: "liste des Rapports Coreg" })
      },
      OpenPayment() {
        localStorage.setItem("payment", JSON.stringify(this.report))
        this.$router.push({ name: "liste des Paiments Global" })
      },
      OpenFacture() {
        localStorage.setItem("facture", JSON.stringify(this.report))
        this.$router.push({ name: "liste des Factures Global" })
      },
      OpenFacture_mg() {
        localStorage.setItem("facture", JSON.stringify(this.report))
        this.$router.push({ name: "liste des Factures Mongustave" })
      },
      OpenFacture_lm() {
        localStorage.setItem("facture", JSON.stringify(this.report))
        this.$router.push({ name: "liste des Factures LeadMarket" })
      },
      OpenFactureAvoir() {
        localStorage.setItem("facture", JSON.stringify(this.report))
        this.$router.push({ name: "liste des Factures Avoir" })
      }
    },
    created() {
      Calendly.initBadgeWidget(null);
    },
    mounted() {
      months = this.$t('labels.Mounths')
      this.report.mounth--
    },
  };
  </script>
  <style></style>
