<template>
    <div class="content">

        <div class="col-sm-6 text-left">

            <h2 class="card-title">TABLEAU DE BORD DE PERFORMANCE</h2>

        </div>

        <div class="row">

            <div class="card card-chart">

                <div class="card-header">

                    <div class="col-sm-6 text-left">

                        <h2 class="card-title">RATIO LTV/CAC</h2>

                    </div>
                    <div class="row">
                    <div class="col-lg-3 col-md-3">
                        <card id ="couleur">

                        <div id="card-bodydate">
                        <base-input>
                            <el-select
                          class="select-primary"
                          id="dates-select"
                          :disabled="loading"
                          placeholder="Choisissez"
                          v-model="dates.selectedYear"
                          @change="handleYearChange"
                        >
                          <el-option
                            v-for="option in dates.listeDates"
                            :key="option"
                            :value="option"
                            :label="option.toUpperCase()"
                            class="select-option"
                          />
                        </el-select>
                        </base-input>
                        </div>

                        </card>

                   </div>
                   </div>

                    <div class="col-md-12 float-right">
                        <div class="btn-group btn-group-toggle float-right">

                            <button class="btn btn-round btn-just-icon btn-danger" :disabled="chartDataList.length <= 0" @click="removeChart">
                      -
                    </button>

                            <base-button v-if="loading" loading class="btn btn-round btn-just-icon btn-primary" disabled>

                                {{ $t("buttons.BtnLoad").toUpperCase() }}

                            </base-button>

                            <base-button v-else class="btn btn-round btn-just-icon btn-primary" disabled>

                                {{ $t("compare").toUpperCase() }}

                            </base-button>

                            <button class="btn btn-round btn-just-icon btn-success" :disabled="chartDataList.length >= maxCharts" @click="addChart">

                      +

                    </button>

                        </div>

                    </div>



                    <div class="row">

                        <div :class="chartDataList.length >= 1 ? 'col-lg-6' : 'col-lg-12'">

                            <div class="card-body" >

                                <div class="row">

                                    <div class="col-lg-6 col-md-6" >

                                        <base-input>

                                            <el-select class="select-primary" v-model="model.product1"  @change="handleChange" placeholder="Tous les produits">

                                                <el-option v-for="product in products.Listproduct" :key="product.value" :label="product.label.toUpperCase()" :value="product.value" />

                                            </el-select>

                                        </base-input>

                                    </div>

                                    <div class="col-lg-6 col-md-6">

                                        <base-input>

                                            <el-select class="select-primary" v-model="model.operation1"  @change="handleChange" placeholder="Toutes les opérations"  >

                                                <el-option v-for="type_op in operations.Listoperation" :key="type_op.value" :label="type_op.label.toUpperCase()" :value="type_op.value" />

                                            </el-select>

                                        </base-input>

                                    </div>

                                </div>


                                <div class="chart-container">
                                <div class="chart-area" v-if="!loading">

                                    <mixedChartData1 :chart-data="filteredChartData1" :options="chartOptions1" class="mix" />

                                </div>
                                </div>

                            </div>

                        </div>



                        <div class="col-lg-6" v-if="activeChart1">

                            <div class="card-body" >

                                <div class="row">

                                    <div class="col-lg-6 col-md-6">

                                        <base-input>

                                            <el-select class="select-primary" v-model="model.product2" @change="handleChange"  placeholder="Tous les produits">

                                                <el-option v-for="product in products.Listproduct" :key="product.value" :label="product.label.toUpperCase()" :value="product.value" />

                                            </el-select>

                                        </base-input>

                                    </div>

                                    <div class="col-lg-6 col-md-6">

                                        <base-input>

                                            <el-select class="select-primary" v-model="model.operation2" @change="handleChange"  placeholder="Toutes les opérations">

                                                <el-option v-for="type_op in operations.Listoperation" :key="type_op.value" :label="type_op.label.toUpperCase()" :value="type_op.value" />

                                            </el-select>

                                        </base-input>

                                    </div>

                                </div>


                                <div class="chart-container">
                                <div class="chart-area" v-if="!loading">

                                    <mixedChartData2 :chart-data="filteredChartData2" :options="chartOptions2" class="mix" />

                                </div>
                                </div>

                            </div>

                        </div>


                    </div>

                </div>



                <div class="row">

                    <div class="col-lg-6" v-if="activeChart2">

                        <div class="card-body" id="card-body1">

                            <div class="row">

                                <div class="col-lg-6 col-md-6">

                                    <base-input>

                                        <el-select class="select-primary" v-model="model.product3" @change="handleChange"  placeholder="Tous les produits">

                                            <el-option v-for="product in products.Listproduct" :key="product.value" :label="product.label.toUpperCase()" :value="product.value" />

                                        </el-select>

                                    </base-input>

                                </div>

                                <div class="col-lg-6 col-md-6">

                                    <base-input>

                                        <el-select class="select-primary" v-model="model.operation3" @change="handleChange"  placeholder="Toutes les opérations">

                                            <el-option v-for="type_op in operations.Listoperation" :key="type_op.value" :label="type_op.label.toUpperCase()" :value="type_op.value" />

                                        </el-select>

                                    </base-input>

                                </div>

                            </div>


                            <div class="chart-container">
                            <div class="chart-area" v-if="!loading">

                                <mixedChartData3 :chart-data="filteredChartData3" :options="chartOptions3" class="mix" />

                            </div>
                            </div>

                        </div>

                    </div>



                    <div class="col-lg-6" v-if="activeChart3">

                        <div class="card-body" id="card-body2">

                            <div class="row">

                                <div class="col-lg-6 col-md-6">

                                    <base-input>

                                        <el-select class="select-primary" v-model="model.product4" @change="handleChange"  placeholder="Tous les produits">

                                            <el-option v-for="product in products.Listproduct" :key="product.value" :label="product.label.toUpperCase()" :value="product.value" />

                                        </el-select>

                                    </base-input>

                                </div>

                                <div class="col-lg-6 col-md-6">

                                    <base-input>

                                        <el-select class="select-primary" v-model="model.operation4"  @change="handleChange" placeholder="Toutes les opérations">

                                            <el-option v-for="type_op in operations.Listoperation" :key="type_op.value" :label="type_op.label.toUpperCase()" :value="type_op.value" />

                                        </el-select>

                                    </base-input>

                                </div>

                            </div>


                            <div class="chart-container">
                            <div class="chart-area" v-if="!loading">

                                <mixedChartData4 :chart-data="filteredChartData4" :options="chartOptions4" class="mix" />

                            </div>
                            </div>

                        </div>

                    </div>

                </div>
                 <div class="row">
                     <div class="col-lg-6" v-if="activeChart4">
                         <div class="card-body">
                             <div class="row">
                                 <div class="col-lg-6 col-md-6">
                                     <base-input>
                                         <el-select class="select-primary" v-model="model.product5" @change="handleChange" placeholder="Tous les produits">
                                             <el-option v-for="product in products.Listproduct" :key="product.value" :label="product.label.toUpperCase()" :value="product.value" />
                                         </el-select>
                                     </base-input>
                                 </div>
                                 <div class="col-lg-6 col-md-6">
                                     <base-input>
                                         <el-select class="select-primary" v-model="model.operation5" @change="handleChange" placeholder="Toutes les opérations">
                                             <el-option v-for="type_op in operations.Listoperation" :key="type_op.value" :label="type_op.label.toUpperCase()" :value="type_op.value" />
                                         </el-select>
                                     </base-input>
                                 </div>
                             </div>
                             <div class="chart-container">
                                 <div class="chart-area" v-if="!loading">
                                     <mixedChartData5 :chart-data="filteredChartData5" :options="chartOptions5" class="mix" />
                                 </div>
                             </div>
                         </div>
                     </div>


                     <div class="col-lg-6" v-if="activeChart5">
                         <div class="card-body">
                             <div class="row">
                                 <div class="col-lg-6 col-md-6">
                                     <base-input>
                                         <el-select class="select-primary" v-model="model.product6" @change="handleChange" placeholder="Tous les produits">
                                             <el-option v-for="product in products.Listproduct" :key="product.value" :label="product.label.toUpperCase()" :value="product.value" />
                                         </el-select>
                                     </base-input>
                                 </div>
                                 <div class="col-lg-6 col-md-6">
                                     <base-input>
                                         <el-select class="select-primary" v-model="model.operation6" @change="handleChange" placeholder="Toutes les opérations">
                                             <el-option v-for="type_op in operations.Listoperation" :key="type_op.value" :label="type_op.label.toUpperCase()" :value="type_op.value" />
                                         </el-select>
                                     </base-input>
                                 </div>
                                              </div>
                             <div class="chart-container">
                                 <div class="chart-area" v-if="!loading">
                                     <mixedChartData6 :chart-data="filteredChartData6" :options="chartOptions6" class="mix" />
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>


            </div>



            <!-- el table -->

            <div class="card card-chart">
    <div class="card-header">
      <div class="col-sm-12 text-left">
        <h2 class="card-title">Liste des contacts</h2>
      </div>
      <div v-loading="!lowder" class="loading-container">
      <div class="row">
        <div class="col-md-4 float-left">
          <base-input>
          <el-select
  class="select-primary pagination-select"
  v-model="pagination.perPage"
  placeholder="Per page"
  @change="paginateContacts"
>
  <el-option
    class="select-primary"
    v-for="item in pagination.perPageOptions"
    :key="item"
    :label="item"
    :value="item"
  ></el-option>
</el-select>
          </base-input>
        </div>

        <div class="col-md-8 float-right">
  <base-input class="col-xl-6 col-sm-4 float-right">
    <el-input
      type="search"
      class="mb-3 search-input float-right"
      prefix-icon="el-icon-search"
      v-model="searchEmail"
      placeholder="Rechercher par email"
      @input="searchByEmail"
    ></el-input>
  </base-input>
</div>

      </div>
        <el-table
          :data="contactsData"
          :disabled="loading"
          row-class-name="booking-table-rows"
          class="customtable_cmd"
          style="width: 100%"
        >
          <el-table-column label="Email" prop="email"></el-table-column>
          <el-table-column label="CAC" prop="cac">
                <template scope="props">
                    {{ formatThounsends(props.row.cac, true, 2) + ' €' }}
                  </template>
                </el-table-column>
          </el-table-column>
          <el-table-column label="LTV" prop="ltv">
            <template scope="props">
                    {{ formatThounsends(props.row.ltv , true, 2) + ' €' }}
                  </template>
        </el-table-column>
          <el-table-column
            label=" MAJ Date d'acquisition"
            :formatter="formatDate1('acquisition')"
            prop="acquisition"
          ></el-table-column>
          <el-table-column
            label=" MAJ Date de bénéfice"
            :formatter="formatDate2('date_benefice')"
            prop="date_benefice"
          ></el-table-column>
        </el-table>

        <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <div></div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
              @current-change="handlePageChange"
            ></base-pagination>
        </div>
      </div>
    </div>
  </div>

        </div>

    </div>
</template>

<script>
import axios from "axios";
import { Button, Select, Option } from "element-ui";
import { Table, TableColumn } from "element-ui";
import { BasePagination } from "src/components";
import { Bar, Line, mixins } from "vue-chartjs";
import moment from "moment";
moment.locale("fr");

const { reactiveProp } = mixins;

export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        BasePagination,

        mixedChartData1: {
            extends: Bar,
            mixins: [reactiveProp],
            props: ["options"],
            mounted() {
                this.renderChart(this.chartData, this.options);
            },
        },
        mixedChartData2: {
            extends: Bar,
            mixins: [reactiveProp],
            props: ["options"],
            mounted() {
                this.renderChart(this.chartData, this.options);
            },
        },

        mixedChartData3: {
            extends: Bar,
            mixins: [reactiveProp],
            props: ["options"],
            mounted() {
                this.renderChart(this.chartData, this.options);
            },
        },
        mixedChartData4: {
            extends: Bar,
            mixins: [reactiveProp],
            props: ["options"],
            mounted() {
                this.renderChart(this.chartData, this.options);
            },
        },
        mixedChartData5: {
        extends: Bar,
        mixins: [reactiveProp],
        props: ["options"],
        mounted() {
            this.renderChart(this.chartData, this.options);
        },
        },
        mixedChartData6: {
        extends: Bar,
        mixins: [reactiveProp],
        props: ["options"],
        mounted() {
            this.renderChart(this.chartData, this.options);
        },
       },
    },
    data() {
        return {
            responseContacts:[],
            loading: false,

           // enableButton: false,
            activeChart: 1,
           // selectedYear: null,
            contactsData: [],
            allContactsData: [],
            yearsList: [],
            contacts: [],
            contacts2: [],
            contacts3: [],
            contacts4: [],
            contacts5: [],
            contacts6: [],
            activeChart1: 0,
            activeChart2: 0,
            activeChart3: 0,
            activeChart4: 0,
            activeChart5: 0,
            mixe2: [],
            mixe3: [],
            mixe4: [],
            searchEmail: "",
            filteredContacts: [],
            filteredContacts1: [],
            filteredContacts2: [],
            filteredContacts3: [],
            filteredContacts4: [],
            filteredContacts5: [],
            filteredContacts6: [],
            chartOptions2: {},
            chartOptions3: {},
            chartOptions4: {},
            chartOptions5: {},
            chartOptions6: {},
            dates: {
            selectedYear: new Date().getFullYear(), // Date sélectionnée
            listeDates: [] // Liste des dates disponibles
             },
            products: {
                Listproduct: [
                    { value: "Tous", label: "Tous les produits" },
                    { value: "sante", label: "Mutuelle santé" },
                    { value: "animaux", label: "Assurance Animaux" },
                    { value: "auto", label: "Assurance Auto" },
                    { value: "mrh", label: "Assurance Habitation" },
                    { value: "emprunteur", label: "Assurance Emprunteur" },
                    { value: "credit_conso", label: "Crédit Consommation" },
                    { value: "per", label: "Plan d'épargne Retraite"},
                ],
            },
            operations: {
                Listoperation: [
                    { value: "Tout", label: "Toutes les opérations" },
                    { value: "mer", label: "mer" },
                    { value: "vente", label: "vente" },
                ],
            },
            selectedProduct1: "",
            selectedOperation1: "",
            selectedProduct2: "",
            selectedOperation2: "",
            selectedProduct3: "",
            selectedOperation3: "",
            selectedProduct4: "",
            selectedOperation4: "",
            selectedProduct5: "",
            selectedOperation5: "",
            selectedProduct6: "",
            selectedOperation6: "",
            newChartData: null,

            pagination: {
                perPage: 10,
                currentPage: 1,
                perPageOptions: [10, 20, 50, 100],
                total: 0,
                lastPage: 1,
            },
         chartOptions1: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                            type: "linear",
                            display: true,
                            position: "left",
                            id: "y-axis-1",
                            ticks: {
                                min: 0,

                            },
                        },
                        {
                            type: "linear",
                            display: true,
                            position: "right",
                            id: "y-axis-2",
                            gridLines: {
                                drawOnChartArea: false,
                            },
                            ticks: {
                                min: 0,

                            },
                        },
                    ],
                },
                tooltips: {
                         callbacks: {
                                title: function(tooltipItem, data) {
                                  // Return the month as the title
                                  return data.labels[tooltipItem[0].index];
                                },
                                label: function(tooltipItem, data) {
                                  const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                                  const value = tooltipItem.yLabel.toFixed(2); // Format the value with two decimal places
                                  return `${datasetLabel}: ${value} €`; // Add euro symbol
                                }
                              },

                          },
                          annotation: {
        annotations: [
            {
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y-axis-2',
                value: 1.0,
                borderColor: 'red',
                borderWidth: 2,
                label: {
                    enabled: false
                }
            }
        ]
    }

        },

            chartOptions2: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                            type: "linear",
                            display: true,
                            position: "left",
                            id: "y-axis-1",
                            ticks: {
                                min: 0,
                            },
                        },
                        {
                            type: "linear",
                            display: true,
                            position: "right",
                            id: "y-axis-2",
                            gridLines: {
                                drawOnChartArea: false,
                            },
                            ticks: {
                                min: 0,

                            },
                        },
                    ],
                },
                tooltips: {
                         callbacks: {
                                title: function(tooltipItem, data) {
                                  // Return the month as the title
                                  return data.labels[tooltipItem[0].index];
                                },
                                label: function(tooltipItem, data) {
                                  const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                                  const value = tooltipItem.yLabel.toFixed(2); // Format the value with two decimal places
                                  return `${datasetLabel}: ${value} €`; // Add euro symbol
                                }
                              },

                          }
            },
            chartOptions3: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                            type: "linear",
                            display: true,
                            position: "left",
                            id: "y-axis-1",
                            ticks: {
                                min: 0,
                            },
                        },
                        {
                            type: "linear",
                            display: true,
                            position: "right",
                            id: "y-axis-2",
                            gridLines: {
                                drawOnChartArea: false,
                            },
                            ticks: {
                                min: 0,

                            },
                        },
                    ],
                },
                tooltips: {
                         callbacks: {
                                title: function(tooltipItem, data) {
                                  // Return the month as the title
                                  return data.labels[tooltipItem[0].index];
                                },
                                label: function(tooltipItem, data) {
                                  const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                                  const value = tooltipItem.yLabel.toFixed(2); // Format the value with two decimal places
                                  return `${datasetLabel}: ${value} €`; // Add euro symbol
                                }
                              },

                          }
            },
            chartOptions4: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                            type: "linear",
                            display: true,
                            position: "left",
                            id: "y-axis-1",
                            ticks: {
                                min: 0,
                            },
                        },
                        {
                            type: "linear",
                            display: true,
                            position: "right",
                            id: "y-axis-2",
                            gridLines: {
                                drawOnChartArea: false,
                            },
                            ticks: {
                                min: 0,
                            },
                        },
                    ],
                },
                tooltips: {
                         callbacks: {
                                title: function(tooltipItem, data) {
                                  // Return the month as the title
                                  return data.labels[tooltipItem[0].index];
                                },
                                label: function(tooltipItem, data) {
                                  const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                                  const value = tooltipItem.yLabel.toFixed(2); // Format the value with two decimal places
                                  return `${datasetLabel}: ${value} €`; // Add euro symbol
                                }
                              },

                          }
            },
            chartOptions5: {
                  responsive: true,
                  maintainAspectRatio: false,
                  scales: {
                      yAxes: [{
                          type: "linear",
                          display: true,
                          position: "left",
                          id: "y-axis-1",
                          ticks: {
                              min: 0,
                          },
                      },
                      {
                          type: "linear",
                          display: true,
                          position: "right",
                          id: "y-axis-2",
                          gridLines: {
                              drawOnChartArea: false,
                          },
                          ticks: {
                              min: 0,
                          },
                      },
                      ],
                },
               tooltips: {
                callbacks: {
                    title: function(tooltipItem, data) {
                        return data.labels[tooltipItem[0].index];
                    },
                     label: function(tooltipItem, data) {
                        const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                        const value = tooltipItem.yLabel.toFixed(2);
                        return `${datasetLabel}: ${value} €`;
                       }
                   },
              }
            },
            chartOptions6: {
                   responsive: true,
                   maintainAspectRatio: false,
                   scales: {
                       yAxes: [{
                           type: "linear",
                           display: true,
                           position: "left",
                           id: "y-axis-1",
                           ticks: {
                               min: 0,
                           },
                       },
                       {
                           type: "linear",
                           display: true,
                           position: "right",
                           id: "y-axis-2",
                           gridLines: {
                               drawOnChartArea: false,
                           },
                           ticks: {
                               min: 0,
                           },
                       },
                       ],
                   },
                   tooltips: {
                       callbacks: {
                           title: function(tooltipItem, data) {
                               return data.labels[tooltipItem[0].index];
                           },
                           label: function(tooltipItem, data) {
                                      const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                               const value = tooltipItem.yLabel.toFixed(2);
                               return `${datasetLabel}: ${value} €`;
                           }
                       },
                   }
            },

            lowder: false,
            leads: [],
            mixedChartData1: null,
            mixedChartData2: null,
            mixedChartData3: null,
            mixedChartData4: null,
            mixedChartData5: null,
            mixedChartData6: null,
            model: {
                product1: "",
                product2: "",
                product3: "",
                product4: "",
                product5: "",
                product6: "",
                operation1: "",
                operation2: "",
                operation3: "",
                operation4: "",
                operation5: "",
                operation6: "",
            },

            chartDataList: [],
            maxCharts: 5,
        };
    },
    computed: {
        filteredChartData1() {
            return this.getFilteredChartData1(
                "mixedChartData1",
                this.selectedProduct1,
                this.selectedOperation1
            );
        },
        filteredChartData2() {
            return this.getFilteredChartData2(
                "mixedChartData2",
                this.selectedProduct2,
                this.selectedOperation2
            );
        },
        filteredChartData3() {
            return this.getFilteredChartData3(
                "mixedChartData3",
                this.selectedProduct3,
                this.selectedOperation3
            );
        },
        filteredChartData4() {
            return this.getFilteredChartData4(
                "mixedChartData4",
                this.selectedProduct4,
                this.selectedOperation4
            );
        },
        filteredChartData5() {
          return this.getFilteredChartData5(
             "mixedChartData5",
            this.model.product5,
            this.model.operation5
          );
        },
        filteredChartData6() {
        return this.getFilteredChartData6(
            "mixedChartData6",
            this.model.product6,
            this.model.operation6
        );
        },
      /*  contactsData() {
            const start = (this.pagination.currentPage - 1) * this.pagination.perPage;
            const end = start + this.pagination.perPage;
            return this.filteredContacts.slice(start, end);
        },*/
        paginateContacts() {
      const start = (this.pagination.currentPage - 1) * this.pagination.perPage;
      const end = start + this.pagination.perPage;
      this.contactsData = this.allContactsData.slice(start, end);
    },

        from() {
            return (this.pagination.currentPage - 1) * this.pagination.perPage;
        },
        to() {
            return Math.min(
                this.pagination.currentPage * this.pagination.perPage,
                this.pagination.total
            );
        },
        total() {
            return this.filteredContacts.length;
        },
    },
    methods: {
        formatThounsends: function(value, fixe = false, number = 0) {
        if (value) {
        value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
        getGainAndSpend(){

            this.loading = true;
            const apiUrl = "https://apileadmarket.wee-do-it.net/api/get_gains_and_depenses";
            const accessToken = localStorage.getItem("token_jwt");
            axios
                .post(
                    apiUrl, {
                        annee: this.dates.selectedYear,
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    this.loading = false;
                    if (response.data && response.data.data) {
                        this.responseContacts = response.data.data;
                        this.fetchGraphData1()
                        this.fetchGraphData2()
                        this.fetchGraphData3()
                        this.fetchGraphData4()
                        this.fetchGraphData5()
                        this.fetchGraphData6()
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.error(
                        "Erreur lors de la récupération des données de graphique 1:",
                        error
                    );
                });

        },

        fetchGraphData1() {
            console.log('fetchGraphData1', this.responseContacts)
            this.contacts = this.responseContacts;
            this.mixedChartData1 = this.getFilteredChartData1(
                "mixedChartData1",
                this.selectedProduct1,
                this.selectedOperation1
            );
        },

     addChart() {
        console.log('addChart');
        if (this.chartDataList.length < this.maxCharts) {
            console.log(this.activeChart);
            console.log(this.contacts2);
            console.log(this.mixedChartData2);
            let newChartData = null;

            // Déterminez les données du graphique à ajouter en fonction de activeChart
            if (this.activeChart === 1 && this.contacts2 && this.mixedChartData2) {
                this.activeChart1 = 1;
                this.activeChart2 = 0;
                this.activeChart3 = 0;
                this.activeChart4 = 0;
                this.activeChart5 = 0;
                newChartData = {
                    product: this.selectedProduct2,
                    type_op: this.selectedOperation2,
                    chartData: this.filteredChartData2,
                    chartOptions: this.chartOptions2,
                };
            } else if (this.activeChart === 2 && this.contacts3 && this.mixedChartData3) {
                this.activeChart1 = 0;
                this.activeChart2 = 2;
                this.activeChart3 = 0;
                this.activeChart4 = 0;
                this.activeChart5 = 0;
                newChartData = {
                    product: this.selectedProduct3,
                    type_op: this.selectedOperation3,
                    chartData: this.filteredChartData3,
                    chartOptions: this.chartOptions3,
                };
            } else if (this.activeChart === 3 && this.contacts4 && this.mixedChartData4) {
                this.activeChart1 = 0;
                this.activeChart2 = 0;
                this.activeChart3 = 3;
                this.activeChart4 = 0;
                this.activeChart5 = 0;
                newChartData = {
                    product: this.selectedProduct4,
                    type_op: this.selectedOperation4,
                    chartData: this.filteredChartData4,
                    chartOptions: this.chartOptions4,
                };
            } else if (this.activeChart === 4 && this.contacts5 && this.mixedChartData5) {
                this.activeChart1 = 0;
                this.activeChart2 = 0;
                this.activeChart3 = 0;
                this.activeChart4 = 4;
                this.activeChart5 = 0;
                newChartData = {
                    product: this.selectedProduct5,
                    type_op: this.selectedOperation5,
                    chartData: this.filteredChartData5,
                    chartOptions: this.chartOptions5,
                };
            } else if (this.activeChart === 5 && this.contacts6 && this.mixedChartData6) {
                this.activeChart1 = 0;
                this.activeChart2 = 0;
                this.activeChart3 = 0;
                this.activeChart4 = 0;
                this.activeChart5 = 5;
                newChartData = {
                    product: this.selectedProduct6,
                    type_op: this.selectedOperation6,
                    chartData: this.filteredChartData6,
                    chartOptions: this.chartOptions6,
                };
            } else {
                console.error("Invalid activeChart value or missing data:", this.activeChart);
            }

            // Vérifiez si newChartData a été correctement initialisé
            if (newChartData) {
                this.chartDataList.push(newChartData);

                // Réinitialisez activeChart à 1 si le maximum de graphiques est atteint
                if (this.chartDataList.length >= this.maxCharts) {
                    this.activeChart = 1;
                } else {
                    this.activeChart++;
                }
                this.updateActiveCharts(); // Assurez-vous de mettre à jour les graphiques actifs
            } else {
                console.error("No new chart data was created. Check the conditions.");
            }
        } else {
            console.log("Max charts reached.");
        }
    },

        removeChart() {
            if (this.chartDataList.length > 0) {
                this.chartDataList.pop();
                this.updateActiveCharts();
            }
        },

        updateActiveCharts() {
            // Reset active chart indices
            this.activeChart1 = 0;
            this.activeChart2 = 0;
            this.activeChart3 = 0;

            // Update based on the current length of chartDataList
            if (this.chartDataList.length >= 1) {
                this.activeChart1 = 1;
                this.activeChart2 = 0;
                this.activeChart3 = 0;
                this.activeChart4 = 0;
                this.activeChart5 = 0;
            }
            if (this.chartDataList.length >= 2) {
                //this.activeChart1 = 0;
                this.activeChart2 = 2;
                this.activeChart3 = 0;
                this.activeChart4 = 0;
                this.activeChart5 = 0;
            }
            if (this.chartDataList.length >= 3) {
                //this.activeChart1 = 0;
                //this.activeChart2 = 0;
                this.activeChart3 = 3;
                this.activeChart4 = 0;
                this.activeChart5 = 0;
            }
            if (this.chartDataList.length >= 4) {
                //this.activeChart1 = 0;
                //this.activeChart2 = 0;
                //this.activeChart3 = 3;
                this.activeChart4 = 4;
                this.activeChart5 = 0;
            }
            if (this.chartDataList.length >= 5) {
                //this.activeChart1 = 0;
                //this.activeChart2 = 0;
                //this.activeChart3 = 3;
                //this.activeChart4 = 4;
                this.activeChart5 = 5;
            }
        },

        fetchGraphData2() {
            console.log('fetchGraphData2', this.responseContacts)
            this.contacts2 = this.responseContacts;
            this.mixedChartData2 = this.getFilteredChartData2(
                "mixedChartData2",
                this.selectedProduct2,
                this.selectedOperation2
            );

        },
        fetchGraphData3() {
            this.contacts3 = this.responseContacts;
            this.mixedChartData3 = this.getFilteredChartData3(
                "mixedChartData3",
                this.selectedProduct3,
                this.selectedOperation3
            );
        },

        fetchGraphData4() {
            this.contacts4 = this.responseContacts;
            this.mixedChartData4 = this.getFilteredChartData4(
                "mixedChartData4",
                this.selectedProduct4,
                this.selectedOperation4
            );

        },
        fetchGraphData5() {
           console.log('fetchGraphData5', this.responseContacts);
             this.contacts5 = this.responseContacts;
             this.mixedChartData5 = this.getFilteredChartData5(
                 "mixedChartData5",
                 this.selectedProduct5,
                 this.selectedOperation5
             );
        },
        fetchGraphData6() {
          console.log('fetchGraphData6', this.responseContacts);
           this.contacts6 = this.responseContacts;
           this.mixedChartData6 = this.getFilteredChartData6(
               "mixedChartData6",
               this.selectedProduct6,
               this.selectedOperation6
           );
        },


fetchContacts(isEmailSearch = false) {
  this.lowder = false;
  const apiUrl = "https://apileadmarket.wee-do-it.net/api/get_contacts_infos";
  const accessToken = localStorage.getItem("token_jwt");

  const requestBody = {
    annee: this.dates.selectedYear,
    version: 2,
  };

  // Inclure l'email dans le corps de la requête si c'est une recherche par email
  if (isEmailSearch && this.searchEmail) {
    requestBody.email = this.searchEmail;
  }

  axios
    .post(apiUrl, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      console.log("Réponse API:", response.data);

      if (response.data && response.data.contacts && Array.isArray(response.data.contacts)) {
        // Mettre à jour toutes les données de contact avec la réponse de l'API
        this.allContactsData = response.data.contacts;

        if (isEmailSearch && this.searchEmail) {
          // Vérifier si l'email est dans les données nouvellement récupérées
          const emailExistsInFetchedData = this.allContactsData.some(contact =>
            contact.email.toLowerCase() === this.searchEmail.toLowerCase()
          );

          if (emailExistsInFetchedData) {
            this.contactsData = this.allContactsData.filter(contact =>
              contact.email.toLowerCase() === this.searchEmail.toLowerCase()
            );
          } else {
            // Gérer le cas où l'email n'est pas trouvé dans la réponse de l'API
            console.warn("Email not found in the API response.");
            this.contactsData = []; // Effacer les données ou gérer l'état vide
          }
        } else {
          // Pas de recherche spécifique par email, utiliser tous les contacts récupérés
          this.contactsData = [...this.allContactsData];

        }

        // Limiter les résultats paginés
        this.contactsData = this.contactsData.slice(0, this.pagination.pageSize);
        this.updatePagination();
        this.paginateContacts();
      } else {
        console.error("Invalid response structure:", response.data);
      }
      this.lowder = true;
    })
    .catch((error) => {
      this.lowder = true;
      console.error("Erreur lors de la récupération des contacts :", error);
    });
},





  updatePagination() {
      if (this.allContactsData && Array.isArray(this.allContactsData)) {
        // Calculate the total number of items for pagination
        this.pagination.total = this.allContactsData.length;
      } else {
        console.error("No data available to update pagination.");
      }
    },


    handlePageChange(newPage) {
      // Update the current page and paginate the data
      this.pagination.currentPage = newPage;
      this.paginateContacts();
    },
    /* handlePerPageChange(newSize) {
    this.pagination.currentPage = 1;
    this.paginateContacts(); // Adjust pagination logic
  },*/



/* handlePageChange(page) {
    console.log("Page changée:", page);  // Vérifie si le changement de page se produit
    this.pagination.currentPage = page;
    this.fetchContacts();  // Appelle fetchContacts après la mise à jour de la page
  },*/


  formatDate1(dateProp) {
    return (row, column, cellValue, index) => {
      if (!cellValue) return '';
      const [year, month, day] = cellValue.split('-');
      return `${day}-${month}-${year}`;
    };
  },
  formatDate2(dateProp) {
    return (row, column, cellValue, index) => {
      if (!cellValue) return '';
      // Split the date and time if present
      const [datePart, timePart] = cellValue.split(' '); // Split into date and time
      const [year, month, day] = datePart.split('-');
      return `${day}-${month}-${year} ${timePart}`;
    };
  },


        updateSelections(index, product, type_op) {
            switch (index) {
                case 1:
                    this.selectedProduct1 = product;
                    this.selectedOperation1 = type_op;
                  //  console.log("selectedOperation1",selectedOperation1)
                    break;
                case 2:
                    this.selectedProduct2 = product;
                    this.selectedOperation2 = type_op;
                    break;
                case 3:
                    this.selectedProduct3 = product;
                    this.selectedOperation3 = type_op;
                    break;
                case 4:
                    this.selectedProduct4 = product;
                    this.selectedOperation4 = type_op;
                    break;
                case 5:
                    this.selectedProduct5 = product;
                    this.selectedOperation5 = type_op;
                    break;
                case 6:
                    this.selectedProduct6 = product;
                    this.selectedOperation6 = type_op;
                    break;
            }
        },

    loadDates() {
      const currentYear = new Date().getFullYear(); // Obtient l'année en cours
      const startYear = 2022;
      const years = [];

      for (let year = startYear; year <= currentYear; year++) {
        years.push(year.toString()); // Convertit l'année en chaîne de caractères
      }

      this.dates.listeDates = years;
      console.log(years)
      this.dates.selectedYear = currentYear.toString();

    },

    handleYearChange() {
        console.log("fetchGraphData1")
        this.getGainAndSpend();
        this. fetchContacts();

 },

     handleChange(){
        this.selectedProduct1 = this.model.product1;
        this.selectedOperation1 = this.model.operation1;
        this.fetchGraphData1();
        this.selectedProduct2 = this.model.product2;
        this.selectedOperation2 = this.model.operation2;
        this.fetchGraphData2();
        this.selectedProduct3 = this.model.product3;
        this.selectedOperation3 = this.model.operation3;
        this.fetchGraphData3();
        this.selectedProduct4 = this.model.product4;
        this.selectedOperation4 = this.model.operation4;
        this.fetchGraphData4();
        this.selectedProduct5 = this.model.product5;
        this.selectedOperation5 = this.model.operation5;
        this.fetchGraphData5();
        this.selectedProduct6 = this.model.product6;
        this.selectedOperation6 = this.model.operation6;
        this.fetchGraphData6();

     },
getFilteredChartData1(chartDataKey, selectedProduct1, selectedOperation1) {
    console.log('getFilteredChartData1')
  // Define the date range for the current year
    const startOfYear = moment(`${this.dates.selectedYear}-01-01`);
    const endOfYear = moment(`${this.dates.selectedYear}-12-31`);

  // Normalize the selected values
  const selectedProductNormalized1 = (selectedProduct1 && selectedProduct1.trim().toLowerCase()) || '';
  const selectedOperationNormalized1 = (selectedOperation1 && selectedOperation1.trim().toLowerCase()) || '';


 // console.log("Selected Product:", selectedProductNormalized1);
 // console.log("Selected Operation:", selectedOperationNormalized1);

  // Filter contacts based on product and operation
  const filteredContacts1 = this.contacts.filter((contact) => {
    const contactDate = moment(contact.date, 'YYYY-MM');
    //const isWithinCurrentYear = true//contactDate.isBetween(startOfYear, endOfYear, 'month', '[)');
    const contactProductNormalized = (contact.product && contact.product.trim().toLowerCase()) || '';
    const contactOperationNormalized = (contact.type_op && contact.type_op.trim().toLowerCase()) || '';

    const matchesProduct =
      selectedProductNormalized1 === '' ||
      selectedProductNormalized1 === 'tous' ||
      contactProductNormalized === selectedProductNormalized1;

    const matchesOperation =
      selectedOperationNormalized1 === '' ||
      selectedOperationNormalized1 === 'tout' ||
      contactOperationNormalized === selectedOperationNormalized1;

    return /* isWithinCurrentYear &&  */matchesProduct && matchesOperation;
  });


  // Log filtered contacts to debug
 console.log("Filtered Contacts1:", filteredContacts1);

  // Prepare data for charts
  const labels1 = [];
  const CACData1 = [];
  const LTVData1 = [];
  const LTV_CACData1 = [];

  let currentMonth = startOfYear.clone();
  while (currentMonth.isSameOrBefore(endOfYear, 'month')) {
    const monthKey = currentMonth.format('YYYY-MM');

    // Filter data for the current month
    const monthData = filteredContacts1.filter(
      (contact) => moment(contact.date, 'YYYY-MM').format('YYYY-MM') === monthKey
    );
    //console.log('monthData', monthData)

    let totalCAC = 0;
    let totalLTV = 0;

    monthData.forEach((contact) => {
      totalCAC += parseFloat(contact.total_depenses) || 0;
      totalLTV += parseFloat(contact.total_gains) || 0;
    });

    if (totalCAC > 0 || totalLTV > 0) {
      labels1.push(monthKey);
      CACData1.push(totalCAC);
      LTVData1.push(totalLTV);
      LTV_CACData1.push(totalCAC === 0 ? 0 : totalLTV / totalCAC);
    }

    currentMonth.add(1, 'month');
  }

  // Return formatted data for the chart
  return {
    labels: labels1,
    datasets: [
      {
        type: "bar",
        label: "Coût d'Acquisition Client (CAC)",
        backgroundColor: "rgba(	241, 143, 1)",
        borderColor: "rgb(241, 143, 1)",
        borderWidth: 2,
        shadowColor: "rgba(0, 0, 0, 0.2)", // Couleur de l'ombre
        shadowBlur: 10, // Flou de l'ombre
        shadowOffsetX: 3, // Décalage horizontal de l'ombre
        shadowOffsetY: 3, // Décalage vertical de l'ombre
        borderWidth: 1,
        data: CACData1,
        order: 2,
      },
      {
        type: "bar",
        label: "Lifetime Value (LTV)",
        backgroundColor: "rgba(	4, 139, 168)",
        borderColor: "rgb(1.2, 37.3, 56.1)",
        borderWidth: 2,
        shadowColor: "rgba(0, 0, 0, 0.2)", // Couleur de l'ombre
        shadowBlur: 10, // Flou de l'ombre
        shadowOffsetX: 3, // Décalage horizontal de l'ombre
        shadowOffsetY: 3, // Décalage vertical de l'ombre
        borderWidth: 1,
        data: LTVData1,
        order: 2,
      },
      {
        type: "line",
        label: "LTV/CAC",
        borderColor: "rgba(220, 218, 300, 0.25)",
        fill: false,
        data: LTV_CACData1,
        yAxisID: "y-axis-2",
        order: 1,
      },
      {
  type: "line",
  label: "Repère",
  borderColor: "red",
  borderWidth: 2, // Thicker line for better visibility
  borderDash: [5, 5], // Dashed line style
  pointRadius: 0, // Remove points on the line
  data: new Array(labels1.length).fill(1.0), // Create an array with 1.0 for each label
  yAxisID: "y-axis-2",
  order: 0,
  lineTension: 0, // Straight lines instead of curves
  borderJoinStyle: 'miter', // Rounded line joins
  borderCapStyle: 'round', // Rounded line caps
},
    ],
  };
},

getFilteredChartData2(chartDataKey2, selectedProduct2, selectedOperation2) {
    // Définir la plage de dates pour l'année en cours
    const startOfYear = moment(`${this.dates.selectedYear}-01-01`);
    const endOfYear = moment(`${this.dates.selectedYear}-12-31`);

    // Normaliser les valeurs sélectionnées
    const selectedProductNormalized2 = (selectedProduct2 && selectedProduct2.trim().toLowerCase()) || '';
    const selectedOperationNormalized2 = (selectedOperation2 && selectedOperation2.trim().toLowerCase()) || '';

    // Filtrer les contacts en fonction des sélections
    const filteredContacts2 = this.contacts.filter((contact) => {
        const contactDate = moment(contact.date, 'YYYY-MM');
        //const isWithinCurrentYear = contactDate.isBetween(startOfYear, endOfYear, 'month', '[)');

        const contactProductNormalized = (contact.product && contact.product.trim().toLowerCase()) || '';
        const contactOperationNormalized = (contact.type_op && contact.type_op.trim().toLowerCase()) || '';

        const matchesProduct =
            selectedProductNormalized2 === '' ||
            selectedProductNormalized2 === 'tous' ||
            contactProductNormalized === selectedProductNormalized2;

        const matchesOperation =
            selectedOperationNormalized2 === '' ||
            selectedOperationNormalized2 === 'tout' ||
            contactOperationNormalized === selectedOperationNormalized2;

        return /* isWithinCurrentYear &&  */matchesProduct && matchesOperation;
    });
    //console.log("Filtered Contacts2:", filteredContacts2);

    // Préparer les données pour les graphiques
    const labels2 = [];
    const CACData2 = [];
    const LTVData2 = [];
    const LTV_CACData2 = [];

    let currentMonth = startOfYear.clone();
    while (currentMonth.isSameOrBefore(endOfYear, 'month')) {
        const monthKey = currentMonth.format('YYYY-MM');

        // Filtrer les données pour le mois courant
        const monthData = filteredContacts2.filter(
            (contact) => moment(contact.date, 'YYYY-MM').format('YYYY-MM') === monthKey
        );

        const totalCAC = monthData.reduce(
            (acc, contact) => acc + (parseFloat(contact.total_depenses) || 0),
            0
        );
        const totalLTV = monthData.reduce(
            (acc, contact) => acc + (parseFloat(contact.total_gains) || 0),
            0
        );

        if (totalCAC > 0 || totalLTV > 0) {
            labels2.push(monthKey);
            CACData2.push(totalCAC);
            LTVData2.push(totalLTV);
            LTV_CACData2.push(totalCAC === 0 ? 0 : totalLTV / totalCAC);
        }

        currentMonth.add(1, 'month');
    }

    // Retourner les données formatées pour le graphique
    return {
        labels: labels2,
        datasets: [
            {
                type: "bar",
                label: "Coût d'Acquisition Client (CAC)",
                backgroundColor: "rgba(241, 143, 1)",
                borderColor: "rgb(241, 143, 1)",
                borderWidth: 2,
                shadowColor: "rgba(0, 0, 0, 0.2)", // Couleur de l'ombre
                shadowBlur: 10, // Flou de l'ombre
                shadowOffsetX: 3, // Décalage horizontal de l'ombre
                shadowOffsetY: 3, // Décalage vertical de l'ombre
                borderWidth: 1,
                data: CACData2,
                order: 2,
            },
            {
                type: "bar",
                label: "Lifetime Value (LTV)",
                backgroundColor: "rgba(	4, 139, 168)",
                borderColor: "rgb(1.2, 37.3, 56.1)",
                borderWidth: 2,
                shadowColor: "rgba(0, 0, 0, 0.2)", // Couleur de l'ombre
                shadowBlur: 10, // Flou de l'ombre
                shadowOffsetX: 3, // Décalage horizontal de l'ombre
                shadowOffsetY: 3, // Décalage vertical de l'ombre
                borderWidth: 1,
                data: LTVData2,
                order: 2,
            },
            {
                type: "line",
                label: "LTV/CAC",
                borderColor: "rgba(220, 218, 300, 0.25)",
                fill: false,
                data: LTV_CACData2,
                yAxisID: "y-axis-2",
                order: 1,
            },
            {
  type: "line",
  label: "Fixed Line",
  borderColor: "red",
  borderWidth: 2, // Thicker line for better visibility
  borderDash: [5, 5], // Dashed line style
  pointRadius: 0, // Remove points on the line
  data: new Array(labels2.length).fill(1.0), // Create an array with 1.0 for each label
  yAxisID: "y-axis-2",
  order: 0,
  lineTension: 0, // Straight lines instead of curves
  borderJoinStyle: 'round', // Rounded line joins
  borderCapStyle: 'round', // Rounded line caps
},
        ],
    };
},

getFilteredChartData3(chartDataKey3, selectedProduct3, selectedOperation3) {
    // Définir la plage de dates pour l'année en cours
    const startOfYear = moment(`${this.dates.selectedYear}-01-01`);
    const endOfYear = moment(`${this.dates.selectedYear}-12-31`);

    // Normaliser les valeurs sélectionnées
    const selectedProductNormalized3 = (selectedProduct3 && selectedProduct3.trim().toLowerCase()) || '';
    const selectedOperationNormalized3 = (selectedOperation3 && selectedOperation3.trim().toLowerCase()) || '';

    // Filtrer les contacts en fonction des sélections
    const filteredContacts3 = this.contacts.filter((contact) => {
        const contactDate = moment(contact.date, 'YYYY-MM');
        //const isWithinCurrentYear = contactDate.isBetween(startOfYear, endOfYear, 'month', '[)');

        const contactProductNormalized = (contact.product && contact.product.trim().toLowerCase()) || '';
        const contactOperationNormalized = (contact.type_op && contact.type_op.trim().toLowerCase()) || '';

        const matchesProduct =
            selectedProductNormalized3 === '' ||
            selectedProductNormalized3 === 'tous' ||
            contactProductNormalized === selectedProductNormalized3;

        const matchesOperation =
            selectedOperationNormalized3 === '' ||
            selectedOperationNormalized3 === 'tout' ||
            contactOperationNormalized === selectedOperationNormalized3;

        return /* isWithinCurrentYear &&  */matchesProduct && matchesOperation;
    });
   // console.log("Filtered Contacts3:", filteredContacts3);

    // Préparer les données pour les graphiques
    const labels3 = [];
    const CACData3 = [];
    const LTVData3 = [];
    const LTV_CACData3 = [];

    let currentMonth = startOfYear.clone();
    while (currentMonth.isSameOrBefore(endOfYear, 'month')) {
        const monthKey = currentMonth.format('YYYY-MM');

        // Filtrer les données pour le mois courant
        const monthData = filteredContacts3.filter(
            (contact) => moment(contact.date, 'YYYY-MM').format('YYYY-MM') === monthKey
        );

        const totalCAC = monthData.reduce(
            (acc, contact) => acc + (parseFloat(contact.total_depenses) || 0),
            0
        );
        const totalLTV = monthData.reduce(
            (acc, contact) => acc + (parseFloat(contact.total_gains) || 0),
            0
        );

        if (totalCAC > 0 || totalLTV > 0) {
            labels3.push(monthKey);
            CACData3.push(totalCAC);
            LTVData3.push(totalLTV);
            LTV_CACData3.push(totalCAC === 0 ? 0 : totalLTV / totalCAC);
        }

        currentMonth.add(1, 'month');
    }

    // Retourner les données formatées pour le graphique
    return {
        labels: labels3,
        datasets: [
            {
                type: "bar",
                label: "Coût d'Acquisition Client (CAC)",
                backgroundColor: "rgba(241, 143, 1)",
                borderColor: "rgb(241, 143, 1)",
                borderWidth: 2,
                shadowColor: "rgba(0, 0, 0, 0.2)",
                shadowBlur: 10,
                shadowOffsetX: 3,
                shadowOffsetY: 3,
                borderWidth: 1,
                data: CACData3,
                order: 2,
            },
            {
                type: "bar",
                label: "Lifetime Value (LTV)",
                backgroundColor: "rgba(	4, 139, 168)",
                borderColor: "rgb(1.2, 37.3, 56.1)",
                borderWidth: 2,
                shadowColor: "rgba(0, 0, 0, 0.2)",
                shadowBlur: 10,
                shadowOffsetX: 3,
                shadowOffsetY: 3,
                borderWidth: 1,
                data: LTVData3,
                order: 2,
            },
            {
                type: "line",
                label: "LTV/CAC",
                borderColor: "rgba(220, 218, 300, 0.25)",
                fill: false,
                data: LTV_CACData3,
                yAxisID: "y-axis-2",
                order: 1,
            },
            {
  type: "line",
  label: "Fixed Line",
  borderColor: "red",
  borderWidth: 2, // Thicker line for better visibility
  borderDash: [5, 5], // Dashed line style
  pointRadius: 0, // Remove points on the line
  data: new Array(labels3.length).fill(1.0), // Create an array with 1.0 for each label
  yAxisID: "y-axis-2",
  order: 0,
  lineTension: 0, // Straight lines instead of curves
  borderJoinStyle: 'round', // Rounded line joins
  borderCapStyle: 'round', // Rounded line caps
},
        ],
    };
},

getFilteredChartData4(chartDataKey4, selectedProduct4, selectedOperation4) {
    // Définir la plage de dates pour l'année en cours
    const startOfYear = moment(`${this.dates.selectedYear}-01-01`);
    const endOfYear = moment(`${this.dates.selectedYear}-12-31`);
    // Normaliser les valeurs sélectionnées
    const selectedProductNormalized4 = (selectedProduct4 && selectedProduct4.trim().toLowerCase()) || '';
    const selectedOperationNormalized4 = (selectedOperation4 && selectedOperation4.trim().toLowerCase()) || '';

    // Filtrer les contacts en fonction des sélections
    const filteredContacts4 = this.contacts.filter((contact) => {
        const contactDate = moment(contact.date, 'YYYY-MM');
        //const isWithinCurrentYear = contactDate.isBetween(startOfYear, endOfYear, 'month', '[)');

        const contactProductNormalized = (contact.product && contact.product.trim().toLowerCase()) || '';
        const contactOperationNormalized = (contact.type_op && contact.type_op.trim().toLowerCase()) || '';

        const matchesProduct =
            selectedProductNormalized4 === '' ||
            selectedProductNormalized4 === 'tous' ||
            contactProductNormalized === selectedProductNormalized4;

        const matchesOperation =
            selectedOperationNormalized4 === '' ||
            selectedOperationNormalized4 === 'tout' ||
            contactOperationNormalized === selectedOperationNormalized4;

        return /*isWithinCurrentYear &&*/ matchesProduct && matchesOperation;
    });
   // console.log("Filtered Contacts4:", filteredContacts4);

    // Préparer les données pour les graphiques
    const labels4 = [];
    const CACData4 = [];
    const LTVData4 = [];
    const LTV_CACData4 = [];

    let currentMonth = startOfYear.clone();
    while (currentMonth.isSameOrBefore(endOfYear, 'month')) {
        const monthKey = currentMonth.format('YYYY-MM');

        // Filtrer les données pour le mois courant
        const monthData = filteredContacts4.filter(
            (contact) => moment(contact.date, 'YYYY-MM').format('YYYY-MM') === monthKey
        );

        const totalCAC = monthData.reduce(
            (acc, contact) => acc + (parseFloat(contact.total_depenses) || 0),
            0
        );
        const totalLTV = monthData.reduce(
            (acc, contact) => acc + (parseFloat(contact.total_gains) || 0),
            0
        );

        if (totalCAC > 0 || totalLTV > 0) {
            labels4.push(monthKey);
            CACData4.push(totalCAC);
            LTVData4.push(totalLTV);
            LTV_CACData4.push(totalCAC === 0 ? 0 : totalLTV / totalCAC);
        }

        currentMonth.add(1, 'month');
    }

    // Retourner les données formatées pour le graphique
    return {
        labels: labels4,
        datasets: [
            {
                type: "bar",
                label: "Coût d'Acquisition Client (CAC)",
                backgroundColor: "rgba(241, 143, 1)",
                borderColor: "rgb(241, 143, 1)",
                borderWidth: 2,
                shadowColor: "rgba(0, 0, 0, 0.2)", // Couleur de l'ombre
                shadowBlur: 10, // Flou de l'ombre
                shadowOffsetX: 3, // Décalage horizontal de l'ombre
                shadowOffsetY: 3, // Décalage vertical de l'ombre
                borderWidth: 1,
                data: CACData4,
                order: 2,
            },
            {
                type: "bar",
                label: "Lifetime Value (LTV)",
                backgroundColor: "rgba(	4, 139, 168)",
                borderColor: "rgb(1.2, 37.3, 56.1)",
                borderWidth: 2,
                shadowColor: "rgba(0, 0, 0, 0.2)", // Couleur de l'ombre
                shadowBlur: 10, // Flou de l'ombre
                shadowOffsetX: 3, // Décalage horizontal de l'ombre
                shadowOffsetY: 3, // Décalage vertical de l'ombre
                borderWidth: 1,
                data: LTVData4,
                order: 2,
            },
            {
                type: "line",
                label: "LTV/CAC",
                borderColor: "rgba(220, 218, 300, 0.25)",
                fill: false,
                data: LTV_CACData4,
                yAxisID: "y-axis-2",
                order: 1,
            },
            {
  type: "line",
  label: "Fixed Line",
  borderColor: "red",
  borderWidth: 2, // Thicker line for better visibility
  borderDash: [5, 5], // Dashed line style
  pointRadius: 0, // Remove points on the line
  data: new Array(labels4.length).fill(1.0), // Create an array with 1.0 for each label
  yAxisID: "y-axis-2",
  order: 0,
  lineTension: 0, // Straight lines instead of curves
  borderJoinStyle: 'round', // Rounded line joins
  borderCapStyle: 'round', // Rounded line caps
},
        ],
    };
},
getFilteredChartData5(chartDataKey5, selectedProduct5, selectedOperation5) {
    // Définir la plage de dates pour l'année en cours
    const startOfYear = moment(`${this.dates.selectedYear}-01-01`);
    const endOfYear = moment(`${this.dates.selectedYear}-12-31`);

    // Normaliser les valeurs sélectionnées
    const selectedProductNormalized5 = (selectedProduct5 && selectedProduct5.trim().toLowerCase()) || '';
    const selectedOperationNormalized5 = (selectedOperation5 && selectedOperation5.trim().toLowerCase()) || '';

    // Filtrer les contacts en fonction des sélections
    const filteredContacts5 = this.contacts5.filter((contact) => {
        const contactDate = moment(contact.date, 'YYYY-MM');

        const contactProductNormalized = (contact.product && contact.product.trim().toLowerCase()) || '';
        const contactOperationNormalized = (contact.type_op && contact.type_op.trim().toLowerCase()) || '';

        const matchesProduct =
            selectedProductNormalized5 === '' ||
            selectedProductNormalized5 === 'tous' ||
            contactProductNormalized === selectedProductNormalized5;

        const matchesOperation =
            selectedOperationNormalized5 === '' ||
            selectedOperationNormalized5 === 'tout' ||
            contactOperationNormalized === selectedOperationNormalized5;

        return matchesProduct && matchesOperation;
    });

    // Préparer les données pour les graphiques
    const labels5 = [];
    const CACData5 = [];
    const LTVData5 = [];
    const LTV_CACData5 = [];

    let currentMonth = startOfYear.clone();
    while (currentMonth.isSameOrBefore(endOfYear, 'month')) {
        const monthKey = currentMonth.format('YYYY-MM');

        const monthData = filteredContacts5.filter(
            (contact) => moment(contact.date, 'YYYY-MM').format('YYYY-MM') === monthKey
        );

        const totalCAC = monthData.reduce(
            (acc, contact) => acc + (parseFloat(contact.total_depenses) || 0),
            0
        );
        const totalLTV = monthData.reduce(
            (acc, contact) => acc + (parseFloat(contact.total_gains) || 0),
            0
        );

        if (totalCAC > 0 || totalLTV > 0) {
            labels5.push(monthKey);
            CACData5.push(totalCAC);
            LTVData5.push(totalLTV);
            LTV_CACData5.push(totalCAC === 0 ? 0 : totalLTV / totalCAC);
        }

        currentMonth.add(1, 'month');
    }

    // Retourner les données formatées pour le graphique
    return {
        labels: labels5,
        datasets: [
            {
                type: "bar",
                label: "Coût d'Acquisition Client (CAC)",
                backgroundColor: "rgba(241, 143, 1)",
                borderColor: "rgb(241, 143, 1)",
                borderWidth: 2,
                shadowColor: "rgba(0, 0, 0, 0.2)",
                shadowBlur: 10,
                shadowOffsetX: 3,
                shadowOffsetY: 3,
                borderWidth: 1,
                data: CACData5,
                order: 2,
            },
            {
                type: "bar",
                label: "Lifetime Value (LTV)",
                backgroundColor: "rgba(4, 139, 168)",
                borderColor: "rgb(1.2, 37.3, 56.1)",
                borderWidth: 2,
                shadowColor: "rgba(0, 0, 0, 0.2)",
                shadowBlur: 10,
                shadowOffsetX: 3,
                shadowOffsetY: 3,
                borderWidth: 1,
                data: LTVData5,
                order: 2,
            },
            {
                type: "line",
                label: "LTV/CAC",
                borderColor: "rgba(220, 218, 300, 0.25)",
                fill: false,
                data: LTV_CACData5,
                yAxisID: "y-axis-2",
                order: 1,
            },
            {
  type: "line",
  label: "Fixed Line",
  borderColor: "red",
  borderWidth: 2, // Thicker line for better visibility
  borderDash: [5, 5], // Dashed line style
  pointRadius: 0, // Remove points on the line
  data: new Array(labels5.length).fill(1.0), // Create an array with 1.0 for each label
  yAxisID: "y-axis-2",
  order: 0,
  lineTension: 0, // Straight lines instead of curves
  borderJoinStyle: 'round', // Rounded line joins
  borderCapStyle: 'round', // Rounded line caps
},
        ],
    };
},
getFilteredChartData6(chartDataKey6, selectedProduct6, selectedOperation6) {
    // Définir la plage de dates pour l'année en cours
    const startOfYear = moment(`${this.dates.selectedYear}-01-01`);
    const endOfYear = moment(`${this.dates.selectedYear}-12-31`);
    // Normaliser les valeurs sélectionnées
    const selectedProductNormalized6 = (selectedProduct6 && selectedProduct6.trim().toLowerCase()) || '';
    const selectedOperationNormalized6 = (selectedOperation6 && selectedOperation6.trim().toLowerCase()) || '';

    // Filtrer les contacts en fonction des sélections
    const filteredContacts6 = this.contacts.filter((contact) => {
        const contactDate = moment(contact.date, 'YYYY-MM');
        //const isWithinCurrentYear = contactDate.isBetween(startOfYear, endOfYear, 'month', '[)');

        const contactProductNormalized = (contact.product && contact.product.trim().toLowerCase()) || '';
        const contactOperationNormalized = (contact.type_op && contact.type_op.trim().toLowerCase()) || '';

        const matchesProduct =
            selectedProductNormalized6 === '' ||
            selectedProductNormalized6 === 'tous' ||
            contactProductNormalized === selectedProductNormalized6;

        const matchesOperation =
            selectedOperationNormalized6 === '' ||
            selectedOperationNormalized6 === 'tout' ||
            contactOperationNormalized === selectedOperationNormalized6;

        return /*isWithinCurrentYear &&*/ matchesProduct && matchesOperation;
    });
   // console.log("Filtered Contacts6:", filteredContacts6);

    // Préparer les données pour les graphiques
    const labels6 = [];
    const CACData6 = [];
    const LTVData6 = [];
    const LTV_CACData6 = [];

    let currentMonth = startOfYear.clone();
    while (currentMonth.isSameOrBefore(endOfYear, 'month')) {
        const monthKey = currentMonth.format('YYYY-MM');

        // Filtrer les données pour le mois courant
        const monthData = filteredContacts6.filter(
            (contact) => moment(contact.date, 'YYYY-MM').format('YYYY-MM') === monthKey
        );

        const totalCAC = monthData.reduce(
            (acc, contact) => acc + (parseFloat(contact.total_depenses) || 0),
            0
        );
        const totalLTV = monthData.reduce(
            (acc, contact) => acc + (parseFloat(contact.total_gains) || 0),
            0
        );

        if (totalCAC > 0 || totalLTV > 0) {
            labels6.push(monthKey);
            CACData6.push(totalCAC);
            LTVData6.push(totalLTV);
            LTV_CACData6.push(totalCAC === 0 ? 0 : totalLTV / totalCAC);
        }

        currentMonth.add(1, 'month');
    }

    // Retourner les données formatées pour le graphique
    return {
        labels: labels6,
        datasets: [
            {
                type: "bar",
                label: "Coût d'Acquisition Client (CAC)",
                backgroundColor: "rgba(241, 143, 1)",
                borderColor: "rgb(241, 143, 1)",
                borderWidth: 2,
                shadowColor: "rgba(0, 0, 0, 0.2)", // Couleur de l'ombre
                shadowBlur: 10, // Flou de l'ombre
                shadowOffsetX: 3, // Décalage horizontal de l'ombre
                shadowOffsetY: 3, // Décalage vertical de l'ombre
                borderWidth: 1,
                data: CACData6,
                order: 2,
            },
            {
                type: "bar",
                label: "Lifetime Value (LTV)",
                backgroundColor: "rgba(	4, 139, 168)",
                borderColor: "rgb(1.2, 37.3, 56.1)",
                borderWidth: 2,
                shadowColor: "rgba(0, 0, 0, 0.2)", // Couleur de l'ombre
                shadowBlur: 10, // Flou de l'ombre
                shadowOffsetX: 3, // Décalage horizontal de l'ombre
                shadowOffsetY: 3, // Décalage vertical de l'ombre
                borderWidth: 1,
                data: LTVData6,
                order: 2,
            },
            {
                type: "line",
                label: "LTV/CAC",
                borderColor: "rgba(220, 218, 300, 0.25)",
                fill: false,
                data: LTV_CACData6,
                yAxisID: "y-axis-2",
                order: 1,
            },
            {
  type: "line",
  label: "Fixed Line",
  borderColor: "red",
  borderWidth: 2, // Thicker line for better visibility
  borderDash: [5, 5], // Dashed line style
  pointRadius: 0, // Remove points on the line
  data: new Array(labels6.length).fill(1.0), // Create an array with 1.0 for each label
  yAxisID: "y-axis-2",
  order: 0,
  lineTension: 0, // Straight lines instead of curves
  borderJoinStyle: 'round', // Rounded line joins
  borderCapStyle: 'round', // Rounded line caps
} ,
        ],
    };
},

searchByEmail() {
  this.pagination.currentPage = 1; // Réinitialiser à la première page lors de la recherche

  console.log("Searching for email:", this.searchEmail);

  if (this.searchEmail) {
    // Filtrer les contacts par email
    this.contactsData = this.allContactsData.filter(contact =>
      contact.email.toLowerCase().includes(this.searchEmail.toLowerCase())
    );
  } else {
    // Si le champ de recherche est vide, réinitialiser les données paginées
    this.contactsData = [...this.allContactsData];
  }

  // Mettre à jour la pagination pour prendre en compte toutes les données filtrées ou réinitialisées
  this.updatePagination();

  // Paginer les données pour afficher uniquement les éléments de la page actuelle
  this.paginateContacts();
},









       /* filterContacts() {
          this.pagination.currentPage = 1; // Reset to the first page on filter change
          this.fetchContacts(); // Fetch filtered contacts
        },*/
    },

    mounted() {
        this.getGainAndSpend();
        this.loadDates();
        this.filteredContacts = this.leads;
        this.pagination.total = this.filteredContacts.length;
        this.fetchContacts();

    },
    created() {
        this.loading = true;
       /*  this.fetchGraphData1();
        this.fetchGraphData2();
        this.fetchGraphData3();
        this.fetchGraphData4(); */
       // this.fetchContacts();
    },

    watch: {
       /* searchEmail() {
        this.searchByEmail(); // Appeler la recherche lorsqu'un email est entré
       },*/

      // Watcher pour les changements de page dans la pagination
      /* 'pagination.currentPage': function (newPage) {
        // Lorsqu'une nouvelle page est sélectionnée, récupérez les contacts pour cette page
        this.paginateContacts();
       },*/

      // Watcher pour d'autres modifications de pagination si nécessaire (ex: nombre d'éléments par page)
      /* 'pagination.perPage': function (newSize) {
        // Réinitialiser à la première page lorsque la taille de la pagination change
        this.pagination.currentPage = 1;
        this.fetchContacts();
        }*/
    },
};
</script>

<style scoped>
.content {
    padding: 20px;
}

.card-chart {
    margin: 20px 0;
}
.chart-container {
    margin-bottom: 20px;
    margin-left: 40px;
    margin-right: 20px;/* Adjust the margin as needed */
   /* background-color: #171c2b;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(24, 21, 21, 0.1); */
}
#card-body1{
    margin-left: 19.05px;
    margin-right: 0.5px;
}
#card-body2{
    margin-left:0.5px;
    margin-right:19.05px;
}
#card-bodydate{
    margin-left: 0.5px;

}


.chart-area {
    width: 100%;
    height: 300px;
    position: relative;
}

.mix {
    width: 100%;
    height: 100%;
}
#couleur{
    background-color:#d0d0e9;
}
.loading-container {
  position: relative;
}

.el-icon-circle-close {
    cursor: pointer;
    color: #b5b9c2;
}
</style>
