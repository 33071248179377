<template>
  <card>
    <div class="row">
      <div class="col-12 text-left">
        <base-button size="sm" type="info" @click="ExportTable()" class="float-right" :disabled='loading'>
          <i class="tim-icons icon-cloud-download-93"></i> EXPORT
        </base-button>
      </div>
    </div>
    <el-table :data="dataCaClientT.filter(obj => { return (idUsers && this.search) ? idUsers.includes(obj.id_user) : true})"
      v-loading="loading" show-summary :summary-method="getSummaries" row-class-name="booking-table-rows">
      <el-table-column
        label= "#"
        min-width="60"
      >
        <template scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        prop='society'
        label="Editeur"
        minWidth='140'
        show-overflow-tooltip
        sortable
        :sort-method="CustomSort_Society"
      >
      </el-table-column>
      <el-table-column
        prop='ca_Global_m1'
        :label="labelM1"
        minWidth='110'
        show-overflow-tooltip
        sortable
      >
        <template scope="scope">
          {{ formatThounsends(scope.row.ca_Global_m1,true,2) + ' €' }}
        </template>
      </el-table-column>
      <el-table-column
        prop='ca_Global_m2'
        :label="labelM2"
        minWidth='200'
        show-overflow-tooltip
        sortable
      >
        <template scope="scope">
          {{ formatThounsends(scope.row.ca_Global_m2,true,2) + ' €' }}
          ( {{ formatThounsends(CaEvolution(scope.row.ca_Global_m2, scope.row.ca_Global_m1),true,0) + " %" }})
          <template v-if="CaEvolution(scope.row.ca_Global_m2, scope.row.ca_Global_m1) < 0">
            <i class="fas fa-arrow-down" style="color: red"></i>
          </template>
          <template v-else>
            <i class="fas fa-arrow-up"  style="color: green"></i>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop='ca_Global_m3'
        :label="labelM3"
        minWidth='200'
        show-overflow-tooltip
        sortable
      >
        <template scope="scope">
          {{ formatThounsends(scope.row.ca_Global_m3,true,2) + ' €' }}
          ( {{ formatThounsends(CaEvolution(scope.row.ca_Global_m3, scope.row.ca_Global_m2),true,0) + " %" }})
          <template v-if="CaEvolution(scope.row.ca_Global_m3, scope.row.ca_Global_m2) < 0">
            <i class="fas fa-arrow-down" style="color: red"></i>
          </template>
          <template v-else>
            <i class="fas fa-arrow-up"  style="color: green"></i>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop='ca_Global_m4'
        :label="labelM4"
        minWidth='200'
        show-overflow-tooltip
        sortable
        v-if="AllYear"
      >
        <template scope="scope">
          {{ formatThounsends(scope.row.ca_Global_m4,true,2) + ' €' }}
          ( {{ formatThounsends(CaEvolution(scope.row.ca_Global_m4, scope.row.ca_Global_m3),true,0) + " %" }})
          <template v-if="CaEvolution(scope.row.ca_Global_m4, scope.row.ca_Global_m3) < 0">
            <i class="fas fa-arrow-down" style="color: red"></i>
          </template>
          <template v-else>
            <i class="fas fa-arrow-up"  style="color: green"></i>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop='ca_Global_m5'
        :label="labelM5"
        minWidth='200'
        show-overflow-tooltip
        sortable
        v-if="AllYear"
      >
        <template scope="scope">
          {{ formatThounsends(scope.row.ca_Global_m5,true,2) + ' €' }}
          ( {{ formatThounsends(CaEvolution(scope.row.ca_Global_m5, scope.row.ca_Global_m4),true,0) + " %" }})
          <template v-if="CaEvolution(scope.row.ca_Global_m5, scope.row.ca_Global_m4) < 0">
            <i class="fas fa-arrow-down" style="color: red"></i>
          </template>
          <template v-else>
            <i class="fas fa-arrow-up"  style="color: green"></i>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop='ca_Global_m6'
        :label="labelM6"
        minWidth='200'
        show-overflow-tooltip
        sortable
        v-if="AllYear"
      >
        <template scope="scope">
          {{ formatThounsends(scope.row.ca_Global_m6,true,2) + ' €' }}
          ( {{ formatThounsends(CaEvolution(scope.row.ca_Global_m6, scope.row.ca_Global_m5),true,0) + " %" }})
          <template v-if="CaEvolution(scope.row.ca_Global_m6, scope.row.ca_Global_m5) < 0">
            <i class="fas fa-arrow-down" style="color: red"></i>
          </template>
          <template v-else>
            <i class="fas fa-arrow-up"  style="color: green"></i>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop='ca_Global_m7'
        :label="labelM7"
        minWidth='200'
        show-overflow-tooltip
        sortable
        v-if="AllYear"
      >
        <template scope="scope">
          {{ formatThounsends(scope.row.ca_Global_m7,true,2) + ' €' }}
          ( {{ formatThounsends(CaEvolution(scope.row.ca_Global_m7, scope.row.ca_Global_m6),true,0) + " %" }})
          <template v-if="CaEvolution(scope.row.ca_Global_m7, scope.row.ca_Global_m6) < 0">
            <i class="fas fa-arrow-down" style="color: red"></i>
          </template>
          <template v-else>
            <i class="fas fa-arrow-up"  style="color: green"></i>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop='ca_Global_m8'
        :label="labelM8"
        minWidth='200'
        show-overflow-tooltip
        sortable
        v-if="AllYear"
      >
        <template scope="scope">
          {{ formatThounsends(scope.row.ca_Global_m8,true,2) + ' €' }}
          ( {{ formatThounsends(CaEvolution(scope.row.ca_Global_m8, scope.row.ca_Global_m7),true,0) + " %" }})
          <template v-if="CaEvolution(scope.row.ca_Global_m8, scope.row.ca_Global_m7) < 0">
            <i class="fas fa-arrow-down" style="color: red"></i>
          </template>
          <template v-else>
            <i class="fas fa-arrow-up"  style="color: green"></i>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop='ca_Global_m9'
        :label="labelM9"
        minWidth='200'
        show-overflow-tooltip
        sortable
        v-if="AllYear"
      >
        <template scope="scope">
          {{ formatThounsends(scope.row.ca_Global_m9,true,2) + ' €' }}
          ( {{ formatThounsends(CaEvolution(scope.row.ca_Global_m9, scope.row.ca_Global_m8),true,0) + " %" }})
          <template v-if="CaEvolution(scope.row.ca_Global_m9, scope.row.ca_Global_m8) < 0">
            <i class="fas fa-arrow-down" style="color: red"></i>
          </template>
          <template v-else>
            <i class="fas fa-arrow-up"  style="color: green"></i>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop='ca_Global_m10'
        :label="labelM10"
        minWidth='200'
        show-overflow-tooltip
        sortable
        v-if="AllYear"
      >
        <template scope="scope">
          {{ formatThounsends(scope.row.ca_Global_m10,true,2) + ' €' }}
          ( {{ formatThounsends(CaEvolution(scope.row.ca_Global_m10, scope.row.ca_Global_m9),true,0) + " %" }})
          <template v-if="CaEvolution(scope.row.ca_Global_m10, scope.row.ca_Global_m9) < 0">
            <i class="fas fa-arrow-down" style="color: red"></i>
          </template>
          <template v-else>
            <i class="fas fa-arrow-up"  style="color: green"></i>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop='ca_Global_m11'
        :label="labelM11"
        minWidth='200'
        show-overflow-tooltip
        sortable
        v-if="AllYear"
      >
        <template scope="scope">
          {{ formatThounsends(scope.row.ca_Global_m11,true,2) + ' €' }}
          ( {{ formatThounsends(CaEvolution(scope.row.ca_Global_m11, scope.row.ca_Global_m10),true,0) + " %" }})
          <template v-if="CaEvolution(scope.row.ca_Global_m11, scope.row.ca_Global_m10) < 0">
            <i class="fas fa-arrow-down" style="color: red"></i>
          </template>
          <template v-else>
            <i class="fas fa-arrow-up"  style="color: green"></i>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop='ca_Global_m12'
        :label="labelM12"
        minWidth='200'
        show-overflow-tooltip
        sortable
        v-if="AllYear"
      >
        <template scope="scope">
          {{ formatThounsends(scope.row.ca_Global_m12,true,2) + ' €' }}
          ( {{ formatThounsends(CaEvolution(scope.row.ca_Global_m12, scope.row.ca_Global_m11),true,0) + " %" }})
          <template v-if="CaEvolution(scope.row.ca_Global_m12, scope.row.ca_Global_m11) < 0">
            <i class="fas fa-arrow-down" style="color: red"></i>
          </template>
          <template v-else>
            <i class="fas fa-arrow-up"  style="color: green"></i>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop='ca_Global'
        label="SOMME"
        minWidth='120'
        show-overflow-tooltip
        sortable
        :sort-method="CustomSort_CaGlobal"

      >
        <template scope="scope">
          <template v-if="AllYear">
            {{ formatThounsends(scope.row.ca_Global_m1 + scope.row.ca_Global_m2 + scope.row.ca_Global_m3 + scope.row.ca_Global_m4 + scope.row.ca_Global_m5 + scope.row.ca_Global_m6 + scope.row.ca_Global_m7 + scope.row.ca_Global_m8 + scope.row.ca_Global_m9 + scope.row.ca_Global_m10 + scope.row.ca_Global_m11 + scope.row.ca_Global_m12,true,2) + ' €' }}
          </template>
          <template v-else>
            {{ formatThounsends(scope.row.ca_Global_m1 + scope.row.ca_Global_m2 + scope.row.ca_Global_m3,true,2) + ' €' }}
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop='secteur'
        label="THEMATIQUE"
        minWidth='140'
        show-overflow-tooltip
        sortable
        :sort-method="CustomSort_Secteur"
      >
      </el-table-column>
      <el-table-column
        label="Platforme"
        minWidth="150"
      >
        <template scope="scope" v-if="AllYear">
          <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="(scope.row.ca_perf + scope.row.ca_perf_m2 + scope.row.ca_perf_m3 + scope.row.ca_perf_m4 + scope.row.ca_perf_m5 + scope.row.ca_perf_m6 + scope.row.ca_perf_m7 + scope.row.ca_perf_m8 + scope.row.ca_perf_m9 + scope.row.ca_perf_m10 + scope.row.ca_perf_m11 + scope.row.ca_perf_m12) != 0">
            <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
              <g>
                <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                  c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                  h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                  />
              </g>
              <title>WEEDO PERF</title>
            </svg>
          </button>
          <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="(scope.row.ca_coreg + scope.row.ca_coreg_m2 + scope.row.ca_coreg_m3 + scope.row.ca_coreg_m4 + scope.row.ca_coreg_m5 + scope.row.ca_coreg_m6 + scope.row.ca_coreg_m7 + scope.row.ca_coreg_m8 + scope.row.ca_coreg_m9 + scope.row.ca_coreg_m10 + scope.row.ca_coreg_m11 + scope.row.ca_coreg_m12) != 0">
            <svg version="1.1" id="Calque_1" fill="#54c4d5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
              <g>
                <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                  c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                  h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                  />
              </g>
              <title>WEEDO COREG</title>
            </svg>
          </button>
          <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="(scope.row.ca_LM + scope.row.ca_LM_m2 + scope.row.ca_LM_m3 + scope.row.ca_LM_m4 + scope.row.ca_LM_m5 + scope.row.ca_LM_m6 + scope.row.ca_LM_m7 + scope.row.ca_LM_m8 + scope.row.ca_LM_m9 + scope.row.ca_LM_m10 + scope.row.ca_LM_m11 + scope.row.ca_LM_m12) != 0">
            <svg version="1.1" id="Calque_1" fill="#588bbd" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
              <g>
                <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                  c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                  h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                  />
              </g>
              <title>WEEDO MARKET</title>
            </svg>
          </button>
          <template v-if="(scope.row.ca_MG + scope.row.ca_MG_m2 + scope.row.ca_MG_m3 + scope.row.ca_MG_m4 + scope.row.ca_MG_m5 + scope.row.ca_MG_m6 + scope.row.ca_MG_m7 + scope.row.ca_MG_m8 + scope.row.ca_MG_m9 + scope.row.ca_MG_m10 + scope.row.ca_MG_m11 + scope.row.ca_MG_m12) != 0">
            <img src="img/ico-mongustave.png" width="20" height="20" title="MON GUSTAVE"/>
          </template>
        </template>
        <template scope="scope" v-else>
          <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="(scope.row.ca_perf + scope.row.ca_perf_m2 + scope.row.ca_perf_m3) != 0">
            <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
              <g>
                <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                  c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                  h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                  />
              </g>
              <title>WEEDO PERF</title>
            </svg>
          </button>
          <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="(scope.row.ca_coreg + scope.row.ca_coreg_m2 + scope.row.ca_coreg_m3) != 0">
            <svg version="1.1" id="Calque_1" fill="#54c4d5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
              <g>
                <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                  c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                  h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                  />
              </g>
              <title>WEEDO COREG</title>
            </svg>
          </button>
          <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="(scope.row.ca_LM + scope.row.ca_LM_m2 + scope.row.ca_LM_m3) != 0">
            <svg version="1.1" id="Calque_1" fill="#588bbd" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
              <g>
                <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                  c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                  h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                  />
              </g>
              <title>WEEDO MARKET</title>
            </svg>
          </button>
          <template v-if="(scope.row.ca_MG + scope.row.ca_MG_m2 + scope.row.ca_MG_m3) != 0">
            <img src="img/ico-mongustave.png" width="20" height="20" title="MON GUSTAVE"/>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        prop='created_at'
        label="Date de création"
        minWidth='150'
        show-overflow-tooltip
        sortable
      >
        <template scope="scope">
          <!-- {{ scope.row.created_at.split(" ")[0] }} -->
          {{ formatDate(scope.row.created_at) }}
        </template>
      </el-table-column>
    </el-table>
  </card>
</template>

<script>
  import { Table, TableColumn } from 'element-ui'
  import XLSX from 'xlsx'
  import moment from 'moment';


  export default {
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    props: ['dataCaClientT', 'AllYear', 'labelM1', 'labelM2', 'labelM3', 'labelM4', 'labelM5', 'labelM6', 'labelM7', 'labelM8', 'labelM9', 'labelM10', 'labelM11', 'labelM12', 'loading'],
    data(){
        return  {
            idUsers:[],
            search:false
        }
    },
    methods: {
      formatDate(dateTimeString) {
            return moment(dateTimeString).format('YYYY-MM-DD');
      },
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      CustomSort_CaGlobal(a, b) {
        return (a.ca_Global_m1 + a.ca_Global_m2 + a.ca_Global_m3) - (b.ca_Global_m1 + b.ca_Global_m2 + b.ca_Global_m3)
      },
      CustomSort_Society(a, b) {
        let s1 = a.society+""
        let s2 = b.society+""
        return s1.localeCompare(s2)
      },
      CustomSort_Secteur(a, b) {
        let s1 = a.secteur+""
        let s2 = b.secteur+""
        return s1.localeCompare(s2)
      },
      ExportTable() {
        let dataCaClientT = JSON.parse(JSON.stringify(this.dataCaClientT));
        dataCaClientT.forEach(data => {
          delete data.__typename
          delete data.created_at
          delete data.client_mongustave
          delete data.mois
          delete data.annee
          delete data.ca_LM
          delete data.ca_coreg
          delete data.ca_perf
          delete data.ca_MG
          delete data.id_user
          data.id_client_myaffile
          data.id_client_mycoreg
          data.id_client_leadmarket

          eval(`delete data.ca_Global`)

          for (let i = 1; i <= 12; i++) {
            eval(`delete data.ca_perf_m${i}`)
            eval(`delete data.ca_LM_m${i}`)
            eval(`delete data.ca_coreg_m${i}`)
            eval(`delete data.ca_MG_m${i}`)
          }

          if(!this.AllYear) {
            for (let i = 3; i <= 12; i++) {
              eval(`delete data.ca_Global_m${i+1}`)
            }
          }

          data.somme = (data.ca_Global_m1 + data.ca_Global_m2 + data.ca_Global_m3).toFixed(2).replace('.',',')
          if(this.AllYear) {
            data.somme = (data.ca_Global_m1 + data.ca_Global_m2 + data.ca_Global_m3 + data.ca_Global_m4 + data.ca_Global_m5 + data.ca_Global_m6 + data.ca_Global_m7 + data.ca_Global_m8 + data.ca_Global_m9 + data.ca_Global_m10 + data.ca_Global_m11 + data.ca_Global_m12).toFixed(2).replace('.',',')
          }
          data.ca_Global_m1 = (data.ca_Global_m1 == 0) ? "" : data.ca_Global_m1.toFixed(2).replace('.',',')
          data.ca_Global_m2 = (data.ca_Global_m2 == 0) ? "" : data.ca_Global_m2.toFixed(2).replace('.',',')
          data.ca_Global_m3 = (data.ca_Global_m3 == 0) ? "" : data.ca_Global_m3.toFixed(2).replace('.',',')
          if(this.AllYear) {
            data.ca_Global_m4 = (data.ca_Global_m4 == 0) ? "" : data.ca_Global_m4.toFixed(2).replace('.',',')
            data.ca_Global_m5 = (data.ca_Global_m5 == 0) ? "" : data.ca_Global_m5.toFixed(2).replace('.',',')
            data.ca_Global_m6 = (data.ca_Global_m6 == 0) ? "" : data.ca_Global_m6.toFixed(2).replace('.',',')
            data.ca_Global_m7 = (data.ca_Global_m7 == 0) ? "" : data.ca_Global_m7.toFixed(2).replace('.',',')
            data.ca_Global_m8 = (data.ca_Global_m8 == 0) ? "" : data.ca_Global_m8.toFixed(2).replace('.',',')
            data.ca_Global_m9 = (data.ca_Global_m9 == 0) ? "" : data.ca_Global_m9.toFixed(2).replace('.',',')
            data.ca_Global_m10 = (data.ca_Global_m10 == 0) ? "" : data.ca_Global_m10.toFixed(2).replace('.',',')
            data.ca_Global_m11 = (data.ca_Global_m11 == 0) ? "" : data.ca_Global_m11.toFixed(2).replace('.',',')
            data.ca_Global_m12 = (data.ca_Global_m12 == 0) ? "" : data.ca_Global_m12.toFixed(2).replace('.',',')
          }
        });
        const fileName = 'CA_CLIENTS.csv'
        var Stats = XLSX.utils.json_to_sheet(dataCaClientT)
        Stats = XLSX.utils.sheet_to_csv(Stats, {FS:";"});

        Stats = Stats.replace("ca_Global_m1", this.labelM1)
        Stats = Stats.replace("ca_Global_m2", this.labelM2)
        Stats = Stats.replace("ca_Global_m3", this.labelM3)
        Stats = Stats.replace("ca_Global_m4", this.labelM4)
        Stats = Stats.replace("ca_Global_m5", this.labelM5)
        Stats = Stats.replace("ca_Global_m6", this.labelM6)
        Stats = Stats.replace("ca_Global_m7", this.labelM7)
        Stats = Stats.replace("ca_Global_m8", this.labelM8)
        Stats = Stats.replace("ca_Global_m9", this.labelM9)
        Stats = Stats.replace("ca_Global_m10", this.labelM10)
        Stats = Stats.replace("ca_Global_m11", this.labelM11)
        Stats = Stats.replace("ca_Global_m12", this.labelM12)

        Stats = Stats.replace("secteur", "thematique")
        Stats = Stats.replace("society", "client")
        Stats = Stats.replace("phone", "telephone")

        var blob = new Blob(["\ufeff", Stats]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      },
      getSummaries(param) {
        const { columns, data } = param;
        const sums = [];
        let sumCa_m1 = 0
        let sumCa_m2 = 0
        let sumCa_m3 = 0
        let sumCa_m4 = 0
        let sumCa_m5 = 0
        let sumCa_m6 = 0
        let sumCa_m7 = 0
        let sumCa_m8 = 0
        let sumCa_m9 = 0
        let sumCa_m10 = 0
        let sumCa_m11 = 0
        let sumCa_m12 = 0
        data.forEach(element => {
          sumCa_m1 += element.ca_Global_m1
          sumCa_m2 += element.ca_Global_m2
          sumCa_m3 += element.ca_Global_m3
          if (this.AllYear) {
            sumCa_m4 += element.ca_Global_m4
            sumCa_m5 += element.ca_Global_m5
            sumCa_m6 += element.ca_Global_m6
            sumCa_m7 += element.ca_Global_m7
            sumCa_m8 += element.ca_Global_m8
            sumCa_m9 += element.ca_Global_m9
            sumCa_m10 += element.ca_Global_m10
            sumCa_m11 += element.ca_Global_m11
            sumCa_m12 += element.ca_Global_m12
          }
        });
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '';
            return;
          }
          else if( index === 2) {
            sums[index] = this.formatThounsends(sumCa_m1,true,2) + ' €';
            return;
          }
          else if( index === 3) {
            sums[index] = this.formatThounsends(sumCa_m2,true,2) + ' €';
            return;
          }
          else if( index === 4) {
            sums[index] = this.formatThounsends(sumCa_m3,true,2) + ' €';
            return;
          }
          else if( index === 5 && this.AllYear) {
            sums[index] = this.formatThounsends(sumCa_m4,true,2) + ' €';
            return;
          }
          else if( index === 6 && this.AllYear) {
            sums[index] = this.formatThounsends(sumCa_m5,true,2) + ' €';
            return;
          }
          else if( index === 7 && this.AllYear) {
            sums[index] = this.formatThounsends(sumCa_m6,true,2) + ' €';
            return;
          }
          else if( index === 8 && this.AllYear) {
            sums[index] = this.formatThounsends(sumCa_m7,true,2) + ' €';
            return;
          }
          else if( index === 9 && this.AllYear) {
            sums[index] = this.formatThounsends(sumCa_m8,true,2) + ' €';
            return;
          }
          else if( index === 10 && this.AllYear) {
            sums[index] = this.formatThounsends(sumCa_m9,true,2) + ' €';
            return;
          }
          else if( index === 11 && this.AllYear) {
            sums[index] = this.formatThounsends(sumCa_m10,true,2) + ' €';
            return;
          }
          else if( index === 12 && this.AllYear) {
            sums[index] = this.formatThounsends(sumCa_m11,true,2) + ' €';
            return;
          }
          else if( index === 13 && this.AllYear) {
            sums[index] = this.formatThounsends(sumCa_m12,true,2) + ' €';
            return;
          }
          else if( index === 5) {
            sums[index] = this.formatThounsends(sumCa_m1+sumCa_m2+sumCa_m3,true, 2) + ' €';
            return;
          }
          else if(index === 14 && this.AllYear) {
            sums[index] = this.formatThounsends(sumCa_m1+sumCa_m2+sumCa_m3+sumCa_m4+sumCa_m5+sumCa_m6+sumCa_m7+sumCa_m8+sumCa_m9+sumCa_m10+sumCa_m11+sumCa_m12,true, 2) + ' €';
            return;
          }
          else {
            sums[index] = '';
            return;
          }
        });
        return sums;
      },
      CaEvolution(Ca_curent, Ca_last) {
        if (Ca_last ==0 ) {
          return 0
        }
        return ((Ca_curent - Ca_last)/Ca_last) * 100
      },
      filterPlatfomre(plf) {
        this.idUsers = []
        this.search = false
        if(plf == "perf") {
            this.search = true
            this.dataCaClientT.forEach(obj => {
                if(this.AllYear){
                    if((obj.ca_perf + obj.ca_perf_m3 + obj.ca_perf_m2 + obj.ca_perf_m4 + obj.ca_perf_m5 + obj.ca_perf_m6 + obj.ca_perf_m7 + obj.ca_perf_m8 + obj.ca_perf_m9 + obj.ca_perf_m10 + obj.ca_perf_m11 + obj.ca_perf_m12) != 0) {
                    this.idUsers.push(obj.id_user)
                    }
                }
                else if((obj.ca_perf + obj.ca_perf_m3 + obj.ca_perf_m2) != 0) {
                    this.idUsers.push(obj.id_user)
                }
                for (let i = 1; i <= 12; i++) {
                    eval(`obj.ca_Global_m${i} = obj.ca_perf_m${i}`)
                }
          })

        }
        else if(plf == "market") {
            this.search = true
            this.dataCaClientT.forEach(obj => {
                if(this.AllYear){
                    if((obj.ca_LM + obj.ca_LM_m3 + obj.ca_LM_m2 + obj.ca_LM_m4 + obj.ca_LM_m5 + obj.ca_LM_m6 + obj.ca_LM_m7 + obj.ca_LM_m8 + obj.ca_LM_m9 + obj.ca_LM_m10 + obj.ca_LM_m11 + obj.ca_LM_m12) != 0) {
                    this.idUsers.push(obj.id_user)
                    }
                }
                else if((obj.ca_LM + obj.ca_LM_m3 + obj.ca_LM_m2) != 0) {
                    this.idUsers.push(obj.id_user)
                }
                for (let i = 1; i <= 12; i++) {
                    eval(`obj.ca_Global_m${i} = obj.ca_LM_m${i}`)
                }
          })
        }
        else if(plf == "coreg") {
            this.search = true
            this.dataCaClientT.forEach(obj => {
                if(this.AllYear){
                    if((obj.ca_coreg + obj.ca_coreg_m3 + obj.ca_coreg_m2 + obj.ca_coreg_m4 + obj.ca_coreg_m5 + obj.ca_coreg_m6 + obj.ca_coreg_m7 + obj.ca_coreg_m8 + obj.ca_coreg_m9 + obj.ca_coreg_m10 + obj.ca_coreg_m11 + obj.ca_coreg_m12) != 0) {
                    this.idUsers.push(obj.id_user)
                    }
                }
                else if((obj.ca_coreg + obj.ca_coreg_m3 + obj.ca_coreg_m2) != 0) {
                    this.idUsers.push(obj.id_user)

                }
                for (let i = 1; i <= 12; i++) {
                    eval(`obj.ca_Global_m${i} = obj.ca_coreg_m${i}`)
                }
            })
        }
        else if(plf == "mg") {
            this.search = true
            this.dataCaClientT.forEach(obj => {
                if(this.AllYear){
                    if((obj.ca_MG + obj.ca_MG_m3 + obj.ca_MG_m2 + obj.ca_MG_m4 + obj.ca_MG_m5 + obj.ca_MG_m6 + obj.ca_MG_m7 + obj.ca_MG_m8 + obj.ca_MG_m9 + obj.ca_MG_m10 + obj.ca_MG_m11 + obj.ca_MG_m12) != 0) {
                    this.idUsers.push(obj.id_user)
                    }
                }
                else if((obj.ca_MG + obj.ca_MG_m3 + obj.ca_MG_m2) != 0) {
                    this.idUsers.push(obj.id_user)
                }
                for (let i = 1; i <= 12; i++) {
                    eval(`obj.ca_Global_m${i} = obj.ca_MG_m${i}`)
                }
            })
        }else{
            this.dataCaClientT.forEach(e => {
                for (let i = 1; i <= 12; i++) {
                    eval(`e.ca_Global_m${i} = e.ca_perf_m${i} + e.ca_LM_m${i} + e.ca_coreg_m${i} + e.ca_MG_m${i}`)
                }
            })
        }
      }
    }
  }

</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
</style>
