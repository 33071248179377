<template>
  <div>
    <div class="content">
      <div class="row mt-5">
        <div class="col-md-12">
          <h3>{{ $t('titlePages.ListPrepayments') }}</h3>
          <div class="card">
            <div class="card-body">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-xl-10 col-lg-12 col-12 order-xl-1 order-1">
                    <div class="row">
                      <base-input class="col-md-4 mb-2" label="CLIENT">
                        <el-select class="select-primary"
                          placeholder="CLIENT"
                          filterable
                          clearable
                          v-model="ClientSelects.Client">
                          <el-option 
                            v-for="option in ClientSelects.ListClient"
                            class="select-primary"
                            :value="option"
                            :label="option.toUpperCase()"
                            :key="option">
                          </el-option>
                        </el-select>
                      </base-input>
                      <base-input class="col-md-4 mb-2" label="DATE">
                        <el-date-picker 
                          v-model="PaymentsDate" 
                          type="date" 
                          value-format="yyyy-MM-dd" 
                          placeholder="DATE"
                        ></el-date-picker>
                      </base-input>
                    </div>
                  </div>
                  <div class="col-xl-2 col-lg-12 col-12 order-xl-2 order-3">
                    <div class="row">
                      <base-button 
                        v-if="$apollo.queries.allPrepays.loading" 
                        loading 
                        class="btn btn-just-icon btn-default mt-4 d-block" 
                      >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                      <button 
                        v-else
                        class="btn btn-just-icon btn-default mt-4 d-block" 
                        @click="reset"
                      >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="col-xl-1 col-6">
                  <base-input>
                    <el-select
                      class="select-primary pagination-select"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-xl-11 col-6" >
                  <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                </div>
              </div>

              <el-table :data="filterListePrePayment" v-loading="$apollo.queries.allPrepays.loading || $apollo.queries.mesPrepaiements.loading" row-class-name="booking-table-rows" :default-sort = "{prop: 'paymentDate', order: 'descending'}">

                <el-table-column
                  prop="id"
                  label="Ref"
                  class="table-striped"
                  sortable
                >
                </el-table-column>

                <el-table-column
                  prop="utilisateur.society"
                  label="Client"
                  class="table-striped"
                  sortable
                  show-overflow-tooltip
                >
                </el-table-column>

                <el-table-column
                  prop="paymentDate"
                  label="Date"
                  class="table-striped"
                  sortable
                  show-overflow-tooltip
                >
                </el-table-column>
                
                <el-table-column
                  prop="montant"
                  :label='$t("table.columns.Amount")+ " ( HT )"'
                  class="table-striped"
                  sortable
                >
                  <template scope="props">
                    {{ formatThounsends(props.row.montant, true, 2) + ' €' }}
                  </template>
                </el-table-column>
  
                  <el-table-column
                    prop="source"
                    label="Source"
                    class="table-striped"
                    sortable
                  >
                    <template scope="props">
                      <label :style="props.row.source == 'credit_virtuelle' ? 'color:orange' : ''">
                        {{ (!props.row.source) ? '-' : props.row.source }}
                      </label>
                    </template>
                  </el-table-column>
                  
                  <el-table-column
                    prop="etat"
                    :label='$t("table.columns.Paymentstatus")'
                    class="table-striped"
                  >
                    <template scope="scope">
                      <template v-if="scope.row.etat === 'acceptee'">
                        <label style="color: green">ACCEPTÉ</label>
                      </template>
                      <template v-else-if="scope.row.etat === 'refusee'">
                        <label style="color: red">REFUSÉ</label>
                      </template>
                    </template>
                  </el-table-column>

                  <el-table-column
                    :label='$t("table.columns.Platform")'
                    :min-width="100"
                  >
                    <template scope="scope">
                      <el-tooltip v-if="scope.row.platform == 'market'" content="WEEDO MARKET" effect="light" :open-delay="300" placement="top">
                        <span class="btn btn-link btn-default btn-icon btn-sm like">
                          <svg version="1.1" id="Calque_1" fill="#588bbd" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                            <g>
                              <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                              <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                                c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                                h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                                />
                            </g>
                          </svg>
                        </span>
                      </el-tooltip>
                      <el-tooltip v-else content="WEEDO PERF" effect="light" :open-delay="300" placement="top">
                        <span class="btn btn-link btn-default btn-icon btn-sm like">
                          <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                            <g>
                              <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                              <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                                c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                                h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                                />
                            </g>
                          </svg>
                        </span>
                      </el-tooltip>
                    </template>
                  </el-table-column>

              </el-table>

            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Select, Option, DatePicker} from 'element-ui'
  import { Table, TableColumn } from 'element-ui';
  import { BasePagination } from 'src/components';
  import gql from 'graphql-tag'

  export default {
    apollo: {
      allPrepays: {
        client: 'AUTH',
        query: gql`
          query {
            allPrepays {
              Prepaiements {
                id
                id_utilisateur
                id_oi_globale
                transaction_id
                paid
                etat
                source
                montant
                paymentDate
                paymentapi
                utilisateur {
                  society
                }
              }
              montantTotal
            }
          }
        `,
        result({ data, loader, networkStatus }) {
          this.ListePrePayment = data.allPrepays[0].Prepaiements
          // let clients = []
          // data.allPrepays[0].Prepaiements.forEach(element => {
          //   clients.push(element.utilisateur.society)
          // });
          // this.ClientSelects.ListClient = [...new Set(clients)]
        },
        fetchPolicy: 'network-only'
      },
      mesPrepaiements: {
        client: 'MARKET',
        query: require("src/graphql/leadmarket/Annonceur/MyPrepayments.gql"),
        variables() {
          return {
            id_utilisateur: null
          }
        },
        result({ data, loader, networkStatus }) {
          let prepayMarket = []
          data.mesPrepaiements.forEach(pay => {
            prepayMarket.push(
              {
                id: pay.pk_prepaiment,
                utilisateur: {
                  society: pay.society
                },
                paymentDate: pay.paymentDate,
                montant: pay.paymentAmount,
                source: (pay.paymentapi === 'sogenactif') ? 'CARTE BANCAIRE' : pay.paymentapi,
                platform: 'market',
                etat: pay.etat
              }
            )
          });
          this.ListePrePayment = this.ListePrePayment.concat(prepayMarket)
          let clients = []
          this.ListePrePayment.forEach(element => {
            clients.push(element.utilisateur.society)
          });
          this.ClientSelects.ListClient = [...new Set(clients)].sort()
        },
        fetchPolicy: 'network-only',
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [DatePicker.name]: DatePicker,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      BasePagination
    },
    computed: {
      filterListePrePayment() {
        let result = null
        if (this.PaymentsDate == null)
          this.PaymentsDate = ""
        try {
          result = this.ListePrePayment.filter(prep => {
            return prep.utilisateur.society.match(this.ClientSelects.Client) &&
                   prep.paymentDate.match(this.PaymentsDate)
          })
          this.total = result.length
          return result.slice(this.from, this.to)
        } catch (error) {
          console.log(error)
          return null
        }
      },
      to() {
        let highBound = this.from + this.pagination.perPage;
        if (this.total < highBound) {
          highBound = this.total;
        }
        return highBound;
      },
      from() {
        return this.pagination.perPage * (this.pagination.currentPage - 1);
      }
    },
    data() {
      return {
        ClientSelects: {
          Client: '',
          ListClient: []
        },
        PaymentsDate: '',
        total: 0,
        ListePrePayment: [],
        pagination: {
          perPage: 50,
          currentPage: 1,
          perPageOptions: [50, 200, 300, 500],
          total: 0
        },
      }
    },
    methods: {
      reset() {
        this.ClientSelects.Client = ""
        this.PaymentsDate = ""
      },
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
        value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
    },
  }
</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
</style>