<template>
  <div class="col-12 background-repoort">
    <div class="row mt-5">
      <div class="col-12">
        <div class="row">
          <div class="col-sm-12 text-left">
            <h2 class="card-title"> 
              <center><template v-if="Facture"> LES FACTURES {{ Facture.month_label }}/{{ Facture.year }} </template></center>
            </h2>
          </div>
        </div>
        <div class="col-md-12">
          <div class="row justify-content-center">

            <div class="col-lg-3">
              <base-input>
                <el-select filterable class="select-primary"
                  placeholder="PLATFORME"
                  clearable
                  v-model="PlatformeSelects.Platforme">
                  <el-option 
                    v-for="option in PlatformeSelects.ListPlatform"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value">
                  </el-option>
                </el-select>
              </base-input>
            </div>
            
            <div class="col-lg-3">
              <base-input>
                <el-select filterable class="select-primary"
                  placeholder="ANNONCEUR"
                  clearable
                  v-model="AnnonceurSelects.Annonceur">
                  <el-option 
                    v-for="option in AnnonceurSelects.ListAnnonceur"
                    class="select-primary"
                    :value="option"
                    :label="option.toUpperCase()"
                    :key="option">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col-lg-3">
              <base-input>
                <el-select filterable class="select-primary"
                  placeholder="ETAT"
                  clearable
                  v-model="EtatSelects.Etat">
                  <el-option 
                    v-for="option in EtatSelects.ListEtat"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value">
                  </el-option>
                </el-select>
              </base-input>
            </div>

          </div>

          <div class="col-md-12 float-right">
            <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
              <base-button 
                v-if="$apollo.queries.getFacture.loading" 
                loading 
                round 
                class="btn btn-round btn-just-icon btn-primary mb-2"
              >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
              <button 
                v-else
                @click="" 
                class="btn btn-round btn-just-icon btn-primary mb-2"
              >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <el-table :data="listFactures_Filter" show-summary :summary-method="getSummaries">

                <el-table-column type="expand">
                  <template slot-scope="props">
                    <p><b>Thématique : </b> {{ props.row.thematique_perf }} </p>
                    <p><b>E-mail : </b> {{ props.row.email_perf || props.row.email_coreg }} </p>
                    <p><b>Dernière modification : </b> {{ props.row.date_modification_perf || props.row.date_modification_coreg }} </p>
                  </template>
                </el-table-column>

                <el-table-column
                  label= ""
                  min-width="90"
                >
                  <template scope="scope">
                    <template v-if="scope.row.etat_perf != null">
                      <base-switch v-model="scope.row.etat_perf"  @input="CheckSwitch(scope.row)">
                        <i class="fa fa-check" slot="on"></i>
                        <i class="fa fa-times" slot="off"></i>
                      </base-switch>
                    </template>
                    <template v-else-if="scope.row.etat_coreg != null">
                      <base-switch v-model="scope.row.etat_coreg" @input="CheckSwitch(scope.row)">
                        <i class="fa fa-check" slot="on"></i>
                        <i class="fa fa-times" slot="off"></i>
                      </base-switch>
                    </template>
                  </template>
                </el-table-column>

                <el-table-column
                  label= "#"
                  min-width="60"
                >
                  <template scope="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop= 'num_perf'
                  label= "N° Facture"
                  min-width="155"
                >
                  <template scope="scope">
                    {{ scope.row.num_perf || scope.row.num_coreg }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop= 'societe_client_perf'
                  label= "Annonceur"
                  min-width="180"
                  sortable
                  :sort-method="CustomSort_societe"
                >

                  <template scope="scope">
                    <template v-if="scope.row.societe_client_perf">
                      <template v-if="scope.row.societe_client_perf.length < 15">
                        {{ scope.row.societe_client_perf.toUpperCase() }}
                      </template>
                      <template v-else>
                        <el-tooltip
                          :content="scope.row.societe_client_perf"
                          effect="light"
                          :open-delay="300"
                          placement="top"
                        >
                          <label>
                            {{ scope.row.societe_client_perf.slice(0,15).toUpperCase() }}...
                          </label>
                        </el-tooltip>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="scope.row.societe_client_coreg.length < 15">
                        {{ scope.row.societe_client_coreg.toUpperCase() }}
                      </template>
                      <template v-else>
                        <el-tooltip
                          :content="scope.row.societe_client_coreg"
                          effect="light"
                          :open-delay="300"
                          placement="top"
                        >
                          <label>
                            {{ scope.row.societe_client_coreg.slice(0,15).toUpperCase() }}...
                          </label>
                        </el-tooltip>
                      </template>
                    </template>
                  </template>

                </el-table-column>

                <!-- <el-table-column
                  prop= 'thematique_perf'
                  label= "Thématique"
                  min-width="100"
                >
                </el-table-column>

                <el-table-column
                  prop= 'email_perf'
                  label= "E-mail"
                  min-width="300"
                >
                  <template scope="scope">
                    {{ scope.row.email_perf || scope.row.email_coreg }}
                  </template>
                </el-table-column> -->

                 <el-table-column
                  prop= 'facture_de_mois'
                  label= "Facture"
                  sortable
                  min-width="160"
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.facture_de_mois,true,2) + ' €' }}
                  </template>
                </el-table-column>
            
                <el-table-column
                  prop= 'solde_non_regle'
                  label= "Solde non reglé"
                  sortable
                  min-width="175"
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.solde_non_regle,true,2) + ' €' }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop= 'total_du_compte'
                  label= "Total compte"
                  sortable
                  min-width="170"
                >
                  <template scope="scope">
                    {{ formatThounsends(scope.row.total_du_compte,true,2) + ' €' }}
                  </template>
                </el-table-column>

                <el-table-column
                  prop= 'marge_perf'
                  label= "Marge"
                  min-width="130px"
                  sortable
                  :sort-method="CustomSort_marge"
                >
                  <template scope="scope">
                    <template v-if="scope.row.marge_perf != null">
                      {{ scope.row.marge_perf + " %" }} 
                    </template>
                    <template v-else-if="scope.row.pourcentage_coreg != null">
                      {{ scope.row.pourcentage_coreg + " %" }} 
                    </template>
                  </template>
                </el-table-column>

                <!-- <el-table-column
                  prop= 'date_modification_perf'
                  label= "Dernière modification"
                  min-width="170"
                >
                  <template scope="scope">
                    {{ scope.row.date_modification_perf || scope.row.date_modification_coreg }}
                  </template>
                </el-table-column> -->

                <el-table-column
                  label="Platforme"
                  :min-width="110"
                  class="table-striped"
                >
                  <template scope="scope">
                    <span class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.flag == 'multi' || scope.row.flag == 'perf' ">
                      <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                    <span class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.flag == 'multi' || scope.row.flag == 'coreg'">
                      <svg version="1.1" id="Calque_1" fill="#54c4d5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                  </template>
                </el-table-column>

                <el-table-column
                  prop= 'status_perf'
                  label= "Etat"
                  min-width="190"
                >
                  <template scope="scope">
                    <template v-if="scope.row.status_perf != null">
                      <base-input>
                        <el-select class="select-primary"
                          filterable 
                          @change="StatChanged(scope.row)"
                          v-model="scope.row.status_perf">
                          <el-option 
                            v-for="option in EtatSelects.ListEtat"
                            class="select-primary"
                            :value="option.value"
                            :label="option.label"
                            :key="option.value">
                          </el-option>
                        </el-select>
                      </base-input>
                    </template>

                    <template v-else>
                      <base-input>
                        <el-select class="select-primary"
                          filterable 
                          @change="StatChanged(scope.row)"
                          v-model="scope.row.status_coreg">
                          <el-option 
                            v-for="option in EtatSelects.ListEtat"
                            class="select-primary"
                            :value="option.value"
                            :label="option.label"
                            :key="option.value">
                          </el-option>
                        </el-select>
                      </base-input>
                    </template>
                  </template>
                </el-table-column>

                <el-table-column :min-width="150" align="right" label="Actions">
                  <div slot-scope="props">
                    <el-tooltip
                      content="PDF"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="handleDownload(props.row)"
                        class="btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="el-icon-document"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                      content="Email"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="SendMail(props.row)"
                        class="btn-link"
                        type="info"
                        size="sm"
                        :disabled="$apollo.queries.sendMail.loading"
                        icon
                      >
                        <i class="el-icon-message"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                      content="Avances"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="GotoAdvance(props.row)"
                        class="btn-link"
                        type="warning"
                        size="sm"
                        icon
                      >
                      <i class="tim-icons icon-coins"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      :show.sync="ShowPDF"
      body-classes="p-0"
      class="modal-listbase"
      :show-close="true"
      >
        <base-button style="float: right;" @click.native="ShowPDF = false" class="like btn-link" type="danger" size="sm" icon>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>
        <object :data="PDFURL" type="application/pdf" style="width: 100%;height: 900px;">
          <iframe :src="PDFURL"></iframe>
        </object>
    </modal>
  </div> 
</template>


<script>
  import { Select, Option } from 'element-ui'
  import { Table, TableColumn } from 'element-ui';
  import gql from 'graphql-tag'
  import axios from 'axios';
  import {Modal} from 'src/components'
  import { BaseSwitch } from 'src/components/index'

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Modal,
      BaseSwitch
    },
    apollo: {
      getFacture: {
        client: 'AUTH',
        query: require("src/graphql/Factures.gql"),
        fetchPolicy: 'network-only',
        result({ data, loader, networkStatus }) {
          this.listFactures = data.getFacture
          // FILLING FILTERS
          let listAnnonceur = []
          this.listFactures.forEach(facture => {
            listAnnonceur.push(facture.societe_client_perf || facture.societe_client_coreg)
          });
          this.AnnonceurSelects.ListAnnonceur = [...new Set(listAnnonceur)]
          // FILLING FILTERS
          this.SkipQuery = true
        },
        skip() {
          return this.SkipQuery;
        },
        variables () {
          return {
            annee: this.Facture.year,
            mois: ((this.Facture.mounth+"").length==1) ? "0"+this.Facture.mounth : this.Facture.mounth
          }
        }
      },
      sendMail: {
        client: 'AUTH',
        query: gql` query (
                            $id_client_perf: Int
                            $id_client_coreg: Int
                            $annee: String
                            $mois: String
                            $somme: Float
                            $id_facture_perf: Int
                            $id_facture_coreg: Int
                            $email: String
                          ) {
                          sendMail (
                                    id_client_perf: $id_client_perf
                                    id_client_coreg: $id_client_coreg
                                    annee: $annee
                                    mois: $mois
                                    somme: $somme
                                    id_facture_perf: $id_facture_perf
                                    id_facture_coreg: $id_facture_coreg
                                    email: $email
                                  )
                          {
                            message
                          }         
        }`,
        fetchPolicy: 'network-only',
        skip() {
          return this.SkipSendMail
        },
        variables() {
          return {
            id_client_perf: this.ClientToSend.id_annonceur_perf,
            id_client_coreg: this.ClientToSend.id_annonceur_coreg,
            annee: this.Facture.year,
            mois: ((this.Facture.mounth+"").length==1) ? "0"+this.Facture.mounth : this.Facture.mounth,
            somme: this.ClientToSend.facture_de_mois,
            id_facture_perf: this.ClientToSend.id_facture_perf,
            id_facture_coreg: this.ClientToSend.id_facture_coreg,
            email: this.ClientToSend.email
          }
        },
        result({ data, loader, networkStatus }) {
          this.$notify({type: 'success', message: this.$t("apiresponse.emailsent") })
          this.SkipSendMail = true
        },
        error(error) {
          this.$notify({type: 'warning', message: this.$t("apiresponse.senterror") })
        }
      }
    },
    computed: {
      listFactures_Filter() {
        let list = null

        try {
          list = this.listFactures.filter((facture) => {
            return  ((facture.status_perf+"").match(this.EtatSelects.Etat) || (facture.status_coreg+"").match(this.EtatSelects.Etat)) &&
                    (facture.flag.match(this.PlatformeSelects.Platforme) || facture.flag.match("multi")) &&
                    ( (facture.societe_client_perf+"").toUpperCase().replace(/[^a-z A-Z0-9]/g,'').match(this.AnnonceurSelects.Annonceur.toUpperCase().replace(/[^a-z A-Z0-9]/g,'')) ||
                      (facture.societe_client_coreg+"").toUpperCase().replace(/[^a-z A-Z0-9]/g,'').match(this.AnnonceurSelects.Annonceur.toUpperCase().replace(/[^a-z A-Z0-9]/g,'')) )
          })
        } catch (error) {
          console.log(error)
          return list
        }

        return list
      }
    },
    data() {
      return {
        SkipSendMail: true,
        ClientToSend: null,
        PDFURL: '',
        ShowPDF: false,
        Facture: null,
        SkipQuery: true,
        listFactures: [],
        AnnonceurSelects: {
          Annonceur: '',
          ListAnnonceur: []
        },
        EtatSelects: {
          Etat: '',
          ListEtat: [
            { value: 0, label: "  "},
            { value: 1, label: "ECHOUÉ"},
            { value: 2, label: "PAYEE"},
            { value: 3, label: "RELANCE1"},
            { value: 4, label: "RELANCE2"},
            { value: 5, label: "RELANCE3"},
            { value: 6, label: "LRAR"},
            { value: 7, label: " "},
            { value: 8, label: "PAYEE AVEC PAYPAL"},
            { value: 9, label: "PAYEE AVEC SOGENACTIF"},
            { value: 11, label: "PRÉPAIEMENT"},
          ]
        },
        PlatformeSelects: {
          Platforme: '',
          ListPlatform: [
            { value: "perf", label: "WEEDO PERF" },
            { value: "coreg", label: "WEEDO COREG" }
          ]
        }
      }
    },
    methods: {
      CustomSort_societe(a, b) {
        if (a.societe_client_perf && b.societe_client_perf)
          return a.societe_client_perf.localeCompare(b.societe_client_perf);
        else if (a.societe_client_coreg && b.societe_client_coreg)
          return a.societe_client_coreg.localeCompare(b.societe_client_coreg);
        else if (a.societe_client_perf && b.societe_client_coreg)
          return a.societe_client_perf.localeCompare(b.societe_client_coreg);
        else if (a.societe_client_coreg && b.societe_client_perf)
          return a.societe_client_coreg.localeCompare(b.societe_client_perf);
      },
      CustomSort_marge(a, b) {
        if (a.marge_perf!=null && b.marge_perf!=null)
          return a.marge_perf - b.marge_perf
        else if (a.pourcentage_coreg!=null && b.pourcentage_coreg!=null)
          return a.pourcentage_coreg - b.pourcentage_coreg
        else if (a.marge_perf!=null && b.pourcentage_coreg!=null)
          return a.marge_perf - b.pourcentage_coreg
        else if (a.pourcentage_coreg!=null && b.marge_perf!=null)
          return a.pourcentage_coreg - b.marge_perf
      },
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      getSummaries(param) {
        const { columns, data } = param;

        let Facture = 0
        let Solde = 0
        let Total = 0
        let ToalatMarge = 0
        data.forEach(payment => {
          Facture += payment.facture_de_mois
          Solde += payment.solde_non_regle
          Total += payment.total_du_compte
          
          if (payment.marge_perf != null) {
            ToalatMarge += payment.marge_perf
          }
          else if (payment.pourcentage_coreg != null) {
            ToalatMarge += payment.pourcentage_coreg
          }
        });
        
        const sums = [];
        columns.forEach((column, index) => {
          if (index === 5) {
            sums[index] = this.formatThounsends(Facture,true,2) + ' €'
            return;
          }
          else if( index === 6) {
            sums[index] = this.formatThounsends(Solde,true,2) + ' €'
            return;
          }
          else if( index === 7) {
            sums[index] = this.formatThounsends(Total,true,2) + ' €'
            return;
          }
          else if( index === 8) {
            sums[index] = this.formatThounsends(ToalatMarge/data.length,true,2) + ' %'
            return;
          }
          else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      async StatChanged(row) {
        await this.$apollo.mutate({
          client: "AUTH",
          mutation: require("src/graphql/mutation/Update_Facture_Stat.gql"),
          variables: {
            id_annonceur_perf: row.id_annonceur_perf,
            id_annonceur_coreg: row.id_annonceur_coreg,
            mois: ((this.Facture.mounth+"").length==1) ? "0"+this.Facture.mounth : this.Facture.mounth,
            annee: this.Facture.year,
            status: row.status_perf || row.status_coreg
          }
        }).then(response => {
          this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
          
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
        })
      },
      handleDownload(row){
        const url_api = "https://api-auth-plf.wee-do-it.net/api/v1/pdf_facture"
        let accessToken = localStorage.getItem("token_jwt");
        let formData = new FormData()
        formData.append('mois', ((this.Facture.mounth+"").length==1) ? "0"+this.Facture.mounth : this.Facture.mounth)
        formData.append('annee', this.Facture.year)
        formData.append('client_perf', row.id_annonceur_perf)
        formData.append('client_coreg', row.id_annonceur_coreg)
        this.axios
          .post(url_api,formData , {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded',authorization: `Bearer ${accessToken}` },
            responseType: "blob"
          }).then(function(response) {
            let blob = new Blob([response.data], { type: 'application/pdf' })
            let link = document.createElement('a')
            this.ShowPDF = true
            this.PDFURL = window.URL.createObjectURL(blob)
          }.bind(this))
          .catch(function (error) {
            console.log(error.message);
          })
      },
      SendMail(row) {
        this.ClientToSend = row
        this.SkipSendMail = false
      },
      GotoAdvance(row) {
        let routeData = this.$router.resolve({ name:'liste des Avances Client', params: {
          client: row.societe_client_perf || row.societe_client_coreg,
          platform: row.flag,
          id_facture_coreg: row.id_facture_coreg || 0,
          id_facture_perf: row.id_facture_perf || 0,
          id_perf: row.id_annonceur_perf || 0,
          id_coreg: row.id_annonceur_coreg || 0
        } 
        });
        window.open(routeData.href, '_blank');
      },
      CheckSwitch(row) {
        let etat = null
        if (row.etat_perf != null) {
          etat = row.etat_perf ? 1 : 0
        }
        else if (row.etat_coreg != null) {
          etat = row.etat_coreg ? 1 : 0
        }
        this.$apollo.mutate({
          client: "AUTH",
          mutation: gql`mutation (
            $id_annonceur_perf: Int
            $id_annonceur_coreg: Int
            $mois: String
            $annee: String
            $etat: Int
          )
          {
            update_etat_facture(
              id_annonceur_perf: $id_annonceur_perf
              id_annonceur_coreg : $id_annonceur_coreg
              mois : $mois
              annee : $annee
              etat: $etat
            ) 
            {
              message
            }
          }`,
          variables: {
            id_annonceur_perf: row.id_annonceur_perf,
            id_annonceur_coreg: row.id_annonceur_coreg,
            mois: ((this.Facture.mounth+"").length==1) ? "0"+this.Facture.mounth : this.Facture.mounth,
            annee: this.Facture.year,
            etat: etat
          }
        }).then(response => {
          this.$notify({type: 'success', message: "Vérifié" })
        }).catch(error => {
          // this.$notify({type: 'warning', message: "Erreur" })
        })
      }
    },
    created() {
      if((this.Facture = JSON.parse(localStorage.getItem("facture"))) == null) {
        this.$router.push({ name: "Globale Reports" })
        return
      }
      // localStorage.removeItem("facture")
      this.SkipQuery = false
    },
  }
</script>