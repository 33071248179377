<template>
  <div class="col-12 background-repoort">
    <div class="row mt-5">
      <div class="col-md-12">
        <div class="col-12">
          <div class="row">
            <div class="col-md-12">
              <div
                      class="btn-group btn-group-toggle"
                      data-toggle="buttons"
              >
                <label class="btn btn-sm btn-info btn-simple">
                  <input
                    type="radio"
                    @click="SwitchToPerf"
                    name="options"
                    autocomplete="off"
                  />
                  <span class="d-none d-sm-block" style="width: 180px;"> <img src="/img/weedo-it-logo-blanc.png" alt="" /> WEEDO PERF LIGHT</span>
                  <span class="d-block d-sm-none">
                    <img src="/img/weedo-it-logo-blanc.png" alt="" />
                  </span>
                </label>
                <label class="btn btn-sm btn-info btn-simple active">
                  <input
                          type="radio"
                          @click=""
                          name="options"
                          autocomplete="off"
                  />
                  <span class="d-none d-sm-block" style="width: 150px;"> <img src="/img/weedo-it-logo-blanc.png" alt="" /> WEEDO PERF</span>
                  <span class="d-block d-sm-none">
                    <img src="/img/weedo-it-logo-blanc.png" alt="" />
                  </span>
                </label>
                <label class="btn btn-sm btn-coreg btn-simple">
                  <input
                          type="radio"
                          @click="SwitchToCoreg"
                          name="options"
                          autocomplete="off"
                  />
                  <span class="d-none d-sm-block" style="width: 150px;"> <img src="/img/weedo-it-logo-blanc.png" alt="" /> WEEDO COREG</span>
                  <span class="d-block d-sm-none">
                    <img src="/img/weedo-it-logo-blanc.png" alt="" />
                  </span>
                </label>
                <label class="btn btn-sm btn-warning btn-simple">
                  <input
                          type="radio"
                          @click="SwitchToMongustave"
                          name="options"
                          autocomplete="off"
                  />
                  <span class="d-none d-sm-block" style="width: 150px;"> 
                    <img src="img/ico-mongustave.png"  width="34" height="29"/> MON GUSTAVE</span>
                  <span class="d-block d-sm-none">
                    <img src="img/ico-mongustave.png"  width="34" height="29"/>
                  </span>
                </label>
                
              </div>
            </div>
            <div class="col-sm-12 text-left">
              <h2 class="card-title">
                <center><template v-if="Rapport"> RAPPORTS {{ Rapport.month_label }}/{{ Rapport.year }} </template></center>
              </h2>
            </div>
          </div>
          <div class="col-md-12">

            <div class="row">
              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                             placeholder="EDITEURS"
                             clearable
                             v-model="FilterEditeursSelects.Editeur">
                    <el-option
                            v-for="option in FilterEditeursSelects.ListEditeur"
                            class="select-primary"
                            :value="option"
                            :label="option.toUpperCase()"
                            :key="option">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                             placeholder="ANNONCEURS"
                             clearable
                             v-model="FilterAnnonceursSelects.Annonceurs">
                    <el-option
                            v-for="option in FilterAnnonceursSelects.ListAnnonceurs"
                            class="select-primary"
                            :value="option"
                            :label="option.toUpperCase()"
                            :key="option">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                             placeholder="CAMPAGNES"
                             clearable
                             v-model="FilterCampagnesSelects.Campagnes">
                    <el-option
                            v-for="option in FilterCampagnesSelects.ListCampagnes"
                            class="select-primary text-uppercase"
                            v-if="(FilterAnnonceursSelects.Annonceurs) ? option.client === FilterAnnonceursSelects.Annonceurs : true"
                            :value="option.value"
                            :label="option.value"
                            :key="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                             placeholder="PAYS"
                             clearable
                             v-model="PaysBasesSelects.PaysBase">
                    <el-option
                            v-for="option in PaysBasesSelects.ListPaysBases"
                            class="select-primary"
                            :value="option.iso2"
                            :label="option.name.toUpperCase()"
                            :key="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

            </div>

            <div class="row">

              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                             placeholder="MANAGER"
                             clearable
                             v-model="ManagerSelects.Manager">
                    <el-option
                            v-for="option in ManagerSelects.ListManager"
                            class="select-primary"
                            :value="option.nom_client +' '+ option.prenom_client"
                            :label="option.prenom_client.toUpperCase() +' '+ option.nom_client.toUpperCase()"
                            :key="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                             placeholder="PROVENANCE"
                             clearable
                             v-model="ProvenanceSelects.Provenance">
                    <el-option
                            v-for="option in ProvenanceSelects.ListProvenance"
                            class="select-primary"
                            :value="option.prov"
                            :label="option.prov.toUpperCase()"
                            :key="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                             placeholder="AFFILIÉ"
                             clearable
                             v-model="AffilieSelects.Affilie">
                    <el-option
                            v-for="option in AffilieSelects.ListAffilie"
                            class="select-primary"
                            :value="option.value"
                            :label="option.label"
                            :key="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                             placeholder="THÉMATIQUE"
                             clearable
                             v-model="ThematiqueSelects.Thematique">
                    <el-option
                            v-for="option in ThematiqueSelects.ListThematique"
                            class="select-primary"
                            :value="option.value"
                            :label="option.categorie.toUpperCase()"
                            :key="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>

            </div>

            <div class="row">
              <div class="col-lg-3">
                <base-input>
                  <el-select filterable class="select-primary"
                             placeholder="TYPE DE DIFFUSION"
                             clearable
                             multiple
                             v-model="TypeDiffusionSelects.TypeDiffusion">
                    <el-option
                            v-for="option in TypeDiffusionSelects.ListTypeDiffusion"
                            class="select-primary"
                            :value="option.id_type_compagne"
                            :label="option.type_2.toUpperCase()"
                            :key="option.id">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>

            <div class="col-md-12 float-right">
              <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                <div class="col-md-12 float-right" v-if="$apollo.queries.getRaport.loading">
                  <radial-progress-bar
                          :diameter="160"
                          :completed-steps="completedSteps"
                          startColor="#988fc3"
                          stopColor="#51357c"
                          :total-steps="totalSteps">
                    <br/>
                    <h3> {{ progress.toFixed() + " %" }}</h3>

                  </radial-progress-bar>
                </div>
                <button
                        v-else
                        @click="Reset()"
                        class="btn btn-round btn-just-icon btn-primary mb-2"
                >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
              </div>
            </div>
            <div class="col-md-12 float-right">
              <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                <base-button
                        :disabled="$apollo.queries.getRaport.loading"
                        round
                        class="btn btn-round btn-just-icon btn-info mb-2"
                        @click.native="showAddRapport = true"
                >AJOUTER UNE LIGNE</base-button>
              </div>
            </div>
            <div class="col-md-12 float-right">
              <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
                <base-button
                  :disabled="$apollo.queries.getRaport.loading"
                  round
                  class="btn btn-round btn-just-icon btn-info mb-2"
                  @click.native="exportcsv"
                >
                  <i class="far fa-file-excel"></i>EXPORT
                </base-button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!showEditRapport" class="card" v-for="(list,index) in ListRapports_Filter">
          <span slot="header" class="badge-pill badge-info tab-title" style="text-transform: uppercase;font-weight: bold;">
            {{ list.rapportEditeur[0].Editeur }}
          </span>
          <div class="card-body">

            <b-table ref="tableselectable" responsive selectable select-mode="multi" :fields="fields" :items="list.rapportEditeur" @row-selected="(items) => handleSelectionChange(index, items)">

              <template slot="bottom-row" slot-scope="data">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{ getSumClick(list.rapportEditeur) }}</td>
                <td>{{ getSumLeads_brut(list.rapportEditeur) }}</td>
                <td>{{ getSumLeads_net(list.rapportEditeur) }}</td>
                <td></td>
                <td>{{ getSum_ht(list.rapportEditeur) + " €" }}</td>
                <td>{{ getSum_htc(list.rapportEditeur) + " €" }}</td>
                <td></td>
                <td>{{ getSum_ht_c(list.rapportEditeur) + " €" }}</td>
                <td></td>
                <td></td>
                <td></td>
              </template>

              <template #head(#)="data">
                <base-button
                  @click.native="deselectOrselectRow(list.rapportEditeur,index)"
                  class="btn-link float-right"
                  type="success"
                  size="sm"
                  icon
                >
                  <i class="far fa-check-square"></i>
                </base-button>
              </template>

              <template v-slot:cell(#)="{ rowSelected }">
                <template v-if="rowSelected">
                  <span aria-hidden="true">&check;</span>
                </template>
                <template v-else>
                  <span aria-hidden="true">&nbsp;</span>
                </template>
              </template>

              <template v-slot:cell(Campagnes)="data">
                <template v-if="data.item.Campagnes.length < 15">
                  {{ data.item.Campagnes.toUpperCase() }}
                </template>
                <template v-else>
                  <el-tooltip
                          :content="data.item.Campagnes"
                          effect="light"
                          :open-delay="300"
                          placement="top"
                  >
                    <label>
                      {{ data.item.Campagnes.slice(0,15).toUpperCase() }}...
                    </label>
                  </el-tooltip>
                </template>
              </template>

              <template v-slot:cell(Client)="data">
                <template v-if="data.item.Client.length < 15">
                  {{ data.item.Client.toUpperCase() }}
                </template>
                <template v-else>
                  <el-tooltip
                          :content="data.item.Client"
                          effect="light"
                          :open-delay="300"
                          placement="top"
                  >
                    <label>
                      {{ data.item.Client.slice(0,15).toUpperCase() }}...
                    </label>
                  </el-tooltip>
                </template>
              </template>

              <template v-slot:cell(Tag)="data">
                <template v-if="data.item.Tag.length < 15">
                  {{ data.item.Tag.toUpperCase() }}
                </template>
                <template v-else>
                  <el-tooltip
                          :content="data.item.Tag"
                          effect="light"
                          :open-delay="300"
                          placement="top"
                  >
                    <label>
                      {{ data.item.Tag.slice(0,15).toUpperCase() }}...
                    </label>
                  </el-tooltip>
                </template>
              </template>

              <template v-slot:cell(leads_net)="data">
                <base-input
                  v-model.number="data.item.leads_net" 
                  type="number"
                  @keyup.enter.native="EditRow_onEnter(data.item)"
                  :disabled="disableinput"
                ></base-input>
              </template>

              <template v-slot:cell(prix_lead)="data">
                <base-input
                  v-model.number="data.item.prix_lead" 
                  type="number"
                  @keyup.enter.native="EditRow_onEnter(data.item)"
                  :disabled="disableinput"
                ></base-input>
              </template>

              <template v-slot:cell(somme_ht)="data">
                {{ formatThounsends(data.item.somme_ht,true,2) + ' €' }}
              </template>

              <template v-slot:cell(somme_ttc)="data">
                {{ formatThounsends(data.item.somme_ttc,true,2) + ' €' }}
              </template>

              <template v-slot:cell(prix_client)="data">
                <base-input
                  v-model.number="data.item.prix_client" 
                  type="number"
                  @keyup.enter.native="EditRow_onEnter(data.item)"
                  :disabled="disableinput"
                ></base-input>
              </template>

              <template v-slot:cell(somme_ht_c)="data">
                {{ formatThounsends(data.item.somme_ht_c,true,2) + ' €' }}
              </template>

              <template v-slot:cell(marge)="data">
                {{ formatThounsends(data.item.marge,true,2) + ' €' }}
              </template>

              <template v-slot:cell(pourcentage)="data">
                {{ data.item.pourcentage + '%' }}
              </template>

              <template v-slot:cell(trafficManager)="data">
                {{ data.item.trafficManager.toUpperCase() }}
              </template>

              <template v-slot:cell(Provenance)="data">
                <template v-if="data.item.Provenance.length < 15">
                  {{ data.item.Provenance.toUpperCase() }}
                </template>
                <template v-else>
                  <el-tooltip
                          :content="data.item.Provenance"
                          effect="light"
                          :open-delay="300"
                          placement="top"
                  >
                    <label>
                      {{ data.item.Provenance.slice(0,15).toUpperCase() }}...
                    </label>
                  </el-tooltip>
                </template>
              </template>

              <template v-slot:cell(action)="data">
                <el-tooltip
                        content="Dupliquer"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                        v-if="data.item.ID != '-'"
                >
                  <base-button
                          @click.native="DupliquerRow(data.item)"
                          class="btn-link"
                          type="success"
                          size="sm"
                          icon
                  >
                    <i class="tim-icons icon-single-copy-04"></i>
                  </base-button>
                </el-tooltip>
              </template>

            </b-table>

            <div class="d-flex justify-content-end" v-if="list.sold.etat != '10'">
              <label class="lablelTabel">{{ list.sold.etat }}</label><br>
              <base-button v-if="list.sold.etat != 'Paye'" @click="CancelRepport(list.rapportEditeur[0].id_editeur, list.sold)" type="info" round class="float-right" title="">
                Annuler <i class="tim-icons icon-minimal-right"></i>
              </base-button>
            </div>
            <div class="d-flex justify-content-end" v-else>
              <label class="lablelTabel">à valider</label><br>
              <base-button @click="ValidateRepport(list.rapportEditeur[0].id_editeur, list.sold)" type="info" round class="float-right" title="">
                Valider <i class="tim-icons icon-minimal-right"></i>
              </base-button>
            </div>
          </div>
        </div>

        <div class="card" v-if="!$apollo.queries.getRaport.loading">
          <span slot="header" class="badge badge-pill badge-warning tab-title">
            TOTAL
          </span>
          <div class="card-body">
            <el-table :data="SumTotal">
              <el-table-column
                      prop= 'Clics'
                      label= "Clics"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Clics) }}
                </template>
              </el-table-column>
              <el-table-column
                      prop= 'Leads_brut'
                      label= "Leads brut"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Leads_brut) }}
                </template>
              </el-table-column>
              <el-table-column
                      prop= 'Leads_net'
                      label= "Leads net"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Leads_net) }}
                </template>
              </el-table-column>
              <el-table-column
                      prop= 'Somme_ht'
                      label= "Somme (HT)"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Somme_ht,true,2) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                      prop= 'Somme_ttc'
                      label= "Somme (TTC)"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Somme_ttc,true,2) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                      prop= 'Somme_ht_c'
                      label= "Somme (HT)"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Somme_ht_c,true,2) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                      prop= 'Marge'
                      label= "Marge (HT)"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Marge,true,2) + ' €' }}
                </template>
              </el-table-column>
              <el-table-column
                      prop= 'Pourcentage'
                      label= "%"
              >
                <template scope="scope">
                  {{ formatThounsends(scope.row.Pourcentage,true,2) + ' %' }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <!-- Mondal Add Rapport -->
    <modal_selfEvent
            :show.sync="showAddRapport"
            body-classes="p-0"
            class="modal-listbase">
      <card type="secondary"
            header-classes="bg-white pb-6"
            body-classes="px-lg-6 py-lg-6"
            class="border-0 mb-0">

        <el-tooltip
          content="Quitter"
          effect="light"
          :open-delay="300"
          placement="top"
        >
          <base-button
            @click.native="showAddRapport = false"
            class="btn-link float-right"
            type="danger"
            size="sm"
            icon
          >
            <i class="tim-icons icon-simple-remove icon-large"></i>
          </base-button>
        </el-tooltip>

        <br>
        <br>

        <div class="form-row">
          <base-input label="Editeurs" class="col-md-6" :error="getError('editeur')">
            <el-select filterable class="select-primary"
                       placeholder="EDITEURS"
                       clearable
                       name="editeur"
                       v-validate="'required'"
                       v-model="Repport.Editeurs">
              <el-option
                      v-for="option in EditeursSelects.ListEditeur"
                      class="select-primary"
                      :value="option.societe_client"
                      :label="option.societe_client.toUpperCase()"
                      :key="option.id">
              </el-option>
            </el-select>
          </base-input>

          <base-input label="Tag de tracking" placeholder="TAG DE TRACKING"
                      class="col-md-6"
                      name="tag"
                      v-model="Repport.Tag"
                      :error="getError('tag')"
                      v-validate="'required'"
          >
          </base-input>

        </div>

        <div class="form-row">
          <base-input class="col-md-6" label="Annonceurs" :error="getError('annonceur')">
            <el-select filterable class="select-primary"
                       placeholder="ANNONCEURS"
                       clearable
                       name="annonceur"
                       v-validate="'required'"
                       v-model="AnnonceursSelects.Annonceurs_Add">
              <el-option
                      v-for="option in AnnonceursSelects.ListAnnonceurs"
                      class="select-primary"
                      :value="option.id_client"
                      :label="option.societe_client.toUpperCase()"
                      :key="option.id">
              </el-option>
            </el-select>
          </base-input>

          <base-input label="Campagnes" class="col-md-6" :error="getError('compagne')">
            <el-select filterable class="select-primary"
                       placeholder="CAMPAGNES"
                       clearable
                       name="compagne"
                       v-validate="'required'"
                       v-model="Repport.Compagnes">
              <el-option
                      v-for="option in CampagnesSelects.ListCampagnes"
                      v-if="option.id_client == AnnonceursSelects.Annonceurs_Add"
                      class="select-primary"
                      :value="option.Titre"
                      :label="option.Titre.toUpperCase()"
                      :key="option.id">
              </el-option>
            </el-select>
          </base-input>

        </div>

        <div class="form-row">
          <base-input label="Leads brut" placeholder="LEADS BRUT"
                      class="col-md-6"
                      name="leads brut"
                      v-model="Repport.Leads_brut"
                      :error="getError('leads brut')"
                      v-validate="'required'"
          >
          </base-input>
          <base-input label="Leads net" placeholder="LEADS NET"
                      class="col-md-6"
                      name="leads net"
                      v-model="Repport.Leads_net"
                      :error="getError('leads net')"
                      v-validate="'required'"
          >
          </base-input>
        </div>

        <div class="form-row">
          <base-input label="Prix de lead (€)" placeholder="PRIX DE LEAD (€)"
                      class="col-md-6"
                      name="prix de lead"
                      v-model="Repport.Prix_lead"
                      :error="getError('prix de lead')"
                      v-validate="'required'"
          >
          </base-input>
          <base-input label="Prix client (€)" placeholder="PRIX CLIENT (€)"
                      class="col-md-6"
                      name="prix client"
                      v-model="Repport.Prix_client"
                      :error="getError('prix client')"
                      v-validate="'required'"
          >
          </base-input>
        </div>

        <div class="form-row">
          <base-input label="Clics " placeholder="CLICS"
                      class="col-md-6"
                      name="clics"
                      v-model="Repport.Clics"
                      :error="getError('clics')"
                      v-validate="'required'"
          >
          </base-input>

          <base-input class="col-md-6" label="Traffic manager" :error="getError('traffic manager')">
            <el-select filterable class="select-primary"
                       placeholder="MANAGER"
                       clearable
                       name="traffic manager"
                       v-validate="'required'"
                       v-model="ManagerSelects.Manager_Add">
              <el-option
                      v-for="option in ManagerSelects.ListManager"
                      class="select-primary"
                      :value="option.nom_client +' '+ option.prenom_client"
                      :label="option.prenom_client.toUpperCase() +' '+ option.nom_client.toUpperCase()"
                      :key="option.id">
              </el-option>
            </el-select>
          </base-input>
        </div>

        <div class="form-row">
          <base-input class="col-md-6" label="Team manager" :error="getError('team manager')">
            <el-select filterable class="select-primary"
                       placeholder="TEAM MANAGER"
                       clearable
                       name="team manager"
                       v-validate="'required'"
                       v-model="TeamSelects.Team">
              <el-option
                      v-for="option in TeamSelects.ListTeam"
                      class="select-primary"
                      :value="option.nom_client +' '+ option.prenom_client"
                      :label="option.prenom_client.toUpperCase() +' '+ option.nom_client.toUpperCase()"
                      :key="option.id">
              </el-option>
            </el-select>
          </base-input>

          <base-input class="col-md-6" label="Pays">
            <el-select filterable class="select-primary"
                       placeholder="PAYS"
                       v-model="PaysBasesSelects.PaysBase_Add">
              <el-option
                      v-for="option in PaysBasesSelects.ListPaysBases"
                      class="select-primary"
                      :value="option.iso2"
                      :label="option.name.toUpperCase()"
                      :key="option.id">
              </el-option>
            </el-select>
          </base-input>

        </div>

        <div class="form-row">

          <base-input class="col-md-6" label="Année">
            <el-select filterable class="select-primary"
                       placeholder="ANNÉE"
                       v-model="YearSelects.Year">
              <el-option
                      v-for="option in YearSelects.ListYear"
                      class="select-primary"
                      :value="option.value"
                      :label="option.value"
                      :key="option.value">
              </el-option>
            </el-select>
          </base-input>

          <base-input class="col-md-6" label="Mois">
            <el-select filterable class="select-primary"
                       placeholder="MOIS"
                       v-model="MonthSelects.Month">
              <el-option
                      v-for="option in MonthSelects.ListMonth"
                      class="select-primary"
                      :value="option.value"
                      :label="option.value"
                      :key="option.value">
              </el-option>
            </el-select>
          </base-input>

        </div>

        <center>
          <base-button
                  v-if="!loaderAddRepport"
                  type="info"
                  @click="AddRepport"
          >{{ $t("buttons.BtnAdd").toUpperCase() }}
          </base-button>
          <base-button
                  v-else
                  type="info"
                  loading
          >
          </base-button>
        </center>

      </card>
    </modal_selfEvent>
    <!-- END MODAL ADD RAPPORT -->

    <Modal_selfEvent
            :show.sync="showEditRapport"
            body-classes="p-0"
            class="modal-listerapport">
      <card type="secondary"
            header-classes="pb-6"
            body-classes="px-lg-6 py-lg-6"
            class="border-0 mb-0">

        <template slot="header">
          <el-tooltip
                  content="Quitter"
                  effect="light"
                  :open-delay="300"
                  placement="top"
          >
            <base-button
                    @click.native="showEditRapport = false"
                    class="btn-link float-right"
                    type="danger"
                    size="sm"
                    icon
            >
              <i class="tim-icons icon-simple-remove icon-large"></i>
            </base-button>
          </el-tooltip>

          <el-tooltip
                  content="Aperçu"
                  effect="light"
                  :open-delay="300"
                  placement="top"
          >
            <base-button
                    @click.native="ShowRapportsChangers"
                    class="btn-link float-left"
                    type="warning"
                    icon
                    :disabled="btn_ShowChangers"
            >
              <i class="el-icon-view icon-Xlarge"></i>
            </base-button>
          </el-tooltip>

          <el-tooltip
                  content="Appliquer"
                  effect="light"
                  :open-delay="300"
                  placement="top"
          >
            <base-button
                    @click.native="updateRapports"
                    class="btn-link float-left"
                    type="success"
                    icon
            >
              <i class="tim-icons icon-check-2 icon-Xlarge"></i>
            </base-button>
          </el-tooltip>

        </template>

        <template>
          <hr>
          <div class="row">

            <div class="col-sm-4 checkbox-radios">
              <base-radio v-model="EditPriceMode" name="1">AUGMENTER LES VALEURS DE</base-radio>
              <base-radio v-model="EditPriceMode" name="2">DIMINUER LES VALEURS DE</base-radio>
              <base-radio v-model="EditPriceMode" name="3">ATTRIBUER LA VALEUR SUIVANTE</base-radio>
            </div>

            <div class="col-sm-4 checkbox-radios">
              <base-input
                      :label="(EditPriceMode == '3') ? 'Valeur' : 'Pourcentage'"
                      v-model.number="RuleValue"
                      type="number">
              </base-input>
            </div>

            <div class="col-sm-4">
              <base-input label="CHAMP">
                <el-select class="select-primary"
                           v-model="FieldSelects.Field">
                  <el-option
                          v-for="option in FieldSelects.ListFields"
                          class="select-primary"
                          :value="option"
                          :label="option.toUpperCase()"
                          :key="option">
                  </el-option>
                </el-select>
              </base-input>
            </div>

          </div>
          <br/>
          <hr>
          <div class="row">
            <div class="col">
              <base-input>
                <el-select filterable class="select-primary"
                           placeholder="EDITEURS"
                           v-model="EditeursSelects.Editeur_Edit">
                  <el-option
                          v-for="option in EditeursSelects.ListEditeur"
                          class="select-primary"
                          :value="option.societe_client"
                          :label="option.societe_client.toUpperCase()"
                          :key="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col">
              <base-input>
                <el-select filterable class="select-primary"
                           placeholder="ANNONCEURS"
                           v-model="AnnonceursSelects.Annonceurs_Edit">
                  <el-option
                          v-for="option in AnnonceursSelects.ListAnnonceurs"
                          class="select-primary"
                          :value="option.id_client+'SPLITE'+option.societe_client"
                          :label="option.societe_client.toUpperCase()"
                          :key="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <div class="col">
              <base-input>
                <el-select filterable class="select-primary"
                           placeholder="MANAGER"
                           v-model="ManagerSelects.Manager_Edit">
                  <el-option
                          v-for="option in ManagerSelects.ListManager"
                          class="select-primary"
                          :value="option.nom_client +' '+ option.prenom_client"
                          :label="option.prenom_client.toUpperCase() +' '+ option.nom_client.toUpperCase()"
                          :key="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>

            <base-input class="col">
              <el-select filterable class="select-primary"
                         placeholder="TEAM MANAGER"
                         v-model="TeamSelects.Team_Edit">
                <el-option
                        v-for="option in TeamSelects.ListTeam"
                        class="select-primary"
                        :value="option.nom_client +' '+ option.prenom_client"
                        :label="option.prenom_client.toUpperCase() +' '+ option.nom_client.toUpperCase()"
                        :key="option.id">
                </el-option>
              </el-select>
            </base-input>

            <div class="col">
              <base-input>
                <el-select filterable class="select-primary"
                           placeholder="PAYS"
                           v-model="PaysBasesSelects.PaysBase_Edit">
                  <el-option
                          v-for="option in PaysBasesSelects.ListPaysBases"
                          class="select-primary"
                          :value="option.iso2"
                          :label="option.name.toUpperCase()"
                          :key="option.id">
                  </el-option>
                </el-select>
              </base-input>
            </div>

          </div>
        </template>
        <hr>
        <br/>
        <base-button @click="selectAllEditTable">TOUT SÉLECTIONNER</base-button>
        <b-table ref="EditTable" responsive selectable select-mode="multi" :fields="fields_Edit" :items="RowsEdit" @row-selected="handleSelectionChangeEdit">

          <template v-slot:cell(#)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
            </template>
          </template>

          <template v-slot:cell(Editeur)="data">
            {{ data.item.Editeur.toUpperCase() }}
          </template>

          <template v-slot:cell(Client)="data">
            {{ data.item.Client.toUpperCase() }}
          </template>

          <!-- <template v-slot:cell(Client)="data">
            <el-select filterable class="select-primary"
              placeholder="Client"
              v-model="data.item.idClient">
              <el-option 
                v-for="option in AnnonceursSelects.ListAnnonceurs"
                class="select-primary"
                :value="option.id_client"
                :label="option.societe_client.toUpperCase()"
                :key="option.id_client">
              </el-option>
            </el-select>
            {{ data.item.idClient }}
            {{ (this.AnnonceursSelects.ListAnnonceurs.find(t => t.id_client === data.item.idClient)).societe_client }}
          </template> -->

          <template v-slot:cell(langue)="data">

            <!-- <el-select filterable class="select-primary"
              placeholder="PAYS"
              v-model="data.item.langue">
              <el-option 
                v-for="option in PaysBasesSelects.ListPaysBases"
                class="select-primary"
                :value="option.iso2"
                :label="option.name.toUpperCase()"
                :key="option.iso2">
              </el-option>
            </el-select> -->
            {{ data.item.langue }}

          </template>

          <template v-slot:cell(trafficManager)="data">
            {{ data.item.trafficManager.toUpperCase() }}
          </template>

          <template v-slot:cell(teamManager)="data">
            {{ data.item.teamManager.toUpperCase() }}
          </template>

          <template v-slot:cell(leads_net)="data">
            {{ data.item.leads_net }}
          </template>

          <template v-slot:cell(prix_lead)="data">
            {{ data.item.prix_lead }}
          </template>

          <template v-slot:cell(prix_client)="data">
            {{ data.item.prix_client }}
          </template>

        </b-table>
      </card>
    </Modal_selfEvent>

    <!-- Mondal Backup -->

    <modal
            :show.sync="ShowBackup"
            body-classes="p-0"
    >
      <card type="secondary"
            header-classes="bg-white pb-6"
            body-classes="px-lg-6 py-lg-6"
            class="border-0 mb-0">

        <div class="form-row">
          <base-input
                  class="col-md-12"
                  label="Nom Sauvegarde"
                  v-model="Backup_name"
                  :error="getError('Nom sauvegarde')"
                  v-validate="'required'"
                  name="Nom sauvegarde"
          >
          </base-input>
        </div>

        <base-button
                type="info"
                @click.native="TakeBackup"
        >VALIDER</base-button>

        <base-button
                type="info"
                @click.native="ShowBackup = false"
        >ANNULER</base-button>

      </card>
    </modal>

    <!-- Mondal Backup -->


    <!-- Mondal Restauration -->

    <modal
            :show.sync="ShowRestauration"
            body-classes="p-0"
    >
      <card type="secondary"
            header-classes="bg-white pb-6"
            body-classes="px-lg-6 py-lg-6"
            class="border-0 mb-0">

        <div class="form-row">
          <base-input class="col-md-12" label="NOM SAUVEGARDE">
            <el-select filterable class="select-primary"
                       placeholder="NOM SAUVEGARDE"
                       v-model="BackupSelects.Backup">
              <el-option
                      v-for="option in BackupSelects.ListBackup"
                      class="select-primary"
                      :value="option.nom_restoration"
                      :label="option.nom_restoration"
                      :key="option.nom_restoration">
              </el-option>
            </el-select>
          </base-input>
        </div>

        <base-button
                type="info"
                @click.native="MakeRestauration"
        >RESTAURER</base-button>

        <base-button
                type="info"
                @click.native="ShowRestauration = false"
        >ANNULER</base-button>

      </card>
    </modal>

    <!-- Mondal Restauration -->

    <fab
            v-if='!$apollo.queries.getRaport.loading'
            position="bottom-left"
            @clear="ClearRows"
            @edit="ShowEditForm"
            @ValideSelection="ValideSelection"
            @delete="DeleteSelection"
            :actions="fabActions"
            main-icon="edit"
            icon-size="medium"
            bg-color="#004F84"
    ></fab>

    <fab
            v-if='!$apollo.queries.getRaport.loading'
            position="bottom-right"
            @SelectAll="SelectAll"
            @backup="backup"
            @restauration="restauration"
            :actions="fabActionsSelct"
            main-icon="list"
            icon-size="medium"
            bg-color="#004F84"
    ></fab>
  </div>
</template>

<script>
  import { Select, Option } from 'element-ui'
  import { Table, TableColumn } from 'element-ui';
  import { Modal } from 'src/components'
  import Modal_selfEvent from 'src/components/Modal_selfEvent'
  import gql from 'graphql-tag'
  import RadialProgressBar from 'vue-radial-progress'
  import fab from 'vue-fab'
  import { BaseRadio } from 'src/components/index';
  import XLSX from 'xlsx'
  import swal from 'sweetalert2';

  export default {
    computed: {
      ListRapports_Filter() {
        let list = null
        try {
          list = this.ListRapports
                  .filter((element) =>
                          element.rapportEditeur.some((rapport) => (rapport.Editeur+"").match(this.FilterEditeursSelects.Editeur) &&
                            (rapport.Client+"").toUpperCase().replace(/[^a-z A-Z0-9]/g,'').match(this.FilterAnnonceursSelects.Annonceurs.toUpperCase().replace(/[^a-z A-Z0-9]/g,'')) &&
                            ((this.FilterCampagnesSelects.Campagnes) ? rapport.Campagnes === this.FilterCampagnesSelects.Campagnes : true ) &&
                            rapport.langue.match(this.PaysBasesSelects.PaysBase) &&
                            rapport.trafficManager.match(this.ManagerSelects.Manager) &&
                            rapport.Provenance.match(this.ProvenanceSelects.Provenance) &&
                            (rapport.Thematique+"").match(this.ThematiqueSelects.Thematique) &&
                            (rapport.id_type_compagne+"").match(this.TypeDiffusionSelects.TypeDiffusion)
                          )
                  )
                  .map(element => {
                    return Object.assign({}, element, {rapportEditeur :
                        element.rapportEditeur.filter(rapport => (rapport.Editeur+"").match(this.FilterEditeursSelects.Editeur) &&
                        (rapport.Client+"").toUpperCase().replace(/[^a-z A-Z0-9]/g,'').match(this.FilterAnnonceursSelects.Annonceurs.toUpperCase().replace(/[^a-z A-Z0-9]/g,'')) &&
                        ((this.FilterCampagnesSelects.Campagnes) ? rapport.Campagnes === this.FilterCampagnesSelects.Campagnes : true ) &&
                        rapport.langue.match(this.PaysBasesSelects.PaysBase) &&
                        rapport.trafficManager.match(this.ManagerSelects.Manager) &&
                        rapport.Provenance.match(this.ProvenanceSelects.Provenance) &&
                        (rapport.Thematique+"").match(this.ThematiqueSelects.Thematique) &&
                        (rapport.id_type_compagne+"").match(this.TypeDiffusionSelects.TypeDiffusion)
                      )}
                    );

                  });
        } catch (error) {
          console.log(error)
        }

        let Clics = 0
        let Leads_brut = 0
        let Leads_net = 0
        let Somme_ht = 0
        let Somme_ttc = 0
        let Somme_ht_c = 0
        let Marge = 0
        list.forEach(rapport => {
          rapport.rapportEditeur.forEach(e => {
            Clics += e.clics
            Leads_brut += e.leads_brut
            Leads_net += e.leads_net
            Somme_ht += e.somme_ht
            Somme_ttc += e.somme_ttc
            Somme_ht_c += e.somme_ht_c
            Marge += e.marge
          });
        });
        this.SumTotal[0].Clics = Clics
        this.SumTotal[0].Leads_brut = Leads_brut
        this.SumTotal[0].Leads_net = Leads_net
        this.SumTotal[0].Somme_ht = Somme_ht
        this.SumTotal[0].Somme_ttc = Somme_ttc
        this.SumTotal[0].Somme_ht_c = Somme_ht_c
        this.SumTotal[0].Marge = Marge
        this.SumTotal[0].Pourcentage = (Somme_ht_c == 0) ? 0 : (Marge / Somme_ht_c) * 100

        this.total = list.length
        this.selected = this.selecteds = []
        // return list.slice(this.from,this.to)
        return list

      },
      progress() {
        return (this.ListRapports.length * 100) / this.Total_Rapport || 0
      },
      completedSteps() {
        return this.ListRapports.length || 0
      },
      totalSteps() {
        return this.Total_Rapport || 0
      }
    },
    watch: {
      showEditRapport(value) {
        if (!value) {
          this.RowsEdit = []
          this.selected = this.selecteds = []
          this.ClearRows()
          this.ClearEditFields()
          this.btn_ShowChangers = false
        } else {
          this.mergeSelection()
        }
      }
    },
    apollo: {
      getRaport: {
        client: 'AUTH',
        query: require("src/graphql/Rapports.gql"),
        fetchPolicy: 'network-only',
        skip() {
          return this.SkipQuery;
        },
        result({ data, loader, networkStatus }) {

          data.getRaport.rapports.forEach(element => {
            element.allchek = false
            if(element.rapportEditeur[element.rapportEditeur.length-1].ID != "-") {
              element.rapportEditeur.push({
                ID: "-",
                Editeur: element.rapportEditeur[0].Editeur,
                Client: "-",
                idClient: "-",
                id_editeur: element.rapportEditeur[0].id_editeur,
                Campagnes: "-",
                Tag: "Revenus reportés depuis les mois précédents",
                clics: 0,
                leads_brut: 0,
                leads_net: 0,
                prix_lead: "-",
                somme_ht: element.sold.sold,
                somme_ttc: element.sold.soldTTC,
                prix_client: "-",
                somme_ht_c: 0,
                marge: 0,
                payee: "-",
                langue: "-",
                trafficManager: "-",
                datecreation_client: "-",
                statut_editeur: "-",
                MOIS: "-",
                ANNEE: "-",
                campagne: "-",
                Thematique: "-",
                Provenance: "-",
                id_type_compagne: "",
                pourcentage: 0
              })
            }
          });

          this.ListRapports = [...this.ListRapports,...data.getRaport.rapports]

          if(data.getRaport.last_editeur != null) {
            this.last_editeur = data.getRaport.last_editeur
            this.Total_Rapport = data.getRaport.total
          }
          else {
            this.SkipQuery = true

            // FILLING FILTERS
            let listEditeur = []
            let listAnnonceurs = []
            let listCampagnes = []

            this.ListRapports.forEach(element => {
              element.rapportEditeur.forEach(rapport => {
                if (rapport.Editeur != "-")
                  listEditeur.push(rapport.Editeur)
                if (rapport.Client != "-")
                  listAnnonceurs.push(rapport.Client)
                if (rapport.Campagnes != "-")
                  listCampagnes.push({ value: rapport.Campagnes, client: rapport.Client})
              });
            });

            this.FilterEditeursSelects.ListEditeur = [...new Set(listEditeur.sort())]
            this.FilterAnnonceursSelects.ListAnnonceurs = [...new Set(listAnnonceurs.sort())]
            // this.FilterCampagnesSelects.ListCampagnes = [...new Set(listCampagnes.sort())]
            this.FilterCampagnesSelects.ListCampagnes = 
            [...new Map(listCampagnes.map(item => [item.value, item])).values()]
            // FILLING FILTERS
          }
        },
        variables () {
          return {
            annee: this.Rapport.year,
            mois: ((this.Rapport.mounth+"").length==1) ? "0"+this.Rapport.mounth : this.Rapport.mounth,
            last_editeur: this.last_editeur,
            total: this.Total_Rapport
          }
        },
        update: data => data
      },
      Filters : {
        query: gql` query {

          getSocieteEditeurs{
            id_client
            societe_client
          }

          listAnnonceur {
            id_client
            email_client
            societe_client
          }

          programmes_objectifs{
            id_programme
            Titre
            id_client
          }

          listPays(iso2:""){
            iso2
            name
          }

          listManager(
            bloque: "non"
            etat: "valide"
            droit: "ma"
            orderBy: [
              { field: "prenom_client", order: ASC }
              { field: "nom_client", order: ASC }
            ]
          ) {
            id_client
            nom_client
            prenom_client
            email_client
          }


          type_compagnes{
            id_type_compagne
            type_2
          }

          listProvenence {
            prov
          }
        }`,
        result({ data, loader, networkStatus }) {
          this.PaysBasesSelects.ListPaysBases = data.listPays
          this.EditeursSelects.ListEditeur = data.getSocieteEditeurs
          this.AnnonceursSelects.ListAnnonceurs = data.listAnnonceur
          this.CampagnesSelects.ListCampagnes = data.programmes_objectifs
          this.ManagerSelects.ListManager = data.listManager
          this.ProvenanceSelects.ListProvenance = data.listProvenence
          this.TypeDiffusionSelects.ListTypeDiffusion = data.type_compagnes
        },
        update: data => data
      },
      teamManager: {
        query: gql` query {

          listManager(
            bloque: "non"
            etat: "valide"
            droit: "tem"
            orderBy: [
              { field: "prenom_client", order: ASC }
              { field: "nom_client", order: ASC }
            ]
          ) {
            id_client
            nom_client
            prenom_client
            email_client
          }

        }`,
        result({ data, loader, networkStatus }) {
          this.TeamSelects.ListTeam = data.listManager
        },
        update: data => data
      },
      getNameSave: {
        client: 'AUTH',
        query: gql`
          query ($annee: String, $mois: String) {
            getNameSave(annee: $annee, mois: $mois) {
              nom_restoration
            }
          }
        `,
        skip() {
          return !this.Rapport
        },
        fetchPolicy: 'no-cache',
        result({ data, loader, networkStatus }) {
          this.BackupSelects.ListBackup = data.getNameSave
          if (data.getNameSave.length)
            this.BackupSelects.Backup = data.getNameSave[0].nom_restoration
        },
        variables () {
          return {
            annee: this.Rapport.year,
            mois: ((this.Rapport.mounth+"").length==1) ? "0"+this.Rapport.mounth : this.Rapport.mounth
          }
        },
        update: data => data
      }
    },
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      Modal,
      Modal_selfEvent,
      RadialProgressBar,
      fab,
      BaseRadio
    },
    data() {
      return {
        fields: [
          '#',
          'ID',
          { key: 'Campagnes', label: 'Campagnes' , tdClass: 'tdtextClass'},
          { key: 'Client', label: 'Client' , tdClass: 'tdtextClass'},
          { key: 'Tag', label: 'Tag' , tdClass: 'tdTagClass'},
          'clics',
          { key: 'leads_brut', label: 'leads brut' , tdClass: 'tdleadsClass'},
          { key: 'leads_net', label: 'leads net' , tdClass: 'tdleadsClass'},
          { key: 'prix_lead', label: 'prix lead' , tdClass: 'tdleadsClass'},
          { key: 'somme_ht', label: 'somme(ht)' , tdClass: 'tdleadsClass'},
          { key: 'somme_ttc', label: 'somme(ttc)' , tdClass: 'tdleadsClass'},
          { key: 'prix_client', label: 'prix client' , tdClass: 'tdleadsClass'},
          { key: 'somme_ht_c', label: 'somme(ht_c)' , tdClass: 'tdleadsClass'},
          { key: 'marge', label: 'marge' , tdClass: 'tdleadsClass'},
          { key: 'pourcentage', label: '%' },
          'payee',
          { key: 'trafficManager', label: 'Traffic Manager' , tdClass: 'tdtextClass'},
          { key: 'Provenance', label: 'Provenance' , tdClass: 'tdtextClass'},
          'action'
        ],
        fields_Edit: [
          '#',
          'ID',
          { key: 'Editeur', label: 'Editeur' },
          { key: 'Client', label: 'Client' },
          { key: 'langue', label: 'Pays' },
          { key: 'trafficManager', label: 'Traffic Manager' },
          { key: 'teamManager', label: 'team Manager' },
          { key: 'leads_net', label: 'leads net' },
          { key: 'prix_lead', label: 'prix lead' },
          { key: 'prix_client', label: 'prix client' }
        ],
        selected: [],
        selecteds: [],
        ShowBackup: false,
        Backup_name: '',
        fabActions: [
          {
            name: 'clear',
            icon: 'clear',
            tooltip: 'Désélectionner'
          },
          {
            name: 'ValideSelection',
            icon: 'playlist_add_check',
            tooltip: 'Valider la sélection'
          },
          {
            name: 'delete',
            icon: 'delete',
            tooltip: 'Supprimer la sélection'
          },
          {
            name: 'edit',
            icon: 'edit',
            tooltip: 'Editer'
          }
        ],
        fabActionsSelct: [
          {
            name: 'restauration',
            icon: 'backup',
            tooltip: 'Restauration'
          },
          {
            name: 'backup',
            icon: 'save',
            tooltip: 'Prendre une sauvegarde'
          },
          {
            name: 'SelectAll',
            icon: 'list',
            tooltip: 'Selectionner tous'
          }
        ],
        SumTotal: [
          { Clics: 0, Leads_brut: 0, Leads_net: 0, Somme_ht: 0, Somme_ttc: 0, Somme_ht_c: 0, Marge: 0 , Pourcentage: 0 }
        ],
        pagination: {
          perPage: 3,
          currentPage: 1,
          total: 0
        },
        pagination2: {
          perPage: 20,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0
        },
        total: 0,
        btn_ShowChangers: false,
        Rapport: null,
        Total_Rapport: null,
        SkipQuery: true,
        showEditRapport: false,
        RowsEdit: [],
        Repport: {
          Editeurs: '',
          Compagnes: '',
          Tag: '',
          Clics: 0,
          Leads_brut: 0,
          Leads_net: 0,
          Prix_lead: 0,
          Prix_client: 0
        },
        showAddRapport: false,
        last_editeur: null,
        ListRapports: [],
        EditeursSelects: {
          Editeur: '',
          Editeur_Edit: '',
          ListEditeur: []
        },
        FilterEditeursSelects: {
          Editeur: '',
          ListEditeur: []
        },
        AnnonceursSelects: {
          Annonceurs: '',
          Annonceurs_Add: '',
          Annonceurs_Edit: '',
          ListAnnonceurs: []
        },
        FilterAnnonceursSelects: {
          Annonceurs: '',
          ListAnnonceurs: []
        },
        CampagnesSelects: {
          Campagnes: '',
          ListCampagnes: []
        },
        FilterCampagnesSelects: {
          Campagnes: '',
          ListCampagnes: []
        },
        PaysBasesSelects: {
          PaysBase: '',
          PaysBase_Add: 'FR',
          PaysBase_Edit: '',
          ListPaysBases: []
        },
        ManagerSelects: {
          Manager: '',
          Manager_Add: '',
          Manager_Edit: '',
          ListManager: []
        },
        TeamSelects: {
          Team: '',
          Team_Edit: '',
          ListTeam: []
        },
        ProvenanceSelects: {
          Provenance: '',
          ListProvenance: []
        },
        AffilieSelects: {
          Affilie: '',
          ListAffilie: [
            { value: "13271",label: "DATADEAL" },
            { value: "10",label: "WEEDOIT" },
            { value: "others",label: "AUTRES" }
          ]
        },
        ThematiqueSelects: {
          Thematique: '',
          ListThematique: [
            {value: 'Adulte & Charme', categorie: 'Adulte & Charme'},
            {value: 'Agence', categorie: 'Agence'},
            {value: 'Nourriture et Boisson', categorie: 'Aliments'},
            {value: 'Animaux', categorie: 'Animaux'},
            {value: 'Assurance', categorie: 'Assurance'},
            {value: 'Audiovisuel', categorie: 'Audiovisuel'},
            {value: 'Automobile', categorie: 'Automobile'},
            {value: 'Business To Business', categorie: 'B2B'},
            {value: 'e-commerce', categorie: 'E-commerce'},
            {value: 'Emplois et formation', categorie: 'Emplois & Formation'},
            {value: 'Énergie & Eau', categorie: 'Énergie & Eau'},
            {value: 'Finance', categorie: 'Finance & Crédit'},
            {value: 'Maison et Jardin', categorie: 'Immobilier'},
            {value: 'Maison et Jardin', categorie: 'Jardin'},
            {value: 'Gaming', categorie: 'Jeux'},
            {value: 'Lingerie & Charme', categorie: 'Lingerie & Charme'},
            {value: 'Livres & Presse', categorie: 'Livres & Presse'},
            {value: 'Maison et Jardin', categorie: 'Logement'},
            {value: 'Luxe', categorie: 'Luxe'},
            {value: 'Musique et produits culturels', categorie: 'Musique & Produits culturels'},
            {value: 'Mobile', categorie: 'Opérateur mobile & internet'},
            {value: 'Photo & Vidéo', categorie: 'Photo & Vidéo'},
            {value: 'Sante et Beaute', categorie: 'Produits pharmaceutiques'},
            {value: 'Reseaux sociaux et Rencontres', categorie: 'Rencontre'},
            {value: 'Sante et Beaute', categorie: 'Santé & Beauté'},
            {value: 'Sports / Loisirs', categorie: 'Sports & Loisirs'},
            {value: 'Tourisme / Loisirs', categorie: 'Tourisme'},
            {value: 'Shopping et Ventes', categorie: 'Ventes privées & Enchères'},
            {value: 'Nourriture et Boisson', categorie: 'Vin, Spiritueux & Tabac'},
            {value: 'Voyance', categorie: 'Voyance'}
          ]
        },
        TypeDiffusionSelects: {
          TypeDiffusion: '',
          ListTypeDiffusion: []
        },
        YearSelects: {
          Year: '2014',
          ListYear: []
        },
        MonthSelects: {
          Month: '01',
          ListMonth: [
            {value: "01"},
            {value: "02"},
            {value: "03"},
            {value: "04"},
            {value: "05"},
            {value: "06"},
            {value: "07"},
            {value: "08"},
            {value: "09"},
            {value: "10"},
            {value: "11"},
            {value: "12"}
          ]
        },
        FieldSelects: {
          Field: 'Leads Net',
          ListFields: ['Leads Net','Prix du lead','Prix client']
        },
        MiltipleRowEdit: [],
        EditPriceMode: '1',
        RuleValue: 0,
        loaderAddRepport: false,
        BackupSelects: {
          Backup: '',
          ListBackup: []
        },
        ShowRestauration: false,
        disableinput: false
      }
    },
    methods: {
      SwitchToCoreg (){
        localStorage.setItem("rapport", JSON.stringify(this.Rapport))
        this.$router.push({ name: "liste des Rapports Coreg" })
      },
      SwitchToPerf (){
        const annee = this.Rapport.year
        const moi = ((this.Rapport.mounth+"").length==1) ? "0"+this.Rapport.mounth : this.Rapport.mounth
        const url = "https://wd@2020:@2020_w@d@www.weedoit.fr/reportv2/reports.php?annee="+annee+"&mois="+moi
        window.open(url, '_blank')
      },
      async DeleteSelection() {
        let IDrow = []
        this.mergeSelection()
        for (let i = 0; i < this.RowsEdit.length; i++) {
          await this.$apollo.mutate({
            client: "AUTH",
            mutation: require("src/graphql/mutation/DeleteReportPerf.gql"),
            variables: {
              id_rapport: this.RowsEdit[i].ID
            }
          }).then(response => {
            this.$notify({type: 'success', message: "Rapport N° "+ this.RowsEdit[i].ID +" a été supprimer" })
            IDrow.push(this.RowsEdit[i].ID)
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errordeleting") })
            console.log(error)
          })
        }

        this.ListRapports.forEach(rapports => {
          rapports.rapportEditeur.forEach((value, index) => {
            for (let i = 0; i < IDrow.length; i++) {
              if(IDrow[i] == value.ID){
                rapports.rapportEditeur.splice(index, 1)
              }
            }
          })
        });

      },
      ClearRows(){
        this.selected = this.selecteds = []
        for(let i = 0; i < this.$refs.tableselectable.length; i++) {
          this.$refs.tableselectable[i].clearSelected()
        }
      },
      ShowEditForm(){
        this.showEditRapport = true
      },
      DupliquerRow(row) {
        this.Repport.Editeurs = row.Editeur
        this.AnnonceursSelects.Annonceurs_Add = ""+row.idClient
        this.Repport.Compagnes = row.Campagnes
        this.Repport.Leads_brut = row.leads_brut
        this.Repport.Leads_net = row.leads_net
        this.Repport.Prix_lead = row.prix_lead
        this.Repport.Prix_client = row.prix_client
        this.Repport.Clics = row.clics
        this.ManagerSelects.Manager_Add = row.trafficManager
        this.PaysBasesSelects.PaysBase_Add = row.langue
        this.YearSelects.Year = row.ANNEE
        this.MonthSelects.Month = row.MOIS

        this.showAddRapport = true
      },
      async EditRow_onEnter(row) {
        this.disableinput = true
        await this.$apollo.mutate({
          client: "AUTH",
          mutation: require("src/graphql/mutation/UpdateReport.gql"),
          variables: {
            id_rapport: row.ID,
            editeur: row.Editeur,
            leads_net: row.leads_net,
            prix_lead: row.prix_lead,
            prix_client: row.prix_client,
            langue: row.langue,
            idclient_annonceur: row.idClient,
            trafficManager: row.trafficManager,
            teamManager: row.teamManager
          }
        }).then(response => {
          this.$notify({type: 'success', message: "Rapport N°: "+ row.ID +" a été modifié avec succès" })
          row.somme_ttc = ((row.leads_net * row.prix_lead) + ((row.leads_net * row.prix_lead) * (20 / 100))),
          row.somme_ht_c = row.leads_net * row.prix_client,
          row.marge = (row.leads_net * row.prix_client)-(row.leads_net * row.prix_lead),
          row.pourcentage = ((row.leads_net * row.prix_client) != 0) ? (((row.leads_net*row.prix_client)-(row.leads_net*row.prix_lead) ) / (row.leads_net*row.prix_client)) * 100 : 0,
          row.pourcentage = this.formatThounsends(row.pourcentage,true,2)
          this.disableinput = false
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          this.disableinput = false
        })
      },
      async updateRapports() {
        if (this.RowsEdit.length) {
          // if(this.MiltipleRowEdit.length)
          //   this.ShowRapportsChangers()
          swal.showLoading()
          let apiresponse = true
          for (let i = 0; i < this.RowsEdit.length; i++) {
            await this.$apollo.mutate({
              client: "AUTH",
              mutation: require("src/graphql/mutation/UpdateReport.gql"),
              variables: {
                id_rapport: this.RowsEdit[i].ID,
                editeur: this.RowsEdit[i].Editeur,
                leads_net: this.RowsEdit[i].leads_net,
                prix_lead: this.RowsEdit[i].prix_lead,
                prix_client: this.RowsEdit[i].prix_client,
                langue: this.RowsEdit[i].langue,
                idclient_annonceur: this.RowsEdit[i].idClient,
                trafficManager: this.RowsEdit[i].trafficManager,
                teamManager: this.RowsEdit[i].teamManager
              }
            }).then(response => {
            }).catch(error => {
              apiresponse = false
            })
          }
          this.ListRapports.forEach((value, index) => {
            value.rapportEditeur.forEach(rapport => {
              for (let i = 0; i < this.RowsEdit.length; i++) {
                if(rapport.ID == this.RowsEdit[i].ID) {
                  rapport.Editeur = this.RowsEdit[i].Editeur,
                          rapport.Client = this.RowsEdit[i].Client,
                          rapport.Tag = this.RowsEdit[i].Tag,
                          rapport.langue = this.RowsEdit[i].langue,
                          rapport.trafficManager = this.RowsEdit[i].trafficManager,
                          rapport.leads_net = parseInt(this.RowsEdit[i].leads_net),
                          rapport.prix_lead = parseFloat(this.RowsEdit[i].prix_lead),
                          rapport.prix_client = parseFloat(this.RowsEdit[i].prix_client)
                  rapport.idClient = parseFloat(this.RowsEdit[i].idClient)
                  rapport.somme_ht = rapport.leads_net * rapport.prix_lead,
                          rapport.somme_ttc = ((rapport.leads_net * rapport.prix_lead) + ((rapport.leads_net * rapport.prix_lead) * (20 / 100))),
                          rapport.somme_ht_c = rapport.leads_net * rapport.prix_client,
                          rapport.marge = (rapport.leads_net * rapport.prix_client)-(rapport.leads_net * rapport.prix_lead),
                          rapport.pourcentage = ((rapport.leads_net * rapport.prix_client) != 0) ? (((rapport.leads_net*rapport.prix_client)-(rapport.leads_net*rapport.prix_lead) ) / (rapport.leads_net*rapport.prix_client)) * 100 : 0,
                          rapport.pourcentage = this.formatThounsends(rapport.pourcentage,true,2)
                  break
                }
              }
            });
          });
          swal.close()
          if (apiresponse) {
            this.$notify({type: 'success', message: "Rapports ont été modifiés avec succès" })
          }
          else {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          }
          this.showEditRapport = false
        }
      },
      Reset() {
        this.FilterEditeursSelects.Editeur = ""
        this.FilterAnnonceursSelects.Annonceurs = ""
        this.FilterCampagnesSelects.Campagnes = ""
        this.PaysBasesSelects.PaysBase = ""
        this.ManagerSelects.Manager = ""
        this.ProvenanceSelects.Provenance = ""
        this.AffilieSelects.Affilie = ""
        this.ThematiqueSelects.Thematique = ""
        this.TypeDiffusionSelects.TypeDiffusion = ""
      },
      getError(fieldName) {
        return this.errors.first(fieldName)
      },
      async AddRepport() {
        let validate
        await this.$validator.validateAll().then(isValid => {
          validate = isValid
        })
        if (!validate)
          return

        this.loaderAddRepport = true

        // ADD row to table if editeur exist
        let EditeurFound = []
        this.ListRapports.forEach((rapport,key) => {
          rapport.rapportEditeur.forEach((editeur,key2) => {
            if (editeur.Editeur == this.Repport.Editeurs) {
              EditeurFound.push(
                      {position: key}
              )
            }
          });
        });
        this.$apollo.mutate({
          client: "AUTH",
          mutation: require("src/graphql/mutation/AddReport.gql"),
          variables: {
            editeur: this.Repport.Editeurs,
            annonceur: this.Repport.Compagnes,
            campagne: this.Repport.Tag,
            clics: this.Repport.Clics,
            leads_brut: this.Repport.Leads_brut,
            leads_net: this.Repport.Leads_net,
            prix_lead: this.Repport.Prix_lead,
            prix_client: this.Repport.Prix_client,
            langue: this.PaysBasesSelects.PaysBase_Add,
            idclient_annonceur: this.AnnonceursSelects.Annonceurs_Add,
            mois: this.MonthSelects.Month,
            annee: this.YearSelects.Year,
            trafficManager: this.ManagerSelects.Manager_Add,
            teamManager: this.TeamSelects.Team
          }
        }).then(response => {
          this.$notify({type: 'success', message: this.$t("apiresponse.reportcreated") })

          // ADD row  to table if editeur exist
          let EditeurFound = []
          this.ListRapports.forEach((rapport,key) => {
            rapport.rapportEditeur.forEach((editeur,key2) => {
              if (editeur.Editeur == this.Repport.Editeurs) {
                EditeurFound.push(
                        {position: key}
                )
              }
            });
          });
          if(EditeurFound.length) {
            this.ListRapports[EditeurFound[0].position].rapportEditeur.push(
                    {
                      ID: response.data.add_report.id_rapport,
                      Editeur: response.data.add_report.editeur,
                      Client: this.AnnonceursSelects.ListAnnonceurs.find(x => x.id_client === response.data.add_report.idclient_annonceur+"").societe_client,
                      idClient: response.data.add_report.idclient_annonceur,
                      id_editeur: "",
                      Campagnes: response.data.add_report.annonceur,
                      Tag: response.data.add_report.campagne,
                      clics: response.data.add_report.clics,
                      leads_brut: response.data.add_report.leads_brut,
                      leads_net: response.data.add_report.leads_net,
                      prix_lead: response.data.add_report.prix_lead,
                      somme_ht: response.data.add_report.leads_net * response.data.add_report.prix_lead,
                      somme_ttc: ((response.data.add_report.leads_net * response.data.add_report.prix_lead) + ((response.data.add_report.leads_net * response.data.add_report.prix_lead) * (20 / 100))),
                      prix_client: response.data.add_report.prix_client,
                      somme_ht_c: response.data.add_report.leads_net * response.data.add_report.prix_client,
                      marge: (response.data.add_report.leads_net * response.data.add_report.prix_client)-(response.data.add_report.leads_net * response.data.add_report.prix_lead),
                      payee: "-",
                      langue: response.data.add_report.langue,
                      trafficManager: response.data.add_report.trafficManager,
                      datecreation_client: "-",
                      statut_editeur: "-",
                      MOIS: response.data.add_report.mois,
                      ANNEE: response.data.add_report.annee,
                      campagne: "-",
                      Thematique: "-",
                      Provenance: "-",
                      id_type_compagne: "",
                      pourcentage: ((response.data.add_report.leads_net * response.data.add_report.prix_client) != 0) ? (((response.data.add_report.leads_net*response.data.add_report.prix_client)-(response.data.add_report.leads_net*response.data.add_report.prix_lead) ) / (response.data.add_report.leads_net*response.data.add_report.prix_client)) * 100 : 0
                    }
            )
          }
          // ADD row to table if editeur exist
          this.showAddRapport = false
          this.loaderAddRepport = false
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
          this.loaderAddRepport = false
        })
      },
      formatThounsends: function(value, fixe = false, number = 0) {
        if (fixe) {
          value = value.toFixed(number);
        }
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      },
      getSummaries(param) {
        const { columns, data } = param;

        let somme_ttc = 0
        let somme_ht = 0
        let clics = 0
        let leads_brut = 0
        let leads_net = 0
        let somme_ht_c = 0
        let marge = 0
        let Pourcentage = 0
        data.forEach(rapport => {
          clics += rapport.clics
          leads_brut += rapport.leads_brut
          leads_net += rapport.leads_net
          somme_ht += rapport.somme_ht
          somme_ttc += rapport.somme_ttc
          somme_ht_c += rapport.somme_ht_c
          marge += rapport.marge
          Pourcentage += rapport.pourcentage
        });

        const sums = [];
        columns.forEach((column, index) => {
          if (index === 5) {
            sums[index] = this.formatThounsends(clics);
            return;
          }
          else if( index === 6) {
            sums[index] = this.formatThounsends(leads_brut)
            return;
          }
          else if( index === 7) {
            sums[index] = this.formatThounsends(leads_net)
            return;
          }
          else if( index === 9) {
            sums[index] = this.formatThounsends(somme_ht,true,2) + ' €'
          }
          else if( index === 10) {
            sums[index] = this.formatThounsends(somme_ttc,true,2) + ' €'
          }
          else if( index === 12) {
            sums[index] = this.formatThounsends(somme_ht_c,true,2) + ' €'
          }
          else if( index === 13) {
            sums[index] = this.formatThounsends(marge,true,2) + ' €'
          }
          else if( index === 14) {
            sums[index] = (somme_ht_c == 0) ? 0 +" %" : this.formatThounsends((marge/somme_ht_c * 100),true,2) + ' %'
          }
          else {
            sums[index] = "";
            return;
          }
        });
        return sums;
      },
      SelectAll() {
        for(let i = 0; i < this.$refs.tableselectable.length; i++) {
          this.$refs.tableselectable[i].selectAllRows()
        }
      },
      removeDuplicates(originalArray, prop) {
        var newArray = [];
        var lookupObject  = {};

        for(var i in originalArray) {
          lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        for(i in lookupObject) {
          newArray.push(lookupObject[i]);
        }
        return newArray;
      },
      handleSelectionChange(index, items) {
        this.selecteds[index] = items
        let combined = []
        this.selecteds.forEach(element => {
          combined = [...combined, ...element];
        });
        this.selected = combined
      },
      async CancelRepport(id_editeur, stat) {
        await this.$apollo.mutate({
          client: "AUTH",
          mutation: require("src/graphql/mutation/Cancel_Report_Stat.gql"),
          variables: {
            id_client: id_editeur,
            mois: ((this.Rapport.mounth+"").length==1) ? "0"+this.Rapport.mounth : this.Rapport.mounth,
            annee: this.Rapport.year
          }
        }).then(response => {
          this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
          stat.etat = "10"
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
        })
      },
      async ValidateRepport(id_editeur, stat) {
        await this.$apollo.mutate({
          client: "AUTH",
          mutation: require("src/graphql/mutation/Validate_Report_Stat.gql"),
          variables: {
            id_client: id_editeur,
            mois: ((this.Rapport.mounth+"").length==1) ? "0"+this.Rapport.mounth : this.Rapport.mounth,
            annee: this.Rapport.year
          }
        }).then(response => {
          this.$notify({type: 'success', message: this.$t("apiresponse.Stateupdated") })
          if (this.getSommeHtEditeur(id_editeur) < 50) {
            stat.etat = "Revenus reportes"
          }
          else {
            stat.etat = "AF emis"
          }
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
        })
      },
      async ValideSelection() {
        this.mergeSelection()
        var uniqueArray = await this.removeDuplicates(this.RowsEdit, "id_editeur")
        for (let i = 0; i < uniqueArray.length; i++) {
          await this.$apollo.mutate({
            client: "AUTH",
            mutation: require("src/graphql/mutation/Validate_Report_Stat.gql"),
            variables: {
              id_client: uniqueArray[i].id_editeur,
              mois: ((this.Rapport.mounth+"").length==1) ? "0"+this.Rapport.mounth : this.Rapport.mounth,
              annee: this.Rapport.year
            }
          }).then(response => {
            this.$notify({type: 'success', message: "État rapport de "+uniqueArray[i].Editeur + " a été modifié" })
            this.ListRapports_Filter.forEach(element => {
              if (this.getSommeHtEditeur(element.rapportEditeur[0].id_editeur) < 50) {
                element.sold.etat = "Revenus reportes"
              }
              else {
                element.sold.etat = "AF emis"
              }
            });
          }).catch(error => {
            this.$notify({type: 'warning', message: this.$t("apiresponse.errorupdate") })
          })
        }
      },
      handleSelectionChangeEdit(items) {
        this.MiltipleRowEdit = items
      },
      ShowRapportsChangers() {
        if (this.MiltipleRowEdit.length) {
          this.btn_ShowChangers = true
        }
        this.MiltipleRowEdit.forEach(rapports => {
          if (this.FieldSelects.Field == 'Leads Net') {
            if (this.EditPriceMode == '1') {
              if (this.RuleValue)
                rapports.leads_net = Math.round(rapports.leads_net * (1 + (this.RuleValue) / 100))
            }
            else if(this.EditPriceMode == '2') {
              if (this.RuleValue)
                rapports.leads_net = Math.round(rapports.leads_net * (1 - (this.RuleValue) / 100))
            }
            else if(this.EditPriceMode == '3') {
              rapports.leads_net = this.RuleValue
            }
          }
          if (this.FieldSelects.Field == 'Prix du lead') {
            if (this.EditPriceMode == '1') {
              if (this.RuleValue)
                rapports.prix_lead = (rapports.prix_lead * (1 + (this.RuleValue) / 100)).toFixed(3)
            }
            else if(this.EditPriceMode == '2') {
              if (this.RuleValue)
                rapports.prix_lead = (rapports.prix_lead * (1 - (this.RuleValue) / 100)).toFixed(3)
            }
            else if(this.EditPriceMode == '3') {
              rapports.prix_lead = this.RuleValue
            }
          }
          if (this.FieldSelects.Field == 'Prix client') {
            if (this.EditPriceMode == '1') {
              if (this.RuleValue)
                rapports.prix_client = (rapports.prix_client * (1 + (this.RuleValue) / 100)).toFixed(3)
            }
            else if(this.EditPriceMode == '2') {
              if (this.RuleValue)
                rapports.prix_client = (rapports.prix_client * (1 - (this.RuleValue) / 100)).toFixed(3)
            }
            else if(this.EditPriceMode == '3') {
              rapports.prix_client = this.RuleValue
            }
          }
          if(this.EditeursSelects.Editeur_Edit != '')
            rapports.Editeur = this.EditeursSelects.Editeur_Edit

          if(this.AnnonceursSelects.Annonceurs_Edit != '') {
            rapports.idClient = this.AnnonceursSelects.Annonceurs_Edit.split("SPLITE")[0]
            rapports.Client = this.AnnonceursSelects.Annonceurs_Edit.split("SPLITE")[1]
          }

          if(this.ManagerSelects.Manager_Edit != '')
            rapports.trafficManager = this.ManagerSelects.Manager_Edit

          if(this.TeamSelects.Team_Edit != '')
            rapports.teamManager = this.TeamSelects.Team_Edit

          if(this.PaysBasesSelects.PaysBase_Edit != '')
            rapports.langue = this.PaysBasesSelects.PaysBase_Edit

        });
      },
      ClearEditFields() {
        this.EditeursSelects.Editeur_Edit = ""
        this.AnnonceursSelects.Annonceurs_Edit = ""
        this.ManagerSelects.Manager_Edit = ""
        this.TeamSelects.Team_Edit = ""
        this.PaysBasesSelects.PaysBase_Edit = ""
        this.FieldSelects.Field = "Leads Net"
        this.EditPriceMode = '1'
        this.RuleValue = 0
      },
      getSommeHtEditeur(id) {
        let somme = 0
        this.ListRapports.forEach(element => {
          element.rapportEditeur.forEach(rapport => {
            if(rapport.id_editeur === id) {
              somme += rapport.somme_ht
            }
          });
        })
        return somme
      },
      backup() {
        this.ShowBackup = true
      },
      async TakeBackup() {
        await this.$apollo.mutate({
          client: "AUTH",
          mutation: require("src/graphql/mutation/SaveReportPerf.gql"),
          variables: {
            mois: ((this.Rapport.mounth+"").length==1) ? "0"+this.Rapport.mounth : this.Rapport.mounth,
            annee: this.Rapport.year,
            nom_sauvegarde: this.Backup_name
          }
        }).then(response => {
          this.$apollo.queries.getNameSave.refresh()
          this.$notify({type: 'success', message: this.$t("apiresponse.backupcreated") })
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.errorcreation") })
        })
      },
      restauration() {
        this.ShowRestauration = true
      },
      async MakeRestauration() {
        await this.$apollo.mutate({
          client: "AUTH",
          mutation: gql`
            mutation ($nom_sauvegarde: String) {
              restauration_rapport (nom_sauvegarde: $nom_sauvegarde) {
                message
              }
            }
          `,
          variables: {
            nom_sauvegarde: this.BackupSelects.Backup
          },
        }).then(response => {
          this.$notify({type: 'success', message: this.$t("apiresponse.restoration") })
          this.ListRapports = []
          this.last_editeur = null
          this.Total_Rapport = null
          this.SkipQuery = false
          this.$apollo.queries.getRaport.refresh()
          this.ShowRestauration = false
        }).catch(error => {
          this.$notify({type: 'warning', message: this.$t("apiresponse.restorationerror") })
        })
      },
      selectAllEditTable() {
        this.$refs.EditTable.selectAllRows()
      },
      mergeSelection() {
        this.RowsEdit = []
        this.selected.forEach(element => {
          if(element.ID != '-' && !this.RowsEdit.find(e => e.ID === element.ID)) {
            this.RowsEdit.push(
                    {
                      ID: element.ID,
                      Editeur: element.Editeur,
                      Client: element.Client,
                      Tag: element.Tag,
                      langue: element.langue,
                      trafficManager: element.trafficManager,
                      teamManager: element.teamManager,
                      leads_net: element.leads_net,
                      prix_lead: element.prix_lead,
                      prix_client: element.prix_client,
                      idClient: element.idClient+"",
                      id_editeur: element.id_editeur
                    }
            )
          }
        });
      },
      getSumClick(rows) {
        let click = 0
        rows.forEach(e => {
          click += parseInt(e.clics)
        });
        return click
      },
      getSumLeads_brut(rows) {
        let leads_brut = 0
        rows.forEach(e => {
          leads_brut += parseInt(e.leads_brut)
        });
        return leads_brut
      },
      getSumLeads_net(rows) {
        let leads_net = 0
        rows.forEach(e => {
          leads_net += parseInt(e.leads_net)
        });
        return leads_net
      },
      getSum_ht(rows) {
        let somme_ht = 0
        rows.forEach(e => {
          somme_ht += parseFloat(e.somme_ht)
        });
        return somme_ht.toFixed(2)
      },
      getSum_htc(rows) {
        let somme_ttc = 0
        rows.forEach(e => {
          somme_ttc += parseFloat(e.somme_ttc)
        });
        return somme_ttc.toFixed(2)
      },
      getSum_ht_c(rows) {
        let somme_ht_c = 0
        rows.forEach(e => {
          somme_ht_c += parseFloat(e.somme_ht_c)
        });
        return somme_ht_c.toFixed(2)
      },
      exportcsv() {
        if (this.ListRapports_Filter.length) {
          let data_export = []
          this.ListRapports_Filter.forEach(repoort => {
            data_export = data_export.concat(repoort.rapportEditeur.filter(function( obj ) {
              delete obj.__typename      
              delete obj.idClient      
              delete obj.id_editeur
              delete obj.id_type_compagne 
              return obj.ID !== '-'; 
            }))
          });
          const fileName = 'rapport_mensuel.xlsx'
          var Sheet_data = XLSX.utils.json_to_sheet(data_export) 
          var wb = XLSX.utils.book_new() 
          XLSX.utils.book_append_sheet(wb, Sheet_data, 'rapport_mensuel') 
          // wb.Sheets.WEEDOMARKET.F1.v = "Volume de leads collectes"
          XLSX.writeFile(wb, fileName)
        }
      },
      deselectOrselectRow(data, index) {
        data.allchek = !data.allchek
        if(data.allchek)
          this.$refs.tableselectable[index].selectAllRows()
        else
          this.$refs.tableselectable[index].clearSelected()
      },
      SwitchToMongustave() {
        let routeData = this.$router.resolve({
          name: "liste des Rapports Mon Gustave"
        });
        window.open(routeData.href, "_blank");
      }
    },
    created() {
      if((this.Rapport = JSON.parse(localStorage.getItem("rapport"))) == null) {
        this.$router.push({ name: "Globale Reports" })
        return
      }
      // localStorage.removeItem("rapport")
      this.SkipQuery = false
      let current_date = new Date()
      let cmm = current_date.getFullYear()
      for (let i = 0; i < 7; i++) {
        this.YearSelects.ListYear.push({
          value: (cmm - i) + ""
        })
      }
      this.YearSelects.Year = this.Rapport.year + ""
      this.MonthSelects.Month = ((this.Rapport.mounth+"").length==1) ? "0"+this.Rapport.mounth : this.Rapport.mounth
    },
  };
</script>

<style scoped>

  .lablelTabel {
    font-weight: 600;
    font-size: 0.99925rem;
    padding-top: 17px;
    padding-right: 17px;
    text-align: right;
  }

  .tab-title {
    font-size: 0.8rem
  }
</style>

<style>
  .tdtextClass {
    min-width: 180px;
  }

  .tdleadsClass {
    min-width: 120px;
  }

  .tdTagClass {
    min-width: 195px;
  }

  .icon-large {
    font-size: 20px !important
  }

  .tdleadsClass .form-control {
    background-color: transparent;
  }
  .icon-Xlarge {
    font-size: 35px !important;
  }
</style>