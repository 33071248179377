<template>
  <div class="content">
    <div id="TOPPAGE"></div>
    <div class="row mt-5">
      <div class="col-md-12">
        <h3>{{ $t('titlePages.listoi') }}</h3>
        <div class="card">
          <div class="card-body">
            <div class="col-md-12">
              <div class="row">
                <div class="col-xl-10 col-lg-12 col-12 order-xl-1 order-1">
                  <div class="row">
                    <div class="col-md-3 mb-2">
                      <div>
                        <base-input :label="$t('table.columns.OInumber').toUpperCase()">
                          <el-select filterable class="select-primary"
                            clearable
                            v-model="OINumberSelects.OINumber">
                            <el-option 
                              v-for="option in OINumberSelects.ListOINumber"
                              class="select-primary"
                              :value="option.numero_oi"
                              :label="option.numero_oi"
                              :key="option.id">
                            </el-option>
                          </el-select>
                        </base-input>
                      </div>
                    </div>
                    <div class="col-md-3 mb-2">
                      <div>
                        <base-input :label="$t('table.columns.CreationDate').toUpperCase()">
                          <el-date-picker v-model="FromDate" value-format="yyyy-MM-dd" type="date" placeholder="YYYY-MM-DD"></el-date-picker>
                        </base-input>
                      </div>
                    </div>
                    <div class="col-md-3 mb-2">
                      <div>
                        <base-input :label="$t('table.columns.SignatureDate').toUpperCase()">
                          <el-date-picker v-model="SignDate" value-format="yyyy-MM-dd" type="date" placeholder="YYYY-MM-DD"></el-date-picker>
                        </base-input>
                      </div>
                    </div>
                    <div class="col-md-3 mb-2">
                      <div>
                        <base-input :label="$t('table.columns.WaitingPaymentPaid').toUpperCase()">
                          <el-select filterable class="select-primary"
                            clearable
                            v-model="PaidSelects.paid">
                            <el-option 
                              v-for="option in PaidSelects.ListPaidStatus"
                              class="select-primary"
                              :value="option.value"
                              :label="option.label"
                              :key="option.nom_societe">
                            </el-option>
                          </el-select>
                        </base-input>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-2 col-lg-12 col-12 order-xl-2 order-3">
						      <div class="row">
                    <base-button 
                      v-if="tableData.length == 0" 
                      loading 
                      class="btn btn-just-icon btn-default mt-4 d-block" 
                    >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
                    <button 
                      v-else 
                      class="btn btn-just-icon btn-default mt-4 d-block" 
                      @click="reset"
                    >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
						      </div>
					      </div>
                <div class="col-xl-12 order-xl-3 order-2">
                  <div class="form-row">
                    <base-input :label="$t('table.columns.SignatureStatus').toUpperCase()" class="col-md-2">
                      <el-select filterable class="select-primary"
                        clearable
                        v-model="SignatureSelects.Signature">
                        <el-option 
                          v-for="option in SignatureSelects.ListSignature"
                          class="select-primary"
                          :value="option.value"
                          :label="option.label"
                          :key="option.nom_societe">
                        </el-option>
                      </el-select>
                    </base-input>

                    <base-input :label="$t('inputs.Society').toUpperCase()" class="col-md-2">
                      <el-select filterable class="select-primary"
                        clearable
                        v-model="SocietySelects.Society">
                        <el-option 
                          v-for="option in SocietySelects.ListSociety"
                          class="select-primary"
                          :value="option.nom_societe"
                          :label="(option.nom_societe.length > 15 ) ? option.nom_societe.slice(0,15).toUpperCase()+'...' : option.nom_societe.toUpperCase()"
                          :key="option.nom_societe">
                        </el-option>
                      </el-select>
                    </base-input>

                    <base-input :label="$t('table.columns.CampaignName').toUpperCase()" class="col-md-2">
                      <el-select filterable class="select-primary"
                        clearable
                        v-model="CampagneSelects.Campagne">
                        <el-option 
                          v-for="option in CampagneSelects.ListCampagne"
                          class="select-primary"
                          :value="option.nom_campagne"
                          :label="option.nom_campagne.slice(0,20).toUpperCase()"
                          :key="option.nom_campagne">
                        </el-option>
                      </el-select>
                    </base-input>

                    <base-input :label="$t('table.columns.SignBy').toUpperCase()" class="col-md-2">
                      <el-select filterable class="select-primary"
                        clearable
                        v-model="ChargeaffaireSelects.Chargeaffaire">
                        <el-option 
                          v-for="option in ChargeaffaireSelects.ListChargeaffaire"
                          v-if="option.nom_client.match('GHALEM') || option.nom_client.match('Kastani') || option.nom_client.match('Irouche')"
                          class="select-primary"
                          :value="option.prenom_client.toUpperCase()+' '+option.nom_client.toUpperCase()"
                          :label="option.nom_client.toUpperCase()+' '+option.prenom_client.toUpperCase()"
                          :key="option.id_client">
                        </el-option>
                      </el-select>
                    </base-input>

                    <base-input label="PLATEFORME" class="col-md-2">
                      <el-select filterable class="select-primary"
                        clearable
                        v-model="platform_filter"
                      >
                        <el-option class="select-primary" value="perf" label="WEEDO PERF"></el-option>
                        <el-option class="select-primary" value="coreg" label="WEEDO COREG"></el-option>
                        <el-option class="select-primary" value="market" label="WEEDO MARKET"></el-option>
                        <el-option class="select-primary" value="data" label="WEEDO DATA"></el-option>
                        <el-option class="select-primary" value="mongustave" label="MONGUSTAVE"></el-option>
                      </el-select>
                    </base-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
        <div class="card">
          <div class="card-body">
            <div>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="col-xl-1 col-6">
                  <base-input>
                    <el-select
                      class="select-primary pagination-select"
                      v-model="pagination.perPage"
                      placeholder="Per page"
                    >
                      <el-option
                        class="select-primary"
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-xl-11 col-6" >
                  <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
                </div>
              </div>
              <el-table :data="filterlistOi">
                <el-table-column
                  prop= 'date_creation_oi'
                  :label='$t("table.columns.CreationDate")'
                  minWidth='130'
                >
                  <template scope="scope">
                    {{ scope.row.date_creation_oi.split(" ")[0] }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop= 'numero_oi'
                  :label='$t("table.columns.OInumber")'
                  minWidth='140'
                >
                </el-table-column>
                <el-table-column
                  prop= 'nom_societe'
                  :label='$t("table.columns.Announcer")'
                  minWidth='170'
                >
                  <template scope="scope">
                    <template v-if="scope.row.nom_societe.length < 15">
                      {{ scope.row.nom_societe.toUpperCase() }}
                    </template>
                    <template v-else>
                      <el-tooltip
                        :content="scope.row.nom_societe"
                        effect="light"
                        :open-delay="300"
                        placement="top"
                      >
                        <label>
                          {{ scope.row.nom_societe.slice(0,15).toUpperCase() }}...
                        </label>
                      </el-tooltip>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  :label='$t("table.columns.CampaignName")'
                  minWidth='170'
                  show-overflow-tooltip
                >
                  <template scope="scope">
                    <template v-if="!scope.row.numero_oi.includes('OI-M')">
                      <!-- PERF -->
                      <template v-if="scope.row.oi_myaffil && scope.row.oi_myaffil.nom_campagne">
                        {{ scope.row.oi_myaffil.nom_campagne.toUpperCase() }},
                      </template>
                      <!-- PERF -->
                      <!-- COREG -->
                      <template v-if="scope.row.oi_mycoreg && scope.row.oi_mycoreg.campagne">
                        {{ scope.row.oi_mycoreg.campagne.toUpperCase() }},
                      </template>
                      <!-- COREG -->
                      <!-- MARKET -->
                      <template v-if="scope.row.oi_leadmarket && scope.row.oi_leadmarket.nom_campagne">
                        {{ scope.row.oi_leadmarket.nom_campagne.toUpperCase() }},
                      </template>
                      <!-- MARKET -->
                      <!-- Datadeal -->
                      <template v-if="scope.row.oi_datadeal && scope.row.oi_datadeal.nom_campagne">
                        {{ scope.row.oi_datadeal.nom_campagne.toUpperCase() }},
                      </template>
                      <!-- Datadeal -->
                    </template>
                    <template v-else>
                        --
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  prop= 'montant_total'
                  :label='$t("table.columns.Amount")+ " (HT)"'
                  minWidth='140'
                >
                  <template scope="scope">
                    <template v-if="!scope.row.numero_oi.includes('OI-M')">
                      {{ (scope.row.montant_total) ? scope.row.montant_total.toLocaleString() : 0 }} €
                    </template>
                    <template v-else>
                        --
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  prop= 'signature_and_tampon'
                  :label='$t("table.columns.OIsigned")'
                  minWidth='150'
                >
                  <template scope="scope">
                    <template v-if="!scope.row.numero_oi.includes('OI-M')">
                      {{ (scope.row.signature_and_tampon) ? scope.row.signature_and_tampon.toUpperCase() : "" }}
                    </template>
                    <template v-else>
                        --
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  :label='$t("table.columns.Platform")'
                  :min-width="120"
                  class="table-striped"
                >
                  <template scope="scope">
                    <template v-if="!scope.row.numero_oi.includes('OI-M')">
                    <span class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.oi_myaffil">
                      <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                    <span class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.oi_mycoreg">
                      <svg version="1.1" id="Calque_1" fill="#54c4d5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                    <span href="#" class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.oi_leadmarket">
                      <svg version="1.1" id="Calque_1" fill="#588bbd" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                    <span href="#" class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.oi_datadeal">
                      <svg version="1.1" id="Calque_1" fill="#f3ac9a" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                        <g>
                          <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                          <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                            c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                            h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                            />
                        </g>
                      </svg>
                    </span>
                    </template>
                    <template v-else>
                      <img src="img/ico-mongustave.png" width="20" height="20" title="MON GUSTAVE"/>
                    </template>
                  </template>
                </el-table-column>
                <el-table-column :min-width="120" align="right" label="Actions">
                  <div slot-scope="props">

                    <el-tooltip
                      content="Verifier la signature"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                      v-if="props.row.status != 'completed'"
                    >
                      <base-button
                        @click.native="handleCheckSign(props.row)"
                        class="btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="fas fa-sync-alt"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip
                      content="Réinitialiser la signature"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                      v-if="props.row.status == 'ready' || props.row.status == 'signed'"
                    >
                      <base-button
                        @click.native="handleClearSign(props.row)"
                        class="btn-link"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="fas fa-trash-restore-alt"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                      content="Dupliquer"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="handleCopy(props.row)"
                        class="btn-link"
                        type="success"
                        size="sm"
                        icon
                        v-if="!props.row.numero_oi.includes('OI-M')"
                      >
                        <i class="tim-icons icon-single-copy-04"></i>
                      </base-button>
                    </el-tooltip>

                    <el-tooltip
                      content="Visualiser"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="handleEdit(props.row)"
                        class="btn-link"
                        type="warning"
                        size="sm"
                        icon
                        v-if="!props.row.numero_oi.includes('OI-M')"
                      >
                        <i class="tim-icons el-icon-view"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip
                      content="PDF"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="handleDownload(props.$index, props.row)"
                        class="btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons el-icon-document"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip
                      v-if="props.row.status == 'completed'"
                      content="OI SIGNÉ"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="handleSignResult(props.$index, props.row)"
                        class="like btn-link"
                        type="success"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-check-2"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip
                      v-else-if="props.row.status == 'signed'"
                      content="1/2 le client a signé"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                    <a :href="props.row.signatureAdminUrl" target="_blank">
                      <base-button
                        class="like btn-link"
                        type="warning"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-upload"></i>
                      </base-button>
                      </a>
                    </el-tooltip>
                    <el-tooltip
                      v-else-if="props.row.status != 'completed'&& props.row.status != 'signed' "
                      content="ENVOI SIGNATURE"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="handleSign(props.$index, props.row)"
                        class="like btn-link"
                        type="info"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-upload"></i>
                      </base-button>
                    </el-tooltip>
                    <el-tooltip
                      content="supprimer"
                      effect="light"
                      :open-delay="300"
                      placement="top"
                    >
                      <base-button
                        @click.native="deleteOi(props.$index, props.row)"
                        class="like btn-link"
                        type="danger"
                        size="sm"
                        icon
                      >
                        <i class="tim-icons icon-trash-simple"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                </el-table-column>
              </el-table>
            </div>
            <div
              slot="footer"
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <div class="">
                <p class="card-category">
                  {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
                </p>
              </div>
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              >
              </base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      :show.sync="ShowPDF"
      body-classes="p-0"
      class="modal-listbase"
      :show-close="true"
      >
        <base-button style="float: right;" @click.native="ShowPDF = false" class="like btn-link" type="danger" size="sm" icon>
          <i class="tim-icons icon-simple-remove"></i>
        </base-button>
        <object :data="PDFURL" type="application/pdf" style="width: 100%;height: 900px;">
          <iframe :src="PDFURL"></iframe>
        </object>
    </modal>
  </div>
</template>

<script>
import {Select, Option ,DatePicker} from 'element-ui'
import { Table, TableColumn } from 'element-ui';
import { BasePagination } from 'src/components';
import gql from 'graphql-tag'
import {Modal} from 'src/components'

export default {
  apollo: {
    filterAuth: { 
      client: 'AUTH',
      query: gql` query {
        listNomSocieteOi{
          nom_societe
        }
      }`,
      result({ data, loader, networkStatus }) {
        this.SocietySelects.ListSociety = data.listNomSocieteOi
      },
      update: data => data
    },
    filtre : { 
      query: gql` query {
        listNumeroOi{
          numero_oi
        },
        listCategorieOi{
          categorie
        },
        listNomCampagneOi{
          nom_campagne
        },
        listTeamOi{
          id_client
          nom_client
          prenom_client
        },
        chargerAffaire(droit:["ca","ad"],etat:"valide",bloque:"non"){
          id_client
          nom_client
          prenom_client
        },
        listTrafficManagerOi{
          id_client
          nom_client
          prenom_client
        },
      }`,
      result({ data, loader, networkStatus }) {
        this.OINumberSelects.ListOINumber = data.listNumeroOi
        this.ChargeaffaireSelects.ListChargeaffaire = data.chargerAffaire
        this.ManagerSelects.ListManager = data.listTrafficManagerOi
        //this.TeamSelects.ListTeam = data.listTeamOi
        this.OICategorieSelects.ListOICategorie = data.listCategorieOi
        this.CampagneSelects.ListCampagne = data.listNomCampagneOi
      },
      update: data => data
    },
    // listOi :
    // { 
    //   client: 'AUTH',
    //   query: gql` query ($id_annonceur: Int) {
    //     listDocumentOi (id_annonceur: $id_annonceur) {
    //       id_oi
    //       paid
    //       langue
    //       date_creation_oi
    //       numero_oi
    //       nom_societe
    //       adresse
    //       complement_adresse
    //       code_postal
    //       ville
    //       pays
    //       nom_representant
    //       prenom_representant
    //       nom_payeur
    //       prenom_payeur
    //       fonction_payeur
    //       email_payeur
    //       tel_payeur
    //       num_tva_or_vat
    //       id_client
    //       status
    //       date_signature
    //       id_oi_old_myaffil
    //       date_diffusion
    //       date_fin
    //       signature_and_tampon
    //       paiement
    //       descriptif
    //       taux_deduplication
    //       taux_depassement
    //       prestations_complementaires
    //       pas_de_frais_pack
    //       montant_total
    //       frais_set_up
    //       prestations_complementaires_prix
    //       transactionId
    //       signatureAdminUrl
    //       oi_myaffil{
    //         id_oi_myaffil
    //         id_oi_globale
    //         nom_campagne
    //         systeme_remuneration
    //         leviers
    //         incentive
    //         prix_unitaire_CPL
    //         prix_unitaire_CPC
    //         prix_unitaire_CPA_fixe
    //         prix_unitaire_CPA_pourcentage
    //         prix_unitaire_CPM
    //         prix_unitaire_CPD
    //         prix_unitaire_Cliclead
    //         prix_unitaire_CPV
    //         prix_unitaire_CPI
    //         prix_unitaire_NUMERO_MOBILE
    //         volume
    //         visible_for_annonceur
    //         id_client
    //         id_programme
    //         id_createur
    //         valider_definitivement
    //         mail_volume
    //         autre
    //         dupliquer_by_ann
    //         dupliquer_by_ad
    //         etat
    //         volume_CPL
    //         volume_CPC
    //         volume_CPA_fixe
    //         volume_CPA_pourcentage
    //         volume_CPM
    //         volume_CPD
    //         volume_Cliclead
    //         volume_CPV
    //         volume_CPI
    //         volume_NUMERO_MOBILE
    //         volume_CPL_illimite
    //         volume_CPC_illimite
    //         volume_CPA_fixe_illimite
    //         volume_CPA_pourcentage_illimite
    //         volume_CPM_illimite
    //         volume_CPD_illimite
    //         volume_Cliclead_illimite
    //         volume_CPV_illimite
    //         volume_CPI_illimite
    //         volume_NUMERO_MOBILE_illimite
    //         montant_total_CPL
    //         montant_total_CPC
    //         montant_total_CPA_fixe
    //         montant_total_CPA_pourcentage
    //         montant_total_CPM
    //         montant_total_CPD
    //         montant_total_Cliclead
    //         montant_total_CPV
    //         montant_total_CPI
    //         montant_total_NUMERO_MOBILE
    //         offre_commerciale
    //         id_manager
    //         nom_manager
    //         prenom_manager
    //         droit
    //         charge_affaire
    //         categorie
    //         id_team
    //       }
    //       oi_mycoreg{
    //         id_oi_mycoreg
    //         id_oi_globale
    //         type
    //         campagne
    //         campagne_id
    //         capping
    //         mentions
    //         champs
    //         filtre_age
    //         filtre_sexe
    //         filtre_geo
    //         filtre_autre
    //         qq
    //         qq_value
    //         date_limite
    //         welcome_mail
    //         livraison_contact
    //         incentive
    //         taux_rejet_maximum
    //         volume
    //         prix_unitaire_CPL
    //         montant_total
    //         id_createur
    //         date_creation
    //       },
    //       oi_leadmarket {
    //         id_oi_leadmarket
    //         id_oi_globale
    //         type_campagne
    //         nom_campagne
    //         leviers
    //         prix_unitaire_Bronze
    //         prix_unitaire_Silver
    //         prix_unitaire_Gold
    //         prix_unitaire_MER
    //         prix_unitaire_Destockage
    //         volume_Bronze
    //         volume_Silver
    //         volume_Gold
    //         volume_MER
    //         volume_Destockage
    //         Incentive_clic_non_autorise
    //         Incentive_remplissage_non_autorise
    //         suppression_mensuelle_maximum
    //       },
    //       oi_datadeal{
    //         id_oi_datadeal
    //         id_oi_globale
    //         nom_campagne
    //         leviers
    //         prix_unitaire_CPL
    //         prix_unitaire_CPC
    //         prix_unitaire_CPD
    //         prix_unitaire_CPM
    //         prix_unitaire_NUMERO_MOBILE
    //         volume_CPL
    //         volume_CPC
    //         volume_CPD
    //         volume_CPM
    //         volume_NUMERO_MOBILE
    //         volume_CPL_illimite
    //         volume_CPC_illimite
    //         volume_CPD_illimite
    //         volume_CPM_illimite
    //         volume_NUMERO_MOBILE_illimite
    //       }
    //     }
    //   }`,
    //   fetchPolicy: 'no-cache',
    //   variables () {
    //     return {
    //       id_annonceur: this.$route.params.id_annonceur
    //     }
    //   },
    //   result({ data, loader, networkStatus }) {
    //     // this.tableData = data.listDocumentOi
    //   },
    //   update: data => data.listDocumentOi
    // },
  },
  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    Modal
  },
  data() {
    return {
      PDFURL: '',
      ShowPDF: false,
      platform_filter: '',
      total: 0,
      searchedData: [],
      FromDate: '',
      SignDate: '',
      ToDate: '',
      Categorie: '',
      OICategorieSelects: {
        OINumber: '',
        ListOICategorie: []
      },
      OINumberSelects: {
        OINumber: '',
        ListOINumber: []
      },
      Platforms: {
        Platform: '',
        ListPlatforms: [
          { name: "WEEDO PERF"},
          { name: "WEEDO COREG"}
        ]
      },
      SocietySelects: {
        Society: '',
        ListSociety: []
      },
      SignatureSelects: {
        Signature: '',
        ListSignature: [
          { value: 'completed', label: 'SIGNATURE TERMINÉ'},
          { value: 'ready', label: 'SIGNATURE EN COURS'},
          { value: 'null', label: 'SIGNATURE EN ATTENTE'}
        ]
      },
      PaidSelects: {
        paid: '',
        ListPaidStatus: [
          { value: 'YES', label: 'PAYÉ'},
          { value: 'NO', label: 'EN ATTENTE DE PAIEMENT'},
        ]
      },
      CampagneSelects: {
        Campagne: '',
        ListCampagne: []
      },
      ChargeaffaireSelects: {
        Chargeaffaire: '',
        ListChargeaffaire: []
      },
      ManagerSelects: {
        Manager: '',
        ListManager: []
      },
      TeamSelects: {
        Team: '',
        ListTeam: [
        { value: "1" , label: "Casablanca"},
        { value: "2" , label: "Lyon"},
        { value: "3" , label: "Datadeal"}
        ]
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      tableColumns: [],
      tableData: []
    }
  },
  watch: {
    FromDate(value) {
      if(this.FromDate == null) {
        this.FromDate = ""
      }
    },
    ToDate(value) {
      if(this.ToDate == null) {
        this.ToDate = ""
      }
    },
  },
  computed: {
    filterlistOi() {
      var list = null
      try {
        list = this.tableData.filter((oi) => {
          if(oi.date_signature == null)
            oi.date_signature = "null"
          if(oi.status == null)
            oi.status = "null"
          if(oi.signature_and_tampon == null)
            oi.signature_and_tampon = ""
          if(oi.oi_myaffil && oi.oi_myaffil.nom_campagne == null)
            oi.oi_myaffil.nom_campagne = ""
          if(oi.oi_mycoreg && oi.oi_mycoreg.campagne == null)
            oi.oi_mycoreg.campagne = ""
          if(oi.oi_leadmarket && oi.oi_leadmarket.nom_campagne == null)
            oi.oi_leadmarket.nom_campagne = ""
          if(oi.oi_datadeal && oi.oi_datadeal.nom_campagne == null)
            oi.oi_datadeal.nom_campagne = ""
            
          return (oi.status+"").match(this.SignatureSelects.Signature) &&
                 (oi.numero_oi+"").match(this.OINumberSelects.OINumber) &&
                 (oi.date_signature+"").match(this.SignDate || "") &&
                 (oi.nom_societe+"").toUpperCase().match(this.SocietySelects.Society.toUpperCase()) &&
                 (oi.date_creation_oi+"").match(this.FromDate) &&
                 (oi.paid+"").match(this.PaidSelects.paid) &&
                 (oi.signature_and_tampon+"").toUpperCase().match(this.ChargeaffaireSelects.Chargeaffaire) &&
                  (
                    (oi.oi_myaffil)
                    ? oi.oi_myaffil.nom_campagne.match(this.CampagneSelects.Campagne)
                    : (oi.oi_mycoreg)
                    ? oi.oi_mycoreg.campagne.match(this.CampagneSelects.Campagne)
                    : (oi.oi_leadmarket)
                    ? oi.oi_leadmarket.nom_campagne.match(this.CampagneSelects.Campagne)
                    : (oi.oi_datadeal)
                    ? oi.oi_datadeal.nom_campagne.match(this.CampagneSelects.Campagne)
                    : true
                  ) 
                  &&
                  (
                    (this.platform_filter)
                    ? oi.oi_platfrm.match(this.platform_filter)
                    : true
                  )
        })
        this.total = list.length
        return list.slice(this.from, this.to);
      } catch (error) {
        console.log(error);
        return null
      }
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  mounted() {
    let elmnt = document.getElementById('TOPPAGE');
    elmnt.scrollIntoView(false);
    const url_api = 'https://api-auth-plf.wee-do-it.net/api/v1/list_documentoi'
    let accessToken = localStorage.getItem("token_jwt")
    let formData = new FormData()
    formData.append("id_annonceur", this.$route.params.id_annonceur || "")
    this.axios.post(url_api, formData, 
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'authorization': `Bearer ${accessToken}`
      }
    })
    .then(response => {
      this.tableData = response.data.response.sort(function(a,b){
        return new Date(b.date_creation_oi) - new Date(a.date_creation_oi);
      })

      this.tableData.forEach(element => {
        element.oi_platfrm = "";
        if (!(element.numero_oi || '').includes('OI-M')) {
          if (element.oi_myaffil) {
            element.oi_platfrm += "perf "
          }

          if (element.oi_mycoreg) {
            element.oi_platfrm += "coreg "
          }

          if (element.oi_leadmarket) {
            element.oi_platfrm += "market "
          }

          if (element.oi_datadeal) {
            element.oi_platfrm += "data "
          }
        }
        else {
          element.oi_platfrm += "mongustave "
        }
        
      });

      console.log(this.tableData)
    })
    .catch(() => {
      console.log('error occured')
    })
  },
  methods: {
    handleCheckSign (oi) {
      this.$apollo.query({
        client: 'AUTH',
        fetchPolicy: 'no-cache',
        query: gql`query ($id_oi: Int, $numero_oi: String) {
          checkSignatureOi (id_oi: $id_oi,numero_oi: $numero_oi) {
            status
            transactionId
            signatureAdminUrl
            signatureUrl
          }
        }`,
        variables: {
          id_oi: oi.id_oi,
          numero_oi:oi.numero_oi
        },
      }).then(data => {
        if(data){
          if(data.data.checkSignatureOi.status != oi.status) {
            oi.status = data.data.checkSignatureOi.status
            oi.transactionId = data.data.checkSignatureOi.transactionId
            oi.signatureAdminUrl = data.data.checkSignatureOi.signatureAdminUrl
            oi.signatureUrl = data.data.checkSignatureOi.signatureAdminUrl
          }
        }
        this.$notify({type: 'success', verticalAlign: 'top', horizontalAlign: 'right', message: 'Le Document OI a été bien actualisé.' });
      }).catch((error) => {
        console.error(error)
      })
    },
    handleClearSign(oi) {
      this.$apollo.query({
        client: 'AUTH',
        fetchPolicy: 'no-cache',
        query: gql`query ($id_oi: Int, $numero_oi: String) {
          clearSignatureOi (id_oi: $id_oi,numero_oi: $numero_oi) {
            message
          }
        }`,
        variables: {
          id_oi: oi.id_oi,
          numero_oi:oi.numero_oi
        },
      }).then(data => {
        if (data.data.clearSignatureOi.message === 'processus de signature terminé veuillez actualiser le document') {
          this.$notify({type: 'success', verticalAlign: 'top', horizontalAlign: 'right', message: 'processus de signature terminé veuillez actualiser le document' });
        }
        else {
          oi.status = null
          oi.transactionId = null
          oi.signatureAdminUrl = null
          oi.signatureUrl = null
          this.$notify({type: 'success', verticalAlign: 'top', horizontalAlign: 'right', message: 'signature réinitialisé avec succès.' });
        }
      }).catch((error) => {
        console.error(error)
      })
    },
    reset() {
      this.OICategorieSelects.OINumber = ''
      this.OINumberSelects.OINumber = ''
      this.SocietySelects.Society= ''
      this.CampagneSelects.Campagne = ''
      this.ChargeaffaireSelects.Chargeaffaire = ''
      this.ManagerSelects.Manager = ''
      this.TeamSelects.Team = ''
      this.Categorie = ''
      this.FromDate = ''
      this.ToDate = ''
    },
    handleEdit(row){
      localStorage.setItem("updateoi", JSON.stringify(row))
      this.$router.push({name: "update Oi"})
    },
    handleDownload($index, row){
      const url_api = "https://api-auth-plf.wee-do-it.net/api/v1/pdf"; //for server test
      let accessToken = localStorage.getItem("token_jwt");
      let formData = new FormData()
      formData.append('id_oi_globale', row.id_oi)
      formData.append('numero_oi', row.numero_oi)
      this.axios
        .post(url_api,formData , {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded',authorization: `Bearer ${accessToken}` },
          responseType: "blob"
        }).then(function(response) {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          this.ShowPDF = true
          this.PDFURL = window.URL.createObjectURL(blob)
        }.bind(this))
        .catch(function (error) {
          console.log(error.message);
        })
    },
    deleteOi(index, row){
      const url_api = "https://api-auth-plf.wee-do-it.net/api/v1/deleteoi"; //for server test
      let accessToken = localStorage.getItem("token_jwt");
      let formData = new FormData()
      formData.append('id_oi', row.id_oi)
      formData.append('numero_oi', row.numero_oi)
      this.axios
        .post(url_api,formData , {
          headers: { authorization: `Bearer ${accessToken}` }
        }).then(response => {
          if(response.data.code == "OI200_3"){
            this.tableData.forEach(rows => {
              if(row == rows){
                this.tableData.splice(index, 1)
              }
            });
            this.$notify({type: 'success', message: 'Document supprimé avec succès'})
          }
        }) 
        .catch(error => {
          console.log(error);
        })
    },
    handleSign($index, row){
      const url_api = "https://api-auth-plf.wee-do-it.net/api/v1/sign"; //for server test
      let accessToken = localStorage.getItem("token_jwt");
      let formData = new FormData()
      formData.append('id_oi_globale', row.id_oi)
      formData.append('numero_oi', row.numero_oi)
      this.axios
        .post(url_api,formData , {
          headers: { authorization: `Bearer ${accessToken}` }
        }).then(response => {
          if(response.data.code == "OI200_01"){
            this.$notify({type: 'success', message: 'signature envoyée avec succès'})
          } else if(response.data.code == "OI200_02"){
            this.$notify({type: 'info', message: 'signature est en cours de traitement'})
          }
        }) 
        .catch(error => {
          console.log(error);
        })
    },
    handleSignResult($index, row){
      const url_api = "https://api-auth-plf.wee-do-it.net/api/v1/signvalide"; //for server test
      let accessToken = localStorage.getItem("token_jwt");
      let formData = new FormData()
      formData.append('transactionId', row.transactionId)
      if(row.type_signature == 'yousign'){
        formData.append('type_signature', row.type_signature)
        formData.append('numero_oi', row.numero_oi)
      }
      this.axios
        .post(url_api,formData , {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded',authorization: `Bearer ${accessToken}` },
          responseType: "blob"
        }).then(function(response) {
          let blob = new Blob([response.data], { type: 'application/pdf' })
          let link = document.createElement('a')
          this.ShowPDF = true
          this.PDFURL = window.URL.createObjectURL(blob)
        }.bind(this))
        .catch(function (error) {
          console.log(error.message);
        })
    },
    handleCopy(row) {
      localStorage.setItem("Copyoi", JSON.stringify(row))
      if (row.oi_myaffil != null)
        this.$router.push({name: "add_oi" ,params: { id_utilisateur: row.id_client, id_annonceur: row.oi_myaffil.id_client } })
      else 
        this.$router.push({name: "add_oi" ,params: { id_utilisateur: row.id_client } })
    }
  }
}
</script>