<template>
  <div class="content">
    <h3>LOGS</h3>
    <card>
      <base-input class="col-md-4">
        <el-select
          filterable
          clearable
          class="select-primary"
          v-model="PlatformSelects.Platform"
          :placeholder='$t("table.columns.Platform").toUpperCase()'
        >
          <el-option
            v-for="option in PlatformSelects.listPlatform"
            class="select-primary"
            :value="option.value"
            :label="option.label"
            :key="option.value">
          </el-option>
        </el-select>
      </base-input>
    </card>
    <card>
      <div class="row">
        <div class="col-xl-1 col-sm-6">
          <base-input>
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div class="col-xl-8 col-sm-6">
          <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
        </div>
      </div>
      <el-table :data="queriedLogs.slice(from, to)" row-class-name="booking-table-rows" v-loading="$apollo.queries.logs.loading">
        <el-table-column
          prop='updated_at'
          :label='$t("table.columns.Date&Hour")'
          minWidth='150'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <!-- <el-table-column
          prop='adresse_ip'
          label="Ip"
          minWidth='150'
          show-overflow-tooltip
          sortable
        >
        </el-table-column> -->
        <el-table-column
          prop='total_cnx'
          :label='$t("table.columns.NumberConnection")'
          minWidth='180'
          show-overflow-tooltip
          sortable
        >
          <template scope="scope">
            <base-button @click="ShowIPsModal = true;IPs = scope.row.adresse_ips" type="info" size="sm">
              {{ scope.row.total_cnx }}
            </base-button>
          </template>
        </el-table-column>
        <el-table-column
          prop='society'
          :label='$t("table.columns.Society")'
          minWidth='150'
          show-overflow-tooltip
          sortable
        >
          <template scope="scope">
            <el-tooltip
              :content="scope.row.first_name + ' ' + scope.row.last_name"
              effect="light"
              :open-delay="300"
              placement="top"
            >
              <div class="tm_photo mr-2 float-left">
                <img
                  class="img-fluid"
                  :src=" (scope.row.image) ? 'https://weedoit-media.s3.eu-central-1.amazonaws.com/'+scope.row.image : '/img/placeholder.jpg'"
                />
              </div>
            </el-tooltip>
            {{ scope.row.society }}
          </template>
        </el-table-column>
        <el-table-column
          prop='email'
          :label='$t("inputs.Email")'
          minWidth='180'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop='country_ip'
          :label='$t("inputs.Country")'
          minWidth='120'
          show-overflow-tooltip
          sortable
        >
        </el-table-column>
        <el-table-column
          prop=""
          :label='$t("table.columns.Platform")'
          :min-width="180"
          class="table-striped"
        >
          <template scope="scope">
            <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.id_clien_myaffil != '0'">
              <svg version="1.1" id="Calque_1" fill="#988fc3" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                <g>
                  <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                  <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                    c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                    h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                    />
                </g>
              </svg>
            </button>
            <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.id_clien_mycoreg != '0'">
              <svg version="1.1" id="Calque_1" fill="#54c4d5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                <g>
                  <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                  <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                    c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                    h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                    />
                </g>
              </svg>
            </button>
            <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.id_clien_datadeal != '0'">
              <svg version="1.1" id="Calque_1" fill="#f3ac9a" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                <g>
                  <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                  <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                    c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                    h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                    />
                </g>
              </svg>
            </button>
            <button class="btn btn-link btn-default btn-icon btn-sm like" v-if="scope.row.id_clien_leadmarket != '0'">
              <svg version="1.1" id="Calque_1" fill="#588bbd" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 355.2 394.3" style="enable-background:new 0 0 355.2 394.3" xml:space="preserve">
                <g>
                  <polygon class="st1" points="183.5,95.9 158.8,167.8 135.1,99 121.4,99 150.6,184 167,184 183.5,135.9 193,163.6 199.9,143.6 	"/>
                  <path class="st1" d="M177.6,41.6c-53.4,0-96.8,43.4-96.8,96.8c0,39.5,23.7,73.4,57.7,88.5l4.1-12c-29-13.3-49.1-42.6-49.1-76.5
                    c0-46.4,37.8-84.2,84.2-84.2c46.4,0,84.2,37.8,84.2,84.2c0,39.2-26.9,72.2-63.2,81.5l4-11.7L239.7,99h-16.1l-25.2,73.8l-11.7,34.1
                    h0.2l-5.3,15.7l-4.4,12.8c0.1,0,0.3,0,0.4,0c5.5,0,10.9-0.5,16.2-1.4c45.7-7.7,80.6-47.6,80.6-95.5C274.4,85,231,41.6,177.6,41.6z"
                    />
                </g>
              </svg>
            </button>
          </template>
        </el-table-column>
      </el-table>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
      >
        <div class="">
          <p class="card-category">
            {{ $t('labels.Datatable.Showing') }} {{ from + 1 }}  {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}
          </p>
        </div>
        <base-pagination
          type="market"
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        >
        </base-pagination>
      </div>
    </card>

    <!-- modal ips addresse -->
    <modal
      :show.sync="ShowIPsModal"
      footerClasses="justify-content-center"
      class="modal-showInfoLeads"
      body-classes="p-0"
    >
      <card class="border-0 mb-0">
        <template slot="header">

          <el-tooltip
            content="Quitter"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              @click.native="ShowIPsModal = false"
              class="btn-link float-right"
              type="danger"
              size="sm"
              icon
            >
              <i class="tim-icons icon-simple-remove"></i>
            </base-button>
          </el-tooltip>
        </template>

        <card class="col-sm-12">
          <h3>IP</h3>
          <div class="table-responsive">
            <base-table :data="[{index:1}]" thead-classes="text-primary" >
              <template slot-scope="{ row }">
                <td v-for="(ip, i) in IPs.split(',')" :key="i">
                  {{ ip }}
                </td>
              </template>
            </base-table>
          </div>
        </card>

      </card>
    </modal>
    <!-- modal ips addresse -->
  </div>
</template>

<script>
import { Select, Option, DatePicker } from 'element-ui'
import { Table, TableColumn } from 'element-ui'
import { BasePagination, Modal, BaseTable } from 'src/components';
import gql from 'graphql-tag'

export default {
  apollo: {
    logs: {
      client: 'MARKET',
      query: gql` query {
        logs {
          utilisateur_id
          adresse_ip
          connexion_number
          updated_at
          society
          country
          image
          last_name
          first_name
          id_clien_datadeal
          id_clien_leadmarket
          id_clien_myaffil
          id_clien_mycoreg
          email
          country_ip
        }
      }`,
      result({ data, loader, networkStatus }) {
        let grouped = alasql('SELECT *,SUM(connexion_number) as total_cnx,GROUP_CONCAT([adresse_ip]) as adresse_ips from ? GROUP BY email',[data.logs])
        this.logs = grouped
      },
      fetchPolicy: 'network-only'
    }
  },
  computed: {
    queriedLogs() {
      let result = [];
      result = this.logs.filter((log) => {
        if (this.PlatformSelects.Platform === 'perf') {
          return log.id_clien_myaffil != '0'
        }
        else if (this.PlatformSelects.Platform === 'market') {
          return log.id_clien_leadmarket != '0'
        }
        else if (this.PlatformSelects.Platform === 'coreg') {
          return log.id_clien_mycoreg != '0'
        }
        else if (this.PlatformSelects.Platform === 'data') {
          return log.id_clien_datadeal != '0'
        }
        else {
          return true
        }
      })
      return result
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.queriedLogs.length
    }
  },
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    BasePagination,
    Modal,
    BaseTable
  },
  data() {
    return {
      ShowIPsModal: false,
      IPs: '',
      logs: [],
      PlatformSelects: {
        Platform: '',
        listPlatform: [
          { value: 'perf', label: 'WEEDO PERF'},
          { value: 'market', label: 'WEEDO MARKET'},
          { value: 'coreg', label: 'WEEDO COREG'},
          { value: 'data', label: 'WEEDO DATA'},
        ]
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [50, 100, 250, 500],
        total: 0
      },
    }
  },
}
</script>

<style>
  .booking-table-rows {
    text-transform: uppercase;
  }
  .tm_photo {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }
  .tm_photo img {
    width: 100%;
  }
</style>
