<template>
  <div class="content">
    <div class="row mt-5">
      <div class="col-md-12">
        <card>
          <div class="col-md-12">
            <div class="row">

              <base-input class="col-md-3" label="CLIENT">
                <el-select filterable class="select-primary"
                  placeholder="CLIENT"
                  clearable
                  v-model="AnnonceursSelects.Annonceurs">
                  <el-option
                    v-for="option in AnnonceursSelects.ListAnnonceurs"
                    class="select-primary"
                    :value="option.societe_client"
                    :label="option.societe_client.toUpperCase()"
                    :key="option.id">
                  </el-option>
                </el-select>
              </base-input>

              <base-input class="col-md-3" label="CAMPAGNE">
                  <el-select filterable class="select-primary"
                    placeholder="CAMPAGNES"
                    clearable
                    v-model="CampagnesSelects.Campagnes">
                    <el-option
                      v-for="option in CampagnesSelects.ListCampagnes"
                      class="select-primary"
                      :value="option.Titre"
                      :label="option.Titre.toUpperCase()"
                      :key="option.id_programme">
                    </el-option>
                  </el-select>
                </base-input>

              <base-input class="col-md-2" label="TM PRINCIPAL">
                <el-select filterable class="select-primary"
                  placeholder="TM PRINCIPAL"
                  clearable
                  v-model="ManagerSelects.Manager">
                  <el-option
                    v-for="option in ManagerSelects.ListManager"
                    class="select-primary"
                    :value="option.nom_client +' '+ option.prenom_client"
                    :label="option.prenom_client +' '+ option.nom_client.toUpperCase()"
                    :key="option.id_client">
                  </el-option>
                </el-select>
              </base-input>

              <base-input class="col-md-2" label="TEAM">
                <el-select filterable class="select-primary"
                  placeholder="TEAM"
                  clearable
                  v-model="TeamSelects.Team">
                  <el-option
                    v-for="option in TeamSelects.ListTeam"
                    class="select-primary"
                    :value="option.value"
                    :label="option.label"
                    :key="option.value">
                  </el-option>
                </el-select>
              </base-input>
              <base-input class="col-md-2" label="RÉMUNÉRATION">
                <el-input
                  type="search"
                  class="mb-3 search-input"
                  clearable
                  prefix-icon="el-icon-search"
                  placeholder="RECHERCHER"
                  v-model="search_type_programme"
                  aria-controls="datatables"
                >
                </el-input>
              </base-input>
              <div class="col-md-3">
                <base-input label="ANNÉE">
                  <el-select
                    class="select-primary"
                    placeholder="ANNÉE"
                    v-model="listeYears.currentyear"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in listeYears.years"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.value"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md-3">
                <base-input label="MOIS">
                  <el-select
                    class="select-primary"
                    placeholder="MOIS"
                    v-model="listeMois.currentmois"
                    filterable
                    clearable
                  >
                    <el-option
                      v-for="option in listeMois.mois"
                      class="select-primary"
                      :value="option.value"
                      :label="option.label.toUpperCase()"
                      :key="option.value"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
          </div>
          <div class="col-md-12 float-right">
            <div class="btn-group btn-group-toggle float-right" data-toggle="buttons">
              <button
                @click="resetFilter"
                class="btn btn-round btn-just-icon btn-primary mb-2 pull-right"
              >{{ $t("buttons.BtnReset").toUpperCase() }}</button>
              <base-button
                v-if="$apollo.queries.getStatsValideMyAffil.loading"
                loading
                round
                class="btn btn-round btn-just-icon btn-primary mb-2"
              >{{ $t("buttons.BtnLoad").toUpperCase() }}...</base-button>
              <button
                v-else @click="rechercher"
                class="btn btn-round btn-just-icon btn-primary mb-2"
              >{{ $t("buttons.BtnSearch").toUpperCase() }}</button>
            </div>
          </div>
        </card>
        <card>
          <div>
            <div class="row">
              <div class="col-xl-1 col-6">
                <base-input>
                  <el-select
                    class="select-primary pagination-select"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-xl-11 col-6">
                <label style="margin-top: 10px; font-size: 0.75rem">{{ $t("labels.Datatable.NbrLinePerPage").toUpperCase() }}</label>
              </div>
            </div>
            <el-table :data="filterRequest" show-summary :summary-method="getSummaries" row-class-name="uppercase-table-rows">
              <el-table-column
                label="CLIENT"
                prop="societe_client"
                class="table-striped"
                min-width="180"
                show-overflow-tooltip
                sortable>
              </el-table-column>
              <el-table-column
                label="CAMPAGNE"
                prop="campagne"
                class="table-striped"
                min-width="180"
                show-overflow-tooltip
                sortable
                >
              </el-table-column>
              <el-table-column
                label="TM"
                prop="trafficManager"
                class="table-striped"
                min-width="60">
                <template scope="scope">
                  <ApolloQuery
                    clientId="AUTH"
                    :query="gql => gql`
                      query ($email: String!) {
                        userByEmail (email: $email)  {
                          image
                        }
                      }
                    `"
                    :variables="{
                      email: scope.row.manager
                    }"
                  >
                    <template v-slot="{ result: { loading, error, data } }">
                      <div v-if="data" class="tm_photo mr-2 float-left">
                        <el-tooltip
                          :content="scope.row.trafficManager"
                          effect="light"
                          :open-delay="300"
                          placement="top"
                        >
                          <img
                            class="img-fluid"
                            :src=" (data.userByEmail) ? 'https://weedoit-media.s3.eu-central-1.amazonaws.com/'+data.userByEmail.image : '/img/placeholder.jpg'">
                          </img>
                        </el-tooltip>
                      </div>
                      <div v-else-if="error" class="error apollo"></div>

                    </template>
                  </ApolloQuery>
                </template>
              </el-table-column>
              <el-table-column
                label="CLIQUES"
                prop="cliques"
                class="table-striped"
                min-width="125"
                :sortable="true" :sort-method="cliquesCustomSort"
                show-overflow-tooltip>
                <template scope="scope">{{ formatThounsends(scope.row.cliques) }}</template>
              </el-table-column>
              <el-table-column
                label="Leads clients"
                prop="leads_brut"
                min-width="150"
                class="table-striped"
                sortable
                show-overflow-tooltip>
                <template scope="scope">{{ formatThounsends(scope.row.leads_brut) }}</template>
              </el-table-column>
              <el-table-column
                label="Leads éditeurs"
                prop="leads_net"
                min-width="155"
                class="table-striped"
                sortable
                show-overflow-tooltip>
                <template scope="scope">{{ formatThounsends(scope.row.leads_net) }}</template>
              </el-table-column>
              <el-table-column
                label="TARIF"
                prop="prix_client"
                min-width="100"
                class="table-striped"
                sortable
                show-overflow-tooltip>
                <template scope="scope">{{ formatThounsends(scope.row.prix_client, true, 2) + ' €' }}</template>
              </el-table-column>
               <el-table-column
                label="TEAM"
                prop="site"
                min-width="95"
                class="table-striped"
                sortable
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                label="RÉM"
                prop="type_programme"
                min-width="90"
                class="table-striped"
                sortable
                show-overflow-tooltip>
              </el-table-column>
              <el-table-column
                label="CHIFFRES CLIENTS"
                prop="leads_valide"
                min-width="170"
                class="table-striped"
                :sortable="true" :sort-method="leadsValideCustomSort"
                >
                <template scope="scope">
                  <base-input
                    type="text"
                    @keyup.enter.native="changeChiffreClients($event, scope.row)"
                    :value="scope.row.leads_valide > 0 ? formatThounsends(scope.row.leads_valide) : 0 "/>
                </template>
              </el-table-column>
              <el-table-column
                label="% VALID"
                prop="pourcentage"
                min-width="120"
                class="table-striped"
                sortable
                :sort-method="CustomSort_prcVALID">
                <template scope="scope">{{ pourcentage(scope.row.leads_valide, scope.row.leads_net) + ' %' }}</template>
              </el-table-column>

              <el-table-column
                label="VALIDER"
                prop="etat"
                min-width="140"
                class="table-striped"
                @change="validateStats"
                >
                <template scope="scope">
                  <el-select class="select-primary"
                            placeholder="VALIDE"
                            v-model="scope.row.etat"
                            @change="validateStats(scope.row)"
                            >
                    <el-option v-for="option in listEtats.etats"
                              class="select-success"
                              :value="option.value"
                              :label="option.label"
                              :key="option.label">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                label="COMMENTAIRES"
                prop="commentaire_comptabilite"
                min-width="160"
                class="table-striped"
                sortable
                show-overflow-tooltip>
                <template scope="scope">{{ scope.row.commentaire_comptabilite }}</template>
              </el-table-column>

              <el-table-column
                prop label="Action"
                class="table-striped"
                min-width="90">
                <template scope="scope">
                  <el-tooltip
                    content="VALIDER"
                    effect="light"
                    :open-delay="300"
                    placement="top"
                  >
                    <base-button
                      @click.native="showCommentaire(scope.row)"
                      class="like btn-link"
                      type="warning"
                      size="sm"
                      icon
                    >
                      <i class="tim-icons icon-pencil"></i>
                    </base-button>
                  </el-tooltip>
                </template>
              </el-table-column>

            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div>
              <p
                class="card-category"
              >{{ $t('labels.Datatable.Showing') }} {{ from + 1 }} {{ $t('labels.Datatable.to') }} {{ to }} {{ $t('labels.Datatable.of') }} {{ total }} {{ $t('labels.Datatable.entries') }}</p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>

    <modal
      :show.sync="commentaireShow"
      footerClasses="justify-content-center"
      class="modal-listbase"
      body-classes="p-0"
    >
      <card class="border-0 mb-0">
        <h3 slot="header" class="modal-title" v-if="commentaireAdd">
          AJOUTER LE COMMENTAIRE
        </h3>
        <h3 slot="header" class="modal-title" v-else>
          MODIFICATION LE COMMENTAIRE
        </h3>

        <div class="col-sm-12">
          <base-input label="COMMENTAIRE">
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="comments"></textarea>
          </base-input>

        </div>

        <div  class="text-center">
          <base-button
            @click.native="editCommentaire('add')"
            type="info"
            v-if="commentaireAdd"
            >AJOUTER
          </base-button>
          <base-button
            @click.native="editCommentaire('edit')"
            type="info"
            v-else
            >MODIFIER
          </base-button>
        </div>
      </card>
    </modal>


  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { Table, TableColumn } from "element-ui";
import gql from "graphql-tag";
import { BasePagination } from "src/components";
import swal from 'sweetalert2'
import { Modal } from "src/components";
import moment from 'moment'

export default {
  apollo: {
    getStatsValideMyAffil: {
      query: gql`
        query ($annee: String ,$mois: String){
          getStatsValideMyAffil(annee: $annee, mois: $mois)
          {
            id_rapport
            id_client
            editeur
            annonceur
            campagne
            clics
            leads_brut
            leads_net
            prix_lead
            prix_client
            langue
            idclient_annonceur
            mois
            annee
            trafficManager
            teamManager
            societe_client
            cliques
            site
            leads_valide
            type_programme
            pourcentage
            etat
            id_sv
            commentaire_comptabilite
            manager
          }
        }`,
        skip(){
          return this.SkipQuery
        },
        variables() {
          return {
            annee: this.listeYears.currentyear,
            mois: this.listeMois.currentmois
          }
        },
        result({ data, loader, networkStatus }) {
          this.Flag = true
          this.SkipQuery = true
          this.AllRequests  = data.getStatsValideMyAffil
        }
    },
    Filters : {
      query: gql` query {
        listAnnonceur {
          id_client
          email_client
          societe_client
        }

        programmes_objectifs{
          id_programme
          Titre
          id_client
        }

        listManager(
          bloque: "non"
          etat: "valide"
          droit: "ma"
          orderBy: [
            { field: "prenom_client", order: ASC }
            { field: "nom_client", order: ASC }
          ]
        ) {
          id_client
          nom_client
          prenom_client
          email_client
        }

      }`,
      result({ data, loader, networkStatus }) {
        this.AnnonceursSelects.ListAnnonceurs = data.listAnnonceur
        this.CampagnesSelects.ListCampagnes = data.programmes_objectifs
        this.ManagerSelects.ListManager = data.listManager
      },
      update: data => data
    }
  },
  components: {
    BasePagination,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    Modal
  },
  data() {
    return {
      commentaireAdd: true,
      commentaireShow: false,
      comments: "",
      id_sv: null,
      listEtats: {
        etats: [
            {value: null, label: '---'},
            {value: 1, label: 'OUI'},
            {value: 0, label: 'NON'}
          ]
      },
      listeMois: {
          currentmois: "",
          mois: [
              { value: "01", label: "Janvier" },
              { value: "02", label: "Février" },
              { value: "03", label: "Mars" },
              { value: "04", label: "Avril" },
              { value: "05", label: "Mai" },
              { value: "06", label: "Juin" },
              { value: "07", label: "Juillet" },
              { value: "08", label: "Août" },
              { value: "09", label: "Septembre" },
              { value: "10", label: "Octobre" },
              { value: "11", label: "Novembre" },
              { value: "12", label: "Décembre" }
          ]
      },
      listeYears: {
          currentyear: "",
          years: []
      },
      SUMClics: 0,
      leadsBrut: 0,
      leadsNet: 0,
      leadsValide: 0,
      pourcentageValidesGlobal: 0,
      AnnonceursSelects: {
        Annonceurs: '',
        ListAnnonceurs: []
      },
      CampagnesSelects: {
        Campagnes: '',
        ListCampagnes: []
      },
      ManagerSelects: {
        Manager: '',
        Manager_Add: '',
        ListManager: []
      },
      TeamSelects: {
        Team: '',
        ListTeam: [
          { value: "LYON", label: "LYON" },
          { value: "CASA", label: "CASA" }
        ]
      },
      search_type_programme: "",
      AllRequests: [],
      SkipQuery: true,
      Flag: false,
      pagination: {
        perPage: 100,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100, 500, 1000],
        total: 0
      },
      total: 0
    }
  },
  methods: {
    showCommentaire(row){
      this.comments = row.commentaire_comptabilite
      this.id_sv = row.id_sv
      if(this.comments != ''){
        this.commentaireAdd = false
      }else{
        this.commentaireAdd = true
      }
      this.commentaireShow = true
    },
    editCommentaire(action){
      console.log(action, this.id_sv, this.comments)
      let id_sv = this.id_sv
      let comments = this.comments

      this.$apollo.mutate({
        // Query
        client: 'AUTH',
        mutation: gql`mutation ($id_sv: Int! $comments: String) {
            edit_comment(
              id_sv: $id_sv
              comments: $comments
            )
            {
              state
            }
          }`,
          variables: {
            id_sv: id_sv,
            comments: comments
          },
      }).then((data) => {
        if(data.data.edit_comment.state){
          this.filterRequest.forEach((value, index) => {
            if(value.id_sv == id_sv){
              this.filterRequest[index].commentaire_comptabilite = comments
            }
          });
          this.$notify({
            type: "success",
            verticalAlign: "top",
            horizontalAlign: "right",
            message: "Le commentaire a été bien modifié.",
            duration: 5000
          });
          this.commentaireShow = false
        }
      }).catch((error) => {
        // Error
        console.error(error)
      })
    },
    changeChiffreClients(e, row){

      var newvalue = e.target.value;
      var id_sv = row.id_sv
      var programme = row.campagne

      if(newvalue == '') {
        this.$notify({
          type: "warning",
          verticalAlign: "top",
          horizontalAlign: "right",
          message: "Entrer le chiffre client s'il vous plait!",
          duration: 5000
        });
      }else{
        swal.fire({
          title: 'Êtes-vous sûr?',
          text: "Vous êtes en train de modifier le chiffre client!",
          width: 400,
          showCancelButton: true,
          confirmButtonColor: '#988fc3',
          cancelButtonColor: '#27293D',
          confirmButtonText: 'Modifier',
          cancelButtonText: 'Annuler'
        }).then((result) => {
          if (result.value) {
            this.$apollo.mutate({
            // Query
            client: 'AUTH',
            mutation: gql`mutation (
                                      $stats_valide_id: Int
                                      $programme: String
                                      $annee: String
                                      $mois: String
                                      $leads_valide: Int
                                    ) {
                change_stats_leads_valide(
                  stats_valide_id: $stats_valide_id
                  programme: $programme
                  annee: $annee
                  mois: $mois
                  leads_valide: $leads_valide
                )
                {
                  state
                }
              }`,
              variables: {
                stats_valide_id: id_sv,
                programme: programme,
                annee: this.listeYears.currentyear,
                mois: this.listeMois.currentmois,
                leads_valide: parseInt(newvalue)
              },
            }).then((data) => {
              if(data.data.change_stats_leads_valide.state){
                row.leads_valide = newvalue
                this.$notify({
                  type: "success",
                  verticalAlign: "top",
                  horizontalAlign: "right",
                  message: "Le chiffre client a été bien modifié.",
                  duration: 5000
                });
              }
            }).catch((error) => {
              // Error
              console.error(error)
            })
          }
        })
      }
    },
    validateStats(row){
      swal.fire({
        title: 'Êtes-vous sûr?',
        text: "Vous êtes en train de modifier l'état!",
        width: 400,
        showCancelButton: true,
        confirmButtonColor: '#988fc3',
        cancelButtonColor: '#27293D',
        confirmButtonText: 'Modifier',
        cancelButtonText: 'Annuler'
      }).then((result) => {
        if (result.value) {
          this.$apollo.mutate({
          // Query
          client: 'AUTH',
          mutation: gql`mutation ($id_sv: Int! $etat: Int) {
              validate_stats(
                id_sv: $id_sv
                etat: $etat
              )
              {
                state
              }
            }`,
            variables: {
              id_sv: row.id_sv,
              etat: row.etat
            },
          }).then((data) => {
            this.$notify({
              type: "success",
              verticalAlign: "top",
              horizontalAlign: "right",
              message: "L'état a été bien modifié.",
              duration: 5000
            });
          }).catch((error) => {
            // Error
            console.error(error)
          })
        }
      })
    },
    rechercher() {
      this.SkipQuery = false
    },
    CustomSort_prcVALID(a, b) {
      return this.pourcentage(a.leads_valide, a.leads_net) - this.pourcentage(b.leads_valide, b.leads_net)
    },
    resetFilter(){
      this.AnnonceursSelects.Annonceurs = ""
      this.CampagnesSelects.Campagnes = ""
      this.ManagerSelects.Manager = ""
      this.TeamSelects.Team = ""
      this.search_type_programme = ""
    },
    formatThounsends: function(value, fixe = false, number = 0) {
      value != null ? (value = parseFloat(value)) : (value = 0);
      if (fixe) {
        value = value.toFixed(number);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
        if (index === 3) {
          sums[index] = this.formatThounsends(this.SUMClics)
          return
        }
        if (index === 4) {
          sums[index] = this.formatThounsends(this.leadsBrut)
          return
        }
        if (index === 5) {
          sums[index] = this.formatThounsends(this.leadsNet)
          return
        }
        if (index === 9) {
          sums[index] = this.formatThounsends(this.leadsValide)
          return
        }
        if (index === 10) {
          sums[index] = this.pourcentageValidesGlobal + ' %'
          return
        }
        else {
          sums[index] = '';
          return;
        }
      });
      return sums;
    },
    pourcentage(leads_valide, lead_brut){
      leads_valide = Number(leads_valide)
      lead_brut = Number(lead_brut)
      let pourcentage = 0

      if(leads_valide> 0 && lead_brut>0){
        pourcentage = (leads_valide / lead_brut) * 100
      }
      return pourcentage.toFixed(1)
    },
    cliquesCustomSort(a,b){
      const numA = Number(a.cliques);
      const numB = Number(b.cliques);
      return numA - numB;
    },
    leadsValideCustomSort(a,b){
      const numA = Number(a.leads_valide);
      const numB = Number(b.leads_valide);
      return numA - numB;
    }
  },
  computed: {

    filterRequest(){
      let list = this.AllRequests.filter(request => {
        console.log(request.societe_client);
        let societe_client = (request.societe_client) ? request.societe_client : ''
        let campagne = (request.campagne) ? request.campagne : ''
        let trafficManager = (request.trafficManager) ? request.trafficManager : ''
        let site = (request.site) ? request.site : ''
        let type_programme = (request.type_programme) ? request.type_programme : ''
        return societe_client.toUpperCase().replace(/[^a-z A-Z0-9]/g,'').match(this.AnnonceursSelects.Annonceurs.toUpperCase().replace(/[^a-z A-Z0-9]/g,'')) &&
               campagne.toUpperCase().match(this.CampagnesSelects.Campagnes.toUpperCase()) &&
               trafficManager.toUpperCase().match(this.ManagerSelects.Manager.toUpperCase()) &&
               site.toUpperCase().match(this.TeamSelects.Team.toUpperCase()) &&
               type_programme.toUpperCase().match(this.search_type_programme.toUpperCase())
      })

      let SUMClics = 0
      let leadsBrut = 0
      let leadsNet = 0
      let leadsValide = 0
      let pourcentageValidesGlobal = 0
      list.forEach(stats_valide => {
        SUMClics += Number(stats_valide.cliques)
        leadsBrut += Number(stats_valide.leads_brut)
        leadsNet += Number(stats_valide.leads_net)
        leadsValide += Number(stats_valide.leads_valide)
      });

      this.SUMClics = SUMClics
      this.leadsBrut = leadsBrut
      this.leadsNet = leadsNet
      this.leadsValide = leadsValide
      if(leadsValide>0 && leadsNet>0){
        this.pourcentageValidesGlobal = ((this.leadsValide / this.leadsNet) * 100).toFixed(1)
      }

      this.total = list.length
      return list.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    }
  },
  mounted(){
    this.listeMois.currentmois =  moment().subtract(1, 'months').format('MM')
    this.listeYears.currentyear =  moment().subtract(1, 'months').format('YYYY')
    this.listeYears.years = Array.from({ length: 10 }, (_, index) => ({
      value: (moment().year() - index).toString(),
      label: (moment().year() - index).toString(),
    }))
    this.SkipQuery = false
  }
};
</script>

<style>
  .uppercase-table-rows {
    text-transform: uppercase;
  }
</style>
